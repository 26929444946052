import './createFirstProfile.css';

//Components
import ProfileCreationAll from '../components/createYourProfileComponents/ProfileCreationAll.js';
import { Footer } from '../components/navbar&Footer/Footer.js';
import { Navbar } from '../components/navbar&Footer/Navbar';

const CreateFirstProfile = ({baseUrl}) => {


  return (
      <div className="CreateFirstProfile skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
         <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
                <div className="ProfileCreationAuthor " style={{flex: 1}}>
                  <ProfileCreationAll baseUrl={baseUrl}/>
                </div>
                <div className="mt-auto mb-20 ">
                    <Footer/>
              </div>
          </div>        
    </div>
  );
}

export default CreateFirstProfile; 