

export const exeedsMaxCharacterTextAreaBig = (input) => {
  // Trim leading and trailing whitespaces
  //const trimmedInput = input.trim(); 
  const sanitizedInputOne = input.replace(/[äöüÄÖÜ]/g, (match) => {
    switch (match) {
      case 'ä':
        return 'ae';
      case 'ö':
        return 'oe';
      case 'ü':
        return 'ue';
      case 'Ä':
        return 'Ae';
      case 'Ö':
        return 'Oe';
      case 'Ü':
        return 'Ue';
      default:
        return match;
    }
  });

 
  if (sanitizedInputOne.length > 3000) {
    const trimmedInput = sanitizedInputOne.trim();
    const truncatedValue = trimmedInput.slice(0,3000);
    return truncatedValue;
  } else {
    return sanitizedInputOne;
  }

};
