import './ProfileCreationAll.css';

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';


//Components
import { ProfileCreationAuthor } from './ProfileCreationAuthor'
import  ProfileCreationEditor from './ProfileCreationEditor'
import { ProfileCreationTestreader } from './ProfileCreationTestreader'
import { ProfileCreationPublisher } from './ProfileCreationPublisher'
import { ProfileCreationCoverdesigner } from './ProfileCreationCoverdesigner';

import { LogoutRedirectCheck } from '../../tools/LogoutRedirectCheck';


const ProfileCreationAll = ({baseUrl}) => {




const ProfileTypes = {
AUTHOR: 'author',
EDITOR: 'editor',
PUBLISHER: 'publisher',
TESTREADER: 'testreader',
COVERDESIGNER: 'coverdesigner',
};

const [profileType, setProfileType] = useState(null);
const [getProfileCreatedButNotUserYet, setProfileCreatedButNotUserYet] = useState(false);

useEffect(() => {
setProfileType(null);
}, []);

const setProfile = async (type) => {
setProfileType(type);
};

const backButton = async () => {
setProfileType(null);
};

const renderProfileComponent = () => {
switch (profileType) {
case ProfileTypes.AUTHOR:
 return <ProfileCreationAuthor baseUrl={baseUrl} setProfileCreatedButNotUserYet={setProfileCreatedButNotUserYet}/>;
// Add cases for other profile types and import their respective components
case ProfileTypes.EDITOR:
 return <ProfileCreationEditor  baseUrl={baseUrl} setProfileCreatedButNotUserYet={setProfileCreatedButNotUserYet}/>;
 break;
case ProfileTypes.PUBLISHER:
 return <ProfileCreationPublisher  baseUrl={baseUrl} setProfileCreatedButNotUserYet={setProfileCreatedButNotUserYet}/>;
 break;
case ProfileTypes.TESTREADER:
 return <ProfileCreationTestreader baseUrl={baseUrl} setProfileCreatedButNotUserYet={setProfileCreatedButNotUserYet} />;
 break;
case ProfileTypes.COVERDESIGNER:
 return <ProfileCreationCoverdesigner  baseUrl={baseUrl} setProfileCreatedButNotUserYet={setProfileCreatedButNotUserYet}/>;
 break;
default:
 return null;
}
};

///////////////////////////////// Logout redirect //////////////////////////7

const navigate = useNavigate();

useEffect(() => {
  LogoutRedirectCheck(navigate);
}, [navigate, profileType]);

///////////////////////////////// Logout redirect //////////////////////////7



return (
<div className=" ">

{!profileType && !getProfileCreatedButNotUserYet && (
<div className="ProfileCreationAll pb-8 flex justify-center items-center h-screen">
    
      <div className="max-w-2xl max-h-500 overflow-hidden">
        <div>
          <h2 className="mb-4">Erstelle ein Profil</h2>
        </div>
        <div className="grid grid-cols-2 gap-8">
          {Object.values(ProfileTypes).map((type, index, array) => (
            <div className={`col ${index === array.length - 1 ? 'col-span-2' : ''}`} key={type}>
              <div className="border p-4 text-black rounded-lg white-glassmorphism-loadingBox text-center">
                <h3
                  className={`px-4 py-2 rounded bg-${type === 'editor' ? 'blue' : 'green'} text-black cursor-pointer`}
                  onClick={() => setProfile(type)}
                >
                {type === 'author' && (<>Autor</>)}
                {type === 'editor' && (<>Lektor</>)}
                {type === 'testreader' && (<>Testleser</>)}
                {type === 'publisher' && (<>Verlag</>)}
                {type === 'coverdesigner' && (<>Coverdesigner</>)}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
   
  </div>
 )}

  <div className="ProfileCreationAll pb-8">
        <div className="">
        {profileType && (
          
          <div className="px-20">
            <> { !getProfileCreatedButNotUserYet && (
              <>
            <div><h2 className="mb-4">Erstelle ein Profil</h2></div>
            <h5>{profileType.charAt(0).toUpperCase() + profileType.slice(1)}</h5>
            <button
              className="px-4 py-2 bg-gray-600 text-white rounded"
              onClick={backButton}
            >
              Zurück
            </button>
            </>
          )}
          </>
            <div className="mb-36"> 
              <div className="">
                  {renderProfileComponent()}
              </div>
            </div>
          </div>
        )}

        <div className="mb-4"></div>
        </div>
      </div>
 </div>
);

}

export default ProfileCreationAll; 
export { default as ProfileCreationAll } from './ProfileCreationAll';