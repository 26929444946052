
import { useEffect, useState } from 'react';



const TrimTalkName = ({originalString}) => {

	const [talkNameTwo, setTalkNameTwo] = useState(null);
  
	useEffect(() => { 

	const talkName = ":" + localStorage.getItem('profileName') + ":";
	const trimmedString = originalString.replace(talkName, '').replace(/:$/, '').replace(/^:/, '');


	setTalkNameTwo(trimmedString);

	}, [originalString]);


	return (
		<div>
			<p>{talkNameTwo}</p>
		</div>);
  }

  export default TrimTalkName; 
  export { default as TrimTalkName } from './TrimTalkName.js';