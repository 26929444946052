
import './MyProfileViewBook.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';

import {TimestampFormatter} from '../../tools/TimestampFormatter.js';
import {TimestampFormatterLight} from '../../tools/TimestampFormatterLight.js';
import {ShortTimestampFormatter} from '../../tools/ShortTimestampFormatter.js';

//Security
import { SanitizeInput } from '../../tools/security/SanitizeInput';
import { sanitizeInputTextArea } from '../../tools/security/SanitizeInputTextArea';
import { sanitizeInputTextAreaBig } from '../../tools/security/SanitizeInputTextAreaBig';
import { exeedsMaxCharacter } from '../../tools/security/exeedsMaxCharacter';
import { exeedsMaxCharacterTextArea } from '../../tools/security/exeedsMaxCharacterTextArea';
import { exeedsMaxCharacterTextAreaBig } from '../../tools/security/exeedsMaxCharacterTextAreaBig';

import { ImCross } from "react-icons/im";
import { ImSpinner9 } from 'react-icons/im';

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import { FaPencilAlt } from "react-icons/fa";
import { FaPlusSquare } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

import ReactQuill from "react-quill";   
import 'react-quill/dist/quill.snow.css';

import he from 'he'; // libraries like he or sanitize-html-react to escape special characters.
import DOMPurify from 'dompurify';


  function IconWithTooltip2({ icon, tooltipText }) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
        className=""
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {React.cloneElement(icon, {
          //style: { fontSize: '' },
          title: isHovered ? tooltipText : '',
        })}
      </div>
    );
  }


 function DisplayHtml({ htmlContent }) {   
    //const escapedHtml = he.escape(htmlContent);  only html visible and not in formated way visible!
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    );
}  




const MyProfileViewBook = ({bookState, setBookState, baseUrl, baseUrl2}) => {


    ////////////////////////////////////////////////////////////////////////
    ////    Code for Profile Update    //////////////////
    /////////////////////////////////////////////////////////////////////

const [prevFileName, setPrevFileName,  ] = useState('');
const [getFileName, setFileName,  ] = useState('');
const [prevBookFileName, setPrevBookFileName,  ] = useState('');
const [getBookFileName, setBookFileName,  ] = useState('');
const [prevBookSampleFileName, setPrevBookSampleFileName,  ] = useState('');
const [getBookSampleFileName, setBookSampleFileName,  ] = useState('');

const [imagePreview, setImagePreview] = useState('');

const [render, setRender] = useState();   

const [imageSrc, setImageSrc] = useState(null);
const [newImageSrc, setNewImageSrc] = useState(null);

const [entityJacketText, setEntityJacketText] = useState('');
const [entityDescr, setEntityDescr] = useState('');
const [entityName, setEntityName] = useState('');
const [entityPages, setEntityPages] = useState('');
const [entitySubTitle, setEntitySubTitle] = useState('');
const [entityTable, setEntityTable] = useState('');
const [entityTargetReader, setEntityTargetReader] = useState('');
const [entitySingleOrTril, setEntitySingleOrTril] = useState('');

const [getFileSizeToBig, setFileSizeToBig] = useState(false);
const [getFileTypeWrong, setFileTypeWrong] = useState(false);

const [getCharacterCountJack, setCharacterCountJack] = useState(0);
const [getCharacterCountTable, setCharacterCountTable] = useState(0);
const [getCharacterCountDescr, setCharacterCountDescr] = useState(0);

const [showGenreChange, setGenreChange] = useState(false);
const [getTrueGenreData, setTrueGenreData] = useState([]);

const [getProfilIsProcessed, setProfilIsProcessed] = useState(false);
const [getNameAlreadyExists, setNameAlreadyExists] = useState(false);

const [getFileNameToLong, setFileNameToLong] = useState(false);

const [error, setError] = useState(null);


const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'], 
    ['clean']
],
      clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
  }
}


const handleQuillTableOfContentChange = useCallback((value) => {
    try {
      const characterCount = value.replace(/<[^>]*>/g, '').length; // Strip HTML tags and count characters
      setCharacterCountTable(characterCount);
      if(characterCount <= 500){ 
        setEntityTable(value);
      } 
      
    } catch (error) {
      handleError('Error handling quill exp change:', error);
    }
}, []);

const handleQuillJacketTextChange = useCallback((value) => {
    try {
      const characterCount = value.replace(/<[^>]*>/g, '').length; // Strip HTML tags and count characters
      setCharacterCountJack(characterCount);
      if(characterCount <= 2500){ 
        setEntityJacketText(value);
      } 
      
    } catch (error) {
      handleError('Error handling quill jacketText change:', error);
    }
}, []);

const handleQuillDescrChange = useCallback((value) => {
  try {
    const characterCount = value.replace(/<[^>]*>/g, '').length; // Strip HTML tags and count characters
    setCharacterCountDescr(characterCount);
    if(characterCount <= 3000){ 
      setEntityDescr(value);
    } 
    
  } catch (error) {
    handleError('Error handling quill descr change:', error);
  }
}, []);

const handleTitleChange = (e) => {
  try {
    const inputValue = exeedsMaxCharacter(e.target.value);
    let cleanValue = inputValue.replace(/[^\w\s_]/g, '');
    cleanValue = cleanValue.replace(/^_+|_+$/g, '');

    setEntityName(cleanValue);
  } catch (error) {
    handleError('Error handling name change:', error);
  }
};

const handlePagesChange = (e) => {
  try {
    const value = e.target.value
    const regex = /^\d*$/; 

    if (regex.test(value) && (value === '' || parseInt(value) <= 10000)) {
      const inputValue = exeedsMaxCharacter(value);
      setEntityPages(inputValue);
    }
  } catch (error) {
    handleError('Error handling age change:', error);
  }
};

const handleSubTitleChange = (e) => {
  try {
    const inputValue = exeedsMaxCharacter(e.target.value);
    setEntitySubTitle(inputValue);
  } catch (error) {
    handleError('Error handling website change:', error);
  }
};

const handleTargetReaderChange = (e) => {
  try {
    const inputValue = exeedsMaxCharacterTextAreaBig(e.target.value);
    setEntityTargetReader(inputValue);
  } catch (error) {
    handleError('Error handling name change:', error);
  }
};

const handleSingleOrTrilChange = (e) => {
  try {
    const inputValue = exeedsMaxCharacterTextAreaBig(e.target.value);
    setEntitySingleOrTril(inputValue);
  } catch (error) {
    handleError('Error handling name change:', error);
  }
};
      


  const entityHandleSubmitOne = async (e) => {
    e.preventDefault();

    setProfilIsProcessed(true);
    setNameAlreadyExists(false);   

    const entityNameVar = SanitizeInput(entityName.trim());
    const pagesVar = SanitizeInput(entityPages);
    const descriptionVar = DOMPurify.sanitize(entityDescr);
    const entitySubTitleVar = SanitizeInput(entitySubTitle);
    const jacketTextVar = DOMPurify.sanitize(entityJacketText);
    const tableOfContentVar = DOMPurify.sanitize(entityTable);
    const targetReaderVar = sanitizeInputTextArea(entityTargetReader);
    const singleOrTrilVar = sanitizeInputTextArea(entitySingleOrTril);

    const imgNameVar = SanitizeInput(getFileName);     
  
    const addNewEntityData = {
          title: entityNameVar,
          subtitle: entitySubTitleVar,
          pages: pagesVar,
          targetReader: targetReaderVar,
          singleOrTril: singleOrTrilVar,
          jacketText: jacketTextVar,
          tableOfContent: tableOfContentVar,
          description: descriptionVar,
          ...(imgNameVar !== '' && { coverImgName: imgNameVar }),
    };

    //console.log('addNewEntityData: ', addNewEntityData);
  
    try {

      if(entityNameVar !== ''){
        const responseExists = await api.get(`/api/userProfile/${entityNameVar}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });
        
        if(responseExists !== '' && responseExists !== null && responseExists !== undefined){
          setProfilIsProcessed(false);
          setNameAlreadyExists(true);
        }else{
  
          entityHandleSubmitFurther(entityNameVar, imgNameVar, addNewEntityData);

        }
      }else{
  
          entityHandleSubmitFurther(entityNameVar, imgNameVar, addNewEntityData);

        }
  

    } catch (error) {
      handleError(error);
    }
  };

  const entityHandleSubmitFurther = async (entityNameVar,imgNameVar, addNewEntityData ) => {   

    setShowUpdateProfile(false);

    const addNewRatingData = {
      changedName: entityNameVar,
      oldName: getBookName     
    };
   
    const response = await api.put(`/api/bookProjects/update/${getBookName}`,  addNewEntityData, { // Use 'data' key to pass request body
      headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
    });



    if(imgNameVar !== null && imgNameVar !== '' && imgNameVar !== getCoverImg && getCoverImg !== ''
       && imgNameVar !== 'Standard_Profile_Pic_1.png' && imgNameVar !== 'Standard_Cover_Pic_1.png'
     ){
      const responseImg = await api.delete(`/api/files/delete/${getCoverImg}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }, 
      });  

      setCoverImg(imgNameVar);
      
      setTimeout(() => {
        fetchImage();
      }, 1000);
    }

    if(entityNameVar !== null && entityNameVar !== ''){
      setBookName(entityNameVar);
      setProfilIsProcessed(false);
      navigate(`/myProfile?bookName=${entityNameVar}&bookType=Book`);
      window.location.reload();
    }
  
    if (response && response.data) {
      if (response.data.entityName === entityNameVar) {
        const response2 = await api.put(`/api/ratings/update/ratings/changedEntityName`, addNewRatingData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });

        if (!response2.data) {
          handleError("Update Ratings request failed");
          console.log(response2.data);
        }

      } else {
        handleError("Update entity request sucessfull until update ratings and ...");
      }
    }

    setTimeout(() => {
      fetchData();
    }, 2000);

    if (response && response.data) {

      const addNewGenreData = {
        ...checkedGenreOptions // Include all genre options
      };

      const profileType = "Book";

        const response5 = await api.put(`/api/genres/update/${getBookName}&${profileType}`, addNewGenreData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });
        if(response5.data != null){

        }else {
        handleError("Update entity request sucessfull until update Genres ...");
      }
      }

      setProfilIsProcessed(false);
      window.location.reload();

   };



  const rerender = () => {
    setRender(true);
  };

  const handleImgFileChange = (e) => {
    try {
      setPrevFileName(SanitizeInput(getFileName));
      const filename = e.target.files[0].name;
      console.log('1.FilenameByChange: ', filename);
      console.log('2.getFileName: ', getFileName);

      setFileName(SanitizeInput(filename)); 
      console.log('3.getFileName: ', getFileName);

      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);

      setRender(true);
    } catch (error) {
      handleError('Error handling file change:', error);
    }
    
    setRender(true);
  };
  
  const handleImgFileUpload = async (e) => {

    

    setFileSizeToBig(false);
    setFileWithSameNameExists(false);
    setFileTypeWrong(false);

    e.preventDefault();

    try {
     

      const fileInput = e.target.querySelector('input[type="file"]');
      const file = fileInput.files[0];
  
      if (!file) {
        handleError('No file selected');
        return;
      }
  
      const allowedFileTypes = ['image/jpeg', 'image/png'];
      if (!allowedFileTypes.includes(file.type)) {
        setFileTypeWrong(true);
        handleError('Invalid file type');
        return;
      }
  
      const maxSize = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSize) {
        setFileSizeToBig(true);
        handleError('File size exceeds the limit of 5 MB');
        return;
      }
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uploadName', localStorage.getItem('profileName'));
      formData.append('belongName', userProfileName);
  
      const response = await api.post('/api/files/uploadCoverImg', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if(response.data === "File with same name already exists."){
        setFileWithSameNameExists(true);
        handleError('Same file Name');
        return;
      }
  
    setTimeout(() => {
      // Update the state to trigger a re-render
      fetchImage();
    }, 1000); // Delay in milliseconds (adjust as needed)

    console.log('4.prevFileName: ', prevFileName);
    console.log('4.getCoverImg: ', getCoverImg);
    if(prevFileName !== null && prevFileName !== '' && prevFileName !== getCoverImg 
       && prevFileName !== 'Standard_Profile_Pic_1.png' && prevFileName !== 'Standard_Cover_Pic_1.png'
    ){
      console.log('4.prevFileName: ', prevFileName);
        const response2 = await api.delete(`/api/files/delete/${prevFileName}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
     }

     rerender();

    } catch (error) {
      handleError('Error uploading file:', error);
    }
    setRender(true);
  };
  
  const fetchImage = async () => {
    try {
      const newImageSrc1 = await api.get(`/api/files/image/${getFileName}`);
      setNewImageSrc(`${baseUrl}/api/files/image/${getFileName}`);
    } catch (error) {
      handleError('Error fetching image:', error);
    }
  };




  
  useEffect(() => {
    if (render) {
      // Fetch and update the image source after a successful upload
      fetchImage();
      
      setRender(false);
      setFileTypeWrong(false);
      setFileSizeToBig(false);
      setFileWithSameNameExists(false)
      setFileNameToLong(false);
    }
  }, [render]);
  
  ///////////////////////////////////////////////////////////////////////
  const [updateProfile, setShowUpdateProfile] = useState(false);
  
  const changeInfo = () => {
    setShowUpdateProfile(true); 
  };
  
  const backButton = () => {
    setShowUpdateProfile(false); 
  };


////////////////////////////////////////////////////////////////////////
    ////    Code for show Profile ...    //////////////////
    /////////////////////////////////////////////////////////////////////


    const [getSingleEntityData,setSingleEntityData] = useState([]);                                                                        
    const [getSingleEntityRating,setSingleEntityRating] = useState([]); 

    const [getBookName, setBookName] = useState();
    const [getUserProfileType, setUserProfileType] = useState();
    const [showAreYouSure, setShowAreYouSure] = useState(false);
    const [ratingScoreAverage, setRatingScoreAverage] = useState();
   
    const [getUpDownRightHrefClick, setUpDownRightHrefClick] = useState(false); 
    const [getUpDownRightDeleteClick, setUpDownRightDeleteClick] = useState(false); 

    const [getCoverImg, setCoverImg] = useState('');
    
    const [expandedTexts, setExpandedTexts] = useState({});
    const [expandedColumn, setExpandedColumn] = useState('Editor');
    const [expandedColumn2, setExpandedColumn2] = useState('Author');

    const [getRightsData, setRightsData] = useState([]);
    const [showShareLink, setShowShareLink] = useState(false);
    
    const [getBookUploadSuccess, setBookUploadSuccess] = useState(false);
    const [getBookSampleUploadSuccess, setBookSampleUploadSuccess] = useState(false);
    const [getUploadFile, setUploadFile] = useState(false);

    const [getAllUserProfileData, setAllUserProfileData] = useState([]);

    const [getFileWithSameNameExists, setFileWithSameNameExists] = useState(false);
    

    const userProfileName = getBookName;

    const location = useLocation(); 

    useEffect(() => {
        try {
            const bookName = new URLSearchParams(location.search).get('bookName');
            const bookType = new URLSearchParams(location.search).get('bookType');
    
            if (bookName) {
                //console.log('bookName:', bookName);
                setBookName(bookName);
            } else {
                console.log('bookName not found in the URL');
            }
    
            if (bookType) {
               // console.log('bookType:', bookType);
                setUserProfileType(bookType);
            } else {
                console.log('bookType not found in the URL');
            }
        } catch (error) {
            handleError('Error extracting parameters from URL:', error);
        }
    }, [location.search]); 


    const toggleCollapse = (column) => {   
        setExpandedColumn(column);    
    };

    const toggleCollapse2 = (column) => {
        setExpandedColumn2(column);
    };
    
   
    
    const getSingleEntity = async () => {
      const addNewEntityData = {
        entityName: getBookName,
      };

      //console.log(localStorage.getItem('profileName'));
  
      try {
          const response = await api.get(`/api/bookProjects/${addNewEntityData.entityName}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
  
          if(response.data != null){
          setSingleEntityData(response.data);

          const singleEntity = response.data;
          showSingleRatings(singleEntity.title);
          setCoverImg(singleEntity.coverImgName);

	        setBookState(false);

          const filteredGenres = Object.keys(singleEntity.genresIds[0])
          .filter(key => key !== '_id' && singleEntity.genresIds[0][key] === true);
          //console.log('trueGenres: ', filteredGenres);

             const groupedData = filteredGenres.reduce((acc, item, index) => {
      const chunkIndex = Math.floor(index / 2);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = []; // start a new chunk
      }
      acc[chunkIndex].push(item);
      return acc;
    }, []);

    setTrueGenreData(groupedData);

    // Map genres retrieved from the database and update checkedGenreOptions
    const genresFromDB = singleEntity.genresIds[0];
    const updatedGenres = Object.keys(checkedGenreOptions).reduce((acc, genre) => {
      acc[genre] = genresFromDB[genre] || false;  // Use saved value or default to false
      return acc;
    }, {});

    setCheckedGenreOptions(updatedGenres);

          }else{
            setRender(true);
          }
      } catch (error) {
          handleError('Error fetching single entity:', error);
      }
  };  


    const getEntityRights = async () => {
        try {
            const addNewEntityData = {
              entityName: getBookName,
            };
    
            const response = await api.get(`/api/userProfile/${addNewEntityData.entityName}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });
    
            if(response.data != null){
            	setRightsData(response.data.allRights);
	    }
          
        } catch (error) {
            handleError('Error fetching single Entity data:', error);
        }
    };

    const getAllUserProfiles = async () => {
      try {
  
          const response = await api.get(`/api/userProfile`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });
  
          if(response.data != null){
            setAllUserProfileData(response.data);
    }
        
      } catch (error) {
          handleError('Error fetching single Entity data:', error);
      }
  };
  
  const areYouSure = () => {
      setShowAreYouSure(!showAreYouSure);
  };
  

  const navigate = useNavigate();


const handleEntityDelete = async () => {
      try { 

           const addNewEntityData = {
            entityName: getBookName,
          };

          const booleanResult = await api.delete(`/api/bookProjects/delete/${addNewEntityData.entityName}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
	  setShowAreYouSure(false);

    console.log('booleanResult: ', booleanResult.data);

	if(booleanResult.data === true){

           	const addNewUserProfileData = {
            		userProfileName: getBookName,
			          userNameEmail: localStorage.getItem('userEmail'),
          	};

            console.log('booleanResult_: ', booleanResult.data);

          	const booleanResult2 = await api.delete(`/api/userProfile/delete`, {
              data: addNewUserProfileData, // Pass data explicitly in the 'data' field
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
            });

            console.log('booleanResult2: ', booleanResult2.data);
		
            if(booleanResult.data === true){
                 navigate("/myProfile");
                 window.location.reload();
            }
	}  

     

      } catch (error) {
          handleError('Error deleting entity:', error);
      }
  };
  
  
  const showSingleRatings = async (entityName) => {
      try {
          const response = await api.get(`/api/ratings/createdFor/${entityName}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
  
          setSingleEntityRating(response.data);
  
          const sumOfRatingScores = response.data.reduce((sum, item) => sum + item.ratingScore, 0);
          const averageRatingScore = sumOfRatingScores / response.data.length;
          const roundedAverageRatingScore = averageRatingScore.toFixed(1);
  
          if (response.data.length > 0) {
              setRatingScoreAverage(parseFloat(roundedAverageRatingScore));
          } else {
              setRatingScoreAverage("");
          }
      } catch (error) {
          handleError('Error fetching single ratings:', error);
      }
  };
  


  const changeUpDownRight = async (rightsDeleteName, whichRights ) => {
    try {
        setUpDownRightDeleteClick((prevUpDownRightDeleteClick) => !prevUpDownRightDeleteClick);


        const addNewUDRData = {
            rightsOwnerName: getBookName,
            rightsGiveToName: rightsDeleteName,
	          whichRights: whichRights,
        };

        const response = await api.post(`/api/rights/update`, addNewUDRData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });

        console.log('UpDownRightName:', response.data);
    } catch (error) {
        handleError('Error changing UpDownRight:', error);
    }
};





const changeOtherProfileName = (otherProfileName, otherProfileType) => {
    localStorage.setItem('siteProfile', otherProfileName);
    localStorage.setItem('otherProfileType', otherProfileType);
    setUpDownRightHrefClick(true);
};


const updateUserProfileTypeAndName = () => {
    try {
      const bookType = new URLSearchParams(location.search).get('bookType');
      const bookName = new URLSearchParams(location.search).get('bookName');

      if (bookName) {
          //console.log('bookName:', bookName);
          setBookName(bookName);
      } 
           
      if (bookType) {
                //console.log('bookType:', bookType);
                setUserProfileType(bookType);
            } else {
              const storedUserProfileType = localStorage.getItem('currentProfileType');
              setUserProfileType(storedUserProfileType);
            }  
    } catch (error) {
        handleError('Error updating user profile type:', error);
    }
};


          const toggleTextExpansion = (createdByProfileName) => {
            setExpandedTexts((prevExpandedTexts) => ({
                ...prevExpandedTexts,
                [createdByProfileName]: !prevExpandedTexts[createdByProfileName],
            }));
        };


        const downloadFile = (filename) => {
          const getMimeType = (filename) => {
            const extension = filename.split('.').pop();
            switch (extension) {
              case 'pdf':
                return 'application/pdf';
              case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
              case 'doc':
                return 'application/msword';
              case 'txt':
                return 'text/plain';
              case 'rtf':
                return 'application/rtf';
              case 'md':
                return 'text/markdown';
              default:
                return 'application/octet-stream';
            }
          };
                 
        
          fetch(`${baseUrl}/api/files/download/${filename}`, {
            method: 'GET',
            headers: {
              'Content-Type': getMimeType(filename),
            },
          })
            .then((response) => {
              if (response.ok) {
                return response.blob();
              }
              throw new Error('File download failed');
            })
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.error('Error:', error));
        };
    
        const handleBookFileChange = (e) => {
          try {
            setPrevBookFileName(getBookFileName);
            const filename = e.target.files[0].name;
            setBookFileName(filename);     
            setBookUploadSuccess(false);
           /* const reader = new FileReader();
            reader.onload = (e) => {
              setImagePreview(e.target.result);
            };
            reader.readAsDataURL(e.target.files[0]); */
      
            //setRenderBookFile(!renderBookFile);
          } catch (error) {
            handleError('Error handling file change:', error);
          }
        };

        const handleBookSampleFileChange = (e) => {
          try {
            setPrevBookSampleFileName(getBookSampleFileName);
            const filename = e.target.files[0].name;
            setBookSampleFileName(filename);     
            setBookSampleUploadSuccess(false);
          } catch (error) {
            handleError('Error handling file change:', error);
          }
        };

        const handleReadingSampleFileUpload = async (e) => {
          setFileWithSameNameExists(false);
          setFileTypeWrong(false);
          setFileSizeToBig(false);
          setFileNameToLong(false);

          try {
            e.preventDefault();
            const fileInput = e.target.querySelector('input[type="file"]');
            const file = fileInput.files[0];
        
            if (!file) {
              handleError('No file selected');
              return;
            }
        
            const allowedFileTypes = ['application/pdf',
            'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            if (!allowedFileTypes.includes(file.type)) {
              setFileTypeWrong(true);
              handleError('Invalid file type');
              return;
            }
        
            const maxSize = 5 * 1024 * 1024; // 5 MB
            if (file.size > maxSize) {
              setFileSizeToBig(true);
              handleError('File size exceeds the limit of 5 MB');
              return;
            }

                    // Check the filename length
        if (file.name.length >= 45) {
          handleError('File name must be less than 45 characters');
          setFileNameToLong(true);
          return;
        }
        
            const formData = new FormData();
            formData.append('file', file);
            formData.append('uploadName', 'ReadingSample');
            formData.append('belongName', userProfileName);
        
            const response = await api.post('/api/files/uploadBook', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            if(response.data === "File with same name already exists."){
              setFileWithSameNameExists(true);
              handleError('Same file Name');
              return;
            }
        
          setTimeout(() => {
            // Update the state to trigger a re-render
            fetchBookSampleFileFunc();
          }, 1000); // Delay in milliseconds (adjust as needed)
      
          if(prevBookSampleFileName !== null && prevBookSampleFileName !== '' && !prevBookSampleFileName.isEmpty){
              const response2 = await api.delete(`/api/files/delete/${prevBookSampleFileName}`, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
           }
    
          } catch (error) {
            handleError('Error uploading file:', error);
          }
        };
    
        const handleBookFileUpload = async (e) => {
          setFileWithSameNameExists(false);
          setFileTypeWrong(false);
          setFileSizeToBig(false);
          setFileNameToLong(false);

          try {
            e.preventDefault();
            const fileInput = e.target.querySelector('input[type="file"]');
            const file = fileInput.files[0];
        
            if (!file) {
              handleError('No file selected');
              return;
            }
        
            const allowedFileTypes = ['application/pdf',
            'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            if (!allowedFileTypes.includes(file.type)) {
              setFileTypeWrong(true);
              handleError('Invalid file type');
              return;
            }
        
            const maxSize = 5 * 1024 * 1024; // 5 MB
            if (file.size > maxSize) {
              setFileSizeToBig(true);
              handleError('File size exceeds the limit of 5 MB');
              return;
            }

                    // Check the filename length
        if (file.name.length >= 45) {
          handleError('File name must be less than 45 characters');
          setFileNameToLong(true);
          return;
        }
        
            const formData = new FormData();
            formData.append('file', file);
            formData.append('uploadName', 'FullBook');
            formData.append('belongName', userProfileName);
        
            const response = await api.post('/api/files/uploadBook', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            //console.log('responseFile: ', response.data);

            if(response.data === "File with same name already exists."){
              setFileWithSameNameExists(true);
              handleError('Same file Name');
              return;
            }
        
          setTimeout(() => {
            // Update the state to trigger a re-render
            fetchBookFileFunc();
          }, 1000); // Delay in milliseconds (adjust as needed)
      
          if(prevBookFileName !== null && prevBookFileName !== '' && !prevBookFileName.isEmpty){
              const response2 = await api.delete(`/api/files/delete/${prevBookFileName}`, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
           }
    
          } catch (error) {
            handleError('Error uploading file:', error);
          }
        };
    
        const fetchBookFileFunc = async () => {
          try {
            const newBookFileData = await api.get(`/api/files/${getBookFileName}`);
            console.log('newBookFileData: ', newBookFileData);
            console.log('newBookFileData.fileName: ', newBookFileData.fileName);
            if(newBookFileData !== null && !newBookFileData.isEmpty){
                setBookUploadSuccess(true);
                console.log('newBookFileData.fileName: ', newBookFileData.data.fileName);
            }
          } catch (error) {
            handleError('Error fetching image:', error);
          }
        };

        const fetchBookSampleFileFunc = async () => {
          try {
            const newBookFileData = await api.get(`/api/files/${getBookSampleFileName}`);
            console.log('newBookSampleFileData: ', newBookFileData);
            console.log('newBookSampleFileData.fileName: ', newBookFileData.fileName);
            if(newBookFileData !== null && !newBookFileData.isEmpty){
                setBookSampleUploadSuccess(true);
                console.log('newBookSampleFileData.fileName: ', newBookFileData.data.fileName);
            }
          } catch (error) {
            handleError('Error fetching image:', error);
          }
        };

        const fetchData = async () => {
          try {
               getSingleEntity();
              //console.log('Book:');
          } catch (error) {
              handleError('Error fetching data:', error);
          }
      };


      const [checkedGenreOptions, setCheckedGenreOptions] = useState({
        fiction: false, nonFiction: false, mystery: false, thriller: false, romance: false,
        fantasy: false, scienceFiction: false, horror: false, historical: false, youngAdult: false, newAdult: false,
         children: false, adventure: false, biography: false, selfHelp: false, poetry: false, drama: false,
        crime: false, graphicNovels: false, shortStories: false, dystopian: false, paranormal: false, memoir: false,
         cookbooks: false, spirituality: false, travel: false, science: false, history: false
      });
      
      const handleGenreCheckboxChange = (option) => {
        setCheckedGenreOptions((prevOptions) => ({
          ...prevOptions,
          [option]: !prevOptions[option],
        }));
      };

      
        useEffect(() => {

            setImageSrc(`${baseUrl}/api/files/image/`);
            setShowAreYouSure(false);
            fetchData();
            getAllUserProfiles()
	          getEntityRights();
            updateUserProfileTypeAndName();
            setExpandedColumn('Editor');
            setExpandedColumn2('Author');
            localStorage.setItem('logoutTrue', false);
            
        }, []);
        
        useEffect(() => {
            const delayTimeout = setTimeout(() => {
                fetchData();
                getEntityRights();
            }, 500);
        
            return () => clearTimeout(delayTimeout);
        }, [getUpDownRightDeleteClick, render]);



      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };


    ///////////////////////////////// Logout redirect //////////////////////////7

    const navigate2 = useNavigate();

    useEffect(() => {
      LogoutRedirectCheck(navigate2);
      setUpDownRightHrefClick(false);
    }, [updateProfile, showAreYouSure, getUpDownRightHrefClick, getUpDownRightDeleteClick, showShareLink,
      getUploadFile, getBookUploadSuccess ]);
    
    ///////////////////////////////// Logout redirect //////////////////////////7



  return (
      <div className="MyProfileViewBook">

        <div className="container" >

        { getProfilIsProcessed && !getNameAlreadyExists &&  (

          <div className='blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
          <div className='loadingBox white-glassmorphism-loadingBox text-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' style={{ minWidth: '250px', maxWidth: '350px', minHeight:'100px' }}>
              <div className="flex justify-content-center align-items-center w-full h-full">
              <div className='mt-10 ml-60 -pl-10'>
                  <ImSpinner9
                      className="spinning-image"
                      size={50}
                      color="#a7b7c8"
                  />
            </div>   
                    </div>
                    <div>
                      <h3>Deine Buchdaten werden verarbeitet.</h3>
                    </div>
                  </div>
            </div>    

          )}


        {updateProfile && !getProfilIsProcessed && (<div><button className='buttonTwo mt-5 btnChange p-2' onClick={backButton}>zurück</button></div>)}

        {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}

        {getNameAlreadyExists && updateProfile && (<div><p className="mt-5" style={{ color: 'red' }}>Ein Buch mit dem selben Namen existiert bereits!</p></div>)}


        {updateProfile && !getProfilIsProcessed && (

          <><div className='max-sm:w-[90vw] max-md:w-[90vw]'>

          <form onSubmit={entityHandleSubmitOne} method="POST">
            
            <div className="row  profileDataCard2  paddingOne">
              <div className="row max-md:flex titleRowBackgroundcolor white-glassmorphism-card ">
                <div className="col-sm-8"><h2 className="FormStyling text-left titleTextColor">Stammdaten</h2></div>
                <div className="col-sm-4 max-sm:mb-2 max-sm:-ml-5">
                <div className="mx-2 FormStyling text-right text-2xl"><IconWithTooltip2 icon={<button className="buttonTwo btnChange p-2 flex"><FaPencilAlt className='pr-1 mt-1'/>Profil updaten</button>} tooltipText="Bestätige deine Änderungen und update deine Profil-Infos." />
                </div> 
              </div>  
              </div>
              <hr className='horizontalLine' />
              <div className="row mainRowBackgroundcolor white-glassmorphism-card pt-3">
                <div className="col col-sm-2 max-sm:col-sm-3 text-left" style={{ minWidth: '100px', minHeight: '180px' }}>
                  <div>
                    <h3 className="FormStyling">Titel</h3>
                    <input className="FormStyling FormStyling2 w-full" placeholder={getSingleEntityData.title} name="entityName" onChange={handleTitleChange} value={entityName} />
                    <br></br>
                    <h3 className="FormStyling">Normseiten</h3>
                    <input className="FormStyling FormStyling2 w-full" placeholder={getSingleEntityData.pages} name="entityPages" onChange={handlePagesChange} value={entityPages} />
                  </div>
                </div>
                <div className="col col-sm-3 text-left" style={{ minWidth: '100px', minHeight: '180px' }}>
                  <div>
                    <h3 className="FormStyling">Untertitel</h3>
                    <input className="FormStyling FormStyling2 w-full" placeholder={getSingleEntityData.subtitle}  name="entitySubTitle" onChange={handleSubTitleChange} value={entitySubTitle} />
                    <br></br>
                    <h3 className="FormStyling">Genre</h3>
                    <div className='buttonTwo btnChange text-center mx-4 mt-5 cursor-pointer' onClick={()=> setGenreChange(!showGenreChange)}>
                            Genres Bearbeiten
                          </div>
                    </div>
                </div>
                <div className="col col-sm-7 text-left" style={{ minWidth: '100px', minHeight: '180px' }}>
                          <div>
                            <h3 className="FormStyling">Lesezielgruppe</h3>
                            <input className="FormStyling FormStyling2 w-full" placeholder={getSingleEntityData.targetReader} name="entityTargetReader" onChange={handleTargetReaderChange} value={entityTargetReader} />
                            <h3 className="FormStyling">Einzelband / Teil einer Reihe</h3>
                            <input className="FormStyling FormStyling2 w-full" placeholder={getSingleEntityData.singleOrTril} name="entitySingleOrTril" onChange={handleSingleOrTrilChange} value={entitySingleOrTril} />                    
                        </div>
                </div>
                <div className="col col-sm-12 max-sm:col-sm-12 text-left">
                          <div className="row textAreaOne">
                            <h3 className="FormStyling">Klappentext</h3>
                            <div className="row textAreaOne pt-5" style={{ width: '100%' }}>
                            <ReactQuill
                                theme='snow'
                                formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link']}
                                placeholder="Deine Klappentext..."
                                modules={modules}
                                onChange={handleQuillJacketTextChange}
                                value={entityJacketText}
                            />
                                    <div className='pt-6 pb-3'
                                      style={{
                                        position: 'relative',
                                        top: '0px',
                                        right: '-10px', // Adjust the distance from the right as needed
                                        color: 'gray',
                                      }}
                                      >
                                  {getCharacterCountJack} / 2500
                                </div>  

			                      {/*}	<div><DisplayHtml htmlContent={contentExpClean} /></div> */}

                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="row mainRowBackgroundcolor white-glassmorphism-card pt-3">
                        { showGenreChange && (
                          <div className="flex flex-wrap gap-4">
                              {Object.entries(checkedGenreOptions).map(([genre, isChecked]) => (
                                <div key={genre} className="flex items-center mb-4 mx-3 text-3xl text-[#8b90a5] hover:text-[#babbcf]">
                                  <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => handleGenreCheckboxChange(genre)}
                                    id={genre}
                                    className="cursor-pointer rounded items-center  mb-6 border-gray-300"
                                  />
                                  <label htmlFor={genre} className="capitalize mt-3 ml-2">{genre}</label>
                                </div>
                              ))}
                            </div>
                            )}
                        </div>
                    </div>

                    <div className="row profileDataCard2  paddingOne">
                      <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                        <div className='col col-sm-4'><h2 className="FormStyling text-left titleTextColor">Inhaltsverzeichnis</h2></div>
                      </div>
                      <hr className='horizontalLine' />
                      <div className="row mainRowBackgroundcolor">
                        <div className="col col-sm-12 text-left mainRowBackgroundcolor white-glassmorphism-card ">
                          <div className="row textAreaOne pt-5" style={{ width: '100%' }}>

                          <ReactQuill
                                theme='snow'
                                formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link']}
                                placeholder="Deine Inhalt..."
                                modules={modules}
                                onChange={handleQuillTableOfContentChange}
                                value={entityTable}
                            />
                                    <div className='pt-6 pb-3'
                                      style={{
                                        position: 'relative',
                                        top: '0px',
                                        right: '-10px', // Adjust the distance from the right as needed
                                        color: 'gray',
                                      }}
                                      >
                                  {getCharacterCountTable} / 500
                                </div>  

			                      {/*}	<div><DisplayHtml htmlContent={contentExpClean} /></div> */}

                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="row profileDataCard2  paddingOne">
                      <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                        <div className='col col-sm-6'><h2 className="FormStyling text-left titleTextColor">Genauere Beschreibung</h2></div>
                      </div>
                      <hr className='horizontalLine' />
                      <div className="row mainRowBackgroundcolor">
                        <div className="col col-sm-12 text-left mainRowBackgroundcolor white-glassmorphism-card ">
                          <div className="row textAreaOne pt-5" style={{ width: '100%' }}>

                                <ReactQuill
                                    theme='snow'
                                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']}
                                    placeholder="Die genauere Beschreibung..."
                                    modules={modules}
                                    onChange={handleQuillDescrChange}
                                    value={entityDescr}
                                />
                                    <div className='pt-6 pb-3'
                                      style={{
                                        position: 'relative',
                                        top: '0px',
                                        right: '-10px', // Adjust the distance from the right as needed
                                        color: 'gray',
                                      }}
                                      >
                                      {getCharacterCountDescr} / 3000
                                    </div>  

                                {/*}	<div><DisplayHtml htmlContent={contentExpClean} /></div> */}

                                </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="row profileDataCard2  paddingOne">
                    <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                      <div className='col col-sm-6'><h2 className="FormStyling text-left titleTextColor">Coverdesign & Coverbild</h2></div>
                    </div>
                    <hr className='horizontalLine' />
                    <div className="row mainRowBackgroundcolor text-center justify-center items-center">
                      <div className="col col-sm-12 mainRowBackgroundcolor white-glassmorphism-card ">
                      {getFileSizeToBig && (<div><p style={{ color: 'red' }}>Deine Datei  überschreitet 5 MB an Größe!</p></div>)}
                      {getFileTypeWrong && (<div><p style={{ color: 'red' }}>Deine Datei muss vom Typ PNG oder JPEG sein!</p></div>)}
                      {getFileWithSameNameExists && (<div><p style={{ color: 'red' }}>Eine Datei mit dem selben Name existiert bereits!</p></div>)}
                      {getFileNameToLong && (<div><p style={{ color: 'red' }}>Der Dateiname ist zu lang. Bitte kürze diesen!</p></div>)}


                        <div className="col col-sm-4 flex text-center justify-center items-center">
                          <div className="">
                            <div>
                              <div className="text-left ml-2.5 my-10 max-sm:my-0" style={{ minWidth: '100px', minHeight: '240px' }}>
                                <div><h3 className='text-center'>Vorschau</h3></div>
                                <div className="" style={{ minWidth: '100px', minHeight: '240px' }}>
                                  <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }}>
                                    <div>
                                      {imagePreview && (
                                        <img src={imagePreview} alt="Preview" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }} />
                                      )}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col col-sm-4 text-center justify-center mt-48 max-md:mt-12">
                          <div className="textAreaOne ">
                            <form className='' onSubmit={handleImgFileUpload} method="POST" encType="multipart/form-data">
                              <input className='w-full text-center justify-center items-center' type="file" onChange={handleImgFileChange} />
                              <br></br>
                              <button className="buttonOne px-4 py-2 bg-gray-600 text-white rounded mr-2">hochladen</button>
                            </form>
                          </div>
                        </div>
                        <div className="col col-sm-4 flex text-center justify-center items-center">
                          <div className="">
                            <div>
                              <div className="my-10" style={{ minWidth: '100px', minHeight: '240px' }}>
                                <div><h3 className='text-center'>Hochgeladen</h3></div>
                                <div className=" " style={{ minWidth: '100px', minHeight: '240px' }}>
                                  <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }}>
                                    <div>
                                      {imageSrc && (
                                         <img className="" src={`${newImageSrc === null ? imageSrc + getSingleEntityData.coverImgName : newImageSrc}`}  alt="Uploaded" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }} />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>        
          </>      

          )}
          

        {getUserProfileType === "Book" && !updateProfile && !getProfilIsProcessed && (
          <div className=''>
          <>
            <div className="mb-10">
              {showAreYouSure && (
                <div className='profileDataCard2 bg-rose-700'>
                  <div className="row text-center justify-content-center ">
                    <div className="col col-sm-12"><h3>Bist du dir sicher, dass du dein Profil löschen willst?</h3></div>
                  </div>
                  <div className="row text-center justify-content-center marginOne">
                    <div className='col-sm-12 flex'>
                      <div className="col col-sm-6 w-full "><div className='text-right'><button className="buttonTwo btnChange p-2" onClick={handleEntityDelete}>Löschen</button></div></div>
                      <div className="col col-sm-6 w-full"><div className='text-left '><button className="buttonTwo btnChange p-2" onClick={areYouSure}>Abbrechen</button></div></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
              {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}
              
              <div className="row paddingOne">
            

                {getSingleEntityData && (

                <div className="row profileDataCard2  paddingOne">
                  <div className="row titleRowBackgroundcolor white-glassmorphism-card ">
                  
                    <div className="col col-sm-6 sm:col-sm-12 max-sm:text-center  md:col-sm-6"><h2 className="FormStyling md:text-left  titleTextColor">Autorname: {localStorage.getItem('profileName')}</h2></div>
                      <h4 className="FormStyling md:text-left pr-10 pt-3 titleTextColor"> | {getSingleEntityData.follower} Follower</h4>                   
                    <div className='col col-sm-12 mb-2 -mt-5 sm:col-sm-12 md:justify-end md:col-sm-12 justify-center flex max-md:pb-5'>
                   {showShareLink && (
                          <div className="mx-2 pt-3 FormStyling text-right">
                               <a
                                href={`${baseUrl2}/shareProfile?shareProfileName=${userProfileName}&shareProfileType=Book`}
                                target="_blank"
                                 rel="noreferrer"
                              >
                          Bluumerbuchprojekt {userProfileName}
                          </a>
                          </div>
                        )}
                        <div className="mx-2 FormStyling text-right text-2xl"><IconWithTooltip2 icon={<button className="buttonTwo btnChange p-2" onClick={()=> setShowShareLink(!showShareLink)}>Teil-Link</button>} tooltipText="Erstellt einen Link, mit dem Andere dein Profil betrachten können, ohne selbst auf Bluumer angemeldet zu sein." /></div>
                        <div className="mx-2 FormStyling text-right text-2xl"><IconWithTooltip2 icon={!updateProfile && (<div><button className="buttonTwo btnChange p-2 flex" onClick={changeInfo}><FaPencilAlt className='pr-1 mt-1'/>Ändere Infos</button></div>)} tooltipText="Hier kannst du deine Profil-Informationen bearbeiten." /></div>
                      <div className="mx-2 FormStyling text-right"> <button className="buttonTwo btnChange p-2" onClick={areYouSure}>Buch Löschen</button></div>
                    </div>
                  </div>
                  <hr className='horizontalLine'/>
                  <div className="row mainRowBackgroundcolor sm:flex white-glassmorphism-card pt-3 pb-3">
                    <div className='col col-sm-6'>
                    <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                      <div className='max-md:mb-20  max-lg:w-full' style={{minWidth: 200}}>
                        <h3 className="FormStyling ">Titel</h3>
                        <h5 className="FormStyling ">{getSingleEntityData.title?getSingleEntityData.title:' - '}</h5>
                        <br></br>
                        <h3 className="FormStyling ">Normseiten</h3>
                        <h5 className="FormStyling ">{getSingleEntityData.pages?getSingleEntityData.pages:' - '}</h5>
                      </div>
                    </div>
                    <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                      <div className='max-sm:mb-10 max-lg:w-full' style={{ minWidth: 200 }}>
                        <h3 className="FormStyling">Untertitel</h3>
                        <h5 className="FormStyling">
                          {getSingleEntityData.subtitle ? getSingleEntityData.subtitle : ' - '}
                        </h5>
                        <br />
                        <h3 className="FormStyling">Genres</h3>
                        <div 
                          className='genre-container' 
                          style={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            justifyContent: 'flex-start',  /* Align left by default */
                            textAlign: 'left'               /* Left align text by default */
                          }}
                        >
                          {getTrueGenreData && getTrueGenreData.map((group, groupIndex) => (
                            <div 
                              key={groupIndex} 
                              className='genre-group' 
                              style={{ 
                                display: 'inline-block', 
                                margin: '0 0px' 
                              }}
                            >
                              {group.map((key, index) => (
                                <span key={index} className="FormStyling capitalize">
                                  {key}{index < group.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                              <br />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    </div>
                    <div className="col col-sm-6 text-left mainRowBackgroundcolor ">
                    <h3 className="FormStyling">Klappentext</h3> 
                    <DisplayHtml htmlContent={getSingleEntityData.jacketText} style={{ wordWrap: 'break-word' }} />
                  </div>
                  </div>
                </div>
                 )}

                {((getSingleEntityData.tableOfContentText && getSingleEntityData.tableOfContentText.length > 0) || getSingleEntityData.coverImgName) && (
                  <div className="row profileDataCard2  paddingOne">
                    <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                      <h2 className="FormStyling text-left titleTextColor">Inhaltsverzeichnis</h2>
                    </div>
                    <hr className='horizontalLine' />
                    <div className="row mainRowBackgroundcolor white-glassmorphism-card">
                          <div className="col col-sm-7 text-left mainRowBackgroundcolor ml-20 mt-4" style={{ wordWrap: 'break-word' }}>
                              <DisplayHtml htmlContent={getSingleEntityData.tableOfContentText} />
                          </div>
                      <div className='col col-sm-4 mb-5'>
                        <div className='col-sm-6 flex text-center justify-center items-center mt-4'>
                        <div className="profileImg  my-3  ">
                          <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }}>                          
                              <img className="img-fluid profileImg3" src={`${imageSrc}${getSingleEntityData.coverImgName}`} alt="Dein Coverbild"
                                style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }} />         
                          </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                )}

                {(getSingleEntityData.description && getSingleEntityData.description.length > 0) && (
                  <div className="row profileDataCard2  paddingOne">
                    <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                      <h2 className="FormStyling text-left titleTextColor">Genauere Beschreibung</h2>
                    </div>
                    <hr className='horizontalLine' />
                    <div className="row mainRowBackgroundcolor white-glassmorphism-card">
                          <div className="col col-sm-12 text-left mainRowBackgroundcolor py-5" style={{ wordWrap: 'break-word' }}>
                              <DisplayHtml htmlContent={getSingleEntityData.description} />
                          </div>
                      </div>
                  </div>
                )}

                
          {(getSingleEntityData.bookFilesIds && getSingleEntityData.bookFilesIds.length > 0) && (
                      <div className="row profileDataCard2  paddingOne">
                        <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                        <div className="col-sm-12">
                           <div className="col col-sm-10">
                              <h2 className="FormStyling text-left titleTextColor -ml-12">Buchdatei und Leseprobe</h2>
                          </div> 
                                  <div className="col col-sm-2 text-right "> 
                                       <IconWithTooltip2 icon={ <button className="buttonTwo btnChange p-2 mt-3 xl:ml-10 flex" onClick={()=> {setUploadFile(!getUploadFile); setBookUploadSuccess(false);}}><FaPlusSquare className='mt-1 mr-3'/>Upload Datei</button>
                                                              } tooltipText="Lade eine zusätzliche Manuskript- / Buch-Datei hoch." />
                                  </div>
                          </div>
                        </div>
                        <div className="row white-glassmorphism-card py-2 text-left">
                          <div className="col col-sm-12 sm:flex  justify-between">
                            <div className="col-sm-2 max-sm:hidden"><h3 className="topRowStyling">Typ</h3></div>
                            <div className="col-sm-5  max-sm:hidden"><h3 className="topRowStyling">Titel</h3></div>
                            <div className="col-sm-3   max-sm:hidden"><h3 className="topRowStyling">Uploaded am</h3></div>
                            <div className='col-sm-2 textBackgroundColor  max-sm:hidden'>Download</div>
                          </div>
                        </div>
                        <hr className='horizontalLine' />
                        
                        <div  className="mainRowBackgroundcolor white-glassmorphism-card">
                        <div>
                        { getSingleEntityData.bookFilesIds.map((bookFiles, index) => (
                            <div key={bookFiles.id.timestamp} className={`row white-glassmorphism-card ${!getUploadFile && (index === 1 || getSingleEntityData.bookFilesIds.length === 1 ) ?'mainRowBackgroundcolor':''}`}>
                              <div className="col col-sm-12 sm:flex text-left justify-between mainRowBackgroundcolor ">
                              <div className="col col-sm-2 flex"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Typ:</h3><h5 className="FormStyling">{bookFiles.uploadedByProfileName === 'ReadingSample'?'Leseprobe':'Buchdatei'}</h5></div>
                              <div className="col col-sm-5"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Titel:</h3><h5 className="FormStyling">{bookFiles.fileName}</h5></div>
                              <div className="col col-sm-3"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Uploaded am:</h3><h5 className="FormStyling"><ShortTimestampFormatter timestamp={bookFiles.filesUploadedAt} /></h5></div>   
                                   
                                      <button className="col-sm-2  FormStyling pb-3 max-sm:ml-6" onClick={()=> downloadFile(bookFiles.fileName)}>Download</button>         
                              <hr className={`horizontalLine ${!getUploadFile && (index === 1 || getSingleEntityData.bookFilesIds.length === 1 ) ?'hidden':'sm:hidden'}`}/>
                            </div>
                               
                            </div>
                          ))}
                          </div>
                          { getUploadFile && (
                                <>
                                  <div className="row mainRowBackgroundcolor text-center justify-center items-center">
                                  <div className="col col-sm-12  white-glassmorphism-card ">

                                    <div className="col col-sm-4 text-center justify-center mt-16 mb-10 max-md:mt-12">
                                      <div className="textAreaOne ">
                                      <h4 className='font-bold mb-10'>Leseprobe</h4>
                                        <form className='' onSubmit={handleReadingSampleFileUpload} method="POST" encType="multipart/form-data">
                                          <input className='w-full text-center justify-center items-center' type="file" onChange={handleBookSampleFileChange} />
                                          <br></br>
                                          <button className="buttonOne px-4 py-2 bg-gray-600 text-white rounded mr-2">hochladen</button>
                                        </form>
                                      </div>
                                    </div>
                                    <div className="col col-sm-8 flex text-center justify-center items-center">
                                    { getBookSampleUploadSuccess && ( 
                                    <div className="">
                                        <div>
                                          <div className="my-10" style={{ minWidth: '300px', minHeight: '50px' }}>
                                            <div><h3 className='text-center'>Erfolgreich Hochgeladen</h3></div>
                                            <div className=" " style={{ minWidth: '300px', minHeight: '60px' }}>
                                              <div className="profileImg2 bg-blue-200" style={{ maxWidth: '240px', maxHeight: '50px', minWidth: '300px', minHeight: '50px' }}>
                                                <div className='p-5 text-center'>
                                                  <p><b>100%</b></p> 
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      )}
                                    </div>
                                  </div>
                                  </div>

                                  <hr style={{ borderColor: 'lightgray' }} className='white-glassmorphism-card horizontalLine'/>


                                <div className="row mainRowBackgroundcolor text-center justify-center items-center">
                              <div className="col col-sm-12 mainRowBackgroundcolor white-glassmorphism-card ">
                              {getFileSizeToBig && (<div><p style={{ color: 'red' }}>Deine Datei  berschreitet 5 MB an Größe!</p></div>)}
                              {getFileTypeWrong && (<div><p style={{ color: 'red' }}>Deine Datei muss vom Typ TXT, PDF oder DOCX sein!</p></div>)}
                              {getFileWithSameNameExists && (<div><p style={{ color: 'red' }}>Eine Datei mit dem selben Name existiert bereits!</p></div>)}
                              {getFileNameToLong && (<div><p style={{ color: 'red' }}>Der Dateiname ist zu lang. Bitte kürze diesen!</p></div>)}

                                <div className="col col-sm-4 text-center justify-center pt-16 mb-10 max-md:pt-12">
                                  <div className="textAreaOne ">
                                  <h4 className='font-bold mb-10'>Buchdatei</h4>
                                    <form className='' onSubmit={handleBookFileUpload} method="POST" encType="multipart/form-data">
                                      <input className='w-full text-center justify-center items-center' type="file" onChange={handleBookFileChange} />
                                      <br></br>
                                      <button className="buttonOne px-4 py-2 bg-gray-600 text-white rounded mr-2">hochladen</button>
                                    </form>
                                  </div>
                                </div>
                                <div className="col col-sm-8 flex text-center justify-center items-center">
                                { getBookUploadSuccess && ( 
                                <div className="">
                                    <div>
                                      <div className="my-10" style={{ minWidth: '300px', minHeight: '50px' }}>
                                        <div><h3 className='text-center'>Erfolgreich Hochgeladen</h3></div>
                                        <div className=" " style={{ minWidth: '300px', minHeight: '60px' }}>
                                          <div className="profileImg2 bg-blue-200" style={{ maxWidth: '240px', maxHeight: '50px', minWidth: '300px', minHeight: '50px' }}>
                                            <div className='p-5 text-center'>
                                              <p><b>100%</b></p> 
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            </>
                            )}

                        </div>                    
                    </div>
                    )}


              </div>   
            

        {getRightsData && (getRightsData.length > 0) && (
          <div className="row profileDataCard2  paddingOne white-glassmorphism-card">
            <div className="row row-col-3 titleRowBackgroundcolor">
              <div className="col col-sm-12 text-left paddingTwo"><h2 className="FormStyling text-left titleTextColor">Besitzer von Rechten</h2></div>
            </div>

            <div className="row flex w-full titleRowBackgroundcolor2">
              <div className='col col-sm-12 xl:flex max-xl:pl-6 w-full md:ml-40'>
                <div className='xl:col-sm-3 flex max-xl:text-left' style={{ width: expandedColumn === 'Editor' ? '33%' : '33%' }} onClick={() => toggleCollapse('Editor')}>
                  <h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Editor' ? 'favoriteColor ' : ''}`}>Lektoren{expandedColumn === 'Editor' ? (<CgChevronDown />) : (<CgChevronRight />)}</h3>
                </div>
                <div className='xl:col-sm-4 flex max-xl:text-left' style={{ width: expandedColumn === 'Testreader' ? '33%' : '33%' }} onClick={() => toggleCollapse('Testreader')}>
                  <h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Testreader' ? 'favoriteColor ' : ''}`}>Testleser{expandedColumn === 'Testreader' ? (<CgChevronDown />) : (<CgChevronRight />)}</h3>
                </div>
                <div className='xl:col-sm-4 flex max-xl:text-left' style={{ width: expandedColumn === 'Publisher' ? '20%' : '20%' }} onClick={() => toggleCollapse('Publisher')}>
                  <h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Publisher' ? 'favoriteColor ' : ''}`}>Verlage{expandedColumn === 'Publisher' ? (<CgChevronDown />) : (<CgChevronRight />)}</h3>
                </div>
              </div>
              <div className='col col-sm-12 xl:flex w-full'>
                <div className={`xl:col-sm-3 flex max-xl:text-left`} style={{ width: expandedColumn2 === 'Author' ? '33%' : '33%' }} onClick={() => toggleCollapse2('Author')}>
                    <h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'Author' ? 'favoriteColor ' : ''}`}>Autoren{expandedColumn2 === 'Author' ? (<CgChevronDown />) : (<CgChevronRight />)}</h3>
                </div>
                <div className='xl:col-sm-4 flex max-xl:text-left' style={{ width: expandedColumn2 === 'CoverDesigner' ? '48%' : '48%' }} onClick={() => toggleCollapse2('CoverDesigner')}>
                  <h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'CoverDesigner' ? 'favoriteColor ' : ''}`}>CoverDesigner{expandedColumn2 === 'CoverDesigner' ? (<CgChevronDown />) : (<CgChevronRight />)}</h3>
                </div>
              </div>
            </div>
            <hr className='horizontalLine' />
            <div className="row  mainRowBackgroundcolor2 paddingThree">

           <div className='row w-full md:flex'>
           {["Editor", "Testreader", "Publisher", "Author", "CoverDesigner"].map((categoryType) => (
             <>
             {getRightsData && getRightsData.map((Rights) => (
              <>
               <><>{expandedColumn === categoryType && (expandedColumn === 'Testreader' || expandedColumn === 'Editor' || expandedColumn === 'Publisher') && (

                  <><> {Rights.toRate && (
                    <div className='col col-sm-1 ml-5' style={{ minWidth: 100 }}>
                      <div className='text-left'>
                        <div>
                          <div className='flex'>
                            <div style={{ minWidth: '200px', maxWidth: '250px' }}>
                              <h4 className="font-bold">Bewerten</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} </>

                    <div className='col col-sm-5 ml-5 mr-16'>
                      <div key={categoryType} className='text-left'>
                        {Rights.toRate && Rights.toRate.map((rightsFor, index) => (
                          <div key={rightsFor.timestamp.toString()}>
                            {getAllUserProfileData && getAllUserProfileData.map((userProfiles) => (
                              <div key={userProfiles.id.timestamp}>
                            { rightsFor.timestamp.toString() === userProfiles.id.timestamp.toString() && userProfiles.userProfileType === categoryType && (
                              <><>

                                <div className='flex'>
                                  <div className='minWidthRights2 '>
                                    <h5>
                                      <a
                                        href={`${baseUrl2}/profile?othProfileName=${userProfiles.userProfileName}&othProfileType=${userProfiles.userProfileType}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => changeOtherProfileName(userProfiles.userProfileName, userProfiles.userProfileType)}
                                      >
                                        {userProfiles.userProfileName}
                                      </a>
                                    </h5>
                                  </div>

                                  <div className='text-right pt-1.5 text-[#ffffff44]' style={{ minWidth: '40px', maxWidth: '120px' }}>
                                  <IconWithTooltip2 icon={ <button onClick={() =>  changeUpDownRight(userProfiles.userProfileName, 'toRate')} className=' deleteBtn p-1 mt-2 scale-125'><MdDeleteForever className="" /></button>
                                                              } tooltipText="Entferne das Recht von dem Profil." />                            
                                  </div>
                                  {expandedColumn === categoryType && (
                                    <h5 className='sm:hidden text-left ml-5'>
                                      {expandedColumn === 'Testreader' ? '(Testleser)' : expandedColumn === 'Editor' ? '(Lektor)' : '(Verlag)'}
                                    </h5>
                                  )}
                                </div>
                              </></>
                            )}
                          </div>
                        ))}
                        </div>
                        ))}
                      </div>
                    </div></>
                )}</><>{expandedColumn2 === categoryType && (expandedColumn2 === 'Author' || expandedColumn2 === 'CoverDesigner') && (
                  <div className='col col-sm-5 ml-5'>
                    <div key={categoryType} className='text-left'>
                      {Rights.toRate && Rights.toRate.map((rightsFor, index) => (
                          <div key={rightsFor.timestamp.toString()}>
                          {getAllUserProfileData && getAllUserProfileData.map((userProfiles) => (
                            <div key={userProfiles.id.timestamp}>
                          { rightsFor.timestamp.toString() === userProfiles.id.timestamp.toString() && userProfiles.userProfileType === categoryType && (
                           <>
                              <div className='flex'>
                                <div className='minWidthRights2'>
                                  <h5>
                                    <a
                                      href={`${baseUrl2}/profile?othProfileName=${userProfiles.userProfileName}&othProfileType=${userProfiles.userProfileType}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={() => changeOtherProfileName(userProfiles.userProfileName, userProfiles.userProfileType)}
                                    >
                                      {userProfiles.userProfileName}
                                    </a>
                                  </h5>
                                </div>
                                <div className='text-right pt-1.5 text-[#ffffff44]' style={{ minWidth: '40px', maxWidth: '120px' }}>
                                <IconWithTooltip2 icon={ <button onClick={() =>  changeUpDownRight(userProfiles.userProfileName, 'toRate')} className=' deleteBtn p-1 mt-2 scale-125'><MdDeleteForever className="" /></button>
                                                              } tooltipText="Entferne das Recht von dem Profil." />                            
                                  </div>
                                {expandedColumn2 === categoryType && (
                                  <h5 className='sm:hidden text-left ml-5'>
                                    {expandedColumn2 === 'Author' ? '(Autor)' : expandedColumn2 === 'CoverDesigner' ? '(Coverdesigner)' : ''}
                                  </h5>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                      </div>
                      ))}
                    </div>
                  </div>
                )}
                  </></>
                 
                 </>
           ))}
           </>
          ))}
         </div>


        {/*  
         <div className='row w-full md:flex'>
           {["Editor", "Testreader", "Publisher", "Author", "CoverDesigner"].map((categoryType) => (
             <>
             {getRightsData && getRightsData.map((Rights) => (
              <>
               <>{expandedColumn === categoryType && (expandedColumn === 'Testreader' || expandedColumn === 'Editor' || expandedColumn === 'Publisher') && (
               
              <><> {Rights.toUpLoad && (
                     <div className='col col-sm-1 ml-5' style={{minWidth: 100}}>
                       <div className='text-left'>
                         <div>
                           <div className='flex'>
                             <div style={{ minWidth: '250px', maxWidth: '250px' }}>
                               <h4 className="font-bold">Upload</h4>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   )} </>
                   
                   <div className='col col-sm-5 ml-5 mr-16'>
                       <div key={categoryType} className='text-left'>
                         {Rights.toUpLoad && Rights.toUpLoad.map((rightsFor, index) => (
                          <div key={rightsFor.timestamp.toString()}>
                          {getAllUserProfileData && getAllUserProfileData.map((userProfiles) => (
                            <div key={userProfiles.id.timestamp}>
                          { rightsFor.timestamp.toString() === userProfiles.id.timestamp.toString() && userProfiles.userProfileType === categoryType && (
                            <><>
                                 <div className='flex'>
                                   <div className='minWidthRights2'>
                                     <h5>
                                       <a
                                         href={`${baseUrl2}/profile?othProfileName=${userProfiles.userProfileName}&othProfileType=${userProfiles.userProfileType}`}
                                         target="_blank"
                                         rel="noreferrer"
                                         onClick={() => changeOtherProfileName(userProfiles.userProfileName, userProfiles.userProfileType)}
                                       >
                                         {userProfiles.userProfileName}
                                       </a>
                                     </h5>
                                   </div>
                                   <div className='text-right pt-1.5 text-[#ffffff44]' style={{ minWidth: '40px', maxWidth: '120px' }}>
                                   <IconWithTooltip2 icon={ <button onClick={() =>  changeUpDownRight(userProfiles.userProfileName, 'toUpload')} className=' deleteBtn p-1 mt-2 scale-125'><MdDeleteForever className="" /></button>
                                                              } tooltipText="Entferne das Recht von dem Profil." />                            
                                  </div>
                                   {expandedColumn === categoryType && (
                                   <h5 className='sm:hidden text-left ml-5'>
                                     {expandedColumn === 'Testreader' ? '(Testleser)' : expandedColumn === 'Editor' ? '(Lektor)' : '(Verlag)'}
                                   </h5>
                                 )}
                                 </div>
                               </></>
                             )}
                           </div>
                         ))}
                       </div>
                         ))}
                       </div>
                     </div></>
               )}</><>{expandedColumn2 === categoryType && (expandedColumn2 === 'Author' || expandedColumn2 === 'CoverDesigner') && (
                 <div className='col col-sm-5 ml-5'>
                   <div key={categoryType} className='text-left'>
                     {Rights.toUpLoad && Rights.toUpLoad.map((rightsFor, index) => (
                          <div key={rightsFor.timestamp.toString()}>
                          {getAllUserProfileData && getAllUserProfileData.map((userProfiles) => (
                            <div key={userProfiles.id.timestamp}>
                          { rightsFor.timestamp.toString() === userProfiles.id.timestamp.toString() && userProfiles.userProfileType === categoryType && (
                            <>
                             <div className='flex'>
                               <div className='minWidthRights2'>
                                 <h5>
                                   <a
                                     href={`${baseUrl2}/profile?othProfileName=${userProfiles.userProfileName}&othProfileType=${userProfiles.userProfileType}`}
                                     target="_blank"
                                     rel="noreferrer"
                                     onClick={() => changeOtherProfileName(userProfiles.userProfileName, userProfiles.userProfileType)}
                                   >
                                     {userProfiles.userProfileName}
                                   </a>
                                 </h5>
                               </div>
                               <div className='text-right pt-1.5 text-[#ffffff44]' style={{ minWidth: '40px', maxWidth: '120px' }}>
                               <IconWithTooltip2 icon={ <button onClick={() =>  changeUpDownRight(userProfiles.userProfileName, 'toUpload')} className=' deleteBtn p-1 mt-2 scale-125'><MdDeleteForever className="" /></button>
                                                              } tooltipText="Entferne das Recht von dem Profil." />                            
                                  </div>
                               {expandedColumn2 === categoryType && (
                               <h5 className='sm:hidden text-left ml-5'>
                                 {expandedColumn2 === 'Author' ? '(Autor)' : expandedColumn2 === 'CoverDesigner' ? '(Coverdesigner)' : ''}
                               </h5>
                             )}
                             </div>
                           </>
                         )}
                       </div>
                     ))}
                   </div>
                     ))}
                   </div>
                 </div>
               )}
                 </></>
           ))}
           </>
          ))}
         </div>
      */}

         <div className='row w-full md:flex'>
           {["Editor", "Testreader", "Publisher", "Author", "CoverDesigner"].map((categoryType) => (
             <>
             {getRightsData && getRightsData.map((Rights) => (
              <>
               <>{expandedColumn === categoryType && (expandedColumn === 'Testreader' || expandedColumn === 'Editor' || expandedColumn === 'Publisher') && (
               
              <><> {Rights.toDownLoad && (
                     <div className='col col-sm-1 ml-5' style={{minWidth: 100}}>
                       <div className='text-left'>
                         <div>
                           <div className='flex'>
                             <div style={{ minWidth: '200px', maxWidth: '250px' }}>
                               <h4 className="font-bold">Download</h4>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   )} </>
                   
                   <div className='col col-sm-5 ml-5 mr-16'>
                       <div key={categoryType} className='text-left'>
                         {Rights.toDownLoad && Rights.toDownLoad.map((rightsFor, index) => (
                          <div key={rightsFor.timestamp.toString()}>
                          {getAllUserProfileData && getAllUserProfileData.map((userProfiles) => (
                            <div key={userProfiles.id.timestamp}>
                          { rightsFor.timestamp.toString() === userProfiles.id.timestamp.toString() && userProfiles.userProfileType === categoryType && (
                            <><>
                                 <div className='flex'>
                                   <div className='minWidthRights2'>
                                     <h5>
                                       <a
                                         href={`${baseUrl2}/profile?othProfileName=${userProfiles.userProfileName}&othProfileType=${userProfiles.userProfileType}`}
                                         target="_blank"
                                         rel="noreferrer"
                                         onClick={() => changeOtherProfileName(userProfiles.userProfileName, userProfiles.userProfileType)}
                                       >
                                         {userProfiles.userProfileName}
                                       </a>
                                     </h5>
                                   </div>
                                   <div className='text-right pt-1.5 text-[#ffffff44]' style={{ minWidth: '40px', maxWidth: '120px' }}>
                                   <IconWithTooltip2 icon={ <button onClick={() =>  changeUpDownRight(userProfiles.userProfileName, 'toDownLoad')} className=' deleteBtn p-1 mt-2 scale-125'><MdDeleteForever className="" /></button>
                                                              } tooltipText="Entferne das Recht von dem Profil." />                            
                                  </div>
                                   {expandedColumn === categoryType && (
                                   <h5 className='sm:hidden text-left ml-5'>
                                     {expandedColumn === 'Testreader' ? '(Testleser)' : expandedColumn === 'Editor' ? '(Lektor)' : '(Verlag)'}
                                   </h5>
                                 )}
                                 </div>
                               </></>
                             )}
                           </div>
                         ))}
                       </div>
                         ))}
                       </div>
                     </div></>
               )}</><>{expandedColumn2 === categoryType && (expandedColumn2 === 'Author' || expandedColumn2 === 'CoverDesigner') && (
                 <div className='col col-sm-5 ml-5'>
                   <div key={categoryType} className='text-left'>
                     {Rights.toDownLoad && Rights.toDownLoad.map((rightsFor, index) => (
                          <div key={rightsFor.timestamp.toString()}>
                          {getAllUserProfileData && getAllUserProfileData.map((userProfiles) => (
                            <div key={userProfiles.id.timestamp}>
                          { rightsFor.timestamp.toString() === userProfiles.id.timestamp.toString() && userProfiles.userProfileType === categoryType && (
                           <>
                             <div className='flex'>
                               <div className='minWidthRights2'>
                                 <h5>
                                   <a
                                     href={`${baseUrl2}/profile?othProfileName=${userProfiles.userProfileName}&othProfileType=${userProfiles.userProfileType}`}
                                     target="_blank"
                                     rel="noreferrer"
                                     onClick={() => changeOtherProfileName(userProfiles.userProfileName, userProfiles.userProfileType)}
                                   >
                                     {userProfiles.userProfileName}
                                   </a>
                                 </h5>
                               </div>
                               <div className='text-right pt-1.5 text-[#ffffff44]' style={{ minWidth: '40px', maxWidth: '120px' }}>
                               <IconWithTooltip2 icon={ <button onClick={() =>  changeUpDownRight(userProfiles.userProfileName, 'toDownLoad')} className=' deleteBtn p-1 mt-2 scale-125'><MdDeleteForever className="" /></button>
                                                              } tooltipText="Entferne das Recht von dem Profil." />                            
                                  </div>
                               {expandedColumn2 === categoryType && (
                               <h5 className='sm:hidden text-left ml-5'>
                                 {expandedColumn2 === 'Author' ? '(Autor)' : expandedColumn2 === 'CoverDesigner' ? '(Coverdesigner)' : ''}
                               </h5>
                             )}
                             </div>
                           </>
                         )}
                       </div>
                     ))}
                   </div>
                     ))}
                   </div>
                 </div>
               )}
                 </></>
           ))}
           </>
          ))}
         </div>



             
            </div>
            <div className="row mainRowBackgroundcolor paddingOne"><br></br></div>
          </div>
        )}

        {(getSingleEntityRating.length > 0) && (
          <div className="row profileDataCard2  paddingOne mb-20">
            <div className="row row-col-3 max-md:flex  titleRowBackgroundcolor white-glassmorphism-card">
              <div className="col md:col-sm-9 col-sm-6 w-full text-left paddingTwo"><h2 className="FormStyling text-left titleTextColor">Ratings</h2></div>
              <div className="col md:col-sm-1  col-sm-4 w-full text-right md:text-left paddingZero">
                <h4 className="FormStyling text-right">Gesamt:</h4>
              </div>
              <div className="col md:col-sm-2 col-sm-1 w-full text-left max-sm:-ml-10  ml-4 paddingZero">
                <h2 className="FormStyling text-left paddingZero ">{ratingScoreAverage}</h2>
              </div>
            </div>
            <div className="row titleRowBackgroundcolor2 max-md:flex white-glassmorphism-card">
              <div className="col col-sm-2 max-md:hidden text-left"><h3 className="topRowStyling">Wert</h3></div>
              <div className="col col-sm-3 max-md:hidden text-left"><h3 className="topRowStyling">Von</h3></div>
              <div className="col col-sm-7 max-md:hidden text-left"><h3 className="topRowStyling">Kommentar</h3></div>
            </div>
            <hr className='horizontalLine' />

            {getSingleEntityRating.map((rating, index) => (
              <div key={rating.id.timestamp} className="row mainRowBackgroundcolor2 paddingThree white-glassmorphism-card">
                <div className="col col-sm-2 max-md:flex text-left">
                  <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Wert:</h3>
                  <h4 className="FormStyling max-md:pl-3 ">{rating.ratingScore}</h4>
                </div>
                <div className="col col-sm-3 max-md:flex text-left">
                  <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Von:</h3>
                  <h5 className="FormStyling max-md:pl-3">{rating.createdByProfileName}</h5>
                </div>
                <div className="col col-sm-7 text-left">
                  <div
                    style={{
                      maxWidth: '730px', // Set a fixed height as an example, adjust as needed
                      overflow: 'hidden',
                      cursor: 'pointer'
                    }}
                  >
                    <h3 className="topRowStyling md:hidden titleTextColor">Kommentar:</h3>
                    <h5
                      className="FormStyling"
                      onClick={() => toggleTextExpansion(rating.createdByProfileName)}
                    >
                      {expandedTexts[rating.createdByProfileName]
                        ? rating.ratingText // Show full text if expanded
                        : `${rating.ratingText.substring(0, 80)}...` // Show truncated text
                      }
                    </h5>
                  </div>
                  <hr style={{ borderColor: 'lightgray' }} className={`horizontalLine mt-5 mb-5 ${(index === 1 ||  getSingleEntityRating.length === index +1) ?'hidden':'sm:hidden'}`}/>
                
                </div>
              </div>
            ))}
            <div className="row mainRowBackgroundcolor paddingOne white-glassmorphism-card"><br></br></div>
          </div>
        )}


        

        </>
        </div> 
      )} 

        </div>        
    </div>  
    
    
  );
}

export default MyProfileViewBook; 
export { default as MyProfileViewBook} from './MyProfileViewBook';