import './createYourProfile.css';

import { useNavigate} from "react-router-dom";
import { useEffect } from 'react';


//Components
import {LogoutRedirectCheck} from '../tools/LogoutRedirectCheck';
import ProfileCreationAll from '../components/createYourProfileComponents/ProfileCreationAll.js';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';

const CreateYourProfile = ({baseUrl}) => {

     ///////////////////////////////// Logout redirect //////////////////////////7

     const navigate = useNavigate();

      useEffect(() => {
        LogoutRedirectCheck(navigate);
      }, [navigate]);
   
   
   ///////////////////////////////// Logout redirect //////////////////////////7


  return (
      <div className="CreateYourProfile skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
       <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
                <div className="ProfileCreationAuthor" style={{ flex: 1 }}>
                  <ProfileCreationAll baseUrl={baseUrl}/>
                </div>
                <div className="mt-auto mb-20 ">
                    <Footer/>
              </div>
        </div>    
    </div>
  );
}

export default CreateYourProfile; 