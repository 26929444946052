
import './AdminAnalysisDashboard.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';

//Security
import { SanitizeInput } from '../../tools/security/SanitizeInput';
import { exeedsMaxCharacter } from '../../tools/security/exeedsMaxCharacter';

import { ImCross } from "react-icons/im";

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";



function AdminAnalysisDashboard() {


const [authorData, setAuthorData] = useState([]);
const [editorData, setEditorData] = useState([]);
const [testReaderData, setTestReaderData] = useState([]);
const [coverDesignerData, setCoverDesignerData] = useState([]);
const [publisherData, setPublisherData] = useState([]);
const [bookData, setBookData] = useState([]);

const [talksData, setTalksData] = useState([]);
const [ratingData, setRatingData] = useState([]);
const [filesData, setFilesData] = useState([]);

const [userData, setUserData] = useState([]);
const [userProfileData, setUserProfileData] = useState([]);

const [getUsersLoggedInTimeArray, setUsersLoggedInTimeArray] = useState([]);
const [getUsersLoggedInTimeArrayAll, setUsersLoggedInTimeArrayAll] = useState([]);

const [getUsersCreatedUserProfilesTimeArray, setUsersCreatedUserProfilesTimeArray] = useState([]);

const [getRoundedAverageUserProfilesPerUser, setRoundedAverageUserProfilesPerUser] = useState();

const [getPercentageAuthor, setPercentageAuthor] = useState('-');
const [getPercentageTestreader, setPercentageTestreader] = useState('-');
const [getPercentagePublisher, setPercentagePublisher] = useState('-');
const [getPercentageCoverDesigner, setPercentageCoverDesigner] = useState('-');
const [getPercentageEditor, setPercentageEditor] = useState('-');

const [getPercentageAuthorAllProfiles, setPercentageAuthorAllProfiles] = useState('-');
const [getPercentageTestreaderAllProfiles, setPercentageTestreaderAllProfiles] = useState('-');
const [getPercentagePublisherAllProfiles, setPercentagePublisherAllProfiles] = useState('-');
const [getPercentageCoverDesignerAllProfiles, setPercentageCoverDesignerAllProfiles] = useState('-');
const [getPercentageEditorAllProfiles, setPercentageEditorAllProfiles] = useState('-');

const [getPercentageLoggedInHour, setPercentageLoggedInHour] = useState('-');
const [getPercentageLoggedInDay, setPercentageLoggedInDay] = useState('-');
const [getPercentageLoggedInWeek, setPercentageLoggedInWeek] = useState('-');
const [getPercentageLoggedInMonth, setPercentageLoggedInMonth] = useState('-');
const [getPercentageLoggedInThreeMonth, setPercentageLoggedInThreeMonth] = useState('-');
const [getPercentageLoggedInSixMonth, setPercentageLoggedInSixMonth] = useState('-');
const [getPercentageLoggedInYear, setPercentageLoggedInYear] = useState('-');

const [getPercentageBooksPerUser, setPercentageBooksPerUser] = useState('-');
const [getPercentageBooksPerAuthor, setPercentageBooksPerAuthor] = useState('-');

const [counts, setCounts] = useState({
  lector: 0,
  coverDesigner: 0,
  testReader: 0,
  writeBuddy: 0,
});

const [overallTalkCounts, setOverallTalkCounts] = useState({
  talksWithLectors: 0,
  talksWithTestReaders: 0,
  talksWithCoverDesigners: 0,
  talksWithPublishers: 0
});

const [getShowKeyKPIs, setShowKeyKPIs] = useState(false);
const [getShowUsersLogginPerTime, setShowUsersLogginPerTime] = useState(false);
const [getShowUsersCreatedPerTime, setShowUsersCreatedPerTime] = useState(false);
const [getShowProfiles, setShowProfiles] = useState(false);
const [getShowRatingsFiles, setShowRatingsFiles] = useState(false);



const [showAreYouSure, setShowAreYouSure] = useState(false);
const [render, setRender] = useState(false);

const [error, setError] = useState(null);

      
 const fetchAllAnalysisData = async () => {
   // e.preventDefault();

    try {

	const response0 = await api.get(`/api/v1/auth`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allUsers = response0.data;	setUserData(allUsers);
      //console.log(allUsers);
          if (!response0.data || response0.data.length === 0) { handleError("Fetching all Users request failed");
            		        console.log(response0.data);
          }

	const response00 = await api.get(`/api/userProfile`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allUsersprofiles = response00.data;	setUserProfileData(allUsersprofiles);
          if (!response00.data || response00.data.length === 0) { handleError("Fetching all Usersprofiles request failed");
            		        console.log(response00.data);
          }

      const response = await api.get(`/api/authors`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allAuthors = response.data;	setAuthorData(allAuthors);
          if (!response.data || response.data.length === 0) { handleError("Fetching all Authors request failed");
            		        console.log(response.data);
          }

      const response2 = await api.get(`/api/editors`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allEditors = response2.data;	setEditorData(allEditors);
          if (!response2.data || response2.data.length === 0) { handleError("Fetching all Editors request failed");
            		        console.log(response2.data);
          }

      const response3 = await api.get(`/api/testReaders`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allTestReaders = response3.data;	setTestReaderData(allTestReaders);
          if (!response3.data || response3.data.length === 0) { handleError("Fetching all Testreaders request failed");
            		        console.log(response3.data);
          }
  
	const response4 = await api.get(`/api/coverDesigners`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allCoverDesigners = response4.data;	setCoverDesignerData(allCoverDesigners);
          if (!response4.data || response4.data.length === 0) { handleError("Fetching all CoverDesigners request failed");
            		        console.log(response4.data);
          }

	const response5 = await api.get(`/api/publishers`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allPublishers = response5.data;	setPublisherData(allPublishers);
          if (!response5.data || response5.data.length === 0) { handleError("Fetching all Publisher request failed");
            		        console.log(response5.data);
          }

	const response6 = await api.get(`/api/bookProjects`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allBookprojects = response6.data;	setBookData(allBookprojects);
          if (!response6.data || response6.data.length === 0) { handleError("Fetching all Bookprojects request failed");
            		        console.log(response6.data);
          }


	const response7 = await api.get(`/api/talks`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allTalks = response7.data;	setTalksData(allTalks);
          if (!response7.data || response7.data.length === 0) { handleError("Fetching all Talks request failed");
            		        console.log(response7.data);
          }

	const response8 = await api.get(`/api/ratings`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allRatings = response8.data;	setRatingData(allRatings);
          if (!response8.data || response8.data.length === 0) { handleError("Fetching all Ratings request failed");
            		        console.log(response8.data);
          }

	const response9 = await api.get(`/api/files`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
	  const allFiles = response9.data;	setFilesData(allFiles);
          if (!response9.data || response9.data.length === 0) { handleError("Fetching all Files request failed");
            		        console.log(response9.data);
          }


    } catch (error) {
      handleError(error);
    }
  };



 const calcAverageUserProfilesPerUser = async () => {
    //e.preventDefault();

    try {

	   const totalUserProfiles = userData.reduce((sum, user) => sum + user.userProfilesIds.length, 0);
  	   const averageUserProfilesPerUser = totalUserProfiles / userData.length;
  	   const roundedAverageUserProfilesPerUser = averageUserProfilesPerUser.toFixed(1);  //parseFloat(UserprofilesPerUser2) for more calc
       setRoundedAverageUserProfilesPerUser(roundedAverageUserProfilesPerUser);
      // console.log('roundedAverageUserProfilesPerUser:', roundedAverageUserProfilesPerUser);

    } catch (error) {
      handleError(error);
      console.log("Calculation averageUserProfilesPerUser failed:", getRoundedAverageUserProfilesPerUser );
    }
  };



  const calcUsersLoggedIn = async () => {
    try {

        // Define time intervals 
           const oneHour = [0,0,0,1,0,0,0];
           const oneDay =  [0,0,1,0,0,0,0];
           const oneWeek = [0,0,7,0,0,0,0];
           
           const oneMonth = [0, 1, 0, 0, 0, 0, 0]; // 1 month
           const threeMonths = [0, 3, 0, 0, 0, 0, 0]; // 3 months
           const sixMonths = [0, 6, 0, 0, 0, 0, 0]; // 6 months
           const oneYear = [1, 0, 0, 0, 0, 0, 0]; // 1 year


        const countUsersWithinInterval = (interval) => {
          // Get the current date and time
          const currentDate = new Date();
      
          // Create a new Date object for the cutoff date
          const cutoffDate = new Date(currentDate);
      
          // Subtract the interval from the cutoff date
          let overflow = 0;
          overflow = adjustDateComponent(cutoffDate, 'Milliseconds', interval[6], overflow);
          overflow = adjustDateComponent(cutoffDate, 'Seconds', interval[5], overflow);
          overflow = adjustDateComponent(cutoffDate, 'Minutes', interval[4], overflow);
          overflow = adjustDateComponent(cutoffDate, 'Hours', interval[3], overflow);
          overflow = adjustDateComponent(cutoffDate, 'Date', interval[2], overflow);
          overflow = adjustDateComponent(cutoffDate, 'Month', interval[1], overflow);
          cutoffDate.setFullYear(cutoffDate.getFullYear() - interval[0]);
      
          //console.log('cutoffDate:', cutoffDate);

          const cutoffDateArray = [cutoffDate.getFullYear(),cutoffDate.getMonth()+1, cutoffDate.getDate(),
             cutoffDate.getHours(), cutoffDate.getMinutes(), cutoffDate.getSeconds(), cutoffDate.getMilliseconds() ]
      
             //console.log('cutoffDateArray:', cutoffDateArray);

          const usersWithinInterval = userData.filter(user => {
                // Extract the date components from the last login date
		const userLastLogInTimestamp = user.lastLoginAt[user.lastLoginAt.length -1];

		const year = parseInt(userLastLogInTimestamp.slice(0, 4));
		const month = parseInt(userLastLogInTimestamp.slice(5, 7));
		const day = parseInt(userLastLogInTimestamp.slice(8, 10));
		const hours = parseInt(userLastLogInTimestamp.slice(11, 13));
		const minutes = parseInt(userLastLogInTimestamp.slice(14, 16));
		const seconds = parseInt(userLastLogInTimestamp.slice(17, 20));
               
               // console.log( 'userLastLogInTimestamp:', userLastLogInTimestamp);
               // console.log('lastLoginAt:', [year, month, day, hours, minutes, seconds]);
                //console.log( 'cutoffDateArray:', cutoffDateArray);

                if (cutoffDateArray[0] !== year) {       return cutoffDateArray[0] < year; // User's last login date year is before the cutoff year
              } else if (cutoffDateArray[1] !== month) { return cutoffDateArray[1] < month; // User's last login date month is before the cutoff month
              } else if (cutoffDateArray[2] !== day) {   return cutoffDateArray[2] < day; // User's last login date day is before the cutoff day
              } else if (cutoffDateArray[3] !== hours) { return cutoffDateArray[3] < hours; // User's last login date hours is before the cutoff hours
              } else if (cutoffDateArray[4] !== minutes) { return cutoffDateArray[4] < minutes; // User's last login date minutes is before the cutoff minutes
              } else { console.log('All elements are equal');  return false; // User's last login date is equal to the cutoff date
                }
              });

          // Return the count of users within the interval
          return usersWithinInterval.length;
      };

      function adjustDateComponent(date, component, value, overflow) {
        let currentValue = date[`get${component}`]();
        let newValue = currentValue - value - overflow;
    
        if (newValue < 0) {
            // Adjust higher component
            
            if (component === 'Month') {
            const higherComponent = 'FullYear'; 
            date[`set${higherComponent}`](date[`get${higherComponent}`]() - 1);
            }
            // Calculate overflow
            overflow = 1;
    
            // Adjust current component
            if (component === 'Month') {
                newValue += 12;
            } else if (component === 'Date') {
                const lastDayOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
                newValue += lastDayOfPreviousMonth;
            }
        }
    
        date[`set${component}`](newValue);
        return overflow;
      }

        // Usage examples
        const usersLoggedInLastHour = countUsersWithinInterval(oneHour);
        const usersLoggedInLastDay = countUsersWithinInterval(oneDay);
        const usersLoggedInLastWeek = countUsersWithinInterval(oneWeek);
        const usersLoggedInLastMonth = countUsersWithinInterval(oneMonth);
        const usersLoggedInLastThreeMonths = countUsersWithinInterval(threeMonths);
        const usersLoggedInLastSixMonths = countUsersWithinInterval(sixMonths);
        const usersLoggedInLastYear = countUsersWithinInterval(oneYear);

        const usersLoggedInTimeArrayOne = [
            usersLoggedInLastHour,
            usersLoggedInLastDay - usersLoggedInLastHour,
            usersLoggedInLastWeek - usersLoggedInLastDay,
            usersLoggedInLastMonth - usersLoggedInLastWeek,
            usersLoggedInLastThreeMonths - usersLoggedInLastMonth,
            usersLoggedInLastSixMonths - usersLoggedInLastThreeMonths,
            usersLoggedInLastYear - usersLoggedInLastSixMonths
        ];
        const usersLoggedInTimeArrayOneAll = [
          usersLoggedInLastHour,
          usersLoggedInLastDay,
          usersLoggedInLastWeek,
          usersLoggedInLastMonth,
          usersLoggedInLastThreeMonths,
          usersLoggedInLastSixMonths,
          usersLoggedInLastYear
      ];

        setUsersLoggedInTimeArray(usersLoggedInTimeArrayOne);
        setUsersLoggedInTimeArrayAll(usersLoggedInTimeArrayOneAll);

      /*  console.log(`Users logged in within the last ... : 
            ${'hour:' + usersLoggedInLastHour + ' day:' + usersLoggedInLastDay + ' Week:' + usersLoggedInLastWeek + ' Month:' + usersLoggedInLastMonth
            + ' HalfYear:' + usersLoggedInLastSixMonths + ' Year:' + usersLoggedInLastYear}`); */
    } catch (error) {
        handleError(error); // Handle error
        console.log("Calculation usersLoggedInIntervals failed:", getUsersLoggedInTimeArray);
    }
};


const calcUsersCreatedUserProfile = async () => {
  try {

      // Define time intervals in milliseconds
         const oneHour = [0,0,0,1,0,0,0];
         const oneDay =  [0,0,1,0,0,0,0];
         const oneWeek = [0,0,7,0,0,0,0];
         
         const oneMonth = [0, 1, 0, 0, 0, 0, 0]; // 1 month
         const threeMonths = [0, 3, 0, 0, 0, 0, 0]; // 3 months
         const sixMonths = [0, 6, 0, 0, 0, 0, 0]; // 6 months
         const oneYear = [1, 0, 0, 0, 0, 0, 0]; // 1 year


      const countUsersWithinInterval = (interval) => {
        // Get the current date and time
        const currentDate = new Date();
    
        // Create a new Date object for the cutoff date
        const cutoffDate = new Date(currentDate);
    
        // Subtract the interval from the cutoff date
        let overflow = 0;
        overflow = adjustDateComponent(cutoffDate, 'Milliseconds', interval[6], overflow);
        overflow = adjustDateComponent(cutoffDate, 'Seconds', interval[5], overflow);
        overflow = adjustDateComponent(cutoffDate, 'Minutes', interval[4], overflow);
        overflow = adjustDateComponent(cutoffDate, 'Hours', interval[3], overflow);
        overflow = adjustDateComponent(cutoffDate, 'Date', interval[2], overflow);
        overflow = adjustDateComponent(cutoffDate, 'Month', interval[1], overflow);
        cutoffDate.setFullYear(cutoffDate.getFullYear() - interval[0]);
    
        //console.log('cutoffDate:', cutoffDate);

        const cutoffDateArray = [cutoffDate.getFullYear(),cutoffDate.getMonth()+1, cutoffDate.getDate(),
           cutoffDate.getHours(), cutoffDate.getMinutes(), cutoffDate.getSeconds(), cutoffDate.getMilliseconds() ]
    
           //console.log('cutoffDateArray:', cutoffDateArray);

        const usersWithinInterval = userData.filter(user => {
              // Extract the date components from the last login date
		const userLastLogInTimestamp = user.lastLoginAt[0];

		const year = parseInt(userLastLogInTimestamp.slice(0, 4));
		const month = parseInt(userLastLogInTimestamp.slice(5, 7));
		const day = parseInt(userLastLogInTimestamp.slice(8, 10));
		const hours = parseInt(userLastLogInTimestamp.slice(11, 13));
		const minutes = parseInt(userLastLogInTimestamp.slice(14, 16));
		const seconds = parseInt(userLastLogInTimestamp.slice(17, 20));
 
              //console.log('lastLoginAt:', [year, month, day, hours, minutes, seconds, milliseconds]);
              //console.log( 'cutoffDateArray:', cutoffDateArray);

              if (cutoffDateArray[0] !== year) {       return cutoffDateArray[0] < year; // User's last login date year is before the cutoff year
            } else if (cutoffDateArray[1] !== month) { return cutoffDateArray[1] < month; // User's last login date month is before the cutoff month
            } else if (cutoffDateArray[2] !== day) {   return cutoffDateArray[2] < day; // User's last login date day is before the cutoff day
            } else if (cutoffDateArray[3] !== hours) { return cutoffDateArray[3] < hours; // User's last login date hours is before the cutoff hours
            } else if (cutoffDateArray[4] !== minutes) { return cutoffDateArray[4] < minutes; // User's last login date minutes is before the cutoff minutes
            } else { console.log('All elements are equal');  return false; // User's last login date is equal to the cutoff date
              }
            });

        // Return the count of users within the interval
        return usersWithinInterval.length;
    };

    function adjustDateComponent(date, component, value, overflow) {
      let currentValue = date[`get${component}`]();
      let newValue = currentValue - value - overflow;
  
      if (newValue < 0) {
          // Adjust higher component
          
          if (component === 'Month') {
          const higherComponent = 'FullYear'; 
          date[`set${higherComponent}`](date[`get${higherComponent}`]() - 1);
          }
          // Calculate overflow
          overflow = 1;
  
          // Adjust current component
          if (component === 'Month') {
              newValue += 12;
          } else if (component === 'Date') {
              const lastDayOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
              newValue += lastDayOfPreviousMonth;
          }
      }
  
      date[`set${component}`](newValue);
      return overflow;
    }

      // Usage examples
      const usersCreatedLastHour = countUsersWithinInterval(oneHour);
      const usersCreatedLastDay = countUsersWithinInterval(oneDay);
      const usersCreatedLastWeek = countUsersWithinInterval(oneWeek);
      const usersCreatedLastMonth = countUsersWithinInterval(oneMonth);
      const usersCreatedLastThreeMonths = countUsersWithinInterval(threeMonths);
      const usersCreatedLastSixMonths = countUsersWithinInterval(sixMonths);
      const usersCreatedLastYear = countUsersWithinInterval(oneYear);

      const usersCreatedUserProfilesTimeArray = [
          usersCreatedLastHour,
          usersCreatedLastDay - usersCreatedLastHour,
          usersCreatedLastWeek - usersCreatedLastDay,
          usersCreatedLastMonth - usersCreatedLastWeek,
          usersCreatedLastThreeMonths - usersCreatedLastMonth,
          usersCreatedLastSixMonths - usersCreatedLastThreeMonths,
          usersCreatedLastYear - usersCreatedLastSixMonths
      ];

      setUsersCreatedUserProfilesTimeArray(usersCreatedUserProfilesTimeArray);

     /* console.log(`Users created in within the last ... : 
          ${'hour:' + usersCreatedUserProfilesTimeArray[0] + ' day:' + usersCreatedUserProfilesTimeArray[1] + ' Week:' + usersCreatedUserProfilesTimeArray[2] + ' Month:' + usersCreatedUserProfilesTimeArray[3]
          + ' HalfYear:' + usersCreatedUserProfilesTimeArray[4] + ' Year:' + usersCreatedUserProfilesTimeArray[5]}`); */
  } catch (error) {
      handleError(error); // Handle error
      console.log("Calculation usersLoggedInIntervals failed:", getUsersCreatedUserProfilesTimeArray);
  }
};

const calcPercentageLoggedIn = () => {

  if(userData.length !== 0 && getUsersLoggedInTimeArrayAll[0] !== 0) {  
  const percentageEntity = (getUsersLoggedInTimeArrayAll[0] / userData.length ) * 100;
  const percentageEntityCeiled = Math.ceil(percentageEntity * 10) / 10; // Round up to 1 decimal place
  setPercentageLoggedInHour(percentageEntityCeiled);
  }
  if(userData.length !== 0 && getUsersLoggedInTimeArrayAll[1] !== 0) {  
      const percentageEntity = (getUsersLoggedInTimeArrayAll[1] / userData.length ) * 100;
      const percentageEntityCeiled = Math.ceil(percentageEntity * 10) / 10; // Round up to 1 decimal place
      setPercentageLoggedInDay(percentageEntityCeiled);
  }
  if(userData.length !== 0 && getUsersLoggedInTimeArrayAll[2] !== 0) {  
      const percentageEntity = (getUsersLoggedInTimeArrayAll[2] / userData.length ) * 100;
      const percentageEntityCeiled = Math.ceil(percentageEntity * 10) / 10; // Round up to 1 decimal place
      setPercentageLoggedInWeek(percentageEntityCeiled);
  }
  if(userData.length !== 0 && getUsersLoggedInTimeArrayAll[3] !== 0) {  
      const percentageEntity = (getUsersLoggedInTimeArrayAll[3] / userData.length ) * 100;
      const percentageEntityFixed = percentageEntity.toFixed(1);
      setPercentageLoggedInMonth(parseFloat(percentageEntityFixed));
  }
  if(userData.length !== 0 && getUsersLoggedInTimeArrayAll[4] !== 0) {  
      const percentageEntity = (getUsersLoggedInTimeArrayAll[4] / userData.length ) * 100;
      const percentageEntityFixed = percentageEntity.toFixed(1);
      setPercentageLoggedInThreeMonth(parseFloat(percentageEntityFixed));
  }
  if(userData.length !== 0 && getUsersLoggedInTimeArrayAll[5] !== 0) {  
    const percentageEntity = (getUsersLoggedInTimeArrayAll[5] / userData.length ) * 100;
    const percentageEntityFixed = percentageEntity.toFixed(1);
    setPercentageLoggedInSixMonth(parseFloat(percentageEntityFixed));
  }
  if(userData.length !== 0 && getUsersLoggedInTimeArrayAll[6] !== 0) {  
    const percentageEntity = (getUsersLoggedInTimeArrayAll[6] / userData.length ) * 100;
    const percentageEntityFixed = percentageEntity.toFixed(1);
    setPercentageLoggedInYear(parseFloat(percentageEntityFixed));
  }
};

const calcPercentageProfiles = () => {

  if(userData.length !== 0 && authorData.length !== 0) {  
  const percentageEntity = (authorData.length / userData.length ) * 100;
  const percentageEntityCeiled = Math.ceil(percentageEntity * 10) / 10; // Round up to 1 decimal place
  setPercentageAuthor(percentageEntityCeiled);
  }
  if(userData.length !== 0 && editorData.length !== 0) {  
      const percentageEntity = (editorData.length / userData.length ) * 100;
      const percentageEntityCeiled = Math.ceil(percentageEntity * 10) / 10; // Round up to 1 decimal place
      setPercentageEditor(percentageEntityCeiled);
  }
  if(userData.length !== 0 && publisherData.length !== 0) {  
      const percentageEntity = (publisherData.length / userData.length ) * 100;
      const percentageEntityCeiled = Math.ceil(percentageEntity * 10) / 10; // Round up to 1 decimal place
      setPercentagePublisher(percentageEntityCeiled);
  }
  if(userData.length !== 0 && coverDesignerData.length !== 0) {  
      const percentageEntity = (coverDesignerData.length / userData.length ) * 100;
      const percentageEntityFixed = percentageEntity.toFixed(1);
      setPercentageCoverDesigner(parseFloat(percentageEntityFixed));
  }
  if(userData.length !== 0 && testReaderData.length !== 0) {  
      const percentageEntity = (testReaderData.length / userData.length ) * 100;
      const percentageEntityFixed = percentageEntity.toFixed(1);
      setPercentageTestreader(parseFloat(percentageEntityFixed));
  }
  if(userData.length !== 0 && bookData.length !== 0) {  
    const percentageEntity = (bookData.length / userData.length ) * 100;
    const percentageEntityFixed = percentageEntity.toFixed(1);
    setPercentageBooksPerUser(parseFloat(percentageEntityFixed));
}
};

const calcPercentageProfilesPerAllProfiles = () => {

  if(userData.length !== 0 && authorData.length !== 0) {  
  const percentageEntity = (authorData.length / userProfileData.length ) * 100;
  const percentageEntityCeiled = Math.ceil(percentageEntity * 10) / 10; // Round up to 1 decimal place
  setPercentageAuthorAllProfiles(percentageEntityCeiled);
  }
  if(userData.length !== 0 && editorData.length !== 0) {  
      const percentageEntity = (editorData.length / userProfileData.length ) * 100;
      const percentageEntityCeiled = Math.ceil(percentageEntity * 10) / 10; // Round up to 1 decimal place
      setPercentageEditorAllProfiles(percentageEntityCeiled);
  }
  if(userData.length !== 0 && publisherData.length !== 0) {  
      const percentageEntity = (publisherData.length / userProfileData.length ) * 100;
      const percentageEntityCeiled = Math.ceil(percentageEntity * 10) / 10; // Round up to 1 decimal place
      setPercentagePublisherAllProfiles(percentageEntityCeiled);
  }
  if(userData.length !== 0 && coverDesignerData.length !== 0) {  
      const percentageEntity = (coverDesignerData.length / userProfileData.length ) * 100;
      const percentageEntityFixed = percentageEntity.toFixed(1);
      setPercentageCoverDesignerAllProfiles(parseFloat(percentageEntityFixed));
  }
  if(userData.length !== 0 && testReaderData.length !== 0) {  
      const percentageEntity = (testReaderData.length / userProfileData.length ) * 100;
      const percentageEntityFixed = percentageEntity.toFixed(1);
      setPercentageTestreaderAllProfiles(parseFloat(percentageEntityFixed));
  }
  if(authorData.length !== 0 && bookData.length !== 0) {  
    const percentageEntity = (bookData.length / authorData.length ) * 100;
    const percentageEntityFixed = percentageEntity.toFixed(1);
    setPercentageBooksPerAuthor(parseFloat(percentageEntityFixed));
}
};


const calcSearchForInAUthors = () => {
      // Calculate counts based on authorData
      const lectorCount = authorData.filter(author => author.searchForLector).length;
      const coverDesignerCount = authorData.filter(author => author.searchForCoverDesign).length;
      const testReaderCount = authorData.filter(author => author.searchForTestReader).length;
      const writeBuddyCount = authorData.filter(author => author.searchForWriteBuddy).length;
  
      setCounts({
        lector: lectorCount,
        coverDesigner: coverDesignerCount,
        testReader: testReaderCount,
        writeBuddy: writeBuddyCount,
      });
}


const calcOverallTalkCountsAuthors = () => {

 // Check if all data is available
 if (authorData.length && editorData.length && testReaderData.length && coverDesignerData.length && publisherData.length && talksData.length) {
  // Initialize overall counters
  let totalTalksWithLectors = 0;
  let totalTalksWithTestReaders = 0;
  let totalTalksWithCoverDesigners = 0;
  let totalTalksWithPublishers = 0;

  // Extract the names of all profiles
  const editors = editorData.map(editor => editor.editorName);
  const testReaders = testReaderData.map(testReader => testReader.testReaderName);
  const coverDesigners = coverDesignerData.map(coverDesigner => coverDesigner.coverDesignerName);
  const publishers = publisherData.map(publisher => publisher.publisherName);

  // Loop through each talk and count occurrences of talks with specific profiles
  talksData.forEach(talk => {
    const talkName = talk.talkName;

    // Count talks with Lectors (Editors)
    if (editors.some(editor => talkName.includes(editor))) {
      totalTalksWithLectors++;
    }

    // Count talks with Test Readers
    if (testReaders.some(testReader => talkName.includes(testReader))) {
      totalTalksWithTestReaders++;
    }

    // Count talks with Cover Designers
    if (coverDesigners.some(coverDesigner => talkName.includes(coverDesigner))) {
      totalTalksWithCoverDesigners++;
    }

    // Count talks with Publishers
    if (publishers.some(publisher => talkName.includes(publisher))) {
      totalTalksWithPublishers++;
    }
  });

  // Set the overall counts
  setOverallTalkCounts({
    talksWithLectors: totalTalksWithLectors,
    talksWithTestReaders: totalTalksWithTestReaders,
    talksWithCoverDesigners: totalTalksWithCoverDesigners,
    talksWithPublishers: totalTalksWithPublishers
  });
}

}


  const rerender = () => {
    setRender(true);
  };
  
  useEffect(() => {
      fetchAllAnalysisData();
      

      const delayTimeout = setTimeout(() => {
        calcUsersLoggedIn();
        calcUsersCreatedUserProfile();
        calcAverageUserProfilesPerUser();
        calcPercentageProfiles();
        calcPercentageProfilesPerAllProfiles();
        calcPercentageLoggedIn();
        calcSearchForInAUthors();
        calcOverallTalkCountsAuthors();
        setRender(false);
      }, 1000);
    
      // Return a cleanup function to clear the timeout when component unmounts or dependencies change
      return () => {
        clearTimeout(delayTimeout);
      };

  }, []);
  
  useEffect(() => {
    if (render) {
      fetchAllAnalysisData();
            
      
      const delayTimeout = setTimeout(() => {
        calcAverageUserProfilesPerUser();
        calcUsersLoggedIn();
        calcUsersCreatedUserProfile();
        calcPercentageProfiles();
        calcPercentageProfilesPerAllProfiles();
        calcPercentageLoggedIn();
        calcSearchForInAUthors();
        calcOverallTalkCountsAuthors();
        setRender(false);
      }, 1000);
    
      // Return a cleanup function to clear the timeout when component unmounts or dependencies change
      return () => {
        clearTimeout(delayTimeout);
      };

    }
  }, [render]);
  

 
////////////////////////////////////////////////////////////////////////
    ////    Code for single Profiles to edit / delete   //////////////////
    /////////////////////////////////////////////////////////////////////

  /*
    const getSingleEntity = async () => {

      const addNewEntityData = {
        entityName: localStorage.getItem('profileName'),
      };

      //console.log(localStorage.getItem('profileName'));
  
      try {
          const response = await api.get(`/api/authors/${addNewEntityData.entityName}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
  
          setSingleEntityData(response.data);

          const singleEntity = response.data;
          setSingleBookprojectData(singleEntity.bookProjectsIds);
          showSingleRatings(singleEntity.authorName);
          localStorage.setItem('currentProfileImg', singleEntity.profileImg);
      } catch (error) {
          handleError('Error fetching single entity:', error);
      }
  };  
  
*/
  
  const handleEntityDelete = async () => {
      try {
          const addNewEntityData = {
            entityName: localStorage.getItem('profileName'),
          };
  
          await api.delete(`/api/authors/delete/${addNewEntityData.entityName}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
          setShowAreYouSure(false);
      } catch (error) {
          handleError('Error deleting entity:', error);
      }
  };  


  const areYouSure = () => {
    setShowAreYouSure(!showAreYouSure);
};

        
      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

    /////////////////////////////////////////////////////////////////////
    ///////////////////////////////// Logout redirect //////////////////////////7

    const navigate2 = useNavigate();

    useEffect(() => {
      LogoutRedirectCheck(navigate2);
    }, [render]);
    
    ///////////////////////////////// Logout redirect //////////////////////////7



  return (
    <>



<div className="AdminAnalyseDashboard">
  <div className="container mx-auto px-4">
    {showAreYouSure && (
      <div className="profileDataCard">
        <div className="text-center mb-4">
          <h3 className="text-lg font-semibold">Bist du dir sicher, dass du dein Profil löschen willst?</h3>
        </div>
        <div className="flex justify-center space-x-4">
          <button className="btnChange bg-red-500 text-white px-4 py-2 rounded" onClick={handleEntityDelete}>
            Löschen
          </button>
          <button className="btnChange bg-gray-300 px-4 py-2 rounded" onClick={areYouSure}>
            Abbrechen
          </button>
        </div>
      </div>
    )}

    {error && <div className="text-red-500">{error}</div>}

    <div className="profileDataCard p-4">
      <div className="flex flex-wrap justify-between items-center">
        <h2 className="text-black text-4xl font-bold max-sm:text-center">
          {localStorage.getItem('profileName')}
        </h2>
        <div className="flex space-x-2">
          <button className="btnChange bg-blue-500 text-white px-4 py-2 rounded" onClick={rerender}>
            Aktualisieren
          </button>
          <button className="btnChange bg-red-500 text-white px-4 py-2 rounded" onClick={areYouSure}>
            Profil Löschen
          </button>
        </div>
      </div>

      <hr className="my-4" />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-20">
      
              <div className="p-4 bg-gray-100 rounded-md">
                <h2 className="FormStyling  text-black text-4xl">Overall Users</h2>
                <p className="FormStyling">{userData ? userData.length : '-'}</p>
              </div>
              <div className="p-4 bg-gray-100 rounded-md">
                <h3 className="FormStyling text-center titleTextColor">Profiles Overall</h3>
                <p className="FormStyling text-center">{userProfileData ? userProfileData.length : '-'}</p>
              </div>
              <div className="p-4 bg-gray-100 rounded-md">
                <h3 className="FormStyling text-center titleTextColor">Profiles per User</h3>
                <p className="FormStyling text-center">{getRoundedAverageUserProfilesPerUser ? getRoundedAverageUserProfilesPerUser : '-'}</p>
              </div>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-20">

<div className="col-span-2 text-left">
           <button className="btnChange px-4 py-2 rounded" onClick={()=> setShowKeyKPIs(!getShowKeyKPIs)} ><h2 className="FormStyling  text-black text-4xl mb-2">Key KPIs</h2></button>
           </div>
           { getShowKeyKPIs && (
            <>
            <div className="p-4 bg-gray-100 rounded-md">
              <h3 className="FormStyling text-center text-xl titleTextColor">Search Lector</h3>
              <p className="FormStyling text-center">{counts.lector ? counts.lector : '-'}</p>
            </div>
            <div className="p-4 bg-gray-100 rounded-md">
              <h3 className="FormStyling text-center text-xl titleTextColor">Search Testreader</h3>
              <p className="FormStyling text-center">{counts.testReader ? counts.testReader : '-'}</p>
            </div>
            <div className="p-4 bg-gray-100 rounded-md">
              <h3 className="FormStyling text-center text-xl titleTextColor">Search CoverDesigner</h3>
              <p className="FormStyling text-center">{counts.coverDesigner ? counts.coverDesigner : '-'}</p>
            </div>
            <div className="p-4 bg-gray-100 rounded-md">
              <h3 className="FormStyling text-center text-xl titleTextColor">Search WriteBuddy</h3>
              <p className="FormStyling text-center">{counts.writeBuddy ? counts.writeBuddy : '-'}</p>
            </div>

      <div className="col-span-2">
      <h2 className="FormStyling  text-black text-4xl mb-2">Talks</h2>
      <div className="p-4 bg-gray-100 rounded-md">
              <h3 className="FormStyling text-center text-2xl titleTextColor">All Talks</h3>
              <p className="FormStyling text-center">{talksData ? talksData.length : '-'}</p>
            </div>
           <h2 className="FormStyling  text-black text-2xl mb-2">Overall Author Talks with Specific Profiles</h2>
           </div>
            <div className="p-4 bg-gray-100 rounded-md">
              <h3 className="FormStyling text-center text-xl titleTextColor">Talks with Editors</h3>
              <p className="FormStyling text-center">{overallTalkCounts.talksWithLectors ? overallTalkCounts.talksWithLectors : '-'}</p>
            </div>
            <div className="p-4 bg-gray-100 rounded-md">
              <h3 className="FormStyling text-center text-xl titleTextColor">Talks with Test Readers</h3>
              <p className="FormStyling text-center">{overallTalkCounts.talksWithTestReaders ? overallTalkCounts.talksWithTestReaders : '-'}</p>
            </div>
            <div className="p-4 bg-gray-100 rounded-md">
              <h3 className="FormStyling text-center text-xl titleTextColor">Talks with CoverDesigner</h3>
              <p className="FormStyling text-center">{overallTalkCounts.talksWithCoverDesigners ? overallTalkCounts.talksWithCoverDesigners : '-'}</p>
            </div>
            <div className="p-4 bg-gray-100 rounded-md">
              <h3 className="FormStyling text-center text-xl titleTextColor">Talks with Publisher</h3>
              <p className="FormStyling text-center">{overallTalkCounts.talksWithPublishers ? overallTalkCounts.talksWithPublishers : '-'}</p>
            </div>
            </>
           )}

      </div>

       

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-20">

        <div className="col-span-2 text-left mb-20">
        <button className="btnChange px-4 py-2 mb-4 rounded" onClick={()=> setShowUsersLogginPerTime(!getShowUsersLogginPerTime)} ><h2 className="FormStyling  text-black text-4xl mb-2">Users Logged In per Time</h2></button>
        { getShowUsersLogginPerTime && (
        
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {['1H', 'D', 'W', 'M', '3M', '6M', 'Y'].map((timeframe, idx) => (
              <div key={idx} className="p-4 bg-gray-100 rounded-md text-center">
                <h3 className="FormStyling">{timeframe}</h3>
                <p className="FormStyling">{getUsersLoggedInTimeArray ? getUsersLoggedInTimeArray[idx] : '-'}</p>
                <p className="FormStyling titleTextColor">per all User</p>
                <p className="FormStyling">
                  {timeframe === '1H' && ( getPercentageLoggedInHour + ' % | ' + userData.length)} 
                  {timeframe === 'D' && ( getPercentageLoggedInDay + ' % | ' + userData.length )} 
                  {timeframe === 'W' && ( getPercentageLoggedInWeek + ' % | ' + userData.length)} 
                  {timeframe === 'M' && ( getPercentageLoggedInMonth + ' % | ' + userData.length )} 
                  {timeframe === '3M' && ( getPercentageLoggedInThreeMonth + ' % | ' + userData.length)} 
                  {timeframe === '6M' && (getPercentageLoggedInSixMonth + ' % | ' + userData.length)} 
                  {timeframe === 'Y' && ( getPercentageLoggedInYear + ' % | ' + userData.length)} 
                
                </p>
              </div>
            ))}
          </div>
        )}
        </div>

        <div className="col-span-2 text-left mb-20">
        <button className="btnChange px-4 py-2 mb-4 rounded" onClick={()=> setShowUsersCreatedPerTime(!getShowUsersCreatedPerTime)} ><h2 className="FormStyling text-black text-4xl mb-2">Created User Account Count per Time</h2></button>
         { getShowUsersCreatedPerTime && (
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {['1H', 'D', 'W', 'M', '3M', '6M', 'Y'].map((timeframe, idx) => (
              <div key={idx} className="p-4 bg-gray-100 rounded-md text-center">
                <h3 className="FormStyling">{timeframe}</h3>
                <p className="FormStyling">{getUsersCreatedUserProfilesTimeArray ? getUsersCreatedUserProfilesTimeArray[idx] : '-'}</p>
              </div>
            ))}
          </div> )}
        </div>

        <div className="col-span-2 text-left mb-20 ">
        <button className="btnChange px-4 py-2 mb-4 rounded " onClick={()=> setShowProfiles(!getShowProfiles)} ><h2 className="FormStyling  text-black text-4xl mb-2">Profiles</h2></button>
          { getShowProfiles && (
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {[
              { title: 'Author', data: authorData, percentage: getPercentageAuthor, percentageAllProfiles: getPercentageAuthorAllProfiles },
              { title: 'Editor', data: editorData, percentage: getPercentageEditor, percentageAllProfiles: getPercentageEditorAllProfiles },
              { title: 'Testreader', data: testReaderData, percentage: getPercentageTestreader, percentageAllProfiles: getPercentageTestreaderAllProfiles },
              { title: 'Book', data: bookData, percentage: getPercentageBooksPerAuthor, percentageAllProfiles: getPercentageBooksPerUser },
              { title: 'Cover Designer', data: coverDesignerData, percentage: getPercentageCoverDesigner, percentageAllProfiles: getPercentageCoverDesignerAllProfiles },
              { title: 'Publisher', data: publisherData, percentage: getPercentagePublisher, percentageAllProfiles: getPercentagePublisherAllProfiles }
            ].map((profile, idx) => (
              <div key={idx} className="p-4 bg-gray-100 rounded-md text-center">
                <h3 className="FormStyling">{profile.title}</h3>
                <p className="FormStyling">{profile.data ? profile.data.length : '-'}</p>
                <p className="FormStyling titleTextColor">per all User</p>
                <p className="FormStyling">{profile.percentage + ' % | ' + userData.length}</p>
                <br />
                <p className="FormStyling titleTextColor">per all Profiles</p>
                <p className="FormStyling">{profile.percentageAllProfiles + ' % | ' + userProfileData.length}</p>
              </div>
            ))}
          </div> )}
        </div>

      <div className="w-full text-left">
      <button className="btnChange px-4 py-2 mb-4 rounded" onClick={()=> setShowRatingsFiles(!getShowRatingsFiles)} ><h2 className="FormStyling ml-5 text-black text-4xl  text-center">Ratings and Files</h2></button>
              { getShowRatingsFiles && (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center mt-4" style={{ minWidth: '300px', minHeight: '50px' }}>

          <div className="p-4 bg-gray-100 rounded-md">
            <h3 className="FormStyling">Ratings</h3>
            <h4 className="FormStyling">{ratingData ? ratingData.length : '-'}</h4>
          </div>

          <div className="p-4 bg-gray-100 rounded-md">
            <h3 className="FormStyling">Files</h3>
            <h4 className="FormStyling">{filesData ? filesData.length : '-'}</h4>
          </div>
        </div>  )}
      </div>



      </div>
    </div>
  </div>
</div>





</>  
    
    
  );
}

export default AdminAnalysisDashboard; 
export { default as AdminAnalysisDashboard } from './AdminAnalysisDashboard';