import './CompFourAppFunc.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { FaUser, FaChartLine, FaUserFriends, FaComments, FaCogs, FaSearch  } from 'react-icons/fa';
import { CgProfile } from "react-icons/cg";
import { BsFillChatLeftTextFill } from "react-icons/bs";

import compFourAppFuncPicMyProfile from '../../images/landingPage_Func_MyProfile6.png';
import compFourAppFuncPicDashboard from '../../images/Dashboard_Landing_Pic.png';
import compFourAppFuncPicChat from '../../images/landingPage_Func_Chat6.png';
import compFourAppFuncPicOthProfile from '../../images/landingPage_Func_OthProfile6.png';
import compFourAppFuncPicProfile from '../../images/landingPage_Func_Profile5.png';
import compFourAppFuncPicForum from '../../images/landingPage_Func_Forum2.png';

const features = [
  {
    icon: <CgProfile />,
    title: 'Profile',
    description: 'Du hast die Möglichkeit, ein Profil als Autor, Lektor & Korrektor, Testleser, Verlag oder Coverdesigner zu erstellen. Sobald du mehrere Rollen nutzen möchtest, erstelle einfach die zusätzlichen Profile in deinem Benutzerkonto.',
    image: compFourAppFuncPicProfile,
  },
  {
    icon: <FaUser />,
    title: 'Mein Profil',
    description: 'Hier findest du eine Übersicht über all deine Informationen und kannst Einstellungen vornehmen. Wenn du nicht schon beim Erstellen deines Profils alle Informationen angegeben hast, kannst du hier weitere hinzufügen oder ändern.',
    image: compFourAppFuncPicMyProfile,
  },
  {
    icon: <FaUserFriends />,
    title: 'Profilansichten',
    description: 'In dieser Ansicht besuchst du die Profile von Kollegen, Freunden oder zukünftigen Partnern und erhältst alle Informationen, die sie teilen. Du kannst ihnen auch Rechte vergeben oder bei Zusammenarbeit eine Bewertung hinterlassen.',
    image: compFourAppFuncPicOthProfile,
  },
  {
    icon: <FaSearch />,
    title: 'Dashboard',
    description: 'Im Dashboard, dem Herzstück unserer Plattform, siehst du alle existierenden Profile, sortiert nach der Wertung der Bewertungen. Zusätzlich kannst du nach spezifischen Kriterien filtern und suchen.',
    image: compFourAppFuncPicDashboard,
  },
  {
    icon: <FaComments />,
    title: 'Chat',
    description: 'Natürlich kannst du auch, nachdem du im Dashboard einen interessanten Kontakt gefunden hast, direkt ein Gespräch in unserem Chat starten.',
    image: compFourAppFuncPicChat,
  },
  {
    icon: <BsFillChatLeftTextFill />,
    title: 'Forum',
    description: 'Im Forum kannst du interessanten Gesprächen folgen oder selbst aktiv werden und Themen erstellen.',
    image: compFourAppFuncPicForum,
  },
];

function CompFourAppFunc() {
  return (
<div className="FeatureGrid grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
  {features.map((feature, index) => (
    <div 
      key={index} 
      className="feature-card  text-black p-4 bg-opacity-50 bg-white rounded-xl shadow-lg text-center transform transition-transform flex flex-col justify-between"
    >
      <div>
        <div className="icon text-4xl mb-4">{feature.icon}</div>
        <h3 className="text-3xl font-semibold mb-2">{feature.title}</h3>
        <img 
          src={feature.image} 
          alt={feature.title} 
          className="w-full h-90 object-cover rounded-lg mb-4" 
        />
      </div>
      <p className="mt-5 text-2xl sm:text-3xl" style={{ textAlign: 'justify' }}>{feature.description}</p>
    </div>
  ))}
</div>

  );
}

export default CompFourAppFunc; 
export { default as CompFourAppFunc } from './CompFourAppFunc';