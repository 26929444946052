import './css/CoverDesignerView.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import { BsChatDots } from "react-icons/bs";
import { GrApps } from "react-icons/gr";


import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoReloadCircle } from "react-icons/io5";
import { IoCheckmarkCircle } from "react-icons/io5";

import { ImSpinner9 } from "react-icons/im";  // zum laden anzeigen

function IconWithTooltip({ icon, tooltipText }) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
        className=""
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {React.cloneElement(icon, {
          style: { fontSize: '10px' },
          title: isHovered ? tooltipText : '',
        })}
      </div>
    );
  }


const CoverDesignerView = ({baseUrl2}) => {


    const [getEntityData,setEntityData,  ] = useState([]);
    const [getSingleEntityData,setSingleEntityData, ] = useState([]);                          
    const [getSinglePrevBookData,setSinglePrevBookData,] = useState([]);

                                
    const [getRatings,setRatings, ] = useState([]);                         
    const [getSingleRating,setSingleRating, ] = useState([]); 

    const [showSearchRatingRow,setShowSearchRatingRow, ] = useState([false]); 
    const [searchInputValue,setSearchInputValue] = useState([]);

    const [getSortedScoreArray,setSortedScoreArray ] = useState([]);

    const [activeSortColumn, setActiveSortColumn] = useState('rating');

    
    const [sortConfig, setSortConfig] = useState('0', 'ascending');
    const [getSortedData, setSortedData] = useState([]);
    const [render, setRender] = useState(false);
    const [partRender, setPartRender] = useState(false);
    const [getRankHrefClick, setRankHrefClick] = useState(false);

    const [inputTrueGenres, setInputTrueGenres] = useState([]);
    const [changeGenreBanner, setChangeGenreBanner] = useState(false);

    const [getTotalPageNumber, setTotalPageNumber] = useState(1);
    const [showLoadingData, setShowLoadingData] = useState(false);
    const [singleEntityArray, setSingleEntityArray] = useState([]);
    const [pageNum, setPageNum] = useState('');
    const [entityDataArray, setEntityDataArray] = useState([]);

    const [error, setError] = useState(null);


       const navigate = useNavigate();
      
           

       const getEntity = async (pageVar) => {
       
        const numericPageVar = Number(pageVar); // Ensure pageVar is a number
      
        // Check if the requested page is already in the cache
        const cachedPage = entityDataArray.find((entry) => entry.page === numericPageVar);
        if (cachedPage) {
          //console.log(2);
          return cachedPage.data; // Return the cached data for this page
        }
    
        // If the page is not cached, make the API call
        try {
          const response = await api.get(`/api/coverDesigners/paginated`, {
            params: { pageVar: numericPageVar }, // Send the pageVar as a query parameter
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
    
          // Destructure authors and totalCount from the response
          const [entities, totalCount] = response.data;
          const totalPageNum = Math.ceil(totalCount / 25);
          setTotalPageNumber(totalPageNum);
    
          // Add the new page data to the cache
          let newCache = [...entityDataArray]; // Create a new copy of the cache
    
          if (newCache.length >= 5) {
            // Find the index of the furthest page from the current page to remove
            const furthestPageIndex = newCache.reduce((furthestIndex, entry, index) => {
              const distance = Math.abs(entry.page - numericPageVar);
              const furthestDistance = Math.abs(newCache[furthestIndex].page - numericPageVar);
              return distance > furthestDistance ? index : furthestIndex;
            }, 0);
    
            // Overwrite the furthest page data with the new data
            newCache[furthestPageIndex] = { page: numericPageVar, data: entities };
          } else {
            // Add the new data to the cache
            newCache = [...newCache, { page: numericPageVar, data: entities }];
          }
          //console.log(1);
          setEntityDataArray(newCache); // Update the original cache state
    
          return entities;
    
        } catch (error) {
          handleError('Error fetching entities:', error);
        }
      }; 
  
      const fetchFirstTimeData = async (pageVar) => {
  
        try {
  
          if(pageVar !== ''){
          setShowLoadingData(true);
          setPageNum('');
          const Entities = await getFirstTimeEntities(pageVar);
          setEntityData(Entities);

          setTimeout(() => {                  
            sortRatingsAndGiveOrder(Entities, pageVar);
            setShowLoadingData(false);

          }, 100);
        }
  
        } catch (error) {
          handleError('Error fetching entities:', error);
        }
      };
  
      const getFirstTimeEntities = async (pageVar) => {
        const numericPageVar = Number(pageVar); // Ensure pageVar is a number
  
            // Check if the requested page is already in the cache
            const cachedPageIndex = entityDataArray.findIndex((entry) => entry.page === numericPageVar);
          
        // If the page is not cached, make the API call
        try {
          const response = await api.get(`/api/coverDesigners/paginated`, {
            params: { pageVar: numericPageVar }, // Send the pageVar as a query parameter
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
    
          // Destructure authors and totalCount from the response
          const [entities, totalCount] = response.data;
          const totalPageNum = Math.ceil(totalCount / 25);
          setTotalPageNumber(totalPageNum);

          let newCache = [...entityDataArray]; // Create a new copy of the cache

          if (cachedPageIndex !== -1) {
            // Replace the cached entry with the new data
            newCache[cachedPageIndex] = { page: numericPageVar, data: entities };
          } else {
            // Add the new page data if not cached before
            newCache = [...newCache, { page: numericPageVar, data: entities }];
          }

          setEntityDataArray(newCache);
          //console.log(newCache);
  
          return entities;
    
        } catch (error) {
          handleError('Error fetching entities:', error);
        }
      };  
      
      const getSingleEntity = async (e) => {
        e.preventDefault();
        const { target } = e;
      
        const addNewData = {
          coverDesignerName: target.name.value,
        };
      
        try {
          const response = await api.get(`/api/testReaders/search/${addNewData.coverDesignerName}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });

          const firstItem = response.data[0];

          // Extract the author object and the rank
          const entity = firstItem[0];  // First author object
          const rank = firstItem[1];    // The rank of the first author
        
          const singleEntity = entity;
                    
          function calculateAverageScore(userRatings) { 
            if(userRatings.length > 0) {
              const totalScore = userRatings.reduce((sum, score) => sum + score.ratingScore, 0);
              const averageScore = totalScore / userRatings.length;
              const roundedAverageRatingScore = averageScore.toFixed(1);
              //console.log(roundedAverageRatingScore);
              return roundedAverageRatingScore;
            }
            
          }

            const averageScore = calculateAverageScore(singleEntity.ratingsReceivedIds);

            const trueGenres = Object.keys(singleEntity.genresIds[0])
            .filter(genre => singleEntity.genresIds[0][genre] && genre !== 'id' && genre !== 'createdAt')
            .map(genre => genre.charAt(0).toUpperCase() + genre.slice(1)) 
            .join(', '); 
            
            //console.log("trueGenres", trueGenres);

           const newSingleEntityArray = [rank, averageScore, entity.coverDesignerName, entity.age,
            trueGenres, entity.prevBookProjectsIds.length, entity.follower ];

          setSingleEntityArray(newSingleEntityArray);
      
          setSingleEntityData(singleEntity);

          setSinglePrevBookData(singleEntity.prevBookProjectsIds);
          showSingleRatings(singleEntity.coverDesignerName);
        } catch (error) {
          handleError('Error fetching single Entity:', error);
        }
      };
      
      
      const showSingleRatings = async (entityName) => {
        const newShowSearchRatingRow = true;
        setShowSearchRatingRow(newShowSearchRatingRow);
        localStorage.setItem('showSearchRatingRow', 'true');
      
        try {
          getEntityData.forEach((entity) => {
            if(entity.authorName === entityName){
              setSingleRating(entity.ratingsReceivedIds);
            }
          });

          //console.log('getSingleRating:', getSingleRating);
        } catch (error) {
          handleError('Error fetching single ratings:', error);
        }
      };

      const deleteSearchSingleEntity = () => {
        setSearchInputValue('');
      
        const newShowSearchRatingRow = false;
        setShowSearchRatingRow(newShowSearchRatingRow);
        localStorage.setItem('showSearchRatingRow', 'false');
      };
      
      const handleSearchInputChange = (e) => {
        setSearchInputValue(e.target.value);
      };
      
      const fetchData = async (pageVar) => {
        try {

          if(pageVar !== ''){
            setShowLoadingData(true);
            setPageNum('');

          const Entities = await getEntity(pageVar);

          if(Entities !== null && Entities !== '' && Entities !== undefined){
          setEntityData(Entities);
          //console.log('Editors: ',Entities )

          setTimeout(() => {                  
            sortRatingsAndGiveOrder(Entities, pageVar);
            setShowLoadingData(false);

          }, 100);
        }
      }
        } catch (error) {
          handleError('Error fetching Entities:', error);
        }
      };
      
      
      const updateSearchHtml = () => {
        try {
          const storedShowSearchRatingRow = localStorage.getItem('showSearchRatingRow');
          setShowSearchRatingRow(storedShowSearchRatingRow === 'true');
        } catch (error) {
          handleError('Error updating search HTML:', error);
        }
      };

const usingSortableData = (items) => {

        let sortableItems = [...items];

        if (sortConfig !== null) {
          // Flip the order when sortConfig.key is '1'
          if (sortConfig.key === '1') {
            // Simply reverse the current order based on the direction
            if (sortConfig.direction === 'descending') {
              sortableItems.reverse();
            }
            return setSortedData(sortableItems);  // Return early after flipping
          }
        }


        if (sortConfig !== null) {
          sortableItems.sort((a, b) => {

       //sort genres
       if (sortConfig.key === '4') {

        // Function to count overlapping genres
        const countOverlap = (genres) => {
          if (!genres) return 0;
          const genresArray = genres.split(', ').map(genre => genre.toUpperCase());
          return genresArray.reduce((count, genre) => {
              if (inputTrueGenres.includes(genre)) {
                  return count + 1;
              }
              return count;
          }, 0);
      };

      //console.log("inputTrueGenres: ", inputTrueGenres);

      const overlapCountA = countOverlap(a[4]);  // Index 4 is where genres are stored
      const overlapCountB = countOverlap(b[4]);

      // Sort based on overlap count
      if (overlapCountA < overlapCountB) {
          return sortConfig.direction === 'descending' ? 1 : -1;
      }
      if (overlapCountA > overlapCountB) {
          return sortConfig.direction === 'descending' ? -1 : 1;
      }

    };


            // sort age numerical
            if (sortConfig.key === '3' || sortConfig.key === '6') {
              const A = parseInt(a[sortConfig.key], 10);
              const B = parseInt(b[sortConfig.key], 10);
              if (!A) {return sortConfig.direction === 'descending' ? -1 : 1;
              }
              if (!B) {return sortConfig.direction === 'descending' ? 1 : -1;
              } 
              if (A < B) {return sortConfig.direction === 'descending' ? -1 : 1;
              }
              if (A > B) {return sortConfig.direction === 'descending' ? 1 : -1;
              }
              return 0;
            };
              // sort alphabetically
            if (sortConfig.key === '2') {
              if (!a[sortConfig.key]) {return sortConfig.direction === 'descending' ? -1 : 1;
              }
              if (!b[sortConfig.key]) {return sortConfig.direction === 'descending' ? 1 : -1;
              } 
              return sortConfig.direction === 'descending' ? 
              b[sortConfig.key].localeCompare(a[sortConfig.key])  : a[sortConfig.key].localeCompare(b[sortConfig.key]);
            }
            
            // sort others
            if (sortConfig.key === '4'|| sortConfig.key === '5' || sortConfig.key === '6' ) {
              if (isNaN(a[sortConfig.key])) {return sortConfig.direction === 'descending' ? -1 : 1;
              }
              if (isNaN(b[sortConfig.key])) { return sortConfig.direction === 'descending' ? 1 : -1;
              }
              if (a[sortConfig.key] < b[sortConfig.key]) {return sortConfig.direction === 'descending' ? -1 : 1;
              }
              if (a[sortConfig.key] > b[sortConfig.key]) {return sortConfig.direction === 'descending' ? 1 : -1;
              }
              return 0;
            }
            return 0;
            });
          }
      setSortedData(sortableItems);
    
      
    };

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
      partRerender();
    };


    const sortRatingsAndGiveOrder = ((entityData, pageVar) => {

      const newArray = [];
try{
      if(entityData != null && entityData !== ""){

      function calculateAverageScore(userRatings) { 
        if(userRatings.length > 0) {
          const totalScore = userRatings.reduce((sum, score) => sum + score.ratingScore, 0);
          const averageScore = totalScore / userRatings.length;
          const roundedAverageRatingScore = averageScore.toFixed(1);
          //console.log(roundedAverageRatingScore);
          return roundedAverageRatingScore;
        }
    }

    entityData.forEach((entity, index) => {
      const averageScore = calculateAverageScore(entity.ratingsReceivedIds);
            
      const trueGenres = Object.keys(entity.genresIds[0])
      .filter(genre => entity.genresIds[0][genre] && genre !== 'id' && genre !== 'createdAt')
      .map(genre => genre.charAt(0).toUpperCase() + genre.slice(1)) 
      .join(', '); 
      
      //console.log("trueGenres", trueGenres);
    		newArray[index] = [((pageVar-1)*25)+index, averageScore, entity.coverDesignerName, entity.age,
				 trueGenres, entity.prevBookProjectsIds.length, entity.follower ];
	});

          //console.log('newArray1Sorted:', newArray );     
     
          //giving rank
          newArray.forEach((row, index) => {
            const rank = ((pageVar - 1) * 25) + (index + 1);  // Ascending rank calculation
            row[0] = rank;  // Directly assign to the first column (rank)
          });

          usingSortableData(newArray);
          setSortedScoreArray(newArray);
          //console.log('newArraySorted:', newArray );
        }
      } catch (error) {
        handleError('Error sorting Entities and Genres:', error);
      }
  });


  const changeOtherProfileName = (otherProfileName, otherProfileType) => {
    localStorage.setItem('siteProfile', otherProfileName);
    localStorage.setItem('otherProfileType', otherProfileType);
    setRankHrefClick(true);
};

  const toggleCollapse = (column) => {   
        setActiveSortColumn(column);    
    };

      
    const rerender = () => {
      setRender(!render);
    }

    const partRerender = () => {
      setPartRender(!partRender);
    }


   const changeToChat = (entityName, e) => {
      e.stopPropagation();
      localStorage.setItem('chatEntityName', entityName);
      //console.log('coverdesigner localsto:',localStorage.getItem('chatEntityName'));
      //console.log('coverdesigner entityName:', entityName);
    }

    const [checkedGenreOptions, setCheckedGenreOptions] = useState({
      fiction: false, nonFiction: false, mystery: false, thriller: false, romance: false,
      fantasy: false, scienceFiction: false, horror: false, historical: false, youngAdult: false, newAdult: false,
       children: false, adventure: false, biography: false, selfHelp: false, poetry: false, drama: false,
      crime: false, graphicNovels: false, shortStories: false, dystopian: false, paranormal: false, memoir: false,
       cookbooks: false, spirituality: false, travel: false, science: false, history: false
    });
    
    const handleGenreCheckboxChange = (option) => {
      setCheckedGenreOptions((prevOptions) => ({
        ...prevOptions,
        [option]: !prevOptions[option],
      }));
    };

    const handlePageNumChange = (e) => {
      const value = e.target.value;
      const regex = /^\d*$/; 
    
      // Check that value is a number, not zero, and within the valid range
      if (regex.test(value) && (value === '' || (parseInt(value) > 0 && parseInt(value) <= getTotalPageNumber))) {
        const inputValue = value;
        setPageNum(inputValue);
      }
    };
    
    useEffect(() => {
        const getCheckedGenres = () => {
            return Object.keys(checkedGenreOptions).filter(genre => checkedGenreOptions[genre]);
        };
        setInputTrueGenres(getCheckedGenres().map(genre => genre.toUpperCase()));
    }, [checkedGenreOptions]);

      
    useEffect(() => {
      const key = "1";
      const direction = 'ascending';
      setSortConfig({ key, direction });
      localStorage.setItem('pageNum', 1);
      fetchFirstTimeData(1);               
      setRankHrefClick(false);
      localStorage.setItem('chatEntityName', '');
    }, []);
    
    useEffect(() => {
      deleteSearchSingleEntity();
      updateSearchHtml();
      localStorage.setItem('chatEntityName', '');
    }, []);

    useEffect(() => {
      //fetchData(); 
    }, [render, getRankHrefClick]);

    useEffect(() => { 
      //fetchRatings(getAuthorData);         
       // sortRatingsAndGiveOrder(getAuthorData);
        usingSortableData(getSortedScoreArray);
    }, [partRender]);

      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

///////////////////////////////// Logout redirect //////////////////////////7

   useEffect(() => {
    LogoutRedirectCheck(navigate);
  }, [navigate, searchInputValue, showSearchRatingRow ]);
  
///////////////////////////////// Logout redirect //////////////////////////7 

  return (
    <div className="CoverDesignerView z-40">
    <div className="container ">
      {/*{error && <div style={{ color: 'red' }}>{error}</div>} */}
      <div className="container-sm ViewCol dashboardSearchDataCard white-glassmorphism-card">
        <div className="flex items-center mb-2 m-2">
          <h5 className="mr-4 text-xl font-bold">SUCHE</h5>
          <form onSubmit={getSingleEntity} className="flex flex-col sm:flex-row w-full gap-2">
            <input
              className="InputFieldOne flex-grow p-2 border border-gray-300 rounded"
              placeholder="Coverdesignername..."
              name="name"
              type="text"
              value={searchInputValue}
              onChange={handleSearchInputChange}
            />
            <div className="flex flex-col sm:flex-row gap-2">
              <button
                className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                type="submit"
              >
                Bestätigen
              </button>
              <button
                className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                type="button"
                onClick={deleteSearchSingleEntity}
              >
                Entfernen
              </button>
            </div>
          </form>
        </div>
      </div>


      { changeGenreBanner && (
                  <div className="absolute z-30 white-glassmorphism-card bg-gray-200 rounded-lg  px-5 mr-14 min-sm:right-2/3 group-hover:block mt-28 top-16">
                        <div className="flex flex-wrap gap-4">
                            {Object.entries(checkedGenreOptions).map(([genre, isChecked]) => (
                              <div key={genre} className="flex items-center mb-4 mx-3 text-3xl text-[#8b90a5] hover:text-[#babbcf]">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => handleGenreCheckboxChange(genre)}
                                  id={genre}
                                  className="cursor-pointer rounded items-center  mb-6 border-gray-300"
                                />
                                <label htmlFor={genre} className="capitalize mt-3 ml-2">{genre}</label>
                              </div>
                            ))}
                          </div>
                  </div>
            )}
  
  <div className="container-sm ViewCol my-2 dashboardPageChangeTopCard white-glassmorphism-card">
          <div className="flex items-center text-center justify-center">

           <button 
              className="text-gray-600 rounded my-6 mr-6 scale-150" 
              onClick={() => {
                const pageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                fetchFirstTimeData(pageNum);
              }}
            >
              <IconWithTooltip icon={<IoReloadCircle className="mr-1  max-sm:mr-0 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
            </button>

            <p className='FormStyling max-sm:text-lg mr-6'>Seite {localStorage.getItem('pageNum') || 1} von {getTotalPageNumber}</p>

            {parseInt(localStorage.getItem('pageNum')) > 1 && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}

            <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum')) || 1) - 1) * 25} - {(parseInt(localStorage.getItem('pageNum')) || 1) * 25}</p>

            {parseInt(localStorage.getItem('pageNum')) < getTotalPageNumber && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum + 1;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowForward />
              </button>
            )}

            <p className="FormStyling max-sm:text-lg ml-6 max-sm:ml-4">Gehe zu Seite</p>
            <input 
              className="-py-1 my-3 ml-2 rounded-xl text-center FormStyling2" 
              style={{ width: '1cm' }} 
              name="pageNumber" 
              onChange={handlePageNumChange} 
              value={pageNum} 
            />

            <button 
              className="text-gray-600 rounded my-6 -ml-1 scale-150 p-0 m-0" 
              onClick={() => {
                const newPageNumber = pageNum !== '' ? pageNum : (parseInt(localStorage.getItem('pageNum')) || 1);
                localStorage.setItem('pageNum', parseInt(newPageNumber));
                fetchData(newPageNumber);
              }}
            >
              <IconWithTooltip icon={<IoCheckmarkCircle className="ml-6 max-sm:ml-5 scale-150" />} tooltipText="Gehe zur gewählten Seite." />
            </button>

    
          </div>
        </div>


        <div className="container-sm overflow-hidden dashboardDataCard white-glassmorphism-card">
      
      <div className={`overflow-auto -mb-8  max-sm:pb-1 `}  style={{maxHeight: 1000}}>

{ showLoadingData === false && ( 
    <table className="table table-bordered table-responsive table-hover mb-0">
      <thead>
        <tr>
          <th className='sticky top-0  white-glassmorphism-table'><h6 className="ml-0 mb-7 scale-75 "> <IconWithTooltip icon={<BsChatDots className='scale-150' />} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." /></h6></th>
          <th  className="sticky top-0  white-glassmorphism-table text-center align-middle"><div className=' sm:hidden mb-5'>Nr.</div><div className=' max-sm:hidden mb-5'>Nr.</div></th>
          <th  className="sticky top-0  white-glassmorphism-table text-center align-middle"><div className=' sm:hidden mb-5'>Rang</div><div className=' max-sm:hidden mb-5'>Rang</div></th>
          <th  onClick={() => { requestSort('1'); toggleCollapse('rating'); }} className="sticky top-0  white-glassmorphism-table text-center">
                  <div className='row text-center -mb-2'><div className=' sm:hidden'>Wert</div><div className='  max-sm:hidden mb-1.5'>Wert</div></div>
                  <div className='row max-sm:pl-9 pl-5'>{activeSortColumn === 'rating' ? (<CgChevronDown />) : (<CgChevronRight />)}</div>
          </th>
          <th  onClick={() => {requestSort('2'); toggleCollapse('name');}} className="sticky top-0  white-glassmorphism-table text-left"><div className=' ml-4 sm:hidden'>Name</div><div className='ml-4 max-sm:hidden'>Name</div>{activeSortColumn === 'name' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
          <th  onClick={() => {requestSort('3'); toggleCollapse('age');}} className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden" >Alter{activeSortColumn === 'age' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
          <th className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden ">
                    <div className="align-items-center -mr-3">
                      <div className='flex ml-6'>Genres
                          <div className='mt-2 ml-2 mr-3 scale-100' onClick={() => {setChangeGenreBanner(!changeGenreBanner);}}><IconWithTooltip icon={<GrApps className='scale-250'/>} tooltipText="Filter nach den gewünschten Genres." /></div></div>
                            <div className="text-center max-sm:hidden ml-2" onClick={() => {requestSort('4'); toggleCollapse('genre');}}>
                              {activeSortColumn === 'genre' ? (<CgChevronDown />) : (<CgChevronRight />)}
                      </div>
                    </div>
          </th>
          <th  onClick={() => {requestSort('5'); toggleCollapse('prevProjects');}} className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden">Projekte{activeSortColumn === 'prevProjects' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
          <th  onClick={() => {requestSort('6'); toggleCollapse('follower');}} className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden"><div className='TextBold sm:hidden'>Follower</div><div className='TextBold max-sm:hidden'>Follower</div>{activeSortColumn === 'follower' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
	      </tr>
            </thead>
            <tbody className="table-group-divider">
  
            {showSearchRatingRow && (
            <>
                <tr className="table-active">
              
                  {localStorage.getItem('profileName') !== singleEntityArray[2] && ( 
                      <td className=' p-0 m-0 mt-2'>
                      <a 
                        href="/chat" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        onClick={(e) => changeToChat(singleEntityArray[2], e)}
                      >
                        <button className="bg-gray-600 text-white rounded scale-150">
                          <IconWithTooltip className="p-0 m-0" icon={<BsChatDots className="scale-150"/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                        </button>
                      </a>
                      </td>
                  )}
                   {localStorage.getItem('profileName') === singleEntityArray[2] && ( 
                        <td className='p-1'></td>
                                      )}
                
                  <td className="text-center min-sm:p-0"><h6 className="p-0 m-0">0</h6></td>  
                  <td className="col text-center  min-sm:p-0">
                     <a
                       href={`${baseUrl2}/profile?othProfileName=${singleEntityArray[2]}&othProfileType=CoverDesigner`}
                       target="_blank"
                       rel="noreferrer"
                       onClick={() => changeOtherProfileName(singleEntityArray[2], 'CoverDesigner')}
                      >
                      <h6 className="TextBold p-0 m-0">{singleEntityArray[0]?'#'+ singleEntityArray[0]:singleEntityArray[0]===0?'#1':'-'}</h6>
                    </a>
                  </td>
                  <td className=""><h6 className='p-0 m-0'>{isNaN(singleEntityArray[1])?'-':singleEntityArray[1]}</h6></td>                    
                  <td className=" text-center">
                      <a
                          href={`${baseUrl2}/profile?othProfileName=${singleEntityArray[2]}&othProfileType=CoverDesigner`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => changeOtherProfileName(singleEntityArray[2], 'CoverDesigner')}
                          >
                        <h6 className='p-0 m-0'>{singleEntityArray[2]?singleEntityArray[2]:'-'}</h6>
                        </a>
                  </td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{singleEntityArray[3]?singleEntityArray[3]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{singleEntityArray[4]?singleEntityArray[4]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{singleEntityArray[5]?singleEntityArray[5]:'-'}</h6></td>   
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{singleEntityArray[6]?singleEntityArray[6]:'-'}</h6></td>         
                </tr>
            </>
            )}
    
    {getSortedData.map((entity,index) => (
                <tr key={entity[2]} className="align-middle text-center justify-center items-center h-10">

              {localStorage.getItem('profileName') !== entity[2] && ( 
                     <td className='mt-2 p-0 m-0'>
                     <a 
                        href="/chat" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        onClick={(e) => changeToChat(entity[2], e)}
                      >
                        <button className="bg-gray-600 text-white rounded scale-150 p-0 m-0">
                        <IconWithTooltip className="p-0 m-0" icon={<BsChatDots className="scale-75"/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                        </button>
                      </a>
                      </td>
                  )}
                 {localStorage.getItem('profileName') === entity[2] && ( 
                        <td className='p-1'></td>
                                      )}

		               <td className=" p-0"><h6 className="p-0 m-0">{index+1}</h6></td>
                  <td className="col text-center  min-sm:p-0">
                     <a
                       href={`${baseUrl2}/profile?othProfileName=${entity[2]}&othProfileType=CoverDesigner`}
                       target="_blank"
                       rel="noreferrer"
                       onClick={() => changeOtherProfileName(entity[2], 'CoverDesigner')}
                      >
                      <h6 className="TextBold p-0 m-0">{entity[0]?'#'+ entity[0]:entity[0]===0?'#1':'-'}</h6>
                    </a>
                  </td>
                  <td className="col"><h6 className='p-0 m-0'>{isNaN(entity[1])?'-':entity[1]}</h6></td>                    
                  <td className=" text-center">
                      <a
                              href={`${baseUrl2}/profile?othProfileName=${entity[2]}&othProfileType=CoverDesigner`}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => changeOtherProfileName(entity[2], 'CoverDesigner')}
                              >
                        <h6 className='p-0 m-0'>{entity[2]?entity[2]:'-'}</h6>
                      </a> 
                  </td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[3]?entity[3]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[4]?entity[4]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[5]?entity[5]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='sm:hidden p-0 m-0'>{entity[6]?entity[6]:'-'}</h6><h6 className='max-sm:hidden p-0 m-0'>{entity[6]?entity[6]:'-'}</h6></td>
                </tr>
              ))}
            </tbody>
          </table>
            )}

{ showLoadingData === true && (
            <div className="flex justify-center items-center min-h-screen">
            <div className='loadingBox white-glassmorphism-loadingBox text-center transform z-30' style={{ minWidth: '250px', maxWidth: '350px', minHeight: '100px' }}>
              <div className="flex justify-center items-center w-full h-full">
                <div className='mt-10'>
                  <ImSpinner9 className="spinning-image animate-spin" size={50} color="#a7b7c8" />
                </div>
                <div className="mt-10 ml-3 text-gray-600">Loading...</div> {/* Optional loading text */}
              </div>
            </div>
          </div>
                  
          )}
        </div>
      </div>
      <div className="container-sm ViewCol mt-2 dashboardPageChangeBottomCard white-glassmorphism-card">
          <div className="flex items-center text-center justify-center">
          <button 
              className="text-gray-600 rounded my-6 mr-6 scale-150" 
              onClick={() => {
                const pageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                fetchFirstTimeData(pageNum);
              }}
            >
              <IconWithTooltip icon={<IoReloadCircle className="mr-1 max-sm:mr-0 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
            </button>

            <p className='FormStyling max-sm:text-lg mr-6'>Seite {localStorage.getItem('pageNum') || 1} von {getTotalPageNumber}</p>

            {parseInt(localStorage.getItem('pageNum')) > 1 && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}

            <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum')) || 1) - 1) * 25} - {(parseInt(localStorage.getItem('pageNum')) || 1) * 25}</p>

            {parseInt(localStorage.getItem('pageNum')) < getTotalPageNumber && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum + 1;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowForward />
              </button>
            )}

            <p className="FormStyling max-sm:text-lg ml-6 max-sm:ml-4">Gehe zu Seite</p>
            <input 
              className="-py-1 my-3 ml-2 rounded-xl text-center FormStyling2" 
              style={{ width: '1cm' }} 
              name="pageNumber" 
              onChange={handlePageNumChange} 
              value={pageNum} 
            />

            <button 
              className="text-gray-600 rounded my-6 -ml-1 scale-150 p-0 m-0" 
              onClick={() => {
                const newPageNumber = pageNum !== '' ? pageNum : (parseInt(localStorage.getItem('pageNum')) || 1);
                localStorage.setItem('pageNum', parseInt(newPageNumber));
                fetchData(newPageNumber);
              }}
            >
              <IconWithTooltip icon={<IoCheckmarkCircle className="ml-6 max-sm:ml-5 scale-150" />} tooltipText="Gehe zur gewählten Seite." />
            </button>
          </div>
        </div>

    </div>
  </div>
  
    
    
  );
}

export default CoverDesignerView; 
export { default as CoverDesignerView } from './CoverDesignerView';