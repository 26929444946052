import api from '../../api/axiosConfig';

import './CompLogin.css';

import { useEffect, useState, Link } from 'react';
import { useNavigate } from "react-router-dom";

import Partnership_Pic from '../../images/Traumwelten Lektorat6.png';

function CompLogin () {

    const [getThereWasAnError,setThereWasAnError] = useState(false);
    const [getIsUnConfirmed, setIsUnConfirmed  ] = useState(false);
    const [getUserNotExists, setUserNotExists  ] = useState(false);
    const [getShowResetPassword, setShowResetPassword  ] = useState(false);
    const [getCurrentProfileImg, setCurrentProfileImg  ] = useState('');
    const [getProfileType, setProfileType  ] = useState('');

    const [getPasswordNotCorrect, setPasswordNotCorrect  ] = useState(true);
    
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const loginFunc = async (e) => {
        e.preventDefault();

        const { target }  = e;

        const newAuthData = {
          userName: target.userName.value,
          password: target.password.value
        }

        localStorage.setItem(`userEmail`, target.userName.value);

        try{

            const response2 = await api.get(`/api/v1/auth/user/${newAuthData.userName}` );
            const isConfirmed = response2.data.isConfirmed

            if(isConfirmed){
                if(response2.data != null){
                    const response = await api.post(`/api/v1/auth/login`, newAuthData);
                    //console.log('Authorization:', response.data);
                    localStorage.setItem(`logintoken`, response.data.token );
                    
                                          
                    const userProfileType = response2.data.userProfilesIds[0].userProfileType;
                    const userProfileName = response2.data.userProfilesIds[0].userProfileName;
                    const userRole = response2.data.userRole;
                    const userProfileIdTimestamp = response2.data.userProfilesIds[0].id.timestamp;

                    localStorage.setItem(`currentProfileType`, userProfileType);
                    localStorage.setItem(`profileName`, userProfileName);
                    localStorage.setItem(`userRole`, userRole);
                    localStorage.setItem(`userProfileIdTimestamp`, userProfileIdTimestamp);
                    //console.log(localStorage.getItem('userRole'));
                    //console.log('userProfileIdTimestamp ', localStorage.getItem(`userProfileIdTimestamp`));

                    if(userProfileType === 'Author'){
                      setProfileType('authors');
                    }
                    if(userProfileType === 'Editor'){
                      setProfileType('editors');
                    } 
                    if(userProfileType === 'Testreader'){
                      setProfileType('testReaders');
                    }
                    if(userProfileType === 'Publisher'){
                      setProfileType('publishers');
                    }
                    if(userProfileType === 'CoverDesigner'){
                      setProfileType('coverDesigners');
                    }

                    const delayTimeout = setTimeout(() => {
                    
                        const response3 = api.get(`/api/${getProfileType}/${response2.data.userProfilesIds[0].userProfileName}`, {
                          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}`}}
                        );
                        setCurrentProfileImg(response3.data.profileImg)
                        localStorage.setItem(`currentProfileImg`, response3.data.profileImg);
                        console.log("response3.data.profileImg: " , response3.data.profileImg);
                  }, 1000);
                  clearTimeout(delayTimeout);

                        if(response.data != null){
                          navigate("/dashboard");
                          localStorage.setItem(`logoutTrue`, 'false');
                        
                        }else{setPasswordNotCorrect(true)
                        }

                }else{ setUserNotExists(true)
                }
            }else {setIsUnConfirmed(true)
            }
          } catch (error) {
            handleError('Error during login:', error);
          }
    };


    const resetPassword = async (e) => {
      e.preventDefault();
    
      const { target } = e;
    
      try {
        const response2 = await api.get(`/api/v1/auth/user/${target.userName.value}`);
        const isConfirmed = response2.data.isConfirmed;
    
        if (isConfirmed) {
          if (response2.data != null) {
            const response = await api.post(`/api/v1/auth/resetPasswordEmail/${target.userName.value}`);
            if (response.data !== true) {
              setThereWasAnError(true);
            }
          } else {
            setUserNotExists(true);
          }
        } else {
          setIsUnConfirmed(true);
        }
    
      } catch (error) {
        handleError('Error during password reset:', error);
      }
    };

    const changeToCreateProfileSite = () => {
      localStorage.setItem('logoutTrue', 'false');
      navigate('/createFirstProfile');
    }

    const changeResetPassword = () => {
      setShowResetPassword(!getShowResetPassword);
    }

   
    useEffect(() => {
      setUserNotExists(false);
      setIsUnConfirmed(false);
      setShowResetPassword(false);
      setThereWasAnError(false);
      setProfileType('Empty'); 
      }, []);

      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

        return (

          <div className="max-sm:pb-80 ">
            <div className=" CompLogin text-center">
              <div className="justify-center items-center h-full mx-10 max-sm:mx-3 max-sm:mb-80 ">
        
                <div className="landing-bg landingCard text-white w-full px-6">

                    <div className='row  justify-center items-center'>
                    <div className='col-sm-12 mt-40 landingColor'>          
                        <h1 className="mb-8 text-center">Der Marktplatz für Buch-Professionals</h1>
                        <h3 className="mb-8 text-center text-white">ALPHA - Version</h3>
                      </div>
                    <div className="col col-sm-6 w-full p-8 my-40 items-center justify-center">
                

                  {error && (<div style={{ color: 'red' }}>{error}</div>)}
                  {getThereWasAnError && (<p>Es tut uns leid. Es gab einen Fehler!</p>)}
                
                <div>
                  {!getShowResetPassword && (
                    <div className=''>
                      <h3 className="mt-1 mb-5 pb-1">Hier kannst du dich Einloggen!</h3>
        
                      {getIsUnConfirmed && (<p>Dein Profil ist nicht verifiziert. Bitte verifiziere dein Profil!</p>)}
                      {getUserNotExists && (<p>Dein Profil existiert nicht. Bitte registriere Dich!</p>)}
                      {getPasswordNotCorrect && (<p>Dein Passwort ist falsch. Bitte versuche es erneut!</p>)}
                      
                      <form onSubmit={loginFunc} className="flex flex-col items-center justify-center">
                        <div className="form-outline mb-4 w-full" style={{ minWidth: '300px',maxWidth: '500px'}}>
                          <input
                            type="email"
                            id="form2Example11"
                            className="form-control"
                            placeholder="Email Adresse"
                            name="userName"
                          />
                          <label className="form-label text-white" htmlFor="form2Example11">
                            Email
                          </label>
                        </div>
        
                        <div className="form-outline mb-4 w-full" style={{ minWidth: '300px',maxWidth: '500px'}}>
                          <input
                            type="password"
                            id="form2Example22"
                            placeholder="Passwort"
                            className="form-control"
                            name="password"
                          />
                          <label className="form-label text-white" htmlFor="form2Example22">
                            Passwort
                          </label>
                        </div>
        
                        <div className="text-center pt-1 mb-5 pb-1 ">
                          <button className="text-4xl py-4 px-36 mx-4 btnLanding hover:text-black hover:bg-blue-200" type="submit">
                            <b>Log In</b>
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                 </div>
                  
                 <div className='col-sm-12 items-center justify-center'>
                    {!getShowResetPassword && (
                      <div>
                      <div className="col-sm-3"></div>
                      <div className="col-sm-12 text-center pt-1 mb-10 pb-1">
                      <button className="text-white p-3 mx-4 buttonTwo cursor-pointer" onClick={changeResetPassword}>
                        Passwort vergessen
                      </button>

                      </div>
                      </div>
                    )}
                  </div>
        
                  </div>
            <div className="col col-sm-6 w-full p-8 my-40 items-center justify-center">
             <div>
                      <div className="col-sm-3"></div>
                      <div className="col-sm-12 text-center pt-1 mb-10 pb-1">
                       <h3 className="mb-32 text-left ">
                        Wir bringen Kreative, Dienstleister und Verleger der Buch-Industrie zusammen, einfach und schnell!
                        Sei dabei mit deinem Manuskript bis zur Veröffentlichung.
                      </h3>
                        <button className=" text-4xl py-4 px-36 max-sm:px-20 mx-4 btnLanding hover:text-black hover:bg-blue-200" onClick={changeToCreateProfileSite}>
                         <b>Profil erstellen</b>
                          </button>  
                      </div>
                    </div>
          </div>
        
                </div>
              </div>
        
                  <div>      
                    <div className="row text-center ">
                    <div className='col col-sm-12 w-full mt-28 '>
                        <h4 className="mb-8 text-center font-bold text-gray-600">UNSERE VERLÄSSLICHEN PARTNER AUS DEM BUCH-PROFESSIONAL BEREICH</h4>
                        <div className="flex justify-center items-center">
                          <img className='m-5' src={Partnership_Pic} alt="Partnership_Pic" style={{ maxHeight: '200px', minHeight: '50px' }} />
                        </div>
                      </div> 
                    </div>
                  </div>
                  
              </div>


            {getShowResetPassword && (
              <><div className=''>
                    <div>
                           <div className='blurPage z-20 fixed inset-0 h-screen w-screen'></div>
                           <div className='loadingBox p-10 white-glassmorphism-loadingBox flex flex-col fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' 
                             style={{ minWidth: '400px' , minHeight:'300px'}}>
                             <div className="w-full text-black">
                             
                               <div className=' col-sm-12 justify-center items-center'>
                               <div className="row">
                                    <div className='col-sm-12 text-center max-sm:text-white'><h2 className="FormStyling text-center LinkColor  ">Passwort Reset!</h2></div>
                                    <div className='col-sm-12 py-5'>
                                        <button className=" px-8 py-2 bg-gray-600 text-white rounded-md mr-2" type="button" onClick={changeResetPassword}>
                                          zurück
                                        </button>
                                    </div>
                                </div>
                            <div>
                              <form onSubmit={resetPassword}>
                              <div className="row">
                                  <div className='col col-sm-12 pb-5'>
                                      <input type="email" id="form2Example11" className="form-control" placeholder="Email address" name="userName" />
                                      <label className="form-label max-sm:text-white" htmlFor="form2Example11">
                                        Benutzername / Email
                                      </label>
                                </div>
                                <p className='mt-5 max-sm:text-white'>Check deine Emails, wir senden Dir einen Link!</p>
                                <div className="col col-sm-12 FormStyling text-center justify-center items-center mb-5">
                                      <button className=" px-8 py-2 bg-gray-600 text-white rounded-md mr-2" type="submit">Passwort zurücksetzen</button>
                                    </div>
                                </div> 
                              </form>
                              </div>
                          </div>   
                    </div>
                </div>
             </div>   
          </div>
            </> 
            )}    
       
    </div>
	</div>
  
  );
}

export default CompLogin ; 
export { default as CompLogin } from './CompLogin';