import './CompThreePic.css';

import Pic1 from '../../images/glühbirne.jpg';
import Pic2 from '../../images/landingAuthor2.jpg';
import Pic3 from '../../images/landingPublisher2.jpg';
import Pic4 from '../../images/landingCover2.jpg';


function CompThreePic() {
  return (
    <>

    <div className="CompThreePic mt-20 pt-20">
      <div className="py-10">
        <h2 className="text-black text-4xl lg:text-6xl font-bold mb-10 text-center md:mx-80">
          Dein Tor zu qualitativen Dienstleistungen, Expertise und unentdeckten Bestsellern.
        </h2>
      </div>

      <div className="mx-4 flex flex-col space-y-4">
        {[
          {
            pic: Pic1,
            text: 'Verwandle als Autor dein rohes Manuskript zu einem hochwertigen Buch. An einem Ort, vom Traum zum Bestseller.',
          },
          {
            pic: Pic2,
            text: 'Teile als Lektor dein Fachwissen und Dienstleistung mit aufstrebenden Schriftstellern oder finde einzigartige Geschichten als Testleser. Deine Leseeindrücke sind von unschätzbarem Wert für Autoren.',
          },
          {
            pic: Pic3,
            text: 'Verleihe als Cover-Designer Manuskripten ein unverwechselbares Antlitz. Ein Kunstwerk, welches beim Anblick schon Leselust macht.',
          },
          {
            pic: Pic4,
            text: 'Als Verlag entdeckst du vielversprechende Manuskripte und neue Talente. Eine Erweiterung deines Pools an aufstrebenden Autoren.',
          },
        ].map((item, index) => (
          <div
            key={index}
            className={`flex flex-col sm:flex-row items-stretch rounded-lg shadow-lg overflow-hidden`}
          >
            {/* Adjust the order: First two on the left, last two on the right */}
            {index <= 1 ? (
              <>
                <img
                  src={item.pic}
                  alt={`Card ${index + 1}`}
                  className="w-full sm:w-1/2 h-full object-cover" // Increased height
                />
                <div className="text-left p-4 flex items-center sm:w-1/2 bg-white bg-opacity-50">
                  <h4 className="text-2xl lg:text-3xl text-black font-semibold text-justify px-20">
                    {item.text}
                  </h4>
                </div>
              </>
            ) : (
              <>
                <div className="text-left p-4 flex items-center sm:w-1/2  bg-white bg-opacity-50">
                  <h4 className="text-2xl lg:text-3xl text-black font-semibold text-justify px-20">
                    {item.text}
                  </h4>
                </div>
                <img
                  src={item.pic}
                  alt={`Card ${index + 1}`}
                  className="w-full sm:w-1/2 h-full object-cover" // Increased height
                />
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  </>
  );
}

export default CompThreePic; 
export { default as CompThreePic } from './CompThreePic';