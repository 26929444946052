
import React, { Component } from 'react';
import './ErrorBoundary.css';


class ErrorBoundary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  componentDidCatch(error, errorInfo) {

     // Log the error to the server or file
    //logErrorToFile(error);

    this.setState({
      hasError: true,
      errorMessage: error.message,
    });
  }



  render() {
    if (this.state.hasError) {
      return (
        <div>
          <div className='blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
          </div>
          <div className='errorBox white-glassmorphism-card flex flex-col items-center justify-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' style={{ minWidth: '450px', maxWidth:'450px'}}>
              <div className="w-full text-center">
                  <h3 className="text-center">ERROR - Fehler</h3>
                  <div className="row">                                       
                      <div className=" text-center"><h4 className="text-center">Leider ist ein Fehler aufgetreten</h4></div>      
                  </div>
                  <div className="row FormStyling mx-10 px-10">
                      <div className="text-center" style={{ minWidth: '100px', minHeight: '70px' }}>
                          <h4 className="text-center">Bitte laden Sie die Seite neu <br></br>oder wechseln Sie diese.</h4>
                      </div>
                  </div> 
              </div>   
          </div>
       </div>
      );
      
    }

        return this.props.children;
      
    }
}

export default ErrorBoundary;
export { default as ErrorBoundary } from './ErrorBoundary';

