
import './MyProfileAllViews.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import { ImSpinner9 } from "react-icons/im";


import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';

//components
import MyProfileViewAuthor from './MyProfileViewAuthor';
import MyProfileViewBook from './MyProfileViewBook';
import MyProfileViewEditor from './MyProfileViewEditor';
import MyProfileViewPublisher from './MyProfileViewPublisher';
import MyProfileViewTestreader from './MyProfileViewTestreader';
import MyProfileViewCoverdesigner from './MyProfileViewCoverdesigner';

const MyProfileAllViews = ({baseUrl, baseUrl2}) => {

    const [error, setError] = useState(null);
    const [getUserProfileType, setUserProfileType] = useState();

    const [editorState, setEditorState] = useState(true);
    const [authorState, setAuthorState] = useState(true);
    const [testReaderState, setTestReaderState] = useState(true);
    const [coverDesignerState, setCoverDesignerState] = useState(true);
    const [publisherState, setPublisherState] = useState(true);
    const [bookState, setBookState] = useState(true);

    const location = useLocation(); 

/* useEffect(() => {
        try {
            const bookType = new URLSearchParams(location.search).get('bookType');
    
            if (bookType) {
                console.log('bookType:', bookType);
                setUserProfileType(bookType);
            } else {
                console.log('bookType not found in the URL');
            }
        } catch (error) {
            handleError('Error extracting parameters from URL:', error);
        }
    }, [location.search]); */   
  
const updateUserProfileType = () => {
    try {
      const bookType = new URLSearchParams(location.search).get('bookType');
    
            if (bookType) {
                //console.log('bookType:', bookType);
                setUserProfileType(bookType);
            } else {
                const storedUserProfileType = localStorage.getItem('currentProfileType');
                //console.log('UserProfileType',storedUserProfileType)
                setUserProfileType(storedUserProfileType);
            }    
    } catch (error) {
        handleError('Error updating user profile type:', error);
    }
};

useEffect(() => {
  updateUserProfileType();
}, []);

useEffect(() => {
  updateUserProfileType();
}, [getUserProfileType, error]);

  

useEffect(() => {
    setEditorState(true);
    setAuthorState(true);
    setTestReaderState(true);
    setCoverDesignerState(true);
    setPublisherState(true);
    setBookState(true);    
}, []);


      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };


    ///////////////////////////////// Logout redirect //////////////////////////7

    const navigate2 = useNavigate();

    useEffect(() => {
      LogoutRedirectCheck(navigate2);
    }, [getUserProfileType]);
    
    ///////////////////////////////// Logout redirect //////////////////////////7


  return (
      <div className="MyProfileAllViews">

        <div className="container" >

         { authorState && editorState && testReaderState && coverDesignerState && publisherState && bookState &&  (
          <div className='blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
            <div className='loadingBox white-glassmorphism-loadingBox text-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' style={{ minWidth: '250px', maxWidth: '350px', minHeight:'100px' }}>
                <div className="flex justify-content-center align-items-center w-full h-full">
                  <div className='mt-10 ml-40'>
                        <ImSpinner9
                            className="spinning-image"
                            size={50}
                            color="#a7b7c8"
                        />
                  </div>               
                </div>
            </div>
        </div>

         )}

        {getUserProfileType === "Author" &&  (<><div className='pt-3 text-3xl'><b>Autor</b></div>
                                                <MyProfileViewAuthor authorState={authorState} setAuthorState={setAuthorState} 
                                                baseUrl={baseUrl} baseUrl2={baseUrl2}/></>
        )}


        {getUserProfileType === "Book" && (<><div className='pt-3 text-3xl'><b>Buch</b></div>
                                           <MyProfileViewBook bookState={bookState} setBookState={setBookState} 
                                           baseUrl={baseUrl} baseUrl2={baseUrl2}/></>
                    )}

        {getUserProfileType === "Editor" && (<><div className='pt-3 text-3xl'><b>Lektor & Korrektor</b></div>
                                             <MyProfileViewEditor editorState={editorState} setEditorState={setEditorState} 
                                             baseUrl={baseUrl} baseUrl2={baseUrl2}/></>
                    )}

        {getUserProfileType === "Testreader" &&  (<><div className='pt-3 text-3xl'><b>Testleser</b></div>
                                                 <MyProfileViewTestreader testReaderState={testReaderState} setTestReaderState={setTestReaderState} 
                                                 baseUrl={baseUrl} baseUrl2={baseUrl2}/></>
                    )}

        {(getUserProfileType === "Publisher" || getUserProfileType === "publisherEmployee") &&  (<><div className='pt-3 text-3xl'><b>Verlag</b></div>
                                              <MyProfileViewPublisher publisherState={publisherState} setPublisherState={setPublisherState} 
                                              baseUrl={baseUrl} baseUrl2={baseUrl2} getUserProfileTypeEmploy={getUserProfileType} /></>
                    )}

        {getUserProfileType === "CoverDesigner" &&  (<><div className='pt-3 text-3xl'><b>Coverdesigner</b></div>
                                              <MyProfileViewCoverdesigner coverDesignerState={coverDesignerState} setCoverDesignerState={setCoverDesignerState} 
                                              baseUrl={baseUrl} baseUrl2={baseUrl2}/></>
                    )}

        </div>              
    </div>  
    
    
  );
}

export default MyProfileAllViews; 
export { default as MyProfileAllViews } from './MyProfileAllViews';