import './css/AuthorView.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';
import {RatingSumAverageScore} from '../../tools/RatingSumAverageScore';

import { FaPencilAlt } from "react-icons/fa";
import { FaGlasses } from "react-icons/fa6";
import { AiFillPicture } from "react-icons/ai";
import { FaUserFriends } from "react-icons/fa";

import { ImSpinner9 } from "react-icons/im";  // zum laden anzeigen
import { GrMoreVertical } from "react-icons/gr";
import { GrApps } from "react-icons/gr";

import { BsChatDots } from "react-icons/bs";

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoReloadCircle } from "react-icons/io5";
import { IoCheckmarkCircle } from "react-icons/io5";


function IconWithTooltip({ icon, tooltipText }) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
        className=""
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {React.cloneElement(icon, {
          style: { fontSize: '10px' },
          title: isHovered ? tooltipText : '',
        })}
      </div>
    );
  }


const AuthorView = ({ firstLoginTrue, setFirstLoginTrue, baseUrl2}) => {


    const [getAuthorData,setAuthorData,  ] = useState([]);
    const [getSingleAuthorData,setSingleAuthorData, ] = useState([]);                          
    const [getSingleBookprojectData,setSingleBookprojectData,] = useState([]);

                                
    const [getAuthorRatings,setAuthorRatings, ] = useState([]);                         
    const [getSingleAuthorRating,setSingleAuthorRating, ] = useState([]); 

    const [showSearchRatingRow,setShowSearchRatingRow, ] = useState([false]); 
    const [searchInputValue,setSearchInputValue] = useState([]);

    const [getSortedScoreArray,setSortedScoreArray ] = useState([]);

    const [activeSortColumn, setActiveSortColumn] = useState('rating');

    const [sortConfig, setSortConfig] = useState('0', 'ascending');
    const [getSortedData, setSortedData] = useState([]);
    const [render, setRender] = useState(false);
    const [partRender, setPartRender] = useState(false);

    const [changeSearchForBanner, setChangeSearchForBanner] = useState(false);
    const [getRankHrefClick, setRankHrefClick] = useState(false);
    
    const [inputTrueGenres, setInputTrueGenres] = useState([]);
    const [changeGenreBanner, setChangeGenreBanner] = useState(false);
    
    const [getTotalPageNumber, setTotalPageNumber] = useState(1);
    const [showLoadingData, setShowLoadingData] = useState(false);
    const [singleEntityArray, setSingleEntityArray] = useState([]);
    const [pageNum, setPageNum] = useState('');
    const [entityDataArray, setEntityDataArray] = useState([]);
    

    const [error, setError] = useState(null); 
    
    const navigate = useNavigate();



    const getAuthors = async (pageVar) => {
      const numericPageVar = Number(pageVar); // Ensure pageVar is a number
      
      // Check if the requested page is already in the cache
      const cachedPage = entityDataArray.find((entry) => entry.page === numericPageVar);
      if (cachedPage) {
        //console.log(2);
        return cachedPage.data; // Return the cached data for this page
      }
  
      // If the page is not cached, make the API call
      try {
        const response = await api.get(`/api/authors/paginated`, {
          params: { pageVar: numericPageVar }, // Send the pageVar as a query parameter
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });
  
        // Destructure authors and totalCount from the response
        const [entities, totalCount] = response.data;
        const totalPageNum = Math.ceil(totalCount / 25);
        setTotalPageNumber(totalPageNum);
  
        // Add the new page data to the cache
        let newCache = [...entityDataArray]; // Create a new copy of the cache
  
        if (newCache.length >= 5) {
          // Find the index of the furthest page from the current page to remove
          const furthestPageIndex = newCache.reduce((furthestIndex, entry, index) => {
            const distance = Math.abs(entry.page - numericPageVar);
            const furthestDistance = Math.abs(newCache[furthestIndex].page - numericPageVar);
            return distance > furthestDistance ? index : furthestIndex;
          }, 0);
  
          // Overwrite the furthest page data with the new data
          newCache[furthestPageIndex] = { page: numericPageVar, data: entities };
        } else {
          // Add the new data to the cache
          newCache = [...newCache, { page: numericPageVar, data: entities }];
        }
        //console.log(1);
        setEntityDataArray(newCache); // Update the original cache state
  
        return entities;
  
      } catch (error) {
        handleError('Error fetching authors:', error);
      }
    }; 

    const fetchFirstTimeData = async (pageVar) => {

      try {

        if(pageVar !== ''){
        setShowLoadingData(true);
        setPageNum('');
        const Authors = await getFirstTimeAuthors(pageVar);
        setAuthorData(Authors);
       
        //console.log('Authors: ',Authors )
        setTimeout(() => {                  
          sortRatingsAndGiveOrder(Authors, pageVar);
          setShowLoadingData(false);

          //console.log('sortRatingsAndGiveOrder: ', Authors )
          //console.log('usingSortableData: ',getSortedScoreArray )
        }, 100);
      }

      } catch (error) {
        handleError('Error fetching authors:', error);
      }
    };

    const getFirstTimeAuthors = async (pageVar) => {
      const numericPageVar = Number(pageVar); // Ensure pageVar is a number
      
      // Check if the requested page is already in the cache
      const cachedPageIndex = entityDataArray.findIndex((entry) => entry.page === numericPageVar);
    
      // Always make the API call to get the latest data
      try {
        const response = await api.get(`/api/authors/paginated`, {
          params: { pageVar: numericPageVar }, // Send the pageVar as a query parameter
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        });
    
        // Destructure authors and totalCount from the response
        const [entities, totalCount] = response.data;
        const totalPageNum = Math.ceil(totalCount / 25);
        setTotalPageNumber(totalPageNum);
    
        // Create a new copy of the cache
        let newCache = [...entityDataArray];
    
        if (cachedPageIndex !== -1) {
          // Replace the cached entry with the new data
          newCache[cachedPageIndex] = { page: numericPageVar, data: entities };
        } else {
          // Add the new page data if not cached before
          newCache = [...newCache, { page: numericPageVar, data: entities }];
        }
    
        setEntityDataArray(newCache); // Update the cache with the new data
        //console.log(newCache);
    
        return entities;
    
      } catch (error) {
        handleError('Error fetching authors:', error);
      }
    };
    
    

      
      const getSingleAuthor = async (e) => {
        e.preventDefault();
        const { target } = e;
      
        const addNewAuthorData = {
          authorName: target.name.value,
        };
      
        try {

          const response = await api.get(`/api/authors/search/${addNewAuthorData.authorName}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });

          //console.log("response.data: ", response.data);
          // Access the first item in the response data
          const firstItem = response.data[0];

          // Extract the author object and the rank
          const entity = firstItem[0];  // First author object
          const rank = firstItem[1];    // The rank of the first author
        
          //console.log("entity: ", entity);
          //console.log("rank: ", rank);
          const singleEntity = entity;
          
          function calculateAverageScore(userRatings) { 
            if(userRatings.length > 0) {
              const totalScore = userRatings.reduce((sum, score) => sum + score.ratingScore, 0);
              const averageScore = totalScore / userRatings.length;
              const roundedAverageRatingScore = averageScore.toFixed(1);
              //console.log(roundedAverageRatingScore);
              return roundedAverageRatingScore;
            }
            
          }

            const averageScore = calculateAverageScore(singleEntity.ratingsReceivedIds);

            const trueGenres = Object.keys(singleEntity.genresIds[0])
            .filter(genre => singleEntity.genresIds[0][genre] && genre !== 'id' && genre !== 'createdAt')
            .map(genre => genre.charAt(0).toUpperCase() + genre.slice(1)) 
            .join(', '); 
            
            //console.log("trueGenres", trueGenres);

           const newSingleEntityArray = [rank, averageScore,  singleEntity.searchForLector,
                singleEntity.searchForTestReader, singleEntity.searchForCoverDesign, singleEntity.authorName, singleEntity.age, 
              trueGenres , singleEntity.bookProjectsIds.length, singleEntity.follower, singleEntity.searchForWriteBuddy ];
        
              setSingleEntityArray(newSingleEntityArray);
          setSingleAuthorData(singleEntity);
          
          setSingleBookprojectData(singleEntity.bookProjectsIds);
          showSingleRatings(singleEntity.authorName);
        } catch (error) {
          handleError('Error fetching single author:', error);
        }
      };
      
      
      const showSingleRatings = async (entityName) => {
        const newShowSearchRatingRow = true;
        setShowSearchRatingRow(newShowSearchRatingRow);
        localStorage.setItem('showSearchRatingRow', 'true');
      
        try {

          getAuthorData.forEach((entity) => {
            if(entity.authorName === entityName){
            setSingleAuthorRating(entity.ratingsReceivedIds);
            }
          });

          //console.log('getSingleAuthorRating:', getSingleAuthorRating);
          //console.log('getSingleAuthorRating (response.data):', response.data);
        } catch (error) {
          handleError('Error fetching single ratings:', error);
        }
      };

      const deleteSearchSingleAuthor = () => {
        setSearchInputValue('');
      
        const newShowSearchRatingRow = false;
        setShowSearchRatingRow(newShowSearchRatingRow);
        localStorage.setItem('showSearchRatingRow', 'false');
      };
      
      const handleSearchInputChange = (e) => {
        setSearchInputValue(e.target.value);
      };
      
      const fetchData = async (pageVar) => {

        try {

          if(pageVar !== ''){
          setShowLoadingData(true);
          setPageNum('');
          const Authors = await getAuthors(pageVar);
          setAuthorData(Authors);
         
          //console.log('Authors: ',Authors )
          setTimeout(() => {                  
            sortRatingsAndGiveOrder(Authors, pageVar);
            setShowLoadingData(false);

            //console.log('sortRatingsAndGiveOrder: ', Authors )
            //console.log('usingSortableData: ',getSortedScoreArray )
          }, 100);
        }

        } catch (error) {
          handleError('Error fetching authors:', error);
        }
      };
      
      
      const updateSearchHtml = () => {
        try {
          const storedShowSearchRatingRow = localStorage.getItem('showSearchRatingRow');
          setShowSearchRatingRow(storedShowSearchRatingRow === 'true');
        } catch (error) {
          handleError('Error updating search HTML:', error);
        }
      };

const usingSortableData = (items) => {

  
  let sortableItems = [...items];

  if (sortConfig !== null) {
    // Flip the order when sortConfig.key is '1'
    if (sortConfig.key === '1') {
      // Simply reverse the current order based on the direction
      if (sortConfig.direction === 'descending') {
        sortableItems.reverse();
      }
      return setSortedData(sortableItems);  // Return early after flipping
    }
  }


    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {


        //sort genres
        if (sortConfig.key === '7') {

            // Function to count overlapping genres
            const countOverlap = (genres) => {
              if (!genres) return 0;
              const genresArray = genres.split(', ').map(genre => genre.toUpperCase());
              return genresArray.reduce((count, genre) => {
                  if (inputTrueGenres.includes(genre)) {
                      return count + 1;
                  }
                  return count;
              }, 0);
          };

          //console.log("inputTrueGenres: ", inputTrueGenres);

          const overlapCountA = countOverlap(a[7]);  // Index 7 is where genres are stored
          const overlapCountB = countOverlap(b[7]);

         // console.log("overlapCountA: ", overlapCountA);
         // console.log("overlapCountB: ", overlapCountB);

          // Sort based on overlap count
          if (overlapCountA < overlapCountB) {
              return sortConfig.direction === 'descending' ? 1 : -1;
          }
          if (overlapCountA > overlapCountB) {
              return sortConfig.direction === 'descending' ? -1 : 1;
          }

        };

        if (sortConfig.key === '6' || sortConfig.key === '9') {
          const A = parseInt(a[sortConfig.key], 10);
          const B = parseInt(b[sortConfig.key], 10);
          
          if (!A) return sortConfig.direction === 'descending' ? 1 : -1;
          if (!B) return sortConfig.direction === 'descending' ? -1 : 1;
        
          if (A < B) return sortConfig.direction === 'descending' ? 1 : -1;
          if (A > B) return sortConfig.direction === 'descending' ? -1 : 1;
        
          return 0;
        }
        
          // sort alphabetically
        if (sortConfig.key === '5') {
          if (!a[sortConfig.key]) {return sortConfig.direction === 'descending' ? -1 : 1;
          }
          if (!b[sortConfig.key]) {return sortConfig.direction === 'descending' ? 1 : -1;
          } 
          return sortConfig.direction === 'descending' ? 
          b[sortConfig.key].localeCompare(a[sortConfig.key])  : a[sortConfig.key].localeCompare(b[sortConfig.key]);
        }

        // sort score and more
      if (sortConfig.key === '2' || sortConfig.key === '3'|| sortConfig.key === '4' || 
        sortConfig.key === '8' || sortConfig.key === '10' || sortConfig.key === '7'  ) {
        if (isNaN(a[sortConfig.key])) {return sortConfig.direction === 'descending' ? 1 : -1;
        }
        if (isNaN(b[sortConfig.key])) { return sortConfig.direction === 'descending' ? -1 : 1;
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {return sortConfig.direction === 'descending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {return sortConfig.direction === 'descending' ? 1 : -1;
        } 
        
        return 0;
      }
      return 0
      });
    }
   
	setSortedData(sortableItems);
	
  
};

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    partRerender();
  };




  const sortRatingsAndGiveOrder = ((AuthorsData, pageVar) => {
    const newArray = [];
try{
    if(AuthorsData != null && AuthorsData !== "" && AuthorsData !== undefined){

          function calculateAverageScore(userRatings) { 
            if(userRatings.length > 0) {
              const totalScore = userRatings.reduce((sum, score) => sum + score.ratingScore, 0);
              const averageScore = totalScore / userRatings.length;
              const roundedAverageRatingScore = averageScore.toFixed(1);
              //console.log(roundedAverageRatingScore);
              return roundedAverageRatingScore;
            }
            
          }

          AuthorsData.forEach((author, index) => {
              const averageScore = calculateAverageScore(author.ratingsReceivedIds);

              const trueGenres = Object.keys(author.genresIds[0])
              .filter(genre => author.genresIds[0][genre] && genre !== 'id' && genre !== 'createdAt')
              .map(genre => genre.charAt(0).toUpperCase() + genre.slice(1)) 
              .join(', '); 
              
              //console.log("trueGenres", trueGenres);

                newArray[index] = [((pageVar-1)*25)+index, averageScore,  author.searchForLector,
                author.searchForTestReader, author.searchForCoverDesign, author.authorName, author.age, 
                trueGenres , author.bookProjectsIds.length, author.follower, author.searchForWriteBuddy ];
          });

          //console.log('newArray1Sorted:', newArray );     
        

          newArray.forEach((row, index) => {
            const rank = ((pageVar - 1) * 25) + (index + 1);  // Ascending rank calculation
            row[0] = rank;  // Directly assign to the first column (rank)
          });
          

          usingSortableData(newArray);
          setSortedScoreArray(newArray);
          //console.log("newArray: ", newArray); 
          //console.log('newArraySorted:', newArray );
        }
      } catch (error) {
        handleError('Error sorting Entities and Genres:', error);
      }
  });


   const [checkedOption, setCheckedOption] = useState(null);

  const handleCheckboxChange = (option) => {
              setCheckedOption(option === checkedOption ? checkedOption : option);
              setChangeSearchForBanner(false);
    };



    const changeOtherProfileName = (otherProfileName, otherProfileType) => {
      localStorage.setItem('siteProfile', otherProfileName);
      localStorage.setItem('otherProfileType', otherProfileType);
      setRankHrefClick(true);
  };


  const toggleCollapse = (column) => {   
        setActiveSortColumn(column);    
    };

    const rerender = () => {
      setRender(!render);
    }

    const partRerender = () => {
      setPartRender(!partRender);
    }

    const changeToChat = (entityName, e) => {
      e.stopPropagation();
      localStorage.setItem('chatEntityName', entityName);
    }


    const [checkedGenreOptions, setCheckedGenreOptions] = useState({
      fiction: false, nonFiction: false, mystery: false, thriller: false, romance: false,
      fantasy: false, scienceFiction: false, horror: false, historical: false, youngAdult: false, newAdult: false,
       children: false, adventure: false, biography: false, selfHelp: false, poetry: false, drama: false,
      crime: false, graphicNovels: false, shortStories: false, dystopian: false, paranormal: false, memoir: false,
       cookbooks: false, spirituality: false, travel: false, science: false, history: false
    });
    
    const handleGenreCheckboxChange = (option) => {
      setCheckedGenreOptions((prevOptions) => ({
        ...prevOptions,
        [option]: !prevOptions[option],
      }));
    };

    const handlePageNumChange = (e) => {
      const value = e.target.value;
      const regex = /^\d*$/; 
    
      // Check that value is a number, not zero, and within the valid range
      if (regex.test(value) && (value === '' || (parseInt(value) > 0 && parseInt(value) <= getTotalPageNumber))) {
        const inputValue = value;
        setPageNum(inputValue);
      }
    };

    ////////////////////////// First Login Explanation//////////////////////

    const updateElements = (currentIndex, currentState, nextIndex, nextState) => {
      const updatedArray = [...firstLoginTrue];
      // Set the current index to the desired state
      updatedArray[currentIndex] = currentState;
      // Set the next index to the desired state
      updatedArray[nextIndex] = nextState;
      //console.log('nextIndex: ', nextIndex);

      if(nextIndex === 4 ){
        localStorage.setItem('explainDone', 'true'); 
        //console.log(localStorage.getItem('explainDone'));
      }

      // Update state with the modified array
      setFirstLoginTrue(updatedArray);
    };
    
   
   ////////////////////////// First Login Explanation//////////////////////

   useEffect(() => {
        const getCheckedGenres = () => {
            return Object.keys(checkedGenreOptions).filter(genre => checkedGenreOptions[genre]);
        };
        setInputTrueGenres(getCheckedGenres().map(genre => genre.toUpperCase()));
   }, [checkedGenreOptions]);

      useEffect(() => {
        const key = "1";
        const direction = 'ascending';
        setSortConfig({ key, direction });

        localStorage.setItem('pageNum', 1);
        fetchFirstTimeData(1);
        //fetchData(1);
        if(localStorage.getItem('currentProfileType') === 'Author'){
          setCheckedOption('writeBuddy');
        }
        if(localStorage.getItem('currentProfileType') === 'Editor'){
          setCheckedOption('lektor');
        }
        if(localStorage.getItem('currentProfileType') === 'CoverDesigner'){
          setCheckedOption('coverDesign');
        }
        if(localStorage.getItem('currentProfileType') === 'Testreader'){
          setCheckedOption('testleser');
        }             
        
        setChangeSearchForBanner(false);
        setRankHrefClick(false);
	      localStorage.setItem('chatEntityName', '');

      }, []);
      
      useEffect(() => {
        deleteSearchSingleAuthor();
        updateSearchHtml();
        localStorage.setItem('chatEntityName', '');
      }, []);

      useEffect(() => {
        //fetchData();
         
      }, [render, getRankHrefClick]);

      useEffect(() => { 
        //fetchRatings(getAuthorData);         
         // sortRatingsAndGiveOrder(getAuthorData);
          usingSortableData(getSortedScoreArray);
      }, [partRender]);

      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

   ///////////////////////////////// Logout redirect //////////////////////////7

       useEffect(() => {
        LogoutRedirectCheck(navigate);
      }, [navigate, searchInputValue, showSearchRatingRow, changeSearchForBanner, checkedOption  ]);
      
  ///////////////////////////////// Logout redirect //////////////////////////7 



  return (
    <div className="AuthorView z-40">
<div className='container max-sm:m-2 '>
      {/*{error && <div style={{ color: 'red' }}>{error}</div>} */}

{ firstLoginTrue[4] === true && localStorage.getItem('explainDone') === 'false' && (
      <div className='z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
  <div 
    className={`loadingBox white-glassmorphism-loadingBox ${firstLoginTrue[3] === true?'bg-slate-200 max-sm:top-1/3 top-1/3 max-sm:bg-slate-200':''} 
    border-sky-600 border-4 text-center fixed  ${firstLoginTrue[3] === false?'top-2/3':''} left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30`} 
    style={{ minWidth: '250px', maxWidth: '550px', minHeight:'100px' }}
  >
    <div className="flex justify-content-center align-items-center w-full h-full">
      <div className='m-5'>
        { firstLoginTrue[0] === true && (
          <>   <h6 className='text-2xl'> Im Dashboard kannst du mühelos zwischen den verschiedenen Profilansichten wechseln und auswählen, welche Profile dir angezeigt werden sollen.</h6>
                        <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                        type="button"  onClick={() => updateElements(0, false, 1, true)}>Weiter</button> </>
        )}
        { firstLoginTrue[1] === true && (
          <>   <h6 className='text-2xl'>Im Dashboard hast du die Möglichkeit, gezielt nach Profilen zu suchen. Gib einfach den vollständigen Namen oder einen Teil davon ein und klicke auf "Bestätigen". Wenn ein passendes Profil gefunden wird, erscheint es direkt an oberster Stelle im Dashboard.</h6>
                        <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                        type="button"  onClick={() => updateElements(1, false, 2, true)}>Weiter</button> </>
        )}
        { firstLoginTrue[2] === true && (
          <>   <h6 className='text-2xl'> Hier kannst du bequem zwischen den Seiten des Dashboards wechseln und direkt eine bestimmte Seite aufrufen, um die gewünschten Profile anzuzeigen.</h6>
                        <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                        type="button"  onClick={() => updateElements(2, false, 3, true)}>Weiter</button> </>
        )}
        { firstLoginTrue[3] === true && (
          <>   <h6 className='text-2xl'>
  Im Dashboard kannst du nach verschiedenen Profilmerkmalen filtern. Besonders wichtig ist die Filterung danach, ob ein Autor einen Lektor, Testleser, Schreibbuddy oder Coverdesigner sucht. Diese Informationen sind durch entsprechende Symbole gekennzeichnet.</h6>
                        <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                        type="button"  onClick={() => {updateElements(3, false, 4, false)}}>Fertig</button> </>
        )}

      </div>               
    </div>
  </div>
</div>
)}

        <div className={`container-sm ViewCol  dashboardSearchDataCard white-glassmorphism-card ${firstLoginTrue[1] ? 'bg-slate-200 border-sky-600 border-4' : ''}`}>
          <div className="flex items-center mb-2 m-2">
            <h5 className="mr-4 text-xl font-bold">SUCHE</h5>
            <form onSubmit={getSingleAuthor} className="flex flex-col sm:flex-row w-full gap-2">
              <input
                className="InputFieldOne flex-grow p-2 border border-gray-300 rounded"
                placeholder="Autorenname..."
                name="name"
                type="text"
                value={searchInputValue}
                onChange={handleSearchInputChange}
              />
              <div className="flex flex-col sm:flex-row gap-2">
                <button
                  className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                  type="submit"
                >
                  Bestätigen
                </button>
                <button
                  className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                  type="button"
                  onClick={deleteSearchSingleAuthor}
                >
                  Entfernen
                </button>
              </div>
            </form>
          </div>
        </div>




      {changeSearchForBanner && (
                  <div className="absolute z-30 white-glassmorphism-card bg-gray-200 rounded-lg  px-5 min-sm:right-2/3 group-hover:block mt-48 top-16">
                    <ul className="lg:flex list-none flex-col text-left">
                      {localStorage.getItem('currentProfileType') === 'Editor' && (
                      <li className="mx-3 text-3xl mt-5 text-[#8b90a5]  hover:text-[#babbcf]">
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedOption === 'lektor'}
                            onChange={() => handleCheckboxChange('lektor')}
                          />
                          <span className='pt-5'> Lektor | Korrektor</span>
                        </label>
                      </li>
                      )}
                       {localStorage.getItem('currentProfileType') === 'Testreader' && (
                      <li className="mx-3 text-3xl  text-[#8b90a5]  hover:text-[#babbcf]">
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedOption === 'testleser'}
                            onChange={() => handleCheckboxChange('testleser')}
                          />
                          <span className='pt-5'> Testleser</span>
                        </label>
                      </li>
                       )}
                        {localStorage.getItem('currentProfileType') === 'CoverDesigner' && (
                      <li className="mx-3 text-3xl  text-[#8b90a5]  hover:text-[#babbcf]">
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedOption === 'coverDesign'}
                            onChange={() => handleCheckboxChange('coverDesign')}
                          />
                          <span className='pt-5'> Cover-Design</span>
                        </label>
                      </li>
                        )}
                      <li className="mx-3 text-3xl  text-[#8b90a5]  hover:text-[#babbcf]">
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedOption === 'writeBuddy'}
                            onChange={() => handleCheckboxChange('writeBuddy')}
                          />
                          <span className='pt-5'> Schreib-Buddy</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                )}

          { changeGenreBanner && (
                  <div className="absolute z-30 white-glassmorphism-card bg-gray-200 rounded-lg  px-5 mr-14 min-sm:right-2/3 group-hover:block mt-28 top-16">
                        <div className="flex flex-wrap gap-4">
                            {Object.entries(checkedGenreOptions).map(([genre, isChecked]) => (
                              <div key={genre} className="flex items-center mb-4 mx-3 text-3xl text-[#8b90a5] hover:text-[#babbcf]">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => handleGenreCheckboxChange(genre)}
                                  id={genre}
                                  className="cursor-pointer rounded items-center  mb-6 border-gray-300"
                                />
                                <label htmlFor={genre} className="capitalize mt-3 ml-2">{genre}</label>
                              </div>
                            ))}
                          </div>
                  </div>
            )}

<div className={`container-sm ViewCol my-2 dashboardPageChangeTopCard white-glassmorphism-card ${firstLoginTrue[2] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}>
          <div className="flex items-center text-center justify-center">

           <button 
              className="text-gray-600 rounded my-6 mr-3 scale-150" 
              onClick={() => {
                const pageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                fetchFirstTimeData(pageNum);
              }}
            >
              <IconWithTooltip icon={<IoReloadCircle className="mr-1 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
            </button>

            <p className='FormStyling max-sm:text-lg mr-6'>Seite {localStorage.getItem('pageNum') || 1} von {getTotalPageNumber}</p>

            {parseInt(localStorage.getItem('pageNum')) > 1 && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}

            <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum')) || 1) - 1) * 25} - {(parseInt(localStorage.getItem('pageNum')) || 1) * 25}</p>

            {parseInt(localStorage.getItem('pageNum')) < getTotalPageNumber && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum + 1;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowForward />
              </button>
            )}

            <p className="FormStyling max-sm:text-lg ml-6 max-sm:ml-4">Gehe zu Seite</p>
            <input 
              className="-py-1 my-3 ml-2 rounded-xl text-center FormStyling2" 
              style={{ width: '1cm' }} 
              name="pageNumber" 
              onChange={handlePageNumChange} 
              value={pageNum} 
            />

            <button 
              className="text-gray-600 rounded my-6 ml-3 scale-150 p-0 m-0" 
              onClick={() => {
                const newPageNumber = pageNum !== '' ? pageNum : (parseInt(localStorage.getItem('pageNum')) || 1);
                localStorage.setItem('pageNum', parseInt(newPageNumber));
                fetchData(newPageNumber);
              }}
            >
              <IconWithTooltip icon={<IoCheckmarkCircle className="ml-1 scale-150" />} tooltipText="Gehe zur gewählten Seite." />
            </button>

    
          </div>
        </div>
  
      <div className={`container-sm overflow-hidden dashboardDataCard white-glassmorphism-card ${firstLoginTrue[3] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}>
      
        <div className={`overflow-auto -mb-8  max-sm:pb-1 `}  style={{maxHeight: 1000}}>

{ showLoadingData === false && (
          <table className="table table-bordered table-responsive table-hover mb-0  ">      
            <thead>
              <tr>
                <th className='sticky top-0  white-glassmorphism-table'><h6 className="ml-0 mb-6 scale-75"> <IconWithTooltip icon={<BsChatDots className='scale-150'/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." /></h6></th>
                <th  className="sticky top-0 max-sm:hidden white-glassmorphism-table text-center align-middle"><div className=' sm:hidden mb-4'>Nr.</div><div className=' max-sm:hidden mb-4'>Nr.</div></th>
                <th  className="sticky top-0  white-glassmorphism-table text-center align-middle"><div className=' sm:hidden mb-4'>Rang</div><div className=' max-sm:hidden mb-4'>Rang</div></th>
                <th  onClick={() => { requestSort('1'); toggleCollapse('rating'); }} className="sticky top-0  white-glassmorphism-table text-center">
                        <div className='row text-center mt-4 -mb-2'><div className=' sm:hidden'>Wert</div><div className='  max-sm:hidden'>Wert</div></div>
                        <div className='row max-sm:pl-3 sm:pl-5'>{activeSortColumn === 'rating' ? (<CgChevronDown />) : (<CgChevronRight />)}</div>
                </th>
                {checkedOption === 'lektor' && localStorage.getItem('currentProfileType') === 'Editor' && (  
                  <th className="sticky top-0  white-glassmorphism-table text-center">
                    <div className="align-items-center -mr-3">
                      <div className='flex '>
                          <div className='min-sm:scale-90 max-sm:scale-75 mt-3.5'><IconWithTooltip icon={<FaPencilAlt className='scale-150' />} tooltipText="Suche nach Lektor | Korrektor" /></div> 
                        <div className='mt-3 ml-3' onClick={() => {setChangeSearchForBanner(!changeSearchForBanner);}}><GrApps className='scale-75 max-sm:scale-100'/></div> </div>
                          <div className="ml-2" onClick={() => {requestSort('2'); toggleCollapse('searchForLector');}}>
                        {activeSortColumn === 'searchForLector' ? (<CgChevronDown />) : (<CgChevronRight />)}
                      </div>
                    </div>
                  </th>
                )}
                {checkedOption === 'testleser' && localStorage.getItem('currentProfileType') === 'Testreader' &&  (  
                  <th className="sticky top-0  white-glassmorphism-table text-center ">
                    <div className="align-items-center -mr-3">
                      <div className='flex '>
                          <div className='min-sm:scale-90 max-sm:scale-75 mt-3.5'><IconWithTooltip icon={<FaGlasses className='scale-150'/>} tooltipText="Suche nach Testleser" /></div>
                        <div className='mt-3 ml-3' onClick={() => {setChangeSearchForBanner(!changeSearchForBanner);}}><GrApps  className="scale-75"/></div> </div>
                          <div className="ml-2" onClick={() => {requestSort('3'); toggleCollapse('searchForTestreader');}}>
                        {activeSortColumn === 'searchForTestreader' ? (<CgChevronDown />) : (<CgChevronRight />)}
                      </div>
                    </div>
                  </th>
                )}
               {checkedOption === 'coverDesign' && localStorage.getItem('currentProfileType') === 'CoverDesigner' && (  
                  <th className="sticky top-0  white-glassmorphism-table text-center">
                    <div className="align-items-center -mr-3">
                      <div className='flex'>
                            <div className='min-sm:scale-90 max-sm:scale-75 mt-3.5'><IconWithTooltip icon={<AiFillPicture className='scale-150' />} tooltipText="Suche nach Cover-Design" /></div>
                          <div className='mt-3 ml-3' onClick={() => {setChangeSearchForBanner(!changeSearchForBanner);}}><GrApps className="scale-75"/></div> </div>
                            <div className="ml-2" onClick={() => {requestSort('4'); toggleCollapse('searchForCoverDesigner');}}>
                              {activeSortColumn === 'searchForCoverDesigner' ? (<CgChevronDown />) : (<CgChevronRight />)}
                      </div>
                    </div>
                  </th>
                )}
               {checkedOption === 'writeBuddy' && (  
                  <th className="sticky top-0  white-glassmorphism-table text-center">
                    <div className="align-items-center -mr-3">
                      <div className='flex'>
                            <div className='min-sm:scale-90 max-sm:scale-150 mb-1'><IconWithTooltip icon={<FaUserFriends className='scale-150' />} tooltipText="Suche nach Schreib-Buddy" /></div>
                         {localStorage.getItem('currentProfileType') !== 'Author' && ( <div className='mt-3 ml-3' onClick={() => {setChangeSearchForBanner(!changeSearchForBanner);}}><GrApps/></div> )} </div>
                            <div className="" onClick={() => {requestSort('10'); toggleCollapse('searchForWriteBuddy');}}>
                              {activeSortColumn === 'searchForWriteBuddy' ? (<CgChevronDown />) : (<CgChevronRight />)}
                      </div>
                    </div>
                  </th>
                )}
                <th  onClick={() => {requestSort('5'); toggleCollapse('name');}} className="sticky top-0  white-glassmorphism-table text-left"><div className=' -mb-1 ml-4 sm:hidden'>Name</div><div className='-mb-1 ml-4 max-sm:hidden'>Name</div>{activeSortColumn === 'name' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
                <th  onClick={() => {requestSort('6'); toggleCollapse('age');}} className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden" >Alter{activeSortColumn === 'age' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
                <th className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden ">
                    <div className="align-items-center -mr-3 ">
                      <div className='flex ml-6'>Genres
                          <div className='mt-2 ml-3 mr-3 scale-100' onClick={() => {setChangeGenreBanner(!changeGenreBanner);}}><IconWithTooltip icon={<GrApps className='scale-250'/>} tooltipText="Filter nach den gewünschten Genres." /></div></div>
                            <div className="text-center max-sm:hidden ml-2" onClick={() => {requestSort('7'); toggleCollapse('genre');}}>
                              {activeSortColumn === 'genre' ? (<CgChevronDown />) : (<CgChevronRight />)}
                      </div>
                    </div>
                  </th>
                <th  onClick={() => {requestSort('8'); toggleCollapse('book');}} className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden">Bücher{activeSortColumn === 'book' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
                <th  onClick={() => {requestSort('9'); toggleCollapse('follower');}} className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden">Follower{activeSortColumn === 'follower' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
               </tr>
            </thead> 
            <tbody className="table-group-divider">
  
          {showSearchRatingRow && (
            <>

             <tr className="table-active">
             
                    {localStorage.getItem('profileName') !== singleEntityArray[5] && ( 
                      <td className='mt-2'>
                       <a 
                          href="/chat" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          onClick={(e) => changeToChat(singleEntityArray[5], e)}
                        >
                        <button className="bg-gray-600 text-white rounded scale-150 p-0 m-0">
                        <IconWithTooltip icon={<BsChatDots className="scale-75"/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                        </button>
                        </a>
                        </td> 
                    )}
                    {localStorage.getItem('profileName') === singleEntityArray[5] && ( 
                        <td className='p-1'></td>
                                      )}
                
                  <td className="text-center max-sm:hidden p-0 m-0 min-sm:p-0"><h6 className="p-0 m-0">0</h6></td>  
                  <td className="col text-center p-0 m-0  min-sm:p-0">
                     <a
                       href={`${baseUrl2}/profile?othProfileName=${singleEntityArray[5]}&othProfileType=Author`}
                       target="_blank"
                       rel="noreferrer"
                       onClick={() => changeOtherProfileName(singleEntityArray[5], 'Author')}
                      >
                      <h6 className="TextBold p-0 m-0">{singleEntityArray[0]?'#'+ singleEntityArray[0]:singleEntityArray[0]===0?'#1':'-'}</h6>
                    </a>
                  </td>
                  <td className="p-0 m-0"><h6 className='p-0 m-0'>{isNaN(singleEntityArray[1])?'-':singleEntityArray[1]}</h6></td>
                  { checkedOption === 'lektor' && localStorage.getItem('currentProfileType') === 'Editor' && (                      
                  <td className=" scale-100">{singleEntityArray[2]?<IconWithTooltip icon={<FaPencilAlt className='mx-auto scale-125  max-sm:scale-150'/>} tooltipText="Suche nach Lektor | Korrektor" />:'-'}</td>
                  )}
                  { checkedOption === 'testleser' && localStorage.getItem('currentProfileType') === 'Testreader' &&  (  
                  <td className=" text-center scale-100 ">{singleEntityArray[3]?<IconWithTooltip icon={<FaGlasses className='mx-auto scale-125' />} tooltipText="Suche nach Testleser" />:'-'}</td>
                  )}
                  { checkedOption === 'coverDesign' && localStorage.getItem('currentProfileType') === 'CoverDesigner' &&  (  
                  <td className=" text-center  scale-100">{singleEntityArray[4]?<IconWithTooltip icon={<AiFillPicture className='mx-auto scale-125' />} tooltipText="Suche nach Cover-Design" />:'-'}</td>
                  )}
                  { checkedOption === 'writeBuddy' && (  
                  <td className=" text-center  scale-100 p-0 m-0">{singleEntityArray[10]?<IconWithTooltip icon={<FaUserFriends className='mx-auto scale-150' />} tooltipText="Suche nach Schreib-Buddy" />:'-'}</td>
                  )}
                  <td className=" text-center p-0 m-0">
                    <a
                        href={`${baseUrl2}/profile?othProfileName=${singleEntityArray[5]}&othProfileType=Author`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => changeOtherProfileName(singleEntityArray[5], 'Author')}
                        >
                      <h6 className='p-0 m-0'>{singleEntityArray[5]?singleEntityArray[5]:'-'}</h6>
                    </a>
                  </td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{singleEntityArray[6]?singleEntityArray[6]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{singleEntityArray[7]?singleEntityArray[7]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{singleEntityArray[8]?singleEntityArray[8]:'-'}</h6></td>
		              <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{singleEntityArray[9]?singleEntityArray[9]:'-'}</h6></td>
               </tr>
            </>
          )}
            
            {getSortedData.map((author, index) => (
                <tr key={author[0]} className="align-middle text-center justify-center items-center h-[10px]">
             
              {localStorage.getItem('profileName') !== author[5] && ( 
                     <td className='mt-2'>
                     <a className='p-0 m-0 ' 
                        href="/chat" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        onClick={(e) => changeToChat(author[5], e)}
                      >
                        <button className="bg-gray-600 text-white rounded scale-150 p-0 m-0">
                        <IconWithTooltip icon={<BsChatDots className="scale-75"/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                        </button>
                      </a>
                      </td>
                       )}

                {localStorage.getItem('profileName') === author[5] && ( 
                        <td className='p-1'></td>
                                      )}
               
                  <td className="max-sm:hidden"><h6 className="m-0 p-0 ">{index+1}</h6></td>
                  <td className="col text-center p-0 min-sm:p-0">
                     <a
                       href={`${baseUrl2}/profile?othProfileName=${author[5]}&othProfileType=Author`}
                       target="_blank"
                       rel="noreferrer"
                       onClick={() => changeOtherProfileName(author[5], 'Author')}
                      >
                      <h6 className="TextBold p-0 m-0">{author[0]?'#'+ author[0]:author[0]===0?'#1':'-'}</h6>
                    </a>
                  </td>
                  <td className="col p-0 m-0"><h6 className='p-0 m-0'>{isNaN(author[1])?'-':author[1]}</h6></td>                    
                  { checkedOption === 'lektor' && localStorage.getItem('currentProfileType') === 'Editor' && (  
                  <td className="col scale-100">{author[2]?<IconWithTooltip icon={<FaPencilAlt className='mx-auto scale-125'/>} tooltipText="Suche nach Lektor | Korrektor" />:'-'}</td>
                  )}
                  { checkedOption === 'testleser' && localStorage.getItem('currentProfileType') === 'Testreader' &&  (  
                  <td className="col text-center scale-100">{author[3]?<IconWithTooltip icon={<FaGlasses className='mx-auto scale-125' />} tooltipText="Suche nach Testleser" />:'-'}</td>
                  )}
                  { checkedOption === 'coverDesign' && localStorage.getItem('currentProfileType') === 'CoverDesigner' &&  (  
                  <td className="col text-center  scale-100">{author[4]?<IconWithTooltip icon={<AiFillPicture className='mx-auto scale-125' />} tooltipText="Suche nach Cover-Design" />:'-'}</td>
                  )}
                  { checkedOption === 'writeBuddy' && (  
                  <td className=" text-center scale-100 p-0 m-0"><h6 className='p-0 m-0'>{author[10]?<IconWithTooltip icon={<FaUserFriends className='mx-auto scale-150 p-0 my-0' />} tooltipText="Suche nach Schreib-Buddy" />:'-'}</h6></td>
                  )}
                  <td className=" text-center">                     
                    <a
                       href={`${baseUrl2}/profile?othProfileName=${author[5]}&othProfileType=Author`}
                       target="_blank"
                       rel="noreferrer"
                       onClick={() => changeOtherProfileName(author[5], 'Author')}
                      >
                      <h6 className='sm:hidden p-0 m-0'>{author[5]?author[5]:'-'}</h6><h6 className='max-sm:hidden p-0 m-0'>{author[5]?author[5]:'-'}</h6>
                    </a>
                  </td>
                  <td className="col text-center max-sm:hidden"><h6 className='p-0 m-0'>{author[6]?author[6]:'-'}</h6></td>
                  <td className="col text-center max-sm:hidden"><h6 className='p-0 m-0'>{author[7]?author[7]:'-'}</h6></td>
                  <td className="col text-center max-sm:hidden"><h6 className='p-0 m-0'>{author[8]?author[8]:'-'}</h6></td>
		              <td className="col text-center max-sm:hidden"><h6 className='p-0 m-0'>{author[9]?author[9]:'-'}</h6></td>
                </tr>
              ))}
            </tbody>
          </table>
              )}
              { showLoadingData === true && (
                      <div className="flex justify-center items-center min-h-screen">
                      <div className='loadingBox white-glassmorphism-loadingBox text-center transform z-30' style={{ minWidth: '250px', maxWidth: '350px', minHeight: '100px' }}>
                        <div className="flex justify-center items-center w-full h-full">
                          <div className='mt-10'>
                            <ImSpinner9 className="spinning-image animate-spin" size={50} color="#a7b7c8" />
                          </div>
                          <div className="mt-10 ml-3 text-gray-600">Loading...</div> {/* Optional loading text */}
                        </div>
                      </div>
                    </div>
                            
              )}

        </div>
      </div>
      <div className={`container-sm ViewCol mt-2 dashboardPageChangeBottomCard white-glassmorphism-card ${firstLoginTrue[2] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}>
          <div className="flex items-center text-center justify-center">
          <button 
              className="text-gray-600 rounded my-6 mr-3 scale-150" 
              onClick={() => {
                const pageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                fetchFirstTimeData(pageNum);
              }}
            >
              <IconWithTooltip icon={<IoReloadCircle className="mr-1 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
            </button>

            <p className='FormStyling max-sm:text-lg mr-6'>Seite {localStorage.getItem('pageNum') || 1} von {getTotalPageNumber}</p>

            {parseInt(localStorage.getItem('pageNum')) > 1 && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}

            <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum')) || 1) - 1) * 25} - {(parseInt(localStorage.getItem('pageNum')) || 1) * 25}</p>

            {parseInt(localStorage.getItem('pageNum')) < getTotalPageNumber && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum + 1;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowForward />
              </button>
            )}

            <p className="FormStyling max-sm:text-lg ml-6 max-sm:ml-4">Gehe zu Seite</p>
            <input 
              className="-py-1 my-3 ml-2 rounded-xl text-center FormStyling2" 
              style={{ width: '1cm' }} 
              name="pageNumber" 
              onChange={handlePageNumChange} 
              value={pageNum} 
            />

            <button 
              className="text-gray-600 rounded my-6 ml-3 scale-150 p-0 m-0" 
              onClick={() => {
                const newPageNumber = pageNum !== '' ? pageNum : (parseInt(localStorage.getItem('pageNum')) || 1);
                localStorage.setItem('pageNum', parseInt(newPageNumber));
                fetchData(newPageNumber);
              }}
            >
              <IconWithTooltip icon={<IoCheckmarkCircle className="ml-1 scale-150" />} tooltipText="Gehe zur gewählten Seite." />
            </button>
          </div>
        </div>
    </div>
  </div>
  
    
    
  );
}

export default AuthorView; 
export { default as AuthorView } from './AuthorView';