
import './MyProfileViewPublisher.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';
//Security
import { SanitizeInput } from '../../tools/security/SanitizeInput';
import { sanitizeInputTextArea } from '../../tools/security/SanitizeInputTextArea';
import { sanitizeInputTextAreaBig } from '../../tools/security/SanitizeInputTextAreaBig';
import { exeedsMaxCharacter } from '../../tools/security/exeedsMaxCharacter';
import { exeedsMaxCharacterTextArea } from '../../tools/security/exeedsMaxCharacterTextArea';
import { exeedsMaxCharacterTextAreaBig } from '../../tools/security/exeedsMaxCharacterTextAreaBig';

import { ImCross } from "react-icons/im";
import { AiOutlineContainer } from "react-icons/ai";
import { PiBaseballCap } from "react-icons/pi";
import { PiBriefcaseDuotone } from "react-icons/pi";

import { ImSpinner9 } from 'react-icons/im';

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import { FaPencilAlt } from "react-icons/fa";
import { FaPlusSquare } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

import ReactQuill from "react-quill";   
import 'react-quill/dist/quill.snow.css';

import he from 'he';
import DOMPurify from 'dompurify';

function IconWithTooltip2({ icon, tooltipText }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className=""
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {React.cloneElement(icon, {
        //style: { fontSize: '' },
        title: isHovered ? tooltipText : '',
      })}
    </div>
  );
}



  function DisplayHtml({ htmlContent }) {
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    //console.log('sanitizedHtml:',sanitizedHtml);
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} className='text-left'></div> 
    );
}




const MyProfileViewPublisher = ({publisherState, setPublisherState, baseUrl, baseUrl2, getUserProfileTypeEmploy }) => {


    ////////////////////////////////////////////////////////////////////////
    ////    Code for Profile Update    //////////////////
    /////////////////////////////////////////////////////////////////////

const [prevFileName, setPrevFileName,  ] = useState('');
const [getFileName, setFileName,  ] = useState('');
const [selectedFile, setSelectedFile] = useState(null);
const [imagePreview, setImagePreview] = useState('');

const [render, setRender] = useState();   
const [imageSrc, setImageSrc] = useState(null);
const [newImageSrc, setNewImageSrc] = useState(null);

  const [entitySocial, setEntitySocial] = useState('');
  const [entityWhoWeAre, setEntityWhoWeAre] = useState('');
  const [entityName, setEntityName] = useState('');
  const [entityAge, setEntityAge] = useState('');
  const [entityEmployCount, setEntityEmployCount] = useState('');
  const [entityWebsite, setEntityWebsite] = useState('');

const [getFileSizeToBig, setFileSizeToBig] = useState(false);
const [getFileTypeWrong, setFileTypeWrong] = useState(false);

const [getCharacterCountSoc, setCharacterCountSoc] = useState(0);
const [getCharacterCountWhoWeAre, setCharacterCountWhoWeAre] = useState(0);

const [showGenreChange, setGenreChange] = useState(false);
const [getTrueGenreData, setTrueGenreData] = useState([]);

const [checkedSelfPublisherTrueOptions, setCheckedSelfPublisherTrueOptions] = useState(false);

const [getFileWithSameNameExists, setFileWithSameNameExists] = useState(false);

const [getProfilIsProcessed, setProfilIsProcessed] = useState(false);
const [getNameAlreadyExists, setNameAlreadyExists] = useState(false);

const [getFileNameToLong, setFileNameToLong] = useState(false);

const [error, setError] = useState(null);


////////////////////////// First Login Explanation//////////////////////

const [firstLoginTrue, setFirstLoginTrue] = useState(new Array(4).fill(false));
  
const getUserFirstLogin = async () => {

 try {

   const response = await api.get(`/api/v1/auth/user/${localStorage.getItem(`userEmail`)}`, {
     headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
   });

   if(response.data !== null || response.data !== ''){
   const user = response.data;

  // console.log('response.data: ', response.data);
   //console.log('user.lastLoginAt.lenght : ', user.lastLoginAt.length );

   if(user.lastLoginAt.length >= 2 && user.lastLoginAt.length <= 3 && localStorage.getItem('explainDone2') === 'false'){
    updateElements(3,true,0,true);
   }else{
    updateElements(0,false,3,false);
     
   }
 }


 } catch (error) {
   handleError('Error fetching userLoginData:', error);
 }
};

const updateElements = (currentIndex, currentState, nextIndex, nextState) => {
const updatedArray = [...firstLoginTrue];
// Set the current index to the desired state
updatedArray[currentIndex] = currentState;
// Set the next index to the desired state
updatedArray[nextIndex] = nextState;
//console.log('nextIndex: ', nextIndex);

if(nextIndex === 3 ){
  localStorage.setItem('explainDone2', 'true'); 
 // console.log(localStorage.getItem('explainDone2'));
}

// Update state with the modified array
setFirstLoginTrue(updatedArray);
};


useEffect(() => { 
 getUserFirstLogin(); 
}, []);


////////////////////////// First Login Explanation//////////////////////



const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'], 
    ['clean']
],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
}
}


const handleContentQuillWhoWeAreChange = useCallback((value) => {
try {
const characterCount = value.replace(/<[^>]*>/g, '').length; // Strip HTML tags and count characters
setCharacterCountWhoWeAre(characterCount);
if(characterCount <= 3000){    
  setEntityWhoWeAre(value); 
}
} catch (error) {
handleError('Error handling quill exp change:', error);
}
}, []);


    const handleSocialChange = (event) => {
      try {
        const input = exeedsMaxCharacterTextArea(event.target.value);
        setEntitySocial(input);
        setCharacterCountSoc(input.length);
      } catch (error) {
        handleError('Error handling social change:', error);
      }
    };
    
    const handleNameChange = (e) => {
      try {
        const inputValue = exeedsMaxCharacter(e.target.value);
        let cleanValue = inputValue.replace(/[^\w\s_]/g, '');
        cleanValue = cleanValue.replace(/^_+|_+$/g, '');
    
        setEntityName(cleanValue);
      } catch (error) {
        handleError('Error handling name change:', error);
      }
    };
    
    const handleFoundedChange = (e) => {
      try {
        const value = e.target.value
        const regex = /^\d*$/; 
    
        if (regex.test(value) && (value === '' || parseInt(value) <= 2030)) {
          const inputValue = exeedsMaxCharacter(value);
          setEntityAge(inputValue);
        }
      } catch (error) {
        handleError('Error handling age change:', error);
      }
    };
    
    
    const handleWebsiteChange = (e) => {
      try {
        const inputValue = exeedsMaxCharacter(e.target.value);
        setEntityWebsite(inputValue);
      } catch (error) {
        handleError('Error handling website change:', error);
      }
    };

    const handleEmployCountChange = (e) => {
      try {
        const value = e.target.value
        const regex = /^\d*$/; 
    
        if (regex.test(value) && (value === '' || parseInt(value) <= 10000)) {
          const inputValue = exeedsMaxCharacter(value);
          setEntityEmployCount(inputValue);
        }
      
      } catch (error) {
        handleError('Error handling age change:', error);
      }
    };
      


  const entityHandleSubmitOne = async (e) => {
    e.preventDefault(); 
  
    setProfilIsProcessed(true);
    setNameAlreadyExists(false);

    const socialMediaAccounts = entitySocial.split('\n').map(account => account.trim());
  
        const entityNameVar = SanitizeInput(entityName.trim());
        const ageVar = SanitizeInput(entityAge);
	      const employCountVar = SanitizeInput(entityEmployCount);
        const whoWeAreVar = DOMPurify.sanitize(entityWhoWeAre);
        const websiteVar = SanitizeInput(entityWebsite);
        const socialMediaAccountsVar = socialMediaAccounts
          .map(account => sanitizeInputTextArea(account))
          .filter(account => account.trim() !== "");
    
        const fileNameVar = SanitizeInput(getFileName);
    
        const addNewEntityData = {  
          publisherName: entityNameVar,
          founded: ageVar,
	        employeeCount: employCountVar,
          whoWeAre: whoWeAreVar,  
          website: websiteVar,
          socialMediaAccounts: socialMediaAccountsVar,
          ...(fileNameVar !== '' && { profileImg: fileNameVar }),
	  selfPublisherTrue: checkedSelfPublisherTrueOptions
        };
    
  
    try {

      if(entityNameVar !== ''){
        const responseExists = await api.get(`/api/userProfile/${entityNameVar}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });
        
        if(responseExists !== '' && responseExists !== null && responseExists !== undefined){
          setProfilIsProcessed(false);
          setNameAlreadyExists(true);
        }else{
  
          entityHandleSubmitFurther(entityNameVar, fileNameVar, addNewEntityData);

        }
      }else{
  
          entityHandleSubmitFurther(entityNameVar, fileNameVar, addNewEntityData);
  
        }  


    } catch (error) {
      handleError(error);
    }
  };

  const entityHandleSubmitFurther = async (entityNameVar,fileNameVar, addNewEntityData ) => {   

    setShowUpdateProfile(false);

    const addNewRatingData = {
      changedName: entityNameVar,
      oldName: localStorage.getItem('profileName')      
    };
  
    const response = await api.put(`/api/publishers/update/v2/${localStorage.getItem('profileName')}`, addNewEntityData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
    });



    if(fileNameVar !== null && fileNameVar !== '' && fileNameVar !== localStorage.getItem('currentProfileImg')
      && localStorage.getItem('currentProfileImg') !== '' && localStorage.getItem('currentProfileImg') !== 'Standard_Profile_Pic_1.png' && localStorage.getItem('currentProfileImg') !== 'Standard_Cover_Pic_1.png'){
        const responseImg = await api.delete(`/api/files/delete/${localStorage.getItem('currentProfileImg')}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

      }

      if(fileNameVar !== null && fileNameVar !== '' && fileNameVar !== localStorage.getItem('currentProfileImg')){
       
        localStorage.setItem('currentProfileImg', fileNameVar)
        
        setTimeout(() => {
          fetchImage();
        }, 1000);
      }

    if(entityNameVar !== null && entityNameVar !== ''){
      localStorage.setItem('profileName', entityNameVar);
    }
 

    if (response && response.data) {
      if (response.data.entityName === entityNameVar) {
        const response2 = await api.put(`/api/ratings/update/ratings/changedEntityName`, addNewRatingData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });

        if (!response2.data) {
          handleError("Update Ratings request failed");
          console.log(response2.data);
        }

        const response3 = await api.put(`/api/talks/update/changedEntityName`, addNewRatingData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });

        if (!response3.data) {
          handleError("Update Talks request failed");
          console.log(response3.data);
        }

        const response4 = await api.put(`/api/message/update/changedEntityName`, addNewRatingData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });

        if (!response4.data) {
          handleError("Update Messages request failed");
          console.log(response4.data);
        }
      } else {
        handleError("Update entity request sucessfull until update ratings and ...");
      }
    }

    setTimeout(() => {
      fetchData();
    }, 2000);

    if (response && response.data) {

      const addNewGenreData = {
        ...checkedGenreOptions // Include all genre options
      };

      const profileType = "Publisher";

        const response5 = await api.put(`/api/genres/update/${localStorage.getItem('profileName')}&${profileType}`, addNewGenreData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });
        if(response5.data != null){

        }else {
        handleError("Update entity request sucessfull until update Genres ...");
      }
    }

    setProfilIsProcessed(false);
    window.location.reload();

   };



  const rerender = () => {
    setRender(true);
  };
  
  const handleFileChange = (e) => {
    try {
      setPrevFileName(SanitizeInput(getFileName));
      const filename = e.target.files[0].name;
      setFileName(SanitizeInput(filename));     
      setSelectedFile(e.target.files[0]);
      
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);

      setRender(true);
    } catch (error) {
      handleError('Error handling file change:', error);
    }
  
    setRender(true);
  };
  
  // File Upload to MongoDB and as Entity
  const handleFileUpload = async (e) => {
    e.preventDefault();
  
    setFileSizeToBig(false);
    setFileWithSameNameExists(false);
    setFileTypeWrong(false);
    setFileNameToLong(false);

    try {

      const fileInput = e.target.querySelector('input[type="file"]');
      const file = fileInput.files[0];
      //console.log(fileInput);
  
      if (!file) {
        handleError('No file selected');
        return;
      }
  
      const allowedFileTypes = ['image/jpeg', 'image/png'];
      if (!allowedFileTypes.includes(file.type)) {
        handleError('Invalid file type');
        setFileTypeWrong(true);
        return;
      }
  
      const maxSize = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSize) {
        handleError('File size exceeds the limit of 5 MB');
        setFileSizeToBig(true);
        return;
      }

                    // Check the filename length
                    if (file.name.length >= 45) {
                      handleError('File name must be less than 45 characters');
                      setFileNameToLong(true);
                      return;
                    }
  
      const formData = new FormData();
      formData.append('file', file);

      if(entityName.trim() === '' || entityName.trim().isEmpty || entityName.trim() === null){
        formData.append('uploadName', localStorage.getItem('profileName'));
        formData.append('belongName', localStorage.getItem('profileName'));
      }else{
        formData.append('uploadName', entityName.trim());
        formData.append('belongName', entityName.trim());
      }
    
  
      const response = await api.post('/api/files/uploadProfileImg', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if(response.data === "File with same name already exists."){
        setFileWithSameNameExists(true);
        handleError('Same file Name');
        return;
      }

      setTimeout(() => {
        // Update the state to trigger a re-render
        fetchImage();
      }, 1000); // Delay in milliseconds (adjust as needed)
  
      if(prevFileName !== null && prevFileName !== '' && prevFileName !== localStorage.getItem('currentProfileImg')
        && prevFileName !== 'Standard_Profile_Pic_1.png' && prevFileName !== 'Standard_Cover_Pic_1.png'){
          const response2 = await api.delete(`/api/files/delete/${prevFileName}`, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
      });
    }
      rerender();
      
    } catch (error) {
      handleError('Error uploading file:', error);
    }
  };
  
  const fetchImage = async () => {
    try {
      const newImageSrc1 = await api.get(`/api/files/image/${getFileName}`);
      setNewImageSrc(`${baseUrl}/api/files/image/${getFileName}`);
      //console.log('55: ', imageSrc);
    } catch (error) {
      handleError('Error fetching image:', error);
    }
  };
  
  useEffect(() => {
    if (render) {
      // Fetch and update the image source after a successful upload
      fetchImage();
      setRender(false);
      setFileTypeWrong(false);
      setFileSizeToBig(false);
      setFileNameToLong(false);
    }
  }, [render]);
  
  ///////////////////////////////////////////////////////////////////////
  const [updateProfile, setShowUpdateProfile] = useState(false);
  
  const changeInfo = () => {
    setShowUpdateProfile(true); 
  };
  
  const backButton = () => {
    setShowUpdateProfile(false); 
  };


////////////////////////////////////////////////////////////////////////
    ////    Code for show Profile ...    //////////////////
    /////////////////////////////////////////////////////////////////////


    const [getSingleEntityData,setSingleEntityData] = useState([]);                                                                       
    const [getSingleBookprojectData,setSingleBookprojectData] = useState([]);
    const [getEmployeeData,setEmployeeData] = useState([]);
     
    const [getSingleEntityRating,setSingleEntityRating] = useState([]); 

    const [getUserProfileType, setUserProfileType] = useState();
    const [showAreYouSure, setShowAreYouSure] = useState(false);
    const [ratingScoreAverage, setRatingScoreAverage] = useState();
   
    const [getFavoriteHrefClick, setFavoriteHrefClick] = useState(false); 
    const [getFavoriteDeleteClick, setFavoriteDeleteClick] = useState(false); 
    
    const [expandedTexts, setExpandedTexts] = useState({});
    const [expandedColumn, setExpandedColumn] = useState('Author');
    const [expandedColumn2, setExpandedColumn2] = useState('Testreader');

    const [showShareLink, setShowShareLink] = useState(false);

    const [publisherData, setPublisherData] = useState('');

    

    const logout = async () => {
      const logoutText = "Please Log in";

      localStorage.setItem('logoutTrue', true);
      try {
        localStorage.setItem(`logintoken`, logoutText);
        localStorage.setItem(`profileName`, logoutText);
        localStorage.setItem('currentProfileImg', logoutText)
        navigate("/");
        const response = await api.get(`/logout`)
        console.log(response.data);
        return response.data;
      } catch (error) {
        handleError('Error during logout:', error);
      }
    };

    const toggleCollapse = (column) => {   
        setExpandedColumn(column);    
    };

    const toggleCollapse2 = (column) => {
        setExpandedColumn2(column);
    };
    
    const userProfileName = localStorage.getItem('profileName');
    
    const getSingleEntity = async () => {

      if(getUserProfileTypeEmploy === "publisherEmployee"){

        const response = await api.get(`/api/v1/auth/user/${localStorage.getItem('profileName')}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });

      //console.log('localStorage.getItem(): ', localStorage.getItem('profileName') );

        if(response.data != null){
        
          const addNewEntityData = {
            entityName:   response.data.userProfilesIds[0].userProfileName,
          };
          setPublisherData(addNewEntityData);
          //console.log('publisherName: ', response.data.userProfilesIds[0].userProfileName );
        }

      }else{

      const addNewEntityData = {
        entityName: localStorage.getItem('profileName'),
      };

      setPublisherData(addNewEntityData);
      //console.log('publisherData: ', publisherData ); //
     }
      //console.log(localStorage.getItem('profileName'));
  
      try {
          const response = await api.get(`/api/publishers/${publisherData.entityName}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
  
          if(response.data != null){
          setSingleEntityData(response.data);

          const singleEntity = response.data;
          setSingleBookprojectData(singleEntity.prevBookProjectsIds);
          showSingleRatings(singleEntity.publisherName);
          setEmployeeData(singleEntity.employeeIds);
          localStorage.setItem('currentProfileImg', singleEntity.profileImg);

          setCheckedSelfPublisherTrueOptions(singleEntity.selfPublisherTrue);

	  setPublisherState(false);	 
    
    const filteredGenres = Object.keys(singleEntity.genresIds[0])
    .filter(key => key !== '_id' && singleEntity.genresIds[0][key] === true);
    //console.log('trueGenres: ', filteredGenres);

       const groupedData = filteredGenres.reduce((acc, item, index) => {
      const chunkIndex = Math.floor(index / 2);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = []; // start a new chunk
      }
      acc[chunkIndex].push(item);
      return acc;
    }, []);

    setTrueGenreData(groupedData);

        // Map genres retrieved from the database and update checkedGenreOptions
        const genresFromDB = singleEntity.genresIds[0];
        const updatedGenres = Object.keys(checkedGenreOptions).reduce((acc, genre) => {
          acc[genre] = genresFromDB[genre] || false;  // Use saved value or default to false
          return acc;
        }, {});
    
        setCheckedGenreOptions(updatedGenres);

          }
      } catch (error) {
          handleError('Error fetching single entity:', error);
      }

      if(publisherData === ''){
        rerender();
      }
  };  
  
  const areYouSure = () => {
      setShowAreYouSure(!showAreYouSure);
  };


  const navigate = useNavigate();
  
 const handleEntityDelete = async () => {
      try {

           const addNewEntityData = {
            entityName: localStorage.getItem('profileName'),
          };

          const booleanResult = await api.delete(`/api/publishers/delete/${addNewEntityData.entityName}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
	      setShowAreYouSure(false);

        if(booleanResult){

          const addNewUserProfileData = {
            userProfileName: localStorage.getItem('profileName'),
            userNameEmail: localStorage.getItem('userEmail'),
          };  
    
                  //console.log(addNewUserProfileData.userNameEmail);
                  //console.log(addNewUserProfileData.userProfileName);
    
                  await api.delete(`/api/userProfile/delete`, {
                    data: addNewUserProfileData,  // Use 'data' key to pass request body
                    headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
                  });
    
    
                  const response = await api.get(`/api/v1/auth/user/${localStorage.getItem('userEmail')}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
                    }); 
    
    
          if(response.data.userProfilesIds[0] && response.data.userProfilesIds[0] !== null && !response.data.userProfilesIds[0].isEmpty){
    
                      localStorage.setItem(`currentProfileType`, response.data.userProfilesIds[0].userProfileType);
                      localStorage.setItem(`profileName`, response.data.userProfilesIds[0].userProfileName);
                      localStorage.setItem(`userRole`, response.data.userRole);
    
                      //navigate("/myProfile");
                      window.location.reload();
    
          }else{
                    await api.delete(`/api/v1/auth/delete/user/${localStorage.getItem('userEmail')}`, {
                          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
                  });
            
            localStorage.setItem('logintoken', "Please Log in")
            logout();
    
          }
    
      }  

      } catch (error) {
          handleError('Error deleting entity:', error);
      }
  };
  

  
  const showSingleRatings = async (entityName) => {
      try {
          const response = await api.get(`/api/ratings/createdFor/${entityName}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
  
          setSingleEntityRating(response.data);
  
          const sumOfRatingScores = response.data.reduce((sum, item) => sum + item.ratingScore, 0);
          const averageRatingScore = sumOfRatingScores / response.data.length;
          const roundedAverageRatingScore = averageRatingScore.toFixed(1);
  
          if (response.data.length > 0) {
              setRatingScoreAverage(parseFloat(roundedAverageRatingScore));
          } else {
              setRatingScoreAverage("");
          }
      } catch (error) {
          handleError('Error fetching single ratings:', error);
      }
  };
  


  const changeFavorite = async (favoriteProfileName) => {
    try {
        setFavoriteDeleteClick((prevFavoriteDeleteClick) => !prevFavoriteDeleteClick);

        const addNewFavoriteData = {
            entityName: localStorage.getItem('profileName'),
            favoriteName: favoriteProfileName,
        };

        const response = await api.post(`/api/publishers/changeFavorite`, addNewFavoriteData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });

        //console.log('Favorite:', response.data);
    } catch (error) {
        handleError('Error changing favorite:', error);
    }
};

const changeOtherProfileName = (otherProfileName, otherProfileType) => {
    localStorage.setItem('siteProfile', otherProfileName);
    localStorage.setItem('otherProfileType', otherProfileType);
    setFavoriteHrefClick(true);
};


const updateUserProfileType = () => {
    try {
        const storedUserProfileType = localStorage.getItem('currentProfileType');
        setUserProfileType(storedUserProfileType);
    } catch (error) {
        handleError('Error updating user profile type:', error);
    }
};


          const toggleTextExpansion = (createdByProfileName) => {
            setExpandedTexts((prevExpandedTexts) => ({
                ...prevExpandedTexts,
                [createdByProfileName]: !prevExpandedTexts[createdByProfileName],
            }));
        };

        const fetchData = async () => {
          try {
              const Entity = await getSingleEntity();
              //console.log('Authors:', Entity);
          } catch (error) {
              handleError('Error fetching data:', error);
          }
      };


    const deleteEmployee = async (employeeEmail) => {
          try {

        	const newEntityData = {
          		employeeEmail: employeeEmail,
          		publisherProfileName: localStorage.getItem('profileName'),
              firstRegister: false
        	};

        	const response2 = await api.post(`/api/publishers/addEmployee`, newEntityData, {
          		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}`  },
        	});

		setRender(!render);

		//console.log('Publisher:', response2.data);

          } catch (error) {
              handleError('Error deleting Employee:', error);
          }
      };

      const [checkedGenreOptions, setCheckedGenreOptions] = useState({
        fiction: false, nonFiction: false, mystery: false, thriller: false, romance: false,
        fantasy: false, scienceFiction: false, horror: false, historical: false, youngAdult: false, newAdult: false,
         children: false, adventure: false, biography: false, selfHelp: false, poetry: false, drama: false,
        crime: false, graphicNovels: false, shortStories: false, dystopian: false, paranormal: false, memoir: false,
         cookbooks: false, spirituality: false, travel: false, science: false, history: false
      });
      
      const handleGenreCheckboxChange = (option) => {
        setCheckedGenreOptions((prevOptions) => ({
          ...prevOptions,
          [option]: !prevOptions[option],
        }));
      };

        
        useEffect(() => {
            setImageSrc(`${baseUrl}/api/files/image/`);
            setShowAreYouSure(false);
            fetchData();
            updateUserProfileType();
            setExpandedColumn('Author');
            setExpandedColumn2('Testreader');
            localStorage.setItem('logoutTrue', false);
	    setCheckedSelfPublisherTrueOptions(getSingleEntityData.selfPublisherTrue);
            
        }, []);
        
        useEffect(() => {
            const delayTimeout = setTimeout(() => {
                fetchData();
            }, 500);
        
            return () => clearTimeout(delayTimeout);
        }, [getFavoriteDeleteClick, render]);



      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };


    ///////////////////////////////// Logout redirect //////////////////////////7

    const navigate2 = useNavigate();

    useEffect(() => {
      LogoutRedirectCheck(navigate2);
      setFavoriteHrefClick(false);
    }, [updateProfile, showAreYouSure, getFavoriteHrefClick, getFavoriteDeleteClick, showShareLink ]);
    
    ///////////////////////////////// Logout redirect //////////////////////////7



  return (
      <div className="MyProfileViewPublisher">

        <div className="" >


        { publisherState === false && firstLoginTrue[3] === true && localStorage.getItem('explainDone2') === 'false' && (
                <div className='z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
            <div 
              className={`loadingBox white-glassmorphism-loadingBox bg-slate-200 border-sky-600 border-4 text-center fixed top-2/3 max-sm:top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30`} 
              style={{ minWidth: '250px', maxWidth: '550px', minHeight:'100px' }}
            >
              <div className="flex justify-content-center align-items-center w-full h-full">
                <div className='m-5'>
                  { firstLoginTrue[0] === true && (
                    <>   <h6 className='text-2xl'>Über diesen Button gelangst du zu einer Seite, auf der du alle Informationen deines Profils bearbeiten kannst – von deinem Profilbild über die Genres, in denen du tätig bist, bis hin zu deinem Namen.</h6>
                                  <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                                  type="button"  onClick={() => updateElements(0, false, 1, true)}>Weiter</button> </>
                  )}
                  { firstLoginTrue[1] === true && (
                    <>   <h6 className='text-2xl'>Hier kannst du dein Profil löschen, wenn du es nicht mehr benötigst – zum Beispiel, wenn du sowohl ein Autoren- als auch ein Lektorenprofil hast, aber als Autor nicht mehr aktiv sein möchtest</h6>
                                  <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                                  type="button"  onClick={() => updateElements(1, false, 2, true)}>Weiter</button> </>
                  )}
                  { firstLoginTrue[2] === true && (
                    <>   <h6 className='text-2xl'>Hier wird dir ein Link angezeigt, den du teilen kannst, um anderen eine Leseansicht deines Profils und deiner Errungenschaften zu zeigen – auch wenn sie keinen Bluumer-Account haben.</h6>
                                  <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                                  type="button"  onClick={() => {updateElements(2, false, 3, true)}}>Fertig</button> </>
                  )}

                </div>               
              </div>
            </div>
          </div>
          )}


        { getProfilIsProcessed && !getNameAlreadyExists &&  (

          <div className='blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
          <div className='loadingBox white-glassmorphism-loadingBox text-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' style={{ minWidth: '250px', maxWidth: '350px', minHeight:'100px' }}>
              <div className="flex justify-content-center align-items-center w-full h-full">
              <div className='mt-10 ml-60 -pl-10'>
                  <ImSpinner9
                      className="spinning-image"
                      size={50}
                      color="#a7b7c8"
                  />
            </div>   
                    </div>
                    <div>
                      <h3>Deine Profildaten werden verarbeitet.</h3>
                    </div>
                  </div>
            </div>    

          )}

        {updateProfile  && !getProfilIsProcessed && (<div><button className='buttonTwo btnChange p-2 mt-5' onClick={backButton}>zurück</button></div>)}

        {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   
        {getFileSizeToBig && (<div><p style={{ color: 'red' }}>Deine Datei überschreitet 5 MB an Größe!</p></div>)}
        {getFileTypeWrong && (<div><p style={{ color: 'red' }}>Deine Datei muss vom Typ PNG oder JPEG sein!</p></div>)}
        */}

        {getNameAlreadyExists && updateProfile && (<div><p className="mt-5" style={{ color: 'red' }}>Ein Profil mit dem selben Namen existiert bereits!</p></div>)}
       

        {updateProfile  && !getProfilIsProcessed && (

          <><div className=''>

          <form onSubmit={entityHandleSubmitOne} method="POST">
            
            <div className="row  profileDataCard2  paddingOne">
              <div className="row max-md:flex titleRowBackgroundcolor white-glassmorphism-card ">
              <div className="col-sm-8"><h2 className="FormStyling text-left titleTextColor">Stammdaten</h2></div>
                <div className="col-sm-4 max-sm:mb-2 max-sm:-ml-5">
                <div className="mx-2 FormStyling text-right text-2xl"><IconWithTooltip2 icon={<button className="buttonTwo btnChange p-2 flex"><FaPencilAlt className='pr-1 mt-1'/>Profil updaten</button>} tooltipText="Bestätige deine Änderungen und update deine Profil-Infos." />
                </div> 
              </div>
              </div>
              <hr className='horizontalLine' />
              <div className="row mainRowBackgroundcolor white-glassmorphism-card pt-3">
                <div className="col col-sm-4 max-sm:col-sm-3 text-left" style={{ minWidth: '100px', minHeight: '180px' }}>
                  <div>
                    <h3 className="FormStyling">Name</h3>
                    <input className="FormStyling FormStyling2 w-full" placeholder={getSingleEntityData.publisherName} name="entityName" onChange={handleNameChange} value={entityName} />
                    <br></br>
                    <h3 className="FormStyling">Alter</h3>
                    <input className="FormStyling FormStyling2 w-full" placeholder={getSingleEntityData.founded} name="entityAge" onChange={handleFoundedChange} value={entityAge} />
                    <br></br>
                    <h3 className="FormStyling">Mitarbeiter</h3>
                    <input className="FormStyling FormStyling2 w-full" placeholder={getSingleEntityData.employeeCount} name="entityGenre" onChange={handleEmployCountChange} value={entityEmployCount} />
                    <br></br>
                    <h3 className="FormStyling">Genres</h3>
                    <div className='buttonTwo btnChange text-center mx-4 mt-5 cursor-pointer' onClick={()=> setGenreChange(!showGenreChange)}>
                            Genres Bearbeiten
                          </div>
                    </div>
                </div>
                <div className="col col-sm-7 max-sm:col-sm-12 text-left" >
                  <div>
                    <h3 className="FormStyling">Website</h3>
                    <input className="FormStyling FormStyling2 w-full" placeholder={getSingleEntityData.website} name="entityWebsite" onChange={handleWebsiteChange} value={entityWebsite} />
                    <br></br>
                  </div>
                  <div className="row textAreaOne">
                    <h3 className="FormStyling">Social Media Accounts</h3>
                    <div className="row textAreaOne">
                      <textarea
                        style={{ overflowY: 'auto', maxHeight: '300px', maxWidth: '800px', minHeight: '180px', minWidth: '280px' }}
                        className="FormStyling FormStyling2 w-full flex-grow-1"
                        placeholder={getSingleEntityData.socialMediaAccounts}
                        name="entitySocial"
                        value={entitySocial}
                        onChange={handleSocialChange} />
                      <div
                        style={{
                          position: 'relative',
                          bottom: '25px', // Adjust the distance from the bottom as needed
                          right: '-10px', // Adjust the distance from the right as needed
                          color: 'gray',
                        }}
                      >
                        {getCharacterCountSoc} / 500
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mainRowBackgroundcolor white-glassmorphism-card pt-3">
              <br></br>
                         <div className='flex text-center'>
                          <div className='col-sm-12'>
                            <div className='sol-sm-6'>
                              <h3 className="FormStyling px-5 mr-5">Selfpublisher- / Druckkostenzuschuss-Verlag</h3>
                          </div>
                          <div className='col-sm-6'>
                             <input
                                  type="checkbox"
                                  checked={checkedSelfPublisherTrueOptions}
                                  onChange={() => setCheckedSelfPublisherTrueOptions(!checkedSelfPublisherTrueOptions)}
                                  className="cursor-pointer rounded items-center px-5 ml-5 mb-6 border-gray-300"
                                />
                                <label htmlFor="Trifft zu" className="mt-6 ml-2 px-3">Trifft zu</label>       
                          </div>
                          </div>
                       </div>
                          <br></br>
              { showGenreChange && (
                <div className="flex flex-wrap gap-4">
                    {Object.entries(checkedGenreOptions).map(([genre, isChecked]) => (
                      <div key={genre} className="flex items-center mb-4 mx-3 text-3xl text-[#8b90a5] hover:text-[#babbcf]">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={() => handleGenreCheckboxChange(genre)}
                          id={genre}
                          className="cursor-pointer rounded items-center  mb-6 border-gray-300"
                        />
                        <label htmlFor={genre} className="capitalize mt-3 ml-2">{genre}</label>
                      </div>
                    ))}
                  </div>
                  )}
              </div>
            </div>
              
              <div className="row profileDataCard2  paddingOne">
                  <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                    <div className='col col-sm-4'><h2 className="FormStyling text-left titleTextColor">Wer wir sind</h2></div>
                  </div>
                  <hr className='horizontalLine' />
              <div className="row mainRowBackgroundcolor">
                    <div className="col col-sm-12 text-left mainRowBackgroundcolor white-glassmorphism-card ">
                     <div className="row textAreaOne pt-5" style={{ width: '100%' }}>

                                <ReactQuill
                                    theme='snow'
                                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']}
                                    placeholder='Füge hier deine Änderungen zu "Wer Ihr seid" ein..'
                                    modules={modules}
                                    onChange={handleContentQuillWhoWeAreChange}
                                    value={entityWhoWeAre}
                                />
                                    <div className='pt-6 pb-3'
                                      style={{
                                        position: 'relative',
                                        top: '0px',
                                        right: '-10px', // Adjust the distance from the right as needed
                                        color: 'gray',
                                      }}
                                      >
                                      {getCharacterCountWhoWeAre} / 3000
                                    </div>  

                                {/*}	<div><DisplayHtml htmlContent={contentExpClean} /></div> */}

                    </div>
                    </div>
                  </div>
                </div>   
          </form>
          </div>

          <div className="row profileDataCard2  paddingOne">
                  <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                    <div className='col col-sm-4'><h2 className="FormStyling text-left titleTextColor">Profilbild</h2></div>
                  </div>
                  <hr className='horizontalLine' />
              <div className="row mainRowBackgroundcolor text-center justify-center items-center">
                    <div className="col col-sm-12  mainRowBackgroundcolor white-glassmorphism-card ">
                    {getFileSizeToBig && (<div><p style={{ color: 'red' }}>Deine Datei überschreitet 5 MB an Größe!</p></div>)}
                     {getFileTypeWrong && (<div><p style={{ color: 'red' }}>Deine Datei muss vom Typ PNG oder JPEG sein!</p></div>)}
                     {getFileWithSameNameExists && (<div><p style={{ color: 'red' }}>Eine Datei mit dem selben Name existiert bereits!</p></div>)}
                     {getFileNameToLong && (<div><p style={{ color: 'red' }}>Der Dateiname ist zu lang. Bitte kürze diesen!</p></div>)}


                    <div className="col col-sm-4 flex text-center justify-center items-center">
                      <div className="">
                        <div>
                          <div className="text-left ml-2.5 my-10 max-sm:my-0" style={{ minWidth: '100px', minHeight: '240px' }}>
                          <div><h3 className='text-center'>Vorschau</h3></div>
                            <div className="" style={{ minWidth: '100px', minHeight: '240px' }}>
                              <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }}>
                                <div>
                                {imagePreview && (
                                  <img src={imagePreview} alt="Preview" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }} />
                                )}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                <div className="col col-sm-4 text-center justify-center mt-48 max-md:mt-12">
                        <div className="textAreaOne ">
                          <form className='' onSubmit={handleFileUpload} method="POST" encType="multipart/form-data">
                            <input className='w-full text-center justify-center items-center' type="file" onChange={handleFileChange} />
                            <br></br>
                            <button className="buttonOne px-4 py-2 bg-gray-600 text-white rounded mr-2">hochladen</button>
                          </form>
                        </div>
                </div>
                <div className="col col-sm-4 flex text-center justify-center items-center">
                      <div className="">
                        <div>
                          <div className="my-10" style={{ minWidth: '100px', minHeight: '240px' }}>
                            <div><h3 className='text-center'>Hochgeladen</h3></div>
                            <div className=" " style={{ minWidth: '100px', minHeight: '240px' }}>
                              <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }}>
                                <div>
                                {imageSrc && (
                                  <img className="" src={`${newImageSrc === null ? imageSrc + getSingleEntityData.profileImg : newImageSrc}`}  alt="Uploaded" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }} />
                                  )}
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
          </div>
          </div>
          </div>

            
          </>      

          )}
          



        {(getUserProfileType === "Publisher" || getUserProfileType === "publisherEmployee" ) && !updateProfile  && !getProfilIsProcessed && (
          <div className='max-sm:w-[90vw]'>
          <>
            <div className="mb-10">
              {showAreYouSure && (
                <div className='profileDataCard2 bg-rose-700'>
                  <div className="row text-center justify-content-center ">
                    <div className="col col-sm-12"><h3>Bist du dir sicher, dass du dein Profil löschen willst?</h3></div>
                  </div>
                  <div className="row text-center justify-content-center marginOne">
                    <div className='col-sm-12 flex'>
                      <div className="col col-sm-6 w-full "><div className='text-right'><button className="buttonTwo btnChange p-2" onClick={handleEntityDelete}>Löschen</button></div></div>
                      <div className="col col-sm-6 w-full"><div className='text-left '><button className="buttonTwo btnChange p-2" onClick={areYouSure}>Abbrechen</button></div></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
              {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}
              
              <div className="row paddingOne">
            

                {getSingleEntityData && (

                <div className="row profileDataCard2  paddingOne">
                  <div className="row titleRowBackgroundcolor white-glassmorphism-card ">
                  
                   <div className=" col-sm-6 sm:col-sm-12 max-sm:text-center  md:col-sm-6">
                      <h2 className="FormStyling md:text-left  titleTextColor">{getSingleEntityData.publisherName}</h2>                   
                      <h4 className="FormStyling md:text-left pr-10 pt-3 titleTextColor sm:hidden"> | {getSingleEntityData.follower} Follower</h4>
                     
                  </div>
                  <div className='flex col-sm-6 sm:col-sm-12 md:justify-end md:col-sm-6 justify-center'>
                  { getEmployeeData.length <= 0 && ( 
                    <>
                        <div className="mx-2 FormStyling text-right text-2xl"><IconWithTooltip2 icon={!updateProfile && (<div><button className="buttonTwo btnChange p-2 flex" onClick={changeInfo}><FaPencilAlt className='pr-1 mt-1'/>Ändere Infos</button></div>)} tooltipText="Hier kannst du deine Profil-Informationen bearbeiten." /></div>
                        <div className="mx-2 FormStyling text-right"> <button className="buttonTwo btnChange p-2" onClick={areYouSure}>Profil Löschen</button></div>
                    </>
                  )}
              { (getEmployeeData !== undefined ) && getEmployeeData.length > 0  && localStorage.getItem('profileName') === getSingleEntityData.publisherName && getUserProfileTypeEmploy === 'Publisher' &&  localStorage.getItem('currentProfileType') !== 'publisherEmployee' && ( 
                <>
                    <>
                        <div className="mx-2 FormStyling text-right text-2xl"><IconWithTooltip2 icon={!updateProfile && (<div><button  className={`buttonTwo btnChange p-2 flex ${firstLoginTrue[0] ? 'bg-slate-200 border-sky-600 border-4' : ''}`} onClick={changeInfo}><FaPencilAlt className='pr-1 mt-1'/>Ändere Infos</button></div>)} tooltipText="Hier kannst du deine Profil-Informationen bearbeiten." /></div>
                        <div className="mx-2 FormStyling text-right"> <button  className={`buttonTwo btnChange p-2 flex ${firstLoginTrue[1] ? 'bg-slate-200 border-sky-600 border-4' : ''}`} onClick={areYouSure}>Profil Löschen</button></div>
                    </>
                       
                      </>  
                    )}   
                  </div>
                  </div> 
                  <div className="row pb-2 -pt-5  white-glassmorphism-card ">
                    <div className='col col-sm-4 sm:col-sm-12 md:justify-start md:col-sm-4 justify-center flex max-md:pb-5'>
                      <h4 className="FormStyling md:text-left pr-10 pt-3 titleTextColor max-sm:hidden"> | {getSingleEntityData.follower} Follower</h4>
                     </div> 
                    <div className='col col-sm-8 sm:col-sm-12 md:justify-end md:col-sm-4 justify-center flex max-md:pb-5'>
                    
                    {showShareLink && (
                          <div className="mx-2 pt-3 FormStyling text-right">
                               <a
                                href={`${baseUrl2}/shareProfile?shareProfileName=${userProfileName}&shareProfileType=Publisher`}
                                target="_blank"
                                 rel="noreferrer"
                              >
                          Bluumerprofil {userProfileName}
                          </a>
                          </div>
                        )}
                    { getEmployeeData.length <= 0 && ( 
                    <div className='flex'>
                        <div className="mx-2 FormStyling text-right text-2xl"><IconWithTooltip2 icon={<button  className={`buttonTwo btnChange p-2 flex ${firstLoginTrue[2] ? 'bg-slate-200 border-sky-600 border-4' : ''}`} onClick={()=> setShowShareLink(!showShareLink)}>Teil-Link</button>} tooltipText="Erstellt einen Link, mit dem Andere dein Profil betrachten können, ohne selbst auf Bluumer angemeldet zu sein." /></div>
                        </div>
                      )} 

              { (getEmployeeData !== undefined ) && getEmployeeData.length > 0  &&  localStorage.getItem('profileName') === getSingleEntityData.publisherName && getUserProfileTypeEmploy === 'Publisher'  && localStorage.getItem('currentProfileType') !== 'publisherEmployee' &&  ( 
                <div>
                   
                    <div className='flex'>
                        <div className="mx-2 FormStyling text-right text-2xl"><IconWithTooltip2 icon={<button className={`buttonTwo btnChange p-2 flex ${firstLoginTrue[2] ? 'bg-slate-200 border-sky-600 border-4' : ''}`} onClick={()=> setShowShareLink(!showShareLink)}>Teil-Link</button>} tooltipText="Erstellt einen Link, mit dem Andere dein Profil betrachten können, ohne selbst auf Bluumer angemeldet zu sein." /></div>
                    </div>
                       
                      </div>  
                    )}        
                      </div>
                  </div>
                  <hr className='horizontalLine' />
                  <div className="row mainRowBackgroundcolor sm:flex white-glassmorphism-card pt-3 pb-3">
                    <div className='col col-sm-8'>
                    <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                      <div className='max-md:mb-20  max-lg:w-full' style={{minWidth: 200}}>
                        <h3 className="FormStyling ">Name</h3>
                        <h5 className="FormStyling ">{getSingleEntityData.publisherName?getSingleEntityData.publisherName:' - '}</h5>
                        <br></br>
                        <h3 className="FormStyling ">Alter</h3>
                        <h5 className="FormStyling ">{getSingleEntityData.founded?getSingleEntityData.founded:' - '}</h5>
                        <br></br>
                        <h3 className="FormStyling">Mitarbeiter</h3>
                        <h5 className="FormStyling">{getSingleEntityData.employeeCount?getSingleEntityData.employeeCount:' - '}</h5>
                        <br></br>
                        <h3 className="FormStyling">Genres</h3>
                        <div 
                          className='genre-container' 
                          style={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            justifyContent: 'flex-start',  /* Align left by default */
                            textAlign: 'left'               /* Left align text by default */
                          }}
                        >
                          {getTrueGenreData && getTrueGenreData.map((group, groupIndex) => (
                            <div 
                              key={groupIndex} 
                              className='genre-group' 
                              style={{ 
                                display: 'inline-block', 
                                margin: '0 0px' 
                              }}
                            >
                              {group.map((key, index) => (
                                <span key={index} className="FormStyling capitalize">
                                  {key}{index < group.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                              <br />
                            </div>
                          ))}
                        </div>

                      </div>
                    </div>
                    </div>
                    <div className='col col-sm-12'>
                        <div className="col col-sm-6 max-lg:w-full text-center md:text-left max-md:mb-20" >
                        <h3 className="FormStyling">Website
                        </h3><h5 className="FormStyling">{getSingleEntityData.website?getSingleEntityData.website:' - '}</h5>
                        <br></br>
                          <div className="row  textAreaOne">
                            <h3 className="FormStyling">Social Media Accounts</h3>
                            {getSingleEntityData.socialMediaAccounts && getSingleEntityData.socialMediaAccounts.map((account, index) => (
                              <h5 key={index} className="FormStyling">{account}</h5>
                            ))}
                          </div>
                          {checkedSelfPublisherTrueOptions === true && (
                          <div className="row  textAreaOne">
                            <h5 className="FormStyling mt-20">Selfpublisher- / Druckkostenzuschuss-Verlag:</h5>
                            <h5 className="FormStyling">JA</h5>
                          </div>
                          )}
                        </div>
                        <div className='col-sm-6 flex text-center justify-center items-center '>
                        <div className="profileImg  my-3  ">
                          <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }}>                          
                              <img className="img-fluid profileImg3" src={`${imageSrc}${getSingleEntityData.profileImg}`} alt="Dein Profilbild"
                                style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }} />         
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
                 )}

                {(getSingleEntityData.whoWeAre && getSingleEntityData.whoWeAre.length > 0) && (
                  <div className="row profileDataCard2  paddingOne">
                    <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                      <h2 className="FormStyling text-left titleTextColor">Wer wir sind</h2>
                    </div>
                    <hr className='horizontalLine' />
                    <div className="row mainRowBackgroundcolor white-glassmorphism-card">
                          <div className="col col-sm-12 text-left mainRowBackgroundcolor py-5">
                              <DisplayHtml htmlContent={getSingleEntityData.whoWeAre} />
                          </div>
                      </div>
                  </div>
                )}
              </div>


     { getEmployeeData && (getEmployeeData !== undefined )  && getEmployeeData.map((Employees, indexOne) => (        
       <div>
        {localStorage.getItem('userEmail') !== Employees.userProfileName && ( indexOne === 1 ||  getEmployeeData.length === 1 ) && (
         <div className="row profileDataCard2  paddingOne white-glassmorphism-card">
          <div className="row titleRowBackgroundcolor paddingTwo ">
              <h2 className="FormStyling text-left titleTextColor">Mitarbeiter-Profile</h2>
            </div>
            <hr className='horizontalLine' /> 

            { getEmployeeData.map((employees, index) => (         
                      <div className='col ml-5 w-1/2   '>
                      <div key={employees.userProfileName} className='text-left '>
			                    <div className='row flex '>
                                <div className='' style={{ minWidth: '200px', maxWidth: '450px' }}>
                                  <h4 className=" p-2">
                                      {employees.userProfileName}
                                  </h4>
                                </div>
                                <div className='text-center pt-3.5 text-[#ffffff44]' style={{ minWidth: '40px', maxWidth: '120px' }}>
                                <IconWithTooltip2 icon={ <button onClick={() => deleteEmployee(employees.userProfileName)} className=' deleteBtn p-1 mt-2 scale-125'><MdDeleteForever className="" /></button>
                                                              } tooltipText="Entferne den Mitarbeiter von deiner Liste." />                            
                                </div>
                        </div>
                      </div>
                    </div>
            ))}
            <div className="row mainRowBackgroundcolor paddingOne"><br></br></div>
          </div>
        )}
       </div>
      ))} 

        {getSingleBookprojectData && (getSingleBookprojectData.length > 0) && (
          <div className="row profileDataCard2  paddingOne">
            <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
              <h2 className="FormStyling text-left titleTextColor">Buchprojekte</h2>
            </div>
            <div className="row titleRowBackgroundcolor2 white-glassmorphism-card ">
              <div className="col col-sm-3 text-left"><h3 className="topRowStyling max-md:hidden">Titel</h3></div>
              <div className="col col-sm-6 text-left"><h3 className="topRowStyling max-md:hidden">Klappentext</h3></div>
              <div className="col col-sm-3 text-left"><h3 className="topRowStyling max-md:hidden">Seitenanzahl</h3></div>
            </div>
            <hr className='horizontalLine' />
          
            {getSingleEntityData.prevBookProjectsIds && getSingleEntityData.prevBookProjectsIds.map((bookProjects, index) => (
              <div key={bookProjects.title}>
                              {bookProjects.ratingsReceivedIds.find(
                                  (rating) => rating.createdByProfileName === localStorage.getItem('profileName')
                                ) && (
                                  <div key={bookProjects.ratingsReceivedIds.find(
                                    (rating) => rating.createdByProfileName === localStorage.getItem('profileName')
                                  ).id}>
                      <div className='max-md:divide-y-3 '>
                      {index !== 0 && (<hr className='horizontalLine2 max-md:hidden' />)}
                        <div className="row mainRowBackgroundcolor2 paddingThree white-glassmorphism-card ">
                            <div className="col col-sm-3 text-left max-md:flex"><h3 className="FormStyling md:hidden">Titel: </h3>
                <h4 className="FormStyling max-md:pt-2 max-md:pl-2">
                  <a
                                              href={`${baseUrl2}/profile?othProfileName=${bookProjects.title}&othProfileType=Book`}
                                                target="_blank"
                                                  rel="noreferrer"
                                                  onClick={() => changeOtherProfileName(bookProjects.title, 'Book')}
                                              >
                                                {bookProjects.title}
                                              </a>		
                </h4>
                </div>
              <div className="col col-sm-6 text-left"><h3 className="FormStyling md:hidden">Klappentext: </h3><h5 className="FormStyling max-md:pt-2 max-md:pl-2"><DisplayHtml htmlContent={bookProjects.jacketText} /></h5></div>
                          <div className="col col-sm-3 text-left max-md:flex"><h3 className="FormStyling md:hidden">Seitenanzahl: </h3><h4 className="FormStyling max-md:pt-2 max-md:pl-2">{bookProjects.pages}</h4></div>
                          </div>
                      </div>
                      
                </div>
                 )}
              </div>
            ))}
            <div className="row mainRowBackgroundcolor paddingOne white-glassmorphism-card"><br></br></div>
          </div>
        )}

        {getSingleEntityData.favoriteIds && (getSingleEntityData.favoriteIds.length > 0) && (
          <div className="row profileDataCard2  paddingOne white-glassmorphism-card">
            <div className="row row-col-3 titleRowBackgroundcolor  ">
              <div className="col col-sm-12 text-left paddingTwo"><h2 className="FormStyling text-left titleTextColor">Favoriten</h2></div>
            </div>
  
             <div className="row flex w-full titleRowBackgroundcolor2 ">
              <div className='col col-sm-12 xl:flex max-xl:pl-6 w-full'>
                <div className={`xl:col-sm-3 flex max-xl:text-left`}  style={{ width: expandedColumn === 'Author' ? '33%' : '33%' }} onClick={() => toggleCollapse('Author')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Author'? 'favoriteColor ':''}`}>Autoren{ expandedColumn === 'Author' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-3 flex max-xl:text-left'  style={{ width: expandedColumn === 'Book' ? '33%' : '33%' }} onClick={() => toggleCollapse('Book')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Book'? 'favoriteColor ':''}`}>Bücher{ expandedColumn === 'Book' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-3 flex max-xl:text-left'  style={{ width: expandedColumn === 'Editor' ? '33%' : '33%' }} onClick={() => toggleCollapse('Editor')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Editor'? 'favoriteColor ':''}`}>Lektoren{ expandedColumn === 'Editor' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
              </div>
              <div className='col col-sm-12 xl:flex w-full'>
                <div className='xl:col-sm-4 flex max-xl:text-left'  style={{ width: expandedColumn === 'Testreader' ? '33%' : '33%' }} onClick={() => toggleCollapse2('Testreader')}><h3 className={`flex topRowStyling  max-sm:text-3xl ${expandedColumn2 === 'Testreader'? 'favoriteColor ':''}`}>Testleser{ expandedColumn2 === 'Testreader' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-4 flex max-xl:text-left'  style={{ width: expandedColumn === 'CoverDesigner' ? '48%' : '48%' }} onClick={() => toggleCollapse2('CoverDesigner')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'CoverDesigner'? 'favoriteColor ':''}`}>CoverDesigner{ expandedColumn2 === 'CoverDesigner' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-4 flex max-xl:text-left'  style={{ width: expandedColumn === 'Publisher' ? '20%' : '20%' }} onClick={() => toggleCollapse2('Publisher')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'Publisher'? 'favoriteColor ':''}`}>Verlage{ expandedColumn2 === 'Publisher' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
              </div> 
            </div> 
            <hr className='horizontalLine' />
            <div className="row flex mainRowBackgroundcolor2 paddingThree">
              <div className='col  sm:flex w-full'>
                {["Author", "Book", "Editor","Testreader", "CoverDesigner","Publisher"].map((categoryType) => (

                 <><><> {expandedColumn === categoryType && (expandedColumn === 'Author' || expandedColumn === 'Book' || expandedColumn === 'Editor') && (

                    <div className='col ml-5 w-1/2'>
                      <div key={categoryType} className='text-left'>
                        {getSingleEntityData.favoriteIds && getSingleEntityData.favoriteIds.map((favorite, index) => (
                          <div key={favorite.userProfileName}>
                            {favorite.userProfileType === categoryType && (
                              <><>{expandedColumn === categoryType && index <= 2 && (<h4 className='sm:hidden text-left ml-5 font-bold'>{expandedColumn === 'Author' ? 'Autoren:' : expandedColumn === 'Editor' ? 'Lektor:' : 'B cher:'}</h4>)}
                              </><div className='flex '>
                                <div className='' style={{ minWidth: '250px', maxWidth: '250px' }}>
                                  <h4 className="">
                                    <a
                                      href={`${baseUrl2}/profile?othProfileName=${favorite.userProfileName}&othProfileType=${favorite.userProfileType}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={() => changeOtherProfileName(favorite.userProfileName, favorite.userProfileType)}
                                    >
                                      {favorite.userProfileName}
                                    </a>
                                  </h4>
                                </div>
                                <div className='text-right pt-1.5 text-[#ffffff44]' style={{ minWidth: '40px', maxWidth: '120px' }}>
                                <IconWithTooltip2 icon={ <button onClick={() => changeFavorite(favorite.userProfileName)} className=' deleteBtn p-1 mt-2 scale-125'><MdDeleteForever className="" /></button>
                                                              } tooltipText="Entferne den Favoriten von deiner Liste." />                            
                                </div>
                              </div></>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  </><> {expandedColumn2 === categoryType && (expandedColumn2 === 'Testreader' || expandedColumn2 === 'CoverDesigner' || expandedColumn2 === 'Publisher') && (
                    <div className='col ml-5 sm:-ml-5 w-1/2'>
                      <div key={categoryType} className='text-left '>
                        {getSingleEntityData.favoriteIds && getSingleEntityData.favoriteIds.map((favorite, index) => (
                          <div key={favorite.userProfileName}>
                            {favorite.userProfileType === categoryType && (
                                <><> {expandedColumn2 === categoryType && index <= (index + 1) && (<h4 className='sm:hidden text-left ml-5 font-bold'>{expandedColumn2 === 'Testreader' ? 'Testleser:' : expandedColumn2 === 'CoverDesigner' ? 'Coverdesigner:' : 'Verlage:'}</h4>)}</><div className='flex '>
                                <div className='' style={{ minWidth: '250px', maxWidth: '250px' }}>
                                  <h4 className="">
                                    <a
                                      href={`${baseUrl2}/profile?othProfileName=${favorite.userProfileName}&othProfileType=${favorite.userProfileType}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={() => changeOtherProfileName(favorite.userProfileName, favorite.userProfileType)}
                                    >
                                      {favorite.userProfileName}
                                    </a>
                                  </h4>
                                </div>
                                <div className='text-right pt-1.5 text-[#ffffff44]' style={{ minWidth: '40px', maxWidth: '120px' }}>
                                <IconWithTooltip2 icon={ <button onClick={() => changeFavorite(favorite.userProfileName)} className=' deleteBtn p-1 mt-2 scale-125'><MdDeleteForever className="" /></button>
                                                              } tooltipText="Entferne den Favoriten von deiner Liste." />                            
                                </div>
                              </div></>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                    </></></>
     
                ))}
    
             </div>
            </div>
            <div className="row mainRowBackgroundcolor paddingOne"><br></br></div>
          </div>
        )}

        {(getSingleEntityRating.length > 0) && (
          <div className="row profileDataCard2  paddingOne mb-20">
            <div className="row row-col-3 max-md:flex  titleRowBackgroundcolor white-glassmorphism-card">
              <div className="col md:col-sm-9 col-sm-6 w-full text-left paddingTwo"><h2 className="FormStyling text-left titleTextColor">Ratings</h2></div>
              <div className="col md:col-sm-1  col-sm-4 w-full text-right md:text-left paddingZero">
                <h4 className="FormStyling text-right">Gesamt:</h4>
              </div>
              <div className="col md:col-sm-2 col-sm-1 w-full text-left ml-4 paddingZero">
                <h2 className="FormStyling text-left paddingZero ">{ratingScoreAverage}</h2>
              </div>
            </div>
            <div className="row titleRowBackgroundcolor2 max-md:flex white-glassmorphism-card">
              <div className="col col-sm-2 max-md:hidden text-left"><h3 className="topRowStyling">Wert</h3></div>
              <div className="col col-sm-3 max-md:hidden text-left"><h3 className="topRowStyling">Von</h3></div>
              <div className="col col-sm-7 max-md:hidden text-left"><h3 className="topRowStyling">Kommentar</h3></div>
            </div>
            <hr className='horizontalLine' />

            {getSingleEntityRating.map((rating, index) => (
              <div key={rating.createdByProfileName} className="row mainRowBackgroundcolor2 paddingThree white-glassmorphism-card">
                <div className="col col-sm-2 max-md:flex text-left">
                  <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Wert:</h3>
                  <h4 className="FormStyling max-md:pl-3 ">{rating.ratingScore}</h4>
                </div>
                <div className="col col-sm-3 max-md:flex text-left">
                  <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Von:</h3>
                  <h5 className="FormStyling max-md:pl-3">{rating.createdByProfileName}</h5>
                </div>
                <div className="col col-sm-7 text-left">
                  <div
                    style={{
                      maxWidth: '730px', // Set a fixed height as an example, adjust as needed
                      overflow: 'hidden',
                      cursor: 'pointer'
                    }}
                  >
                    <h3 className="topRowStyling md:hidden titleTextColor">Kommentar:</h3>
                    <h5
                      className="FormStyling"
                      onClick={() => toggleTextExpansion(rating.createdByProfileName)}
                    >
                      {expandedTexts[rating.createdByProfileName]
                        ? rating.ratingText // Show full text if expanded
                        : `${rating.ratingText.substring(0, 80)}...` // Show truncated text
                      }
                    </h5>
                  </div>
                  <hr style={{ borderColor: 'lightgray' }} className={`horizontalLine mt-5 mb-5 ${(index === 1 ||  getSingleEntityRating.length === index +1) ?'hidden':'sm:hidden'}`}/>
                
                </div>
              </div>
            ))}
            <div className="row mainRowBackgroundcolor paddingOne white-glassmorphism-card"><br></br></div>
          </div>
        )}
        

        </>
        </div> 
      )} 

        </div>        
    </div>  
    
    
  );
}

export default MyProfileViewPublisher; 
export { default as MyProfileViewPublisher} from './MyProfileViewPublisher';