import './App.css';

import React from 'react';

//Web Pages
import Home from './pages/home';
import LandingPageLogin from './pages/landingPageLogin';
import CreateFirstProfile from './pages/createFirstProfile';

import CreateYourProfile from './pages/createYourProfile';
import ConfirmEmail from './pages/confirmEmail';
import PasswordReset from './pages/passwordReset';

import Dashboard from './pages/dashboard';
import Chat from './pages/chat';
import MyProfile from './pages/myProfile';
import Contact from './pages/contact';
import Forum from './pages/forum';
import Rules from './pages/rules';

import OthersProfile from './pages/othersProfile';
import ShareProfile from './pages/shareProfile';
import AdminConsole from './pages/adminConsole';
import BookCreation from './pages/bookCreation';

import Settings from './pages/settings';
import AddProfile from './pages/addProfile';
import AddEmployee from './pages/addEmployee';




//Components
import { Navbar } from './components/navbar&Footer/Navbar';

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";





function App() {  

    ///////////// for Development  //////////////

   //var baseUrl = "http://localhost:8080";  
   //var baseUrl2 = "http://localhost:3000";      

    ///////////// for Production  ////////////

   var baseUrl = "https://api.bluumer.com";  //     bookroots.de 
   var baseUrl2 = "https://www.bluumer.com";   


    return (
        <Router>
            <div className="relative min-h-screen bg-[fffff]">
                <div className="min-h-screen text-black  z-0 ">
                    <div className='z-10 skin-glassmorphism-background'> {/* App */}
                        <div className='relative z-30 skin-glassmorphism-background'>
                        
                     {/*   <Navbar baseUrl={baseUrl}/> */}
                                    <Routes>
                                       {/*} <Route path="/" element={<Home />} /> */}
                                       <Route path="/"  element={<LandingPageLogin baseUrl={baseUrl}/>} />
                                    </Routes>
                                    <div className=''>
                                    <Routes>
                                       {/*} <Route path="/login" element={<LandingPageLogin baseUrl={baseUrl}/>} />  */}
                                        <Route path="/createFirstProfile" element={<CreateFirstProfile baseUrl={baseUrl}/>} />
                                        <Route path="/createYourProfile" element={<CreateYourProfile baseUrl={baseUrl}/>} />
                                        <Route path="/confirmEmail" element={<ConfirmEmail baseUrl={baseUrl}/>} />
                                        <Route path="/resetPassword" element={<PasswordReset baseUrl={baseUrl}/>} />
                                        <Route path="/dashboard" element={<Dashboard baseUrl={baseUrl} baseUrl2={baseUrl2} />} />
                                        <Route path="/chat" element={<Chat  baseUrl={baseUrl}/>} />    
                                        <Route path="/myProfile" element={<MyProfile baseUrl={baseUrl} baseUrl2={baseUrl2}/>} />
                                        <Route path="/profile" element={<OthersProfile baseUrl={baseUrl} baseUrl2={baseUrl2}/>} />
                                        <Route path="/bookCreation" element={<BookCreation baseUrl={baseUrl}/>} />
                                        <Route path="/adminConsole" element={<AdminConsole baseUrl={baseUrl}/>} />
                                        <Route path="/settings" element={<Settings baseUrl={baseUrl}/>} />
                                        <Route path="/addProfile" element={<AddProfile baseUrl={baseUrl}/>} />
                                        <Route path="/addEmployee" element={<AddEmployee baseUrl={baseUrl} />} />
                                        <Route path="/contact" element={<Contact baseUrl={baseUrl} />} />
                                        <Route path="/shareProfile" element={<ShareProfile baseUrl={baseUrl}/>} />
					                    <Route path="/forum" element={<Forum baseUrl={baseUrl} baseUrl2={baseUrl2} />} />  	 
                                        <Route path="/rules" element={<Rules baseUrl={baseUrl} baseUrl2={baseUrl2} />} />
                                    </Routes>
                                    </div>
                           

                        </div>        
                    </div>                    
                </div>
            </div>
        </Router>
    );
}

export default App;