import DOMPurify from 'dompurify';

export const sanitizeInputTextArea = (input) => {
  // Trim leading and trailing whitespaces
  //const trimmedInput = input.trim();

  const sanitizedInput1 = escapeHtml(input)

  const sanitizedInput2 = sanitizedInput1.replace(/[äöüÄÖÜ]/g, (match) => {
    switch (match) {
      case 'ä':
        return 'ae';
      case 'ö':
        return 'oe';
      case 'ü':
        return 'ue';
      case 'Ä':
        return 'Ae';
      case 'Ö':
        return 'Oe';
      case 'Ü':
        return 'Ue';
      default:
        return match;
    }
  });

  // Use DOMPurify to sanitize HTML
  const sanitizedInput3 = DOMPurify.sanitize(sanitizedInput2);

  const truncatedValue = sanitizedInput3.slice(0,500);

  return truncatedValue;
};

// Helper function to escape HTML entities
const escapeHtml = (unsafe) => {
    return unsafe.replace(/[&<"'>]/g, (match) => {
      switch (match) {
        case '&':
          return '&amp;';
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '"':
          return '&quot;';
        case "'":
          return '&#39;';
        default:
          return match;
      }
    });
  };