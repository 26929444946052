import './ConfirmEmailComponent.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import logo from '../../images/Logo_E_Social_White_bigger.png';


function ConfirmEmailComponent() {

    

  const [getToken,setToken,  ] = useState([]);
  const [getAuthorizationSuccess,setAuthorizationSuccess] = useState([false]);

  const [getIsConfirmed, setIsConfirmed  ] = useState([false]);

  const [getUserProfileNameForIds,setUserProfileNameForIds,  ] = useState('');

  const [getEmailOrPasswordError,setEmailOrPasswordError,  ] = useState(false);
  const [getUserNotExist,setUserNotExist,  ] = useState(false);

  const [getUserEmail,setUserEmail,  ] = useState('');
  
  const [error, setError] = useState(null);

  const location = useLocation(); 

  const handleTokenExtraction = () => {
    // Extract token from the URL
    const token = new URLSearchParams(location.search).get('token');
    const userProfileNameForIds = new URLSearchParams(location.search).get('token2');
    const userEmail = new URLSearchParams(location.search).get('token3');
  
    if (token) {
      //console.log('Token:', token);
      setToken(token);
      setAuthorizationSuccess(false);
    } else {
      handleError('Token not found in the URL');
    }
  

  if (userProfileNameForIds) {
    //console.log('Token2:', userProfileNameForIds);
    setUserProfileNameForIds(userProfileNameForIds);
  } else {
    handleError('Token2 not found in the URL');
  }

  if (userEmail) {
    //console.log('Token2:', userProfileNameForIds);
    setUserEmail(userEmail);
  } else {
    handleError('Token2 not found in the URL');
  }

  if(token !== "Please Log in" && token !== '' && userProfileNameForIds !== "" && userEmail !== ""){
   
    setTimeout(() => {
      authenticate(token, userProfileNameForIds, userEmail);
    }, 300); 

  }else{

  //  handleTokenExtraction()
  }
       

};


  
  const authenticate = async (token, UserProfileNameForIds, UserEmail) => {
    
  
    setEmailOrPasswordError(false);
    setUserNotExist(false);

    const newAuthData = {
      profileNameForIds: UserProfileNameForIds
    };

    //console.log('userName:', newAuthData.userName);
    //console.log('password:', newAuthData.password);
  
    try{
    
      const response2 = await api.get(`/api/v1/auth/user/${UserEmail}`);
      if(response2.data != null){

        const isConfirmed = response2.data.isConfirmed;     
  
            if (!isConfirmed) {
              const response = await api.post(`/api/v1/auth/authenticate/${token}`, newAuthData, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
        
              console.log('Authorization:', response.data);
              if (response.data != null) {
                setAuthorizationSuccess(true);
              }else{
                setEmailOrPasswordError(true);
              }
        
            } else {
              setIsConfirmed(true);
            }
        }else{
          setUserNotExist(true);
        }

      } catch (error) {
        handleError('Error during :', error);
        setEmailOrPasswordError(true);
      }
  };
  
  useEffect(() => {
    setIsConfirmed(false);
    
  }, []);
  
  useEffect(() => {
    handleTokenExtraction();
  }, [location.search]);
  

  const handleError = (message, error) => {
    console.error(message, error);
    setError('An error occurred. Please try again.');
    };


  return (
        <div className="ConfirmEmailComponent">
              
            <div><h3>Email-Bestätigung</h3></div>
        
      {/*}  {error && <div style={{ color: 'red' }}>{error}</div>} */}  

            {getEmailOrPasswordError && (<p className='py-6' style={{ color: 'red' }}>Es tut uns leid. Es gab einen Fehler bei deiner Registrierung. Bitte versuche es erneut!</p>)}
            {getUserNotExist && (<p className='py-6' style={{ color: 'red' }}>Es tut uns leid. Es gab einen Fehler bei deiner Registrierung. Bitte versuche es erneut!</p>)}
                              

       { getIsConfirmed &&  (   

              <div className="py-5 h-100 text-center">
                      <div className="row row-col-3 text-center h-100">
                              <div className="col-sm-4 text-center"></div>
                              <div className="col-sm-4 text-center colConfirmEmail">
                              <div className="col-sm-12 items-center justify-content-center  -mb-10">
                                <div className="col-sm-2"></div>
                                    <div className="col-sm-8"><img className="scale-50" src={logo} alt="logo" /></div>
                                      <div className="col-sm-2"></div>
                                </div>
                                    <h2 className="mt-1 mb-5 pb-1">Email bereits verifiziert!</h2>
                                    <h3 className="mt-1 mb-5 pb-1">Du kannst dich nun einloggen!</h3>
                                
                                  </div>
                                  <div className="col-sm-4 text-center"></div>
                                </div>
                              </div>

              )}

        { getAuthorizationSuccess &&  (   

              <div className="py-5 h-100 text-center mb-40">
                      <div className="row row-col-3 text-center h-100">
                              <div className="col-sm-4 text-center"></div>
                              <div className="col-sm-4 text-center colConfirmEmail">
                              <div className="col-sm-12 items-center justify-content-center -mb-10">
                                <div className="col-sm-2"></div>
                                    <div className="col-sm-8"><img className="scale-50" src={logo} alt="logo" /></div>
                                      <div className="col-sm-2"></div>
                                </div>
                                    <h2 className="mt-1 mb-5 pb-1">Email erfolgreich verifiziert!</h2>
                                    <h3 className="mt-1 mb-5 pb-1">Du kannst dich nun einloggen!</h3>
                                
                                  </div>
                                  <div className="col-sm-4 text-center"></div>
                                </div>
                              </div>

        )}

{/*}
        { !getAuthorizationSuccess && !getIsConfirmed && (

            <div className="py-5 h-100 text-center mb-20">
              <div className="row row-col-3 text-center h-100">
                      <div className="col-sm-4 text-center"></div>
                      <div className="col-sm-4 text-center colConfirmEmail">
                      <div className="col-sm-12 -mb-10">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-8"><img className="scale-50" src={logo} alt="logo" /></div>
                            <div className="col-sm-2"></div>
                      </div>   
                      {getEmailOrPasswordError && (<p className='py-6' style={{ color: 'red' }}>Es gab einen Fehler. Bitte kontrolliere deine Email und Passwort!</p>)}
                      {getUserNotExist && (<p className='py-6' style={{ color: 'red' }}>Es gab einen Fehler. Bitte kontrolliere deine Email!</p>)}
                
                          <form  onSubmit={authenticate}>
                            <p className='pb-6'>Bitte log dich ein zum Bestätigen deiner Email</p>

                            <div className="form-outline mb-4">
                              <input type="email" id="form2Example11" className="form-control"
                                placeholder="Email-Adresse" name="userName"/>
                              <label className="form-label" for="form2Example11">Email</label>
                            </div>

                            <div className="form-outline mb-4">
                              <input type="password" id="form2Example22" className="form-control" name="password" placeholder="Passwort" />
                              <label className="form-label" for="form2Example22">Passwort</label>
                            </div>

                            <div className="text-center pt-1 mb-5 pb-1">
                              <button className="buttonTextColor text-2xl p-2 -ml-2 px-32 mx-4 btnChange mb-3" 
                                type="submit">Bestätige Email</button>
                            </div>
                          </form>
                          </div>
                          <div className="col-sm-4 text-center"></div>
                        </div>
                      </div>
                )}
            
      */}

          </div>  

        

    
  );
}

export default ConfirmEmailComponent; 
export { default as ConfirmEmailComponent } from './ConfirmEmailComponent';