import './changeEmail.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';


function ChangeEmail() {

    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');

    const [thereWasAnError, setThereWasAnError] = useState(false);
    const [changeSuccessful, setChangeSuccessful] = useState(false);
    const [noEmailMatch, setNoEmailMatch] = useState(false);
    
    const navigate = useNavigate();
    
    useEffect(() => {
      LogoutRedirectCheck(navigate);
    }, [navigate]);
    
    useEffect(() => {
      setChangeSuccessful(false);
      setThereWasAnError(false);
      setNoEmailMatch(false);
    }, []);
    
    const resetEmail = async (e) => {
      e.preventDefault();

      setChangeSuccessful(false);
      setThereWasAnError(false);
      setNoEmailMatch(false);
    
      const { target } = e;
    
      const newResetData = {
        userName: localStorage.getItem('userEmail'),
        newEmail: target.userEmail.value
      };
    
      const emailMatch = target.userEmail2.value;
    
      if (emailMatch === newResetData.newEmail) {
        try {
          const response = await api.post(`/api/v1/auth/resetEmail`, newResetData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });
    
          if (response.data === true) {
            setChangeSuccessful(true);
            localStorage.setItem(`userEmail`, target.userEmail.value);
          } else {
            setThereWasAnError(true);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setNoEmailMatch(true);
      }
    
      // Clear email states
      setEmail('');
      setEmail2('');
    };


    return (
      <div className="ChangeEmail pb-20">
        <div className="container rounded-3xl py-5 my-20 h-100 text-center  white-glassmorphism-card">
          <div className='text-center'><h3>Ändere deine Email</h3></div>
          <div className="row row-col-3 text-center h-100">
            <div className="col-sm-4 text-center"></div>
            <div className="col-sm-4 text-center colConfirmEmail">
              <form onSubmit={resetEmail}>
                {changeSuccessful && (<p className='text-[#3dc872]'>Deine Emailänderung war Erfolgreich.</p>)}
                {thereWasAnError && (<p>Es tut uns leid. Es gab einen Fehler!</p>)}
                {noEmailMatch && (<p style={{ color: 'red' }}>Die Emails stimmen nicht überein!</p>)}
    
                <div className="form-outline mb-4">
                  <input
                    type="email"
                    id="form2Example11"
                    className="form-control"
                    placeholder="Email-Adresse"
                    name="userEmail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="form-label" htmlFor="form2Example11">Neue Email</label>
                </div>
    
                <div className="form-outline mb-4">
                  <input
                    type="email"
                    id="form2Example11"
                    className="form-control"
                    placeholder="Email-Adresse"
                    name="userEmail2"
                    value={email2}
                    onChange={(e) => setEmail2(e.target.value)}
                  />
                  <label className="form-label" htmlFor="form2Example11">Neue Email</label>
                </div>
    
                <div className="text-center pt-1 mb-5 pb-1 -ml-6">
                  <button
                    className="buttonTextColor text-2xl p-2 sm:px-56 max-sm:px-10 mx-4 btnChange mb-3 mt-5"
                    type="submit"
                    ><b>
                    Bestätigen</b>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-sm-4 text-center"></div>
          </div>
        </div>
      </div>
    );
  }    

export default ChangeEmail; 
export { default as ChangeEmail } from './changeEmail';