import './changePassword.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';


function ChangePassword() {

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [thereWasAnError, setThereWasAnError] = useState(false);
    const [changeSuccessful, setChangeSuccessful] = useState(false);
    const [noPasswordMatch, setNoPasswordMatch] = useState(false);
    
    const navigate = useNavigate();
    
    useEffect(() => {
      LogoutRedirectCheck(navigate);
    }, [password, password2]);
    
    useEffect(() => {
      setChangeSuccessful(false);
      setThereWasAnError(false);
      setNoPasswordMatch(false);
    }, []);
    
    const resetPassword = async (e) => {
      e.preventDefault();

      setChangeSuccessful(false);
      setThereWasAnError(false);
      setNoPasswordMatch(false);
    
      const { target } = e;
    
      const newResetData = {
        userName: localStorage.getItem('userEmail'),
        newPassword: target.password.value
      };
    
      const passwordMatch = target.password2.value;
    
      //console.log('userName:', newResetData.userName);
      //console.log('newPassword:', newResetData.newPassword);
      //console.log('password2:', passwordMatch);
    
      if (passwordMatch === newResetData.newPassword) {
        try {
          const response = await api.post(`/api/v1/auth/resetPassword`, newResetData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });
    
          if (response.data === true) {
            setChangeSuccessful(true);
          } else {
            setThereWasAnError(true);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setNoPasswordMatch(true);
      }
    
      // Clear password states
      setPassword('');
      setPassword2('');
    };


    return (
      <div className="ChangePassword">
    
        <div className="container rounded-3xl py-5 h-100 text-center white-glassmorphism-card">
          <div className='text-center'><h3>Ändere dein Passwort</h3></div>
          <div className="row row-col-3 text-center h-100">
            <div className="col-sm-4 text-center"></div>
            <div className="col-sm-4 text-center colConfirmEmail">
              <form onSubmit={resetPassword}>
                {changeSuccessful && (<p className='text-[#3dc872]'>Deine Passwortänderung war erfolgreich.</p>)}
                {thereWasAnError && (<p>Es tut uns leid. Es gab einen Fehler!</p>)}
                {noPasswordMatch && (<p style={{ color: 'red' }}>Die Passwörter stimmen nicht überein!</p>)}
    
                <div className="form-outline mb-4">
                  <input
                    type="password"
                    id="form2Example22"
                    className="form-control"
                    placeholder="Passwort"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label className="form-label" htmlFor="form2Example22">Neues Passwort</label>
                </div>
    
                <div className="form-outline mb-4">
                  <input
                    type="password"
                    id="form2Example22"
                    className="form-control"
                    placeholder="Passwort"
                    name="password2"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                  <label className="form-label" htmlFor="form2Example22">Neues Passwort</label>
                </div>
    
                <div className="text-center pt-1 mb-5 pb-1 -ml-6">
                  <button
                    className="buttonTextColor text-2xl p-2 sm:px-56 max-sm:px-10 mx-4 btnChange mb-3 mt-5"
                    type="submit"
                  ><b>
                    Bestätigen</b>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-sm-4 text-center"></div>
          </div>
        </div>
    
      </div>
    );
    
}

export default ChangePassword; 
export { default as ChangePassword } from './changePassword';