
import { useEffect, useState } from 'react';




const TimestampFormatterLight = ({ timestamp, prevTimestamp }) => {
	const [formattedDate, setFormattedDate] = useState(null);
  
	//console.log('timestamp: ', timestamp);
	//console.log('prevTimestamp: ', prevTimestamp);

	useEffect(() => {

		//const [year, month, day, hour, minute, second, millisecond] = timestamp
		const [a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s] = timestamp;

		const year = a + b + c + d;
		const month = f + g;
		const day = i + j;
		const hour = l + m;
		const minute = o + p;
		//console.log('year  : ', year  );  
		

		//console.log('[a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s]  : ', [a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s]  );
		 // Function to add leading zero to single-digit numbers
		 const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

		if (prevTimestamp && prevTimestamp !== null ) {

			//const [year2, month2, day2, hour2, minute2, second2, millisecond2] = prevTimestamp  

			const [a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s] = prevTimestamp;

			const year2 = a + b + c + d;
			const month2 = f + g;
			const day2 = i + j;
			const hour2 = l + m;
			const minute2 = o + p;      
	
		  if (year === year2 && month === month2 && day === day2) {
			
			  //const formattedString = `${addLeadingZero(hour)}:${addLeadingZero(minute)}`;
			  const formattedString = `${hour}:${minute}`;
			  setFormattedDate(formattedString);
			  //console.log('formattedString: ', formattedString);

		  } else {
			const formattedString =  (
				<>
				{hour}:{minute}  
				 {/* {addLeadingZero(hour)}:{addLeadingZero(minute)} 	*/}		  
				</>
			  );
			  setFormattedDate(formattedString);
			  //console.log('formattedString: ', formattedString);
		  }

		}else{
			const formattedString =  (
				<>
				{hour}:{minute} 
				{/* {addLeadingZero(hour)}:{addLeadingZero(minute)}*/}	 				  
				</>
			  );
			  setFormattedDate(formattedString);
			  //console.log('formattedString: ', formattedString);
		}
		
	  }, [timestamp, prevTimestamp]);
  
	 // console.log('formattedDate: ', formattedDate);

	return (
	  <div>
		{formattedDate}
	  </div>
	);
  }

  export default TimestampFormatterLight; 
  export { default as TimestampFormatterLight } from './TimestampFormatterLight.js';