import DOMPurify from 'dompurify';


// Helper function to escape HTML entities
const escapeHtml = (unsafe) => {
  return unsafe.replace(/[&<"'>]/g, (match) => {
    switch (match) {
      case '&':
        return '&amp;';
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '"':
        return '&quot;';
      case "'":
        return '&#39;';
      default:
        return match;
    }
  });
};

const SanitizeInput = (input) => {
/*
  const [getInput, setInput] = useState('');

  if (typeof input === 'string') {
    setInput(input.trim());
  } else {
    setInput(input);
    console.error('Input is not a string');
  } */


  const sanitizedInput1 = escapeHtml(input)

  const sanitizedInput2 = sanitizedInput1.replace(/[äöüÄÖÜ]/g, (match) => {
    switch (match) {
      case 'ä':
        return 'ae';
      case 'ö':
        return 'oe';
      case 'ü':
        return 'ue';
      case 'Ä':
        return 'Ae';
      case 'Ö':
        return 'Oe';
      case 'Ü':
        return 'Ue';
      default:
        return match;
    }
  });

  // Use DOMPurify to sanitize HTML
  const sanitizedInput3 = DOMPurify.sanitize(sanitizedInput2);

  const truncatedValue = sanitizedInput3.slice(0, 50);

  return truncatedValue;
};


export default SanitizeInput;
export { default as SanitizeInput } from './SanitizeInput';


