import './css/PinboardView.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, Link } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';
import {RatingSumAverageScore} from '../../tools/RatingSumAverageScore';

import { FaPencilAlt } from "react-icons/fa";
import { FaGlasses } from "react-icons/fa6";
import { AiFillPicture } from "react-icons/ai";
import { FaUserFriends } from "react-icons/fa";

import { ImSpinner9 } from "react-icons/im";  // zum laden anzeigen
import { GrMoreVertical } from "react-icons/gr";
import { GrApps } from "react-icons/gr";

import { BsChatDots } from "react-icons/bs";

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoReloadCircle } from "react-icons/io5";
import { IoCheckmarkCircle } from "react-icons/io5";

import {JustDateTimestampFormatter} from '../../tools/JustDateTimestampFormatter.js';

//Security
import { exeedsMaxCharacter } from '../../tools/security/exeedsMaxCharacter';
import { exeedsMaxCharacterTitel } from '../../tools/security/exeedsMaxCharacterTitel';
import { exeedsMaxCharacterTextAreaBig } from '../../tools/security/exeedsMaxCharacterTextAreaBig';

import ReactQuill from "react-quill";   
import 'react-quill/dist/quill.snow.css';

import DOMPurify from 'dompurify';

function IconWithTooltip({ icon, tooltipText }) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
        className=""
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {React.cloneElement(icon, {
          style: { fontSize: '10px' },
          title: isHovered ? tooltipText : '',
        })}
      </div>
    );
  }

  function DisplayHtml({ htmlContent }) {
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    //console.log('sanitizedHtml:',sanitizedHtml);
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} className='text-left'></div> 
    );
}


const PinboardView = ({ selectedSearchOption , showSearch, selectedDemandOption, showDemand,
                        totalCounts, setTotalCounts, reloadPinboardCount, firstLoginTrue2, setFirstLoginTrue2, baseUrl2}) => {


    const [getPinboardData,setPinboardData,  ] = useState([]);
    const [getSinglePinboardData,setSinglePinboardData, ] = useState([]);                          
                          
    const [showSearchRatingRow,setShowSearchRatingRow, ] = useState(false); 
    const [searchInputValue,setSearchInputValue] = useState([]);

    const [getSortedScoreArray,setSortedScoreArray ] = useState([]);

    const [activeSortColumn, setActiveSortColumn] = useState('rating');

    const [sortConfig, setSortConfig] = useState('0', 'ascending');
    const [getSortedData, setSortedData] = useState([]);
    const [render, setRender] = useState(false);
    const [partRender, setPartRender] = useState(false);

    const [getRankHrefClick, setRankHrefClick] = useState(false);

    
    const [getTotalPageNumber, setTotalPageNumber] = useState(1);
    const [showLoadingData, setShowLoadingData] = useState(false);
    const [singleEntityArray, setSingleEntityArray] = useState([]);
    const [pageNum, setPageNum] = useState('');
    const [entityDataArray, setEntityDataArray] = useState([]);

    const [categoryFilter, setCategoryFilter] = useState('Lektorat');
    const [getPinboardCreatorName, setPinboardCreatorName] = useState('');
    const [searchForTitle, setSearchForTitle] = useState(true);
    const [searchForPinboardCreator, setSearchForPinboardCreator] = useState(false);
    const [showCreateNewPinboardPost, setShowCreateNewPinboardPost] = useState(false);

    const [pinboardOfferDemand, setPinboardOfferDemand] = useState('');
    const [pinboardCategory, setPinboardCategory] = useState('');
    const [pinboardTitle, setPinboardTitle] = useState('');
    const [pinboardText, setPinboardText] = useState('');
    const [pinboardPrice, setPinboardPrice] = useState('');

    const [characterCountText, setCharacterCountText] = useState(0);

    const [myPinboardPosts, setMyPinboardPosts] = useState(false);
    const [getTotalPageNumber2, setTotalPageNumber2] = useState(1);
    const [pageNum2, setPageNum2] = useState('');
    const [creatorNameAsTitle, setCreatorNameAsTitle] = useState('');
    const [searchActive, setSearchActive] = useState(false);

    const [myPinboardArray, setMyPinboardArray] = useState([]);
    const [myPinboardData, setMyPinboardData] = useState([]);
    const [titleOrIDArray, setTitleOrIDArray] = useState([]);
    const [titleOrIDData, setTitleOrIDData] = useState([]);
    
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [showReallySendInquiryEmail, setShowReallySendInquiryEmail] = useState(false);
    const [getInquiryData, setInquiryData] = useState([]);
    const [getUserProfileNames, setUserProfileNames] = useState([]);

    const [showReallyDelete, setShowReallyDelete] = useState(false);
    const [getDeleteData, setDeleteData] = useState([]);
    const [getDeletedButNotRefreshedPinboards, setDeletedButNotRefreshedPinboards] = useState([]);
    const [timer, setTimer] = useState(null);  // Track the timer 

    const [searchForAllProfileEntriesDone, setSearchForAllProfileEntriesDone] = useState(false);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [hoveredSearchRow, setHoveredSearchRow] = useState(null);

    const [showCreatedPinboardInfo, setShowCreatedPinboardInfo] = useState(false);
    
    
    const [error, setError] = useState(null); 
    
    const navigate = useNavigate();
    

    const modules = {
      toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'], 
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
    }
  }

  const entitiesPerPage = 15;

// Sanitize a text field by removing unwanted characters
const sanitizeText = (input) => {
  let cleanInput = exeedsMaxCharacterTitel(input);
  cleanInput = cleanInput.replace(/[^\w\s_]/g, ''); // Allow only alphanumeric, spaces, and underscores
  return cleanInput.replace(/^_+|_+$/g, ''); // Trim leading or trailing underscores
};


// Sanitize and validate numeric input, retaining last valid input
const sanitizeNumber = (input, lastValid = '', max = 125) => {
  const regex = /^\d+(\.\d{0,1})?$/; // Allow digits with up to one decimal place
  const isValid = regex.test(input) && (input === '' || parseFloat(input) <= max);

  return isValid ? input : lastValid;
};



    const handleTitleChange = (e) => setPinboardTitle(sanitizeText(e.target.value));
 
    const handlePriceChange = (e) => {
      setPinboardPrice((prevValue) => {
        const newValue = e.target.value; // Get the current input value
        return sanitizeNumber(newValue, prevValue, 10000); // Pass prevValue as the last valid
      });
    };
    
      const handleTextChange = useCallback((value) => {
        try {
          const characterCount = value.replace(/<[^>]*>/g, '').length; // Strip HTML tags and count characters
          setCharacterCountText(characterCount);
          if(characterCount <= 2000){    
            setPinboardText(exeedsMaxCharacterTextAreaBig(value));
          }
        } catch (error) {
          handleError('Error handling quill exp change:', error);
        }
    }, []);
  
    const handlePinboardSubmit = async (e) => {
      e.preventDefault();
  
      const newPinboardPost = {
        pinboardActive: true,
        pinboardCreatorName: localStorage.getItem('profileName'),
        pinboardCreatorType: localStorage.getItem('currentProfileType'),
        pinboardOfferDemand,
        pinboardCategory,
        pinboardTitle: pinboardTitle.trim(),
        pinboardText,
        pinboardPrice: parseFloat(pinboardPrice) || 0.0,
      };
  
      try {
        const response = await api.post('/api/pinboard/create', newPinboardPost, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        });


        if (response.status === 201 || response.status === 200 ) {
          //alert('Pinboard post created successfully!');
          setShowCreatedPinboardInfo(true);
        }

      } catch (error) {
        console.error('Error creating pinboard post:', error);
       // alert('Failed to create pinboard post');
      }
    };


    const categoryFilters = [
      "SucheLektorat", "SucheKorrektorat", "SucheTestlesung", "SucheCoverdesign", "SucheAnderes",
      "BieteLektorat", "BieteKorrektorat", "BieteTestlesung", "BieteCoverdesign", "BieteAnderes"
    ];
  
    const getPinboardCountEachCategory = async () => {
      setMyPinboardPosts(false);
      const numericPageVar = 1;
      const countsArray = [];
  
      try {
        for (const categoryFilter of categoryFilters) {
          const response = await api.get(`/api/pinboard/paginated`, {
            params: { pageVar: numericPageVar, categoryFilter },
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
  
          const filteredEntities = response.data[0].filter(
            (entity) => !getDeletedButNotRefreshedPinboards.includes(entity.randomIdNumber)
          );
          const removedCount = response.data[0].length - filteredEntities.length;
          const totalCount = response.data[1] - removedCount;
  
          // Push each count to the counts array in order of categoryFilters
          countsArray.push(totalCount);
        }
  
        // After all counts are fetched, set the totalCounts state
        setTotalCounts(countsArray);
  
      } catch (error) {
        handleError('Error fetching pinboard:', error);
      }
    };





    const getPinboard = async (pageVar) => {

      setMyPinboardPosts(false);
      const numericPageVar = Number(pageVar); // Ensure pageVar is a number
      
      // Check if the requested page is already in the cache
      const cachedPage = entityDataArray.find((entry) => entry.page === numericPageVar);
      if (cachedPage) {
        //console.log(2);
        return cachedPage.data; // Return the cached data for this page
      }

      // If the page is not cached, make the API call
      try {
        const response = await api.get(`/api/pinboard/paginated`, {
          params: { pageVar: numericPageVar , categoryFilter: categoryFilter}, // Send the pageVar as a query parameter
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
        });


        const filteredEntities = response.data[0].filter(entity => 
          !getDeletedButNotRefreshedPinboards.includes(entity.randomIdNumber)
        );
        // Count how many entities were removed by the filter
        const removedCount = response.data[0].length - filteredEntities.length;

        // Correctly calculate the total count
        const totalCount = response.data[1] - removedCount;

        const totalPageNum = Math.ceil(parseFloat(totalCount) / entitiesPerPage);
        setTotalPageNumber(totalPageNum===0?1:totalPageNum);
  
        // Add the new page data to the cache
        let newCache = [...entityDataArray]; // Create a new copy of the cache
  
        if (newCache.length >= 5) {
          // Find the index of the furthest page from the current page to remove
          const furthestPageIndex = newCache.reduce((furthestIndex, entry, index) => {
            const distance = Math.abs(entry.page - numericPageVar);
            const furthestDistance = Math.abs(newCache[furthestIndex].page - numericPageVar);
            return distance > furthestDistance ? index : furthestIndex;
          }, 0);
  
          // Overwrite the furthest page data with the new data
          newCache[furthestPageIndex] = { page: numericPageVar, data: filteredEntities };
        } else {
          // Add the new data to the cache
          newCache = [...newCache, { page: numericPageVar, data: filteredEntities }];
        }
        //console.log(1);
        setEntityDataArray(newCache); // Update the original cache state
  
        return filteredEntities;
  
      } catch (error) {
        handleError('Error fetching pinboard:', error);
      }
    }; 

    const fetchFirstTimeData = async (pageVar, selection) => {

      setMyPinboardPosts(false);

      try {

        if(pageVar !== ''){
        setShowLoadingData(true);
        setPageNum('');
        localStorage.setItem('pageNum', pageVar);
        const Pinboard = await getFirstTimePinboard(pageVar, selection);
        setPinboardData(Pinboard);
       
        //console.log('Authors: ',Authors )
        setTimeout(() => {                  
          sortRatingsAndGiveOrder(Pinboard, pageVar);
          setShowLoadingData(false);

          //console.log('sortRatingsAndGiveOrder: ', Authors )
          //console.log('usingSortableData: ',getSortedScoreArray )
        }, 100);
      }

      } catch (error) {
        handleError('Error fetching authors:', error);
      }
    };

   const getFirstTimePinboard = async (pageVar, selection) => {
      const numericPageVar = Number(pageVar); // Ensure pageVar is a number
      
      // Check if the requested page is already in the cache
      const cachedPageIndex = entityDataArray.findIndex((entry) => entry.page === numericPageVar);
    
      //console.log('categoryFilter: ', categoryFilter);
      //console.log('selection: ', selection);
      // Always make the API call to get the latest data
      try {
        const response = await api.get(`/api/pinboard/paginated`, {
          params: { pageVar: numericPageVar, categoryFilter: selection !=null?selection:categoryFilter }, // Send the pageVar as a query parameter
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        });

        const filteredEntities = response.data[0].filter(entity => 
          !getDeletedButNotRefreshedPinboards.includes(entity.randomIdNumber)
        );

        // Count how many entities were removed by the filter
        const removedCount = response.data[0].length - filteredEntities.length;

        // Correctly calculate the total count
        const totalCount = response.data[1] - removedCount;

       // console.log('response.data: ', response.data);
        //console.log('filteredEntities: ', filteredEntities);
        //console.log('getDeletedButNotRefreshedPinboards: ', getDeletedButNotRefreshedPinboards);
    
        // Destructure pinboard and totalCount from the response
        const totalPageNum = Math.ceil( totalCount  / entitiesPerPage);
        setTotalPageNumber(totalPageNum===0?1:totalPageNum);
    
        // Create a new copy of the cache
        let newCache = [...entityDataArray];
    
        if (cachedPageIndex !== -1) {
          // Replace the cached entry with the new data
          newCache[cachedPageIndex] = { page: numericPageVar, data: filteredEntities };
        } else {
          // Add the new page data if not cached before
          newCache = [...newCache, { page: numericPageVar, data: filteredEntities }];
        }
    
        setEntityDataArray(newCache); // Update the cache with the new data
        //console.log(newCache);
        
        return filteredEntities;
    
      } catch (error) {
        handleError('Error fetching first time pinboard:', error);
      }
    };  
    
    

      
      const getSinglePinboardDocument = async (e) => {
        e.preventDefault();
        const { target } = e;

        setShowSearchRatingRow(true);
        setSearchActive(true);
        setMyPinboardPosts(false);
      
        const addNewPinboardData = {
          pinboardTitle: target.name.value,
        };

        //console.log("target.name.value: ", target.name.value);
      
        try {

          const response = await api.get(`/api/pinboard/search/${addNewPinboardData.pinboardTitle}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });

          const filteredEntities = response.data.filter(entity => 
            !getDeletedButNotRefreshedPinboards.includes(entity.randomIdNumber)
          );

          //console.log("response.data: ", response.data);
          // Access the first item in the response data
          const firstItem = filteredEntities[0];

          // Extract the author object and the rank
          const entity = firstItem[0];  // First author object
          const rank = 0;//firstItem[1];    // The rank of the first author
        
         // console.log("entity: ", entity);
          //console.log("rank: ", rank);
          const newArray = [];

          const dealCount = entity.pinboardDealIds ? entity.pinboardDealIds.length : '-';

           const newSingleEntityArray = [rank, entity.randomIdNumber, dealCount, entity.pinboardCreatorName,
            entity.pinboardCreatorType, entity.pinboardTitle, entity.pinboardText, entity.pinboardPrice, entity.pinboardCreatedAt,  entity.pinboardActive];
        
          newArray[0] = [newSingleEntityArray]

          //console.log(" newArray[0]: ",  newArray[0]);
          //setShowSearchRatingRow(true);

          setTitleOrIDArray(newArray[0]);
          setTitleOrIDData(entity);
          setTimeout(() => {                  
            rerender();
          }, 100);


         // console.log('myPinboardPosts: ', myPinboardPosts);
         // console.log('showSearchRatingRow: ', showSearchRatingRow);
         // console.log('searchActive: ', searchActive);
        //  console.log('showCreateNewPinboardPost: ', showCreateNewPinboardPost);
        //  console.log('singleEntityArray: ', singleEntityArray);
        } catch (error) {
          handleError('Error fetching single pinboard:', error);
        }
      };


      const handlePinboardCreatorNameChange = (e) => {
        const inputValue = exeedsMaxCharacter(e.target.value);
        let cleanValue = inputValue.replace(/[^\w\s_]/g, '');
        cleanValue = cleanValue.replace(/^_+|_+$/g, '');
    
        setPinboardCreatorName(cleanValue);
    
      };
      

      const defineSingleCreatorPinboardName = async (pageVar) => {

        setShowLoadingData(true);

        localStorage.setItem('pageNum3', pageVar);

        if(searchForPinboardCreator){
          localStorage.setItem('pageNum2', pageVar);
          setSearchForAllProfileEntriesDone(true);
        }

          setMyPinboardPosts(true);
          setShowSearchRatingRow(true);
          setSearchActive(true);
          
        
          setTimeout(() => {                  
            getSingleCreatorPinboardDocuments(pageVar, getPinboardCreatorName);
          }, 100);
      
       // console.log('myPinboardPosts: ', myPinboardPosts);
       // console.log('showSearchRatingRow: ', showSearchRatingRow);
       // console.log('searchActive: ', searchActive);
       // console.log('showCreateNewPinboardPost: ', showCreateNewPinboardPost);

      };
   
     

      const getSingleCreatorPinboardDocuments = async (pageNum, pinboardCreatorNameVar) => {
        try {
            const response = await api.get(`/api/pinboard/search/creator/${pinboardCreatorNameVar}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });
    
           // console.log("response.data: ", response.data);
    
        // Filter data to include only entities where the first item in each sub-array has pinboardActive === true
        const filteredEntityData = response.data.filter(item => item[0].pinboardActive === true);
        const filteredEntities = filteredEntityData.filter(entity => 
          !getDeletedButNotRefreshedPinboards.includes(entity.randomIdNumber)
        );

           // console.log("filteredEntityData: ", filteredEntityData);
            // Set total pages based on filtered data length
            const totalPageNum = Math.ceil(filteredEntities.length / entitiesPerPage);
            setTotalPageNumber2(totalPageNum===0?1:totalPageNum);
    
            if (filteredEntityData.length > 0) { 
                // Calculate the range of entities for the current page
                const startIndex = (pageNum - 1) * entitiesPerPage;
                const endIndex = startIndex + entitiesPerPage;
    
                // Slice the filtered data for the current page
                const currentPageData = filteredEntityData.slice(startIndex, endIndex);
    
               // console.log("currentPageData: ", currentPageData);
    
            // Populate newArray with only the current page data
            const newArray = currentPageData.map((item, index) => {
              const entity = item[0]; // Access the first element in the sub-array
              
              setCreatorNameAsTitle(entity.pinboardCreatorName);
              const dealCount = entity.pinboardDealIds ? entity.pinboardDealIds.length : '-';
                
                    return [
                        startIndex + index, 
                        entity.randomIdNumber, 
                        dealCount, 
                        entity.pinboardCreatorName, 
                        entity.pinboardCreatorType, 
                        entity.pinboardTitle, 
                        entity.pinboardText, 
                        entity.pinboardPrice, 
                        entity.pinboardCreatedAt, 
                        entity.pinboardActive
                    ];
                });
    
               // console.log("newArray: ", newArray);
    
                setSingleEntityArray(newArray);
                setSinglePinboardData(newArray);
                setShowLoadingData(false);
            }else{
              const newEmptyArray = [];
              setSingleEntityArray(newEmptyArray);
              setSinglePinboardData(newEmptyArray);
              setShowLoadingData(false);
            }
        } catch (error) {
            handleError('Error fetching single pinboard:', error);
        }
    };
    

      const getMyPinboardDocuments = async () => {

        setShowLoadingData(true);
        
        const pageNum = parseInt(localStorage.getItem('pageNum2')) || 1;
        const pinboardCreatorNameVar = localStorage.getItem('profileName');

        //console.log("localStorage.getItem('myPinboardPosts'): ", localStorage.getItem('myPinboardPosts'));
       // console.log("pinboardCreatorNameVar: ", pinboardCreatorNameVar);

      
        try {

          const response = await api.get(`/api/pinboard/search/creator/${pinboardCreatorNameVar}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });

          //console.log("response.data: ", response.data);

          const filteredEntities = response.data.filter(entity => 
            !getDeletedButNotRefreshedPinboards.includes(entity.randomIdNumber)
          );

          const EntityData =  filteredEntities;

          const totalPageNum = Math.ceil(EntityData.length / entitiesPerPage);
          setTotalPageNumber2(totalPageNum===0?1:totalPageNum);

        
          //console.log("entityData: ", EntityData);
         // console.log("localStorage.getItem('myPinboardPosts'): ", localStorage.getItem('myPinboardPosts'));

          if (EntityData != null && EntityData.length > 0) { 
            // Calculate the range of entities for the current page
            const startIndex = (pageNum - 1) * entitiesPerPage;
            const endIndex = startIndex + entitiesPerPage;
        
            // Slice EntityData to get only the entities for the current page
            const currentPageData = EntityData.slice(startIndex, endIndex);
        
            // Populate newArray with only the current page data
            const newArray = currentPageData.map((entityZero, index) => {
                const entity = entityZero[0]; // First element is the object
                setCreatorNameAsTitle(entity.pinboardCreatorName)
                const dealCount = entity.pinboardDealIds ? entity.pinboardDealIds.length : '-';
        
                return [
                    startIndex + index, 
                    entity.randomIdNumber, 
                    dealCount, 
                    entity.pinboardCreatorName, 
                    entity.pinboardCreatorType, 
                    entity.pinboardTitle, 
                    entity.pinboardText, 
                    entity.pinboardPrice, 
                    entity.pinboardCreatedAt, 
                    entity.pinboardActive
                ];
            });
        
            setMyPinboardArray(newArray);
        
              //console.log("singleEntityArray:", newArray);
          setMyPinboardData(newArray);
          setShowLoadingData(false);
          }else{
            localStorage.setItem('pageNum2',1);
            const newEmptyArray = [];
            setMyPinboardArray(newEmptyArray);
            setMyPinboardData(newEmptyArray);
            setShowLoadingData(false);
          }
        } catch (error) {
          handleError('Error fetching single pinboard:', error);
        }
      };
      
      

      const deleteSearchSinglePinboard = () => {
        setSearchInputValue('');
        setPinboardCreatorName('');
        setSearchActive(false);

        if(searchForPinboardCreator && searchForAllProfileEntriesDone){
          setSearchForPinboardCreator(false);
          setMyPinboardPosts(false);
        }

        if(searchForPinboardCreator){
          setSearchForPinboardCreator(false);
        }
        
        if(!myPinboardPosts){
            setShowSearchRatingRow(false);
        }
      
        const newShowSearchRatingRow = false;
        setShowSearchRatingRow(newShowSearchRatingRow);
        localStorage.setItem('showSearchRatingRow', 'false');
      };
      
      const handleSearchInputChange = (e) => {
        setSearchInputValue(e.target.value);
      };
      
      const fetchData = async (pageVar) => {

        try {

          if(pageVar !== ''){
          setShowLoadingData(true);
          setPageNum('');
          setExpandedRowIndex(null);

          const Pinboard = await getPinboard(pageVar);
          setPinboardData(Pinboard);
          //console.log('Authors: ',Authors )
          setTimeout(() => {                  
            sortRatingsAndGiveOrder(Pinboard, pageVar);
            setShowLoadingData(false);

            //console.log('sortRatingsAndGiveOrder: ', Authors )
            //console.log('usingSortableData: ',getSortedScoreArray )
          }, 100);
        }
        //console.log("fetch 1: ", 1);

        } catch (error) {
          handleError('Error fetching authors:', error);
        }
      };

      const fetchData2 = async (pageVar) => {

        try {

          if(pageVar !== ''){
          setShowLoadingData(true);
          setPageNum2('');
          setExpandedRowIndex(null);

          if(myPinboardPosts && !searchActive){
            localStorage.setItem('pageNum2', pageVar);
            getMyPinboardDocuments()
          }else{
            defineSingleCreatorPinboardName(pageVar)
          }
        }
        //console.log("fetch 2: ", 2);
        } catch (error) {
          handleError('Error fetching authors:', error);
        }
      };
      
      
      const updateSearchHtml = () => {
        try {
          const storedShowSearchRatingRow = localStorage.getItem('showSearchRatingRow');
          setShowSearchRatingRow(storedShowSearchRatingRow === 'true');
        } catch (error) {
          handleError('Error updating search HTML:', error);
        }
      };

const usingSortableData = (items) => {

  
  let sortableItems = [...items];

  if (sortConfig !== null) {
    // Flip the order when sortConfig.key is '1'
    if (sortConfig.key === '8') {
      // Simply reverse the current order based on the direction
      if (sortConfig.direction === 'descending') {
        sortableItems.reverse();
      }
      return setSortedData(sortableItems);  // Return early after flipping
    }
  }


    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {

        if (sortConfig.key === '2' || sortConfig.key === '7') {
          const A = parseFloat(a[sortConfig.key], 10);
          const B = parseFloat(b[sortConfig.key], 10);
          
          if (!A) return sortConfig.direction === 'descending' ? 1 : -1;
          if (!B) return sortConfig.direction === 'descending' ? -1 : 1;
        
          if (A < B) return sortConfig.direction === 'descending' ? 1 : -1;
          if (A > B) return sortConfig.direction === 'descending' ? -1 : 1;
        
          return 0;
        }
        
          // sort alphabetically
        if (sortConfig.key === '3') {
          if (!a[sortConfig.key]) {return sortConfig.direction === 'descending' ? -1 : 1;
          }
          if (!b[sortConfig.key]) {return sortConfig.direction === 'descending' ? 1 : -1;
          } 
          return sortConfig.direction === 'descending' ? 
          b[sortConfig.key].localeCompare(a[sortConfig.key])  : a[sortConfig.key].localeCompare(b[sortConfig.key]);
        }
        

      return 0
      });
    }
   
	setSortedData(sortableItems);
	
  
};

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    partRerender();
  };




  const sortRatingsAndGiveOrder = ((EntityData, pageVar) => {
    const newArray = [];
try{
    if(EntityData != null && EntityData !== "" && EntityData !== undefined){


          EntityData.forEach((entity, index) => {
               // if(entity.pinboardActive){
                  newArray[index] = [((pageVar-1)*entitiesPerPage)+index, entity.randomIdNumber, entity.pinboardDealIds.length, entity.pinboardCreatorName,
                    entity.pinboardCreatorType, entity.pinboardTitle, entity.pinboardText, entity.pinboardPrice, entity.pinboardCreatedAt, entity.pinboardActive];
             //   }
            });

        //  console.log('newArray1Sorted:', newArray );     
        
          newArray.forEach((row, index) => {
            const rank = ((pageVar - 1) * entitiesPerPage) + (index + 1);  // Ascending rank calculation
            row[0] = rank;  // Directly assign to the first column (rank)
          });
          

          usingSortableData(newArray);
          setSortedScoreArray(newArray);
          //console.log("newArray: ", newArray); 
          //console.log('newArraySorted:', newArray );
        }
      } catch (error) {
        handleError('Error sorting Entities:', error);
      }
  });


    const changeOtherProfileName = (otherProfileName, otherProfileType) => {
      localStorage.setItem('siteProfile', otherProfileName);
      localStorage.setItem('otherProfileType', otherProfileType);
      setRankHrefClick(true);
  };


  const toggleCollapse = (column) => {   
        setActiveSortColumn(column);    
    };

    const rerender = () => {
      setRender(!render);
    }

    const partRerender = () => {
      setPartRender(!partRender);
    }

    const changeToChat = (entityName, e) => {
      e.stopPropagation();
      localStorage.setItem('chatEntityName', entityName);
    }

    const handlePageNumChange = (e) => {
      const value = e.target.value;
      const regex = /^\d*$/; 
    
      // Check that value is a number, not zero, and within the valid range
      if (regex.test(value) && (value === '' || (parseInt(value) > 0 && parseInt(value) <= getTotalPageNumber))) {
        const inputValue = value;
        setPageNum(inputValue);
      }
    };

    const handlePageNum2Change = (e) => {
      const value = e.target.value;
      const regex = /^\d*$/; 
    
      // Check that value is a number, not zero, and within the valid range
      if (regex.test(value) && (value === '' || (parseInt(value) > 0 && parseInt(value) <= getTotalPageNumber2))) {
        const inputValue = value;
        setPageNum2(inputValue);
      }
    };

    const changeActiveOrInactiv = async(ID) => {

      const creatorName = localStorage.getItem('profileName');
      try {

        const addNewActiveData = {
          pinboardName: creatorName,
          ID:ID,
          categoryFilter: showDemand?'Biete'+selectedDemandOption:'Suche'+selectedSearchOption
        };

        const response = await api.post(`/api/pinboard/update/active`, addNewActiveData ,{
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        });


        if(myPinboardPosts){
          if(searchActive){
            const pageNumber = parseInt(localStorage.getItem('pageNum3')) || 1;
            fetchData2(pageNumber);
          }else{
            const pageNumber = parseInt(localStorage.getItem('pageNum2')) || 1;
            fetchData2(pageNumber);
          }
        }else{
          const pageNumber = parseInt(localStorage.getItem('pageNum')) || 1;
          fetchFirstTimeData(pageNumber, categoryFilter);
        }
    
      } catch (error) {
        handleError('Error fetching single pinboard:', error);
      }
    };



    // Toggle the expand state for a specific row
    const toggleExpand = (index) => {
      setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };


    const inquiryClick = (postID, postCreator) => {
    
        setShowReallySendInquiryEmail(true);
        const inquiryData = [postID, postCreator];
        setInquiryData(inquiryData);
    };

    const inquiryClickSend = async() => {  
      setShowReallySendInquiryEmail(false);

      try {

        const addNewInquiryData = {
          pinboardName: getInquiryData[1],
          ID:getInquiryData[0],
          inquiryName: localStorage.getItem('profileName')
        };
    
        //console.log('addNewDeleteData: ',addNewDeleteData)
        const response = await api.post(`/api/pinboard/sendInquiry`, addNewInquiryData ,{
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        });
  
    
      } catch (error) {
        handleError('Error fetching single pinboard:', error);
      }
    };


  const checkProfileNamesOfUser = async () => {
    try {
      const response = await api.get(`/api/v1/auth/user/${localStorage.getItem('userEmail')}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });
  
      const user = response.data;
  
      // Check if the user and userProfilesIds exist
      if (user && Array.isArray(user.userProfilesIds)) {
        // Extract all userProfileNames into a list
        const userProfileNames = user.userProfilesIds
          .map(profile => profile.userProfileName)
          .filter(name => name); // Filters out any null or undefined names if present
  
        //console.log(userProfileNames); // Log the list of userProfileNames

        setUserProfileNames(userProfileNames);
        return userProfileNames;
      } else {
       // console.log("No user profiles found.");
        return [];
      }
    } catch (error) {
      //console.error("Error fetching user profile names:", error);
      return [];
    }
  };
  
  const deletePinboardClick = (postID, postCreator) => {
    
    setShowReallyDelete(true);
    const inquiryData = [postID, postCreator];
    setDeleteData(inquiryData);
};



const deletePinboardEntry = async () => {  
  setShowReallyDelete(false);
  try {

    const addNewDeleteData = {
      pinboardName: getDeleteData[1],
      ID:getDeleteData[0],
    };

    //console.log('addNewDeleteData: ',addNewDeleteData)
    const response = await api.delete(`/api/pinboard/delete/${addNewDeleteData.pinboardName}&${addNewDeleteData.ID}` ,{
      headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
    });

    if (response.data) {
      setDeletedButNotRefreshedPinboards((prevPinboards) => [
        ...prevPinboards,
        getDeleteData[0],
      ]);

          // Clear any existing timer and set a new one
    if (timer) {
      clearTimeout(timer);  // Clear previous timer
    }

    // Set a new timer to clear the array after 5 minutes
    const newTimer = setTimeout(() => {
      setDeletedButNotRefreshedPinboards([]);  // Clear the array after 5 minutes
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    setTimer(newTimer); // Store the timer ID
    }


    if(myPinboardPosts){
      if(searchActive){
        const pageNumber = parseInt(localStorage.getItem('pageNum3')) || 1;
        fetchData2(pageNumber);
      }else{
        const pageNumber = parseInt(localStorage.getItem('pageNum2')) || 1;
        fetchData2(pageNumber);
      }
    }else{
      const pageNumber = parseInt(localStorage.getItem('pageNum')) || 1;
      fetchFirstTimeData(pageNumber, categoryFilter);
    }

  

  } catch (error) {
    handleError('Error fetching single pinboard:', error);
  }
};

const [colSpan, setColSpan] = useState(6);
const [colSpan2, setColSpan2] = useState(2);

useEffect(() => {
  const updateColSpan = () => {
    // Match Tailwind's `sm` breakpoint of 640px
    setColSpan(window.matchMedia('(max-width: 640px)').matches ? 5 : 6);
    setColSpan2(window.matchMedia('(max-width: 640px)').matches ? 1 : 2);

  };

  // Initial check on component mount
  updateColSpan();

  // Listen for screen resize
  window.addEventListener('resize', updateColSpan);
  return () => window.removeEventListener('resize', updateColSpan);
}, []);

    


    useEffect(() => {

     if(showSearch){
      setCategoryFilter('Suche'+selectedSearchOption);
      const selection = 'Suche'+selectedSearchOption
      fetchFirstTimeData(1, selection )
     }else{
      setCategoryFilter('Biete'+selectedDemandOption);
      const selection = 'Biete'+selectedDemandOption
      fetchFirstTimeData(1, selection )
     }
   
     //console.log('categoryFilter1: ', categoryFilter);

     //console.log('showDemand: ', showDemand);
     //console.log('selectedDemandOption: ', selectedDemandOption);
     //console.log('selectedSearchOption: ', selectedSearchOption);
    }, [showDemand, showSearch, selectedDemandOption, selectedSearchOption  ]);
    

    ////////////////////////// First Login Explanation//////////////////////

    const updateElements = (currentIndex, currentState, nextIndex, nextState) => {
      const updatedArray = [...firstLoginTrue2];
      // Set the current index to the desired state
      updatedArray[currentIndex] = currentState;
      // Set the next index to the desired state
      updatedArray[nextIndex] = nextState;
      //console.log('nextIndex: ', nextIndex);

      if(nextIndex === 5 ){
        localStorage.setItem('explainDone0', 'true'); 
        //console.log(localStorage.getItem('explainDone'));
      }

      // Update state with the modified array
      setFirstLoginTrue2(updatedArray);
    };
    
   
   ////////////////////////// First Login Explanation//////////////////////


      useEffect(() => {
        const key = "1";
        const direction = 'ascending';
        setSortConfig({ key, direction });

        localStorage.setItem('pageNum', 1);
        const selection = 'BieteLektorat'
        fetchFirstTimeData(1, selection);

        setRankHrefClick(false);
	      localStorage.setItem('chatEntityName', '');

      }, []);
      
      useEffect(() => {
        getPinboardCountEachCategory()

        deleteSearchSinglePinboard();
        updateSearchHtml();
        localStorage.setItem('chatEntityName', '');
        setSearchForPinboardCreator(false);
        setSearchForTitle(true);
        setShowCreateNewPinboardPost(false);
        checkProfileNamesOfUser();
      }, []);

      useEffect(() => {
        getPinboardCountEachCategory()
        if(myPinboardPosts){
            const pageNum = !searchActive?parseInt(localStorage.getItem('pageNum2')) || 1:parseInt(localStorage.getItem('pageNum3')) || 1;
            fetchData2(pageNum);
        }else{
          const pageNum = parseInt(localStorage.getItem('pageNum')) || 1;
          fetchFirstTimeData(pageNum, categoryFilter);
        }
         
      }, [render, reloadPinboardCount]);

      useEffect(() => { 
        //fetchRatings(getAuthorData);         
         // sortRatingsAndGiveOrder(getAuthorData);
          usingSortableData(getSortedScoreArray);
      }, [partRender]);

      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

   ///////////////////////////////// Logout redirect //////////////////////////7

       useEffect(() => {
        LogoutRedirectCheck(navigate);
      }, [navigate, searchInputValue, showSearchRatingRow, showCreateNewPinboardPost, categoryFilter,
        getSinglePinboardData, getPinboardCreatorName
        ]);
      
  ///////////////////////////////// Logout redirect //////////////////////////7 



  return (
    <div className="PinboardView z-40">
<div className='min-sm:container max-sm:mr-2 '>
      {/*{error && <div style={{ color: 'red' }}>{error}</div>} */}

{ firstLoginTrue2[5] === true && localStorage.getItem('explainDone0') === 'false' && (
      <div className='z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
  <div 
    className={`loadingBox white-glassmorphism-loadingBox ${firstLoginTrue2[4] === true?'bg-white-100 max-sm:top-1/3 top-1/2 max-sm:bg-slate-200':'bg-slate-200'} 
    border-sky-600 border-4 text-center fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30
     ${firstLoginTrue2[0] === true?'bg-white-100 max-sm:top-1/3 top-2/3 max-sm:bg-slate-200':'bg-slate-200'}
    ${(firstLoginTrue2[1] === true || firstLoginTrue2[2] === true || firstLoginTrue2[3] === true) ?'top-1/3 bg-slate-200':'bg-slate-200'}`} 
    style={{ minWidth: '250px', maxWidth: '550px', minHeight:'100px' }}
  >
    <div className="flex justify-content-center align-items-center w-full h-full">
      <div className='m-5'>
        { firstLoginTrue2[0] === true && (
          <>   <h6 className='text-2xl'> Im Dashboard - Pinnwand kannst du mühelos zwischen den verschiedenen Kategorien wechseln und auswählen, welche Kategorien dir angezeigt werden sollen.</h6>
                        <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded"
                        type="button"  onClick={() => updateElements(0, false, 1, true)}>Weiter</button> </>
        )}
        { firstLoginTrue2[1] === true && (
          <>   <h6 className='text-2xl'>Hier hast du die Möglichkeit, gezielt nach Pinnwandeinträgen zu suchen. Du kannst einzelne Einträge per Titel oder ID suchen oder aber alle Einträge eines Profils. Gib einfach den vollständigen Titel, ID, Profilname oder einen Teil davon ein und klicke auf "Bestätigen". Wenn passende Ergebnisse gefunden wurden, erscheinen diese direkt an oberster Stelle im Dashboard.</h6>
                        <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded"
                        type="button"  onClick={() => updateElements(1, false, 2, true)}>Weiter</button> </>
        )}
        { firstLoginTrue2[2] === true && (
          <>   <h6 className='text-2xl'> Hier kannst du bequem zwischen den Seiten des Dashboards wechseln und direkt eine bestimmte Seite aufrufen, um die gewünschten Einträge anzuzeigen.</h6>
                        <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded"
                        type="button"  onClick={() => updateElements(2, false, 3, true)}>Weiter</button> </>
        )}
        { firstLoginTrue2[3] === true && (
          <>   <h6 className='text-2xl'>Außerdem kannst du über "Meine Pinnwandeinträge" alle eigenen Einträge anzeigen lassen oder mit "Alle Pinnwandeinträge" wieder zurückkehren.
          Ebenfalls kannst du neue Einträge leicht mit "+ Pinnwandeintrag" hinzufügen.</h6>
                        <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded"
                        type="button"  onClick={() => updateElements(3, false, 4, true)}>Weiter</button> </>
        )}
        { firstLoginTrue2[4] === true && (
          <>   <h6 className='text-2xl'>
                    Im Dashboard kannst du nach verschiedenen Eintragmerkmalen filtern. Besonders wichtig ist die Filterung nach Preis, Erstelldatum und bereits erhaltene Anfragen sogennante "Deals".</h6>
                        <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded"
                        type="button"  onClick={() => {updateElements(4, false, 5, false)}}>Fertig</button> </>
        )}

      </div>               
    </div>
  </div>
</div>
)}

{ showCreatedPinboardInfo && (

  <div className="z-20 fixed inset-0 h-screen w-screen flex justify-center overflow-hidden">
  <div 
    className="loadingBox white-glassmorphism-loadingBox border-gray-600 border-2 bg-slate-200 text-center p-6 rounded-lg shadow-lg fixed top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-2/3 z-30"
    style={{
      minWidth: '250px',
      maxWidth: '60vw',
      minHeight: '50px',
      maxHeight: '80vh',
    }}
  >
    <div className="flex flex-col items-center justify-center w-full h-full">
      <h4 className="text-2xl sm:text-3xl mb-4">
        Dein Pinnwand-Eintrag wurde erfolgreich erstellt!
      </h4>
      <h6 className="text-lg sm:text-2xl mb-6 text-gray-700">
        Es kann bis zu 5 Minuten dauern, bis wir deinen Eintrag geprüft, verarbeitet und aktiv gesetzt haben.
        Möglicherweise musst du das Dashboard aktualisieren.
      </h6>
      <button
        className="py-2 px-4 bg-slate-600 text-white rounded-md text-base sm:text-lg"
        type="button"
        onClick={() => {
          setShowCreatedPinboardInfo(false);
          setShowCreateNewPinboardPost(false);
        }}
      >
        OK
      </button>
    </div>
  </div>
</div>


)}


{ showCreateNewPinboardPost === true && (
<>
<div className={`container-sm ViewCol my-2 dashboardSearchDataCard white-glassmorphism-card `}>
  <div className="flex w-full justify-center items-center   text-center  gap-2">
    <button
    className="bg-slate-600 text-white rounded p-2 m-2"
      type="button"
      onClick={() => {
        setShowCreateNewPinboardPost(false);
      }}
    >
      zurück zur Pinnwand
    </button>
   </div>
  </div>
  
    <div className="overflow-hidden ">
      <form onSubmit={handlePinboardSubmit} className=''>
        <table className="table table-bordered table-responsive table-hover w-full dashboardDataCard white-glassmorphism-card">
          <tbody>

            {/* Pinboard Creator Name */}
            <tr className="align-middle text-center h-10">
              <td className="font-bold w-1/4">Ersteller-Name</td>
              <td className="text-left pl-4">{localStorage.getItem('profileName')}</td>
            </tr>

            {/* Pinboard Title */}
            <tr className="align-middle text-center h-10">
              <td className="font-bold w-1/4">Titel</td>
              <td className="text-left">
                <input
                  type="text"
                  value={pinboardTitle}
                  onChange={handleTitleChange}
                  placeholder="Gib einen treffenden Titel an."
                  className="w-full px-2 py-1 bg-gray-100 hover:bg-white"
                />
              </td>
            </tr>

            {/* Dropdown for Pinboard Category */}
            <tr className="align-middle text-center h-10">
              <td className="font-bold w-1/4">Kategorie</td>
              <td className="text-left">
                <select
                  value={pinboardCategory}
                  onChange={(e) => setPinboardCategory(e.target.value)}
                  className="w-full px-2 py-1"
                >
                  <option value="" disabled>Wähle eine Kategorie</option>
                  <option value="Lektorat">Lektorat</option>
                  <option value="Korrektorat">Korrektorat</option>
                  <option value="Testlesung">Testlesung</option>
                  <option value="Coverdesign">Coverdesign</option>
                  <option value="Anderes">Anderes</option>
                </select>
              </td>
            </tr>

            {/* Dropdown for Offer/Demand */}
            <tr className="align-middle text-center h-10">
              <td className="font-bold w-1/4">Suche/Biete</td>
              <td className="text-left">
                <select
                  value={pinboardOfferDemand}
                  onChange={(e) => setPinboardOfferDemand(e.target.value)}
                  className="w-full px-2 py-1"
                >
                  <option value="" disabled>Wähle Suche oder Biete</option>
                  <option value="Suche">Suche</option>
                  <option value="Biete">Biete</option>
                </select>
              </td>
            </tr>

            {/* Pinboard Price */}
            <tr className="align-middle text-center h-10">
              <td className="font-bold w-1/4">Preis in €</td>
              <td className="text-left">
                <input
                  type="number"
                  value={pinboardPrice}
                  onChange={handlePriceChange}
                  placeholder="Kosten deiner Leistung oder Preisvorstellung"
                  className="w-full px-2 py-1 bg-gray-100 hover:bg-white"
                />
              </td>
            </tr>

            {/* Pinboard Text */}
            <tr className="align-middle text-center h-10">
              <td className="font-bold w-1/4">Text</td>
              <td className="text-left overflow-hidden">
                <ReactQuill
                  theme="snow"
                  formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']}
                  placeholder="Beschreibe die Leistung."
                  modules={modules}
                  onChange={handleTextChange}
                  value={pinboardText}
                  className="bg-gray-100 hover:bg-white px-2 py-1 w-full"
                  style={{ maxWidth: '100%' }} // Restrict editor width to the column size
                />
                <div
                  className="pt-6 pb-3"
                  style={{
                    position: 'relative',
                    top: '0px',
                    right: '-10px',
                    color: 'gray',
                  }}
                >
                  {characterCountText} / 2000
                </div>
              </td>
            </tr>

          </tbody>
        </table>

       { !showCreatedPinboardInfo && (
          <div className="flex w-full -mt-6 max-sm:-mt-4 justify-center items-center text-center  dashboardPageChangeBottomCard white-glassmorphism-card">
                <button type="submit" className="bg-slate-600 my-2 text-white rounded p-2">
                  Eintrag erstellen
                </button>
         </div> )}
      
      </form>
</div>

</>
)}



{ !showCreateNewPinboardPost && (
<>

<div className={`container-sm ViewCol dashboardSearchDataCard white-glassmorphism-card ${firstLoginTrue2[1] ? 'bg-slate-200 border-sky-600 border-4' : ''}`}>
  <div className="flex w-full gap-2">
    <button
      className={`w-1/2 sm:w-1/2 max-sm:w-full px-2 py-2 ml-2 mt-2  white-glassmorphism-card bg-slate-200 text-black  rounded ${searchForTitle ? 'bg-slate-400 border-slate-600 border-2 text-white' : ''}`}
      type="button"
      onClick={() => {
        setSearchForTitle(true);
        deleteSearchSinglePinboard();
      }}
    >
      Suche nach einem Eintrag
    </button>
    <button
      className={`w-1/2 sm:w-1/2 max-sm:w-full px-2 mr-2 py-2 mt-2 white-glassmorphism-card bg-slate-200 text-black rounded ${searchForPinboardCreator ? 'bg-slate-400 border-slate-600 border-2 text-white' : ''}`}
      type="button"
      onClick={() => {
        setSearchForPinboardCreator(true);
        setSearchForTitle(false);
        deleteSearchSinglePinboard();
      }}
    >
      Suche alle Einträge von einem Profil
    </button>
  </div>
  
  <div className="flex items-center mb-2 m-2">
    <h5 className="mr-4 text-xl font-bold">SUCHE</h5>
    {searchForTitle && (
      <form onSubmit={getSinglePinboardDocument} className="flex flex-col sm:flex-row w-full gap-2">
        <input
          className="InputFieldOne flex-grow p-2 border border-slate-300 rounded"
          placeholder="Titel oder ID ..."
          name="name"
          type="text"
          value={searchInputValue}
          onChange={handleSearchInputChange}
        />
        <div className="flex flex-col sm:flex-row gap-2">
          <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded" type="submit">
            Bestätigen
          </button>
          <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded" type="button" onClick={deleteSearchSinglePinboard}>
            Entfernen
          </button>
        </div>
      </form>        

    )}

    {searchForPinboardCreator && (
      <form onSubmit={(e)=> { e.preventDefault(); defineSingleCreatorPinboardName(1);  }} 
       className="flex flex-col sm:flex-row w-full gap-2">
        <input
          className="InputFieldOne flex-grow p-2 border border-slate-300 rounded"
          placeholder="Profilname..."
          name="name"
          type="text"
          value={getPinboardCreatorName}
          onChange={handlePinboardCreatorNameChange}
        />
        <div className="flex flex-col sm:flex-row gap-2">
          <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded" type="submit">
            Bestätigen
          </button>
          <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded" type="button" onClick={deleteSearchSinglePinboard}>
            Entfernen
          </button>
        </div>
      </form>
    )}
  </div>
</div>





<div className={`container-sm ViewCol max-sm:my-6 `}>

     <div className={`col-sm-12 my-2 dashboardPageChangeTopCard white-glassmorphism-card ${firstLoginTrue2[2] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}>
        <div className='col-sm-6'>
        {!myPinboardPosts && (
          <div className="flex max-sm:-mx-10 items-center text-center justify-center">
          <button 
              className="text-slate-600 rounded my-6 mr-3 scale-150" 
              onClick={() => {
                const pageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                fetchFirstTimeData(pageNum, categoryFilter);
              }}
            >
              <IconWithTooltip icon={<IoReloadCircle className="mr-1 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
            </button>

            <p className='FormStyling max-sm:text-lg mr-6'>Seite {localStorage.getItem('pageNum') || 1} von {getTotalPageNumber}</p>

            {parseInt(localStorage.getItem('pageNum')) > 1 && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}

            <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum')) || 1) - 1) * entitiesPerPage} - {(parseInt(localStorage.getItem('pageNum')) || 1) * entitiesPerPage}</p>

            {parseInt(localStorage.getItem('pageNum')) < getTotalPageNumber && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum + 1;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowForward />
              </button>
            )}

            <p className="FormStyling max-sm:text-lg ml-6 max-sm:ml-4">Gehe zu Seite</p>
            <input 
              className="-py-1 my-3 ml-2 rounded-xl text-center FormStyling2" 
              style={{ width: '1cm' }} 
              name="pageNumber" 
              onChange={handlePageNumChange} 
              value={pageNum} 
            />

            <button 
              className="text-slate-600 rounded my-6 ml-3 scale-150 p-0 m-0" 
              onClick={() => {
                const newPageNumber = pageNum !== '' ? pageNum : (parseInt(localStorage.getItem('pageNum')) || 1);
                localStorage.setItem('pageNum', parseInt(newPageNumber));
                fetchData(newPageNumber);
              }}
            >
              <IconWithTooltip icon={<IoCheckmarkCircle className="ml-1 scale-150" />} tooltipText="Gehe zur gewählten Seite." />
            </button>

            
            </div>
            )}

{myPinboardPosts && (
          <div className="flex  max-sm:-mx-10 items-center text-center justify-center">
          <button 
              className="text-slate-600 rounded my-6 mr-3 scale-150" 
              onClick={() => {
                const pageNum = !searchActive?parseInt(localStorage.getItem('pageNum2')) || 1:parseInt(localStorage.getItem('pageNum3')) || 1;
                fetchData2(pageNum);
              }}
            >
              <IconWithTooltip icon={<IoReloadCircle className="mr-1 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
            </button>

            <p className='FormStyling max-sm:text-lg mr-6'>Seite {localStorage.getItem('pageNum2') || 1} von {getTotalPageNumber2}</p>

            {parseInt(localStorage.getItem('pageNum2')) > 1 && !searchActive &&(
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum2')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum2', newPageNumber);
                  fetchData2(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}
           {parseInt(localStorage.getItem('pageNum3')) > 1 && searchActive && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum3')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum3', newPageNumber);
                  fetchData2(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}
{!searchActive &&( <>
            <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum2')) || 1) - 1) * entitiesPerPage} - {(parseInt(localStorage.getItem('pageNum2')) || 1) * entitiesPerPage}</p>

            {parseInt(localStorage.getItem('pageNum2')) < getTotalPageNumber2 && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum2')) || 1;
                  const newPageNumber = currentPageNum + 1;
                  localStorage.setItem('pageNum2', newPageNumber);
                  fetchData2(newPageNumber);
                }}
                
                
              >
                <IoIosArrowForward />
              </button>
            )} </>
          )}

        {searchActive &&( <>
              <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum3')) || 1) - 1) * entitiesPerPage} - {(parseInt(localStorage.getItem('pageNum3')) || 1) * entitiesPerPage}</p>

              {parseInt(localStorage.getItem('pageNum3')) < getTotalPageNumber2 && (
                <button
                  className="mt-0.5 scale-150"
                  onClick={() => {
                    const currentPageNum = parseInt(localStorage.getItem('pageNum3')) || 1;
                    const newPageNumber = currentPageNum + 1;
                    localStorage.setItem('pageNum3', newPageNumber);
                    fetchData2(newPageNumber);
                  }}
                  
                  
                >
                  <IoIosArrowForward />
                </button>
              )}
          </> )}

            <p className="FormStyling max-sm:text-lg ml-6 max-sm:ml-4">Gehe zu Seite</p>
            <input 
              className="-py-1 my-3 ml-2 rounded-xl text-center FormStyling2" 
              style={{ width: '1cm' }} 
              name="pageNumber2" 
              onChange={handlePageNum2Change} 
              value={pageNum2} 
            />

            <button 
              className="text-slate-600 rounded my-6 ml-3 scale-150 p-0 m-0" 
              onClick={() => {
                const newPageNumber = pageNum2 !== '' ? pageNum2 : (!searchActive?parseInt(localStorage.getItem('pageNum2')) || 1:parseInt(localStorage.getItem('pageNum3')) || 1);
                !searchActive?localStorage.setItem('pageNum2', parseInt(newPageNumber)):localStorage.setItem('pageNum3', parseInt(newPageNumber));
                fetchData2(newPageNumber);
              }}
            >
              <IconWithTooltip icon={<IoCheckmarkCircle className="ml-1 scale-150" />} tooltipText="Gehe zur gewählten Seite." />
            </button>

            
            </div>
            )}
          </div>
        <div className='col-sm-6  max-sm:-mx-10 items-center justify-center mt-2'>
        { myPinboardPosts && (
            <button
                  className="sm:py-2 px-2 max-sm:mr-2 marginRightSM max-sm:text-lg bg-slate-600 text-white rounded"
                  type="button"
                  onClick={()=>{ fetchFirstTimeData(1, categoryFilter); 
                    setMyPinboardPosts(false); setShowSearchRatingRow(false)}}
                     >
                 Alle Pinnwandeinträge
                </button>
           )}
           { !myPinboardPosts  && (
            <button
                  className={`sm:py-2 px-2 max-sm:mr-2 marginRightSM max-sm:text-lg bg-slate-600 text-white rounded  ${firstLoginTrue2[3] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}
                  type="button"
                  onClick={()=>{getMyPinboardDocuments(); setSearchActive(false); setMyPinboardPosts(true);}}
                >
                 Meine Pinnwandeinträge
                </button>
           )}
              <button
                  className={`sm:py-2 px-8 max-sm:mb-2 max-sm:text-lg bg-slate-600 text-white rounded  ${firstLoginTrue2[3] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}
                  type="button"
                  onClick={()=> setShowCreateNewPinboardPost(true)}
                >
                  + Pinnwandeintrag
                </button>
            </div>
            </div>
        </div>
  
      <div className={`container-sm overflow-hidden dashboardDataCard white-glassmorphism-card ${firstLoginTrue2[4] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}>
      
        <div className={`overflow-auto -mb-8  max-sm:pb-1 `}  style={{maxHeight: 1000}}>
         { myPinboardPosts && !searchActive && creatorNameAsTitle === localStorage.getItem('profileName') &&( <h4>Meine Pinnwandeinträge</h4>)}
         { myPinboardPosts && showSearchRatingRow && searchActive && creatorNameAsTitle !== localStorage.getItem('profileName') && ( <h4>Pinnwandeinträge von {creatorNameAsTitle}</h4>)}
         { !myPinboardPosts &&  !showSearchRatingRow &&  ( <h4>Alle Pinnwandeinträge der Kategorie: {showDemand?'Biete':'Suche'} {showDemand?selectedDemandOption:selectedSearchOption}</h4>)}

{ showLoadingData === false && (
          <table className="table table-bordered table-responsive mb-0  "> 
               
            <thead>
              <tr>
             { !myPinboardPosts && (
              <>
                <th className='sticky top-0  white-glassmorphism-table max-sm:hidden'><h6 className="ml-0 mb-6 scale-75"> <IconWithTooltip icon={<BsChatDots className='scale-150'/>} tooltipText="Wechsel zum Chat und schreibe direkt" /></h6></th>
                <th  className="sticky top-0 white-glassmorphism-table text-center align-middle"><div className=' mb-4'>Nr.</div></th>
                <th  className="sticky top-0  white-glassmorphism-table text-left"><div className=' mb-4 ml-4'>ID</div></th>
                <th  onClick={() => { requestSort('2'); toggleCollapse('deals'); }} className="sticky top-0  white-glassmorphism-table text-center">
                        <div className='row text-center mt-4 -mb-1'><div className=' '>Deals</div></div>
                        <div className='row max-sm:pl-3 sm:pl-5'>{activeSortColumn === 'deals' ? (<CgChevronDown />) : (<CgChevronRight />)}</div>
                </th>
                <th  onClick={() => {requestSort('3'); toggleCollapse('name');}} className="sticky top-0  white-glassmorphism-table text-left"><div className=' -mb-1 ml-4 sm:hidden'>Name</div><div className='-mb-1 ml-4 max-sm:hidden'>Name</div>{activeSortColumn === 'name' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
                <th  className="sticky top-0  mb-4 white-glassmorphism-table text-center max-sm:hidden" ><div className=' mb-4 ml-4'>Titel</div></th>
                <th  onClick={() => {requestSort('7'); toggleCollapse('price');}} className="sticky top-0  white-glassmorphism-table text-center ">Preis{activeSortColumn === 'price' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
                <th  onClick={() => {requestSort('8'); toggleCollapse('createdAt');}} className="sticky top-0  white-glassmorphism-table text-center ">Erstellt{(activeSortColumn === 'createdAt' ) ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
             </>)}
             { myPinboardPosts && (
              <>
                <th className='sticky top-0  white-glassmorphism-table max-sm:hidden'></th>
                <th  className="sticky top-0 white-glassmorphism-table text-center align-middle"><div className=''>Nr.</div></th>
                <th  className="sticky top-0  white-glassmorphism-table text-left"><div className='ml-4'>ID</div></th>
                <th  className="sticky top-0  white-glassmorphism-table text-center">
                        <div className='row text-center mt-4'><div className=''>Deals</div></div>
                        <div className='row max-sm:pl-3 sm:pl-5'></div>
                </th>
                <th  className="sticky top-0  white-glassmorphism-table text-left"><div className=' ml-4'>Name</div></th>
                <th  className="sticky top-0  mb-4 white-glassmorphism-table text-center max-sm:hidden" ><div className=' ml-4'>Titel</div></th>
                <th  className="sticky top-0  white-glassmorphism-table text-center ">Preis</th>
                <th  className="sticky top-0  white-glassmorphism-table text-center ">Erstellt</th>
             </>)}
                </tr>
            </thead> 
            <tbody className="table-group-divider">



            {showReallyDelete && (
  <div className="z-50 fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center overflow-hidden">
    <div
      className="loadingBox white-glassmorphism-loadingBox bg-white-100 max-sm:bg-slate-400 bg-slate-400 border-4 text-center z-30"
      style={{
        minWidth: '250px',
        maxWidth: '550px',
        minHeight: '100px',
      }}
    >
      <div className="m-5">
        <h6 className="text-2xl">
          Möchtest du den Pinnwandeintrag mit ID: <span className="text-gray-300">{getDeleteData[0]}</span> wirklich löschen?
        </h6>

        <button
          className="sm:py-2 px-4 mr-4 max-sm:text-lg bg-slate-600 text-white rounded"
          type="button"
          onClick={() => setShowReallyDelete(false)}
        >
          zurück
        </button>

        <button
          className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded"
          type="button"
          onClick={() => deletePinboardEntry()}
        >
          Löschen
        </button>
      </div>
    </div>
  </div>
)}

{ showReallySendInquiryEmail && (
  <>

<div className='z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
  <div 
    className={`loadingBox white-glassmorphism-loadingBox bg-white-100 max-sm:top-1/3 max-sm:bg-slate-400 bg-slate-400 border-4  text-center fixed top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30`} 
    style={{ minWidth: '250px', maxWidth: '550px', minHeight:'100px' }}
  >
    <div className="flex justify-content-center align-items-center w-full h-full">
      <div className='m-5'>
          <>  <h6 className='text-2xl'>
                Möchtest du <span className="text-gray-300">{getInquiryData[1]}</span> eine Email senden, um dem Profil 
                mitzuteilen, dass du, <span className="text-gray-300">{localStorage.getItem('profileName')}</span>, Interesse hast?
              </h6>

          <button className="sm:py-2 px-4 mr-4 max-sm:text-lg bg-slate-600 text-white rounded"
              type="button"  onClick={() => setShowReallySendInquiryEmail(false)}>zurück
              </button>
                        
              <button className="sm:py-2 px-4 max-sm:text-lg bg-slate-600 text-white rounded"
                        type="button"  onClick={() => inquiryClickSend()}>Senden
              </button>
                         </>

      </div>               
    </div>
  </div>
</div>

</>
  )}




            
          { (showSearchRatingRow && searchActive && !searchForTitle) && !showLoadingData &&  (
            <>
              {singleEntityArray && singleEntityArray.length === 0 && (
                  <>
                 <tr> <td colSpan={12} className="col text-center ">
                          <h4 className='p-0 m-10'>
                            Derzeit sind keine Einträge vorhanden.
                          </h4>
                        </td>
                  </tr>
                  </>
            )}
            
           {singleEntityArray && singleEntityArray.length > 0 && singleEntityArray.slice().reverse().map((singleEntity, index) => (
                <>
            { singleEntity[9] && getDeletedButNotRefreshedPinboards && !getDeletedButNotRefreshedPinboards.includes(singleEntity[1]) && (
                  <>
            <tr key={singleEntity[1]}   
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                    className={`align-middle text-center justify-center items-center h-[8px] ${
                      hoveredRow === index ? 'bg-stone-100' : ''
                    }`}
                  >
                  {localStorage.getItem('profileName') !== singleEntity[3] && ( 
                      <td className='w-12 max-sm:hidden'>
                        <div className='-my-1'>
                          <a className='p-0 m-0' 
                            href="/chat" 
                              target="_blank" 
                              rel="noopener noreferrer"
                              onClick={(e) => changeToChat(singleEntity[3], e)}
                            >
                              <button className="bg-slate-600 text-white rounded scale-150 p-0 m-0">
                                <IconWithTooltip icon={<BsChatDots className="scale-75"/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                              </button>
                            </a>
                        </div>
                      </td>
                    )}

                    {localStorage.getItem('profileName') === singleEntity[3] && (
                                  <td className="w-12 max-sm:hidden border-none  items-center justify-center">
                                       <IconWithTooltip icon={
                              <div>
                                  <div
                                      className={`w-4 h-4  mt-1.5 ml-0.5 rounded-full border-none  ${
                                          singleEntity[9] ? 'bg-green-500' : 'bg-red-500'
                                      }`}
                                      style={{
                                        boxShadow: singleEntity[9]
                                            ? '0 0 6px rgba(0, 255, 0, 0.8)'
                                            : '0 0 6px rgba(255, 0, 0, 0.8)',
                                    }}
                                  ></div> </div>} tooltipText={`${singleEntity[9]?'aktiv':'inaktiv'}`} />
                              </td>
                    )}

                    <td className="w-8">
                     {myPinboardPosts &&( <h6 className="m-0 p-0 ">{(((!searchActive?localStorage.getItem('pageNum2'):localStorage.getItem('pageNum3'))-1)*entitiesPerPage)+index+1}</h6> )}
                     {!myPinboardPosts &&( <h6 className="m-0 p-0 ">0</h6> )}
                    </td>
                    <td className="col text-center w-20 text-lg">
                      <div
                        className="p-0 m-0 truncate w-16 -mr-2 cursor-pointer"
                        title="Klick zum kopieren"
                        onClick={() => {
                          const textToCopy = (singleEntity[1] || '-')
                        navigator.clipboard.writeText(textToCopy);
                      }}
                    >
                        {singleEntity[1] || '-'}
                      </div>
                    </td>
                    <td className="col text-center w-12"><h6 className='w-12 p-0 m-0'>{singleEntity[2] || '-'}</h6></td>
                    <td className="text-center truncate w-32">                     
                      <a
                        href={`${baseUrl2}/profile?othProfileName=${singleEntity[3]}&othProfileType=${singleEntity[4]}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => changeOtherProfileName(singleEntity[3], singleEntity[4])}
                      >
                        <h6 className='max-sm:truncate max-sm:w-20 w-60 p-0 m-0'>{singleEntity[3] || '-'}</h6>
                      </a>
                    </td>
                    <td className="col text-center max-sm:hidden "><h6 className='p-0 m-0'>{singleEntity[5] || '-'}</h6></td>
                    <td className="col text-center w-28 "><h6 className='p-0 m-0'>{singleEntity[7] + ' €' || '-'}</h6></td>
                    <td className="col text-center w-16 ">
                          <h6 className='p-0 m-0'>
                            <JustDateTimestampFormatter timestamp={singleEntity[8]}></JustDateTimestampFormatter>
                          </h6>
                        </td>


                  </tr>
                                 {/* New row for entity[6] */}
                  
                       <tr
                            onMouseEnter={() => setHoveredRow(index)}
                            onMouseLeave={() => setHoveredRow(null)}
                            className={`${   hoveredRow === index ? 'bg-stone-100' : '' }`}
                          >
                          <td colSpan={colSpan} className="text-right">
                            <div
                              className={`p-0 m-0 overflow-hidden ${expandedRowIndex === index ? 'max-h-full' : 'hidden'}`}
                              style={{ transition: 'max-height 0.3s ease' }}
                            >
                              <DisplayHtml htmlContent={singleEntity[6] || '-'} />
                            </div>

                            {singleEntity[3] === localStorage.getItem('profileName') && (
                                <button
                                className="sm:py-1 -my-2 mr-4 px-2 text-lg bg-slate-600 text-white rounded"
                                type="button"
                                onClick={() => changeActiveOrInactiv(singleEntity[1])} // Ensure this is only called on click
                            >
                                {singleEntity[9] ? 'inaktiv setzen' : 'aktiv setzen'}
                            </button>
                            )}

                            <button
                              className="sm:py-1 -my-2 px-2 -mt-2 text-lg bg-slate-600 text-white rounded"
                              type="button"
                              onClick={()=>toggleExpand(index)}
                            >
                              {expandedRowIndex === index ? 'Weniger anzeigen' : 'Mehr anzeigen'}
                            </button>
                          </td>
                          { getUserProfileNames && !getUserProfileNames.includes(singleEntity[3]) && (
                              <td colSpan={colSpan2} className="text-center">
                                <button
                                  className="sm:py-1 -my-2 px-2 text-lg bg-slate-600 text-white rounded"
                                  type="button"
                                  onClick={() => inquiryClick(singleEntity[1], singleEntity[3])}
                                >
                                  Anfragen
                                </button>
                              </td>
                            )}
                          {singleEntity[3] === localStorage.getItem('profileName') && (
                            <td colSpan={colSpan2} className="text-center">
                                <button
                                className="sm:py-1 -my-2 -mt-1 px-2 text-lg bg-slate-600 text-white rounded"
                                type="button"
                                onClick={() => deletePinboardClick(singleEntity[1], singleEntity[3])}
                              >
                                Eintrag Löschen
                              </button>
                            </td>
                            )}

                  </tr>
                  </>
                )}
                </>
              ))}
            </>
          )}


          { (showSearchRatingRow && searchActive && searchForTitle) && !showLoadingData &&  (
            <>
                        <>
            {titleOrIDArray && titleOrIDArray.length === 0 && (
                  <>
                  <tr>
                  <td colSpan={12} className="col text-center ">
                          <h4 className='p-0 m-10'>
                            Die Suche ergab keine Ergebnisse.
                          </h4>
                        </td>
                     </tr>   
                  </>
            )}
            </>
           {titleOrIDArray && titleOrIDArray.length > 0 && titleOrIDArray.slice().reverse().map((singleEntity, index) => (
                <>
            { singleEntity[9] && getDeletedButNotRefreshedPinboards && !getDeletedButNotRefreshedPinboards.includes(singleEntity[1]) && (
                  <>
            <tr key={singleEntity[1]}       
                  onMouseEnter={() => setHoveredSearchRow(index)}
                  onMouseLeave={() => setHoveredSearchRow(null)}
                  className={`align-middle text-center justify-center items-center h-[8px] ${
                    hoveredSearchRow === index ? 'bg-stone-100' : ''
                  }`}
                >
                  
                  
                  {localStorage.getItem('profileName') !== singleEntity[3] && ( 
                      <td className='w-12 max-sm:hidden'>
                        <div className='-my-1'>
                          <a className='p-0 m-0' 
                            href="/chat" 
                              target="_blank" 
                              rel="noopener noreferrer"
                              onClick={(e) => changeToChat(singleEntity[3], e)}
                            >
                              <button className="bg-slate-600 text-white rounded scale-150 p-0 m-0">
                                <IconWithTooltip icon={<BsChatDots className="scale-75"/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                              </button>
                            </a>
                        </div>
                      </td>
                    )}

                    {localStorage.getItem('profileName') === singleEntity[3] && (
                                  <td className="w-12 max-sm:hidden border-none  items-center justify-center">
                                  <IconWithTooltip icon={
                         <div>
                             <div
                                 className={`w-4 h-4  mt-1.5 ml-0.5 rounded-full border-none  ${
                                     singleEntity[9] ? 'bg-green-500' : 'bg-red-500'
                                 }`}
                                 style={{
                                   boxShadow: singleEntity[9]
                                       ? '0 0 6px rgba(0, 255, 0, 0.8)'
                                       : '0 0 6px rgba(255, 0, 0, 0.8)',
                               }}
                             ></div> </div>} tooltipText={`${singleEntity[9]?'aktiv':'inaktiv'}`} />
                         </td>
                    )}

                    <td className="w-8">
                     {myPinboardPosts &&( <h6 className="m-0 p-0 ">{((localStorage.getItem('pageNum2')-1)*entitiesPerPage)+index+1}</h6> )}
                     {!myPinboardPosts &&( <h6 className="m-0 p-0 ">0</h6> )}
                    </td>
                    <td className="col text-center w-20 text-lg">
                      <div
                        className="p-0 m-0 truncate w-16 -mr-2 cursor-pointer"
                        title="Klick zum kopieren"
                        onClick={() => {
                          const textToCopy = (singleEntity[1] || '-')
                        navigator.clipboard.writeText(textToCopy);
                      }}
                    >
                        {singleEntity[1] || '-'}
                      </div>
                    </td>
                    <td className="col text-center w-12"><h6 className='w-12 p-0 m-0'>{singleEntity[2] || '-'}</h6></td>
                    <td className="text-center truncate w-32">                     
                      <a
                        href={`${baseUrl2}/profile?othProfileName=${singleEntity[3]}&othProfileType=${singleEntity[4]}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => changeOtherProfileName(singleEntity[3], singleEntity[4])}
                      >
                        <h6 className='max-sm:truncate max-sm:w-20 w-60 p-0 m-0'>{singleEntity[3] || '-'}</h6>
                      </a>
                    </td>
                    <td className="col text-center max-sm:hidden"><h6 className='p-0 m-0'>{singleEntity[5] || '-'}</h6></td>
                    <td className="col text-center w-28 "><h6 className='p-0 m-0'>{singleEntity[7] + ' €' || '-'}</h6></td>
                    <td className="col text-center w-16 ">
                    <h6 className='p-0 m-0'>
                            <JustDateTimestampFormatter timestamp={singleEntity[8]}></JustDateTimestampFormatter>
                          </h6>
                        </td>


                  </tr>
                                 {/* New row for entity[6] */}
                  
                       <tr
                            onMouseEnter={() => setHoveredSearchRow(index)}
                            onMouseLeave={() => setHoveredSearchRow(null)}
                            className={`${hoveredSearchRow === index ? 'bg-stone-100' : '' }`}
                          >
                          <td colSpan={colSpan} className="text-right">
                            <div
                              className={`p-0 m-0 overflow-hidden ${expandedRowIndex === index ? 'max-h-full' : 'hidden'}`}
                              style={{ transition: 'max-height 0.3s ease' }}
                            >
                              <DisplayHtml htmlContent={singleEntity[6] || '-'} />
                            </div>

                            {singleEntity[3] === localStorage.getItem('profileName') && (
                                <button
                                className="sm:py-1 -my-2 mr-4 px-2 text-lg bg-slate-600 text-white rounded"
                                type="button"
                                onClick={() => changeActiveOrInactiv(singleEntity[1])} // Ensure this is only called on click
                            >
                                {singleEntity[9] ? 'inaktiv setzen' : 'aktiv setzen'}
                            </button>
                            )}

                            <button
                              className="sm:py-1 -my-2 px-2 -mt-2 text-lg bg-slate-600 text-white rounded"
                              type="button"
                              onClick={()=>toggleExpand(index)}
                            >
                              {expandedRowIndex === index ? 'Weniger anzeigen' : 'Mehr anzeigen'}
                            </button>
                          </td>

                          { getUserProfileNames && !getUserProfileNames.includes(singleEntity[3]) && (
                              <td colSpan={colSpan2} className="text-center">
                                <button
                                  className="sm:py-1 -my-2 px-2 text-lg bg-slate-600 text-white rounded"
                                  type="button"
                                  onClick={() => inquiryClick(singleEntity[1], singleEntity[3])}
                                >
                                  Anfragen
                                </button>
                              </td>
                            )}

                          {singleEntity[3] === localStorage.getItem('profileName') && (
                            <td colSpan={colSpan2} className="text-center">
                                <button
                                className="sm:py-1 -my-2 -mt-1 px-2 text-lg bg-slate-600 text-white rounded"
                                type="button"
                                onClick={() => deletePinboardClick(singleEntity[1], singleEntity[3])}
                              >
                                Eintrag Löschen
                              </button>
                            </td>
                            )}
                  </tr>
                  </>
                )}
                </>
              ))}
            </>
          )}



{ (myPinboardPosts && !searchActive) && !showLoadingData &&  (
        <>
            <>
            {myPinboardArray && myPinboardArray.length === 0 && (
                  <>
                 <tr> <td colSpan={12} className="col text-center ">
                          <h4 className='p-0 m-10'>
                            Derzeit sind keine Einträge vorhanden.
                          </h4>
                        </td>
                  </tr>
                  </>
            )}
            </>
          
           {myPinboardArray && myPinboardArray.length > 0 && myPinboardArray.slice().reverse().map((singleEntity, index) => (
                <>
                { getDeletedButNotRefreshedPinboards && !getDeletedButNotRefreshedPinboards.includes(singleEntity[1]) && (
                  <>
            <tr key={singleEntity[1]}      
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                  className={`align-middle text-center justify-center items-center h-[8px] ${
                    hoveredRow === index ? 'bg-stone-100' : ''
                  }`}
                >
            <td className="w-12 max-sm:hidden border-none  items-center justify-center">
                                       <IconWithTooltip icon={
                              <div>
                                  <div
                                      className={`w-4 h-4  mt-1.5 ml-0.5 rounded-full border-none  ${
                                          singleEntity[9] ? 'bg-green-500' : 'bg-red-500'
                                      }`}
                                      style={{
                                        boxShadow: singleEntity[9]
                                            ? '0 0 6px rgba(0, 255, 0, 0.8)'
                                            : '0 0 6px rgba(255, 0, 0, 0.8)',
                                    }}
                                  ></div> </div>} tooltipText={`${singleEntity[9]?'aktiv':'inaktiv'}`} />
                              </td>

                    <td className="w-8">
                     <h6 className="m-0 p-0 ">{((localStorage.getItem('pageNum2')-1)*entitiesPerPage)+index+1}</h6> 
                    </td>
                    <td className="col text-center w-20 text-lg">
                      <div
                        className="p-0 m-0 truncate w-16 -mr-2 cursor-pointer"
                        title="Klick zum kopieren"
                        onClick={() => {
                          const textToCopy = (singleEntity[1] || '-')
                        navigator.clipboard.writeText(textToCopy);
                      }}
                    >
                        {singleEntity[1] || '-'}
                      </div>
                    </td>
                    <td className="col text-center w-12 "><h6 className='w-12 p-0 m-0'>{singleEntity[2] || '-'}</h6></td>
                    <td className="text-center truncate w-32">                     
                      <a
                        href={`${baseUrl2}/profile?othProfileName=${singleEntity[3]}&othProfileType=${singleEntity[4]}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => changeOtherProfileName(singleEntity[3], singleEntity[4])}
                      >
                        <h6 className='max-sm:truncate max-sm:w-20 w-60 p-0 m-0'>{singleEntity[3] || '-'}</h6>
                      </a>
                    </td>
                    <td className="col text-center max-sm:hidden "><h6 className='p-0 m-0'>{singleEntity[5] || '-'}</h6></td>
                    <td className="col text-center w-28 "><h6 className='p-0 m-0'>{singleEntity[7] + ' €' || '-'}</h6></td>
                    <td className="col text-center w-16">
                    <h6 className='p-0 m-0'>
                            <JustDateTimestampFormatter timestamp={singleEntity[8]}></JustDateTimestampFormatter>
                          </h6>
                        </td>


                  </tr>
                                 {/* New row for entity[6] */}
                  
                        <tr
                            onMouseEnter={() => setHoveredRow(index)}
                            onMouseLeave={() => setHoveredRow(null)}
                            className={` ${hoveredRow === index ? 'bg-stone-100' : ''
                            }`}
                          >
                          <td colSpan={colSpan} className="text-right">
                            <div
                              className={`p-0 m-0 overflow-hidden ${expandedRowIndex === index ? 'max-h-full' : 'hidden'}`}
                              style={{ transition: 'max-height 0.3s ease' }}
                            >
                              <DisplayHtml htmlContent={singleEntity[6] || '-'} />
                            </div>

                            {singleEntity[3] === localStorage.getItem('profileName') && (
                                <button
                                className="sm:py-1 -my-2 mr-4 px-2 text-lg bg-slate-600 text-white rounded"
                                type="button"
                                onClick={() => changeActiveOrInactiv(singleEntity[1])} // Ensure this is only called on click
                            >
                                {singleEntity[9] ? 'inaktiv setzen' : 'aktiv setzen'}
                            </button>
                            )}
                            <button
                              className="sm:py-1 -my-2 px-2 -mt-2 text-lg bg-slate-600 text-white rounded"
                              type="button"
                              onClick={()=>toggleExpand(index)}
                            >
                              {expandedRowIndex === index ? 'Weniger anzeigen' : 'Mehr anzeigen'}
                            </button>
                          </td>

                          { getUserProfileNames && !getUserProfileNames.includes(singleEntity[3]) && (
                              <td colSpan={colSpan2} className="text-center">
                                <button
                                  className="sm:py-1 -my-2 px-2 text-lg bg-slate-600 text-white rounded"
                                  type="button"
                                  onClick={() => inquiryClick(singleEntity[1], singleEntity[3])}
                                >
                                  Anfragen
                                </button>
                              </td>
                            )}

                          {singleEntity[3] === localStorage.getItem('profileName') && (
                            <td colSpan={colSpan2} className="text-center">
                                <button
                                className="sm:py-1 -my-2 -mt-1 px-2 text-lg bg-slate-600 text-white rounded"
                                type="button"
                                onClick={() => deletePinboardClick(singleEntity[1], singleEntity[3])}
                              >
                                Eintrag Löschen
                              </button>
                            </td>
                            )}
                  </tr>
                  </>
                )}
                </>
              ))}
            </>
          )}

          { myPinboardPosts === false && !showLoadingData && (  
          <>
                      <>
            { !searchActive && getSortedData && getSortedData.length === 0 && (
                  <>
                  <tr><td colSpan={12} className="col text-center ">
                          <h4 className='p-0 m-10'>
                            Diese Kategorie enthält noch keine Einträge.
                          </h4>
                        </td>
                    </tr>
                  </>
            )}
            </>
            {getSortedData && getSortedData.length > 0 && getSortedData.map((entity, index) => (
                <>
                { entity[9] && getDeletedButNotRefreshedPinboards && !getDeletedButNotRefreshedPinboards.includes(entity[1]) && (
           <> 
            <tr key={entity[1]}
              onMouseEnter={() => setHoveredRow(index)}
              onMouseLeave={() => setHoveredRow(null)}
              className={`align-middle text-center justify-center items-center h-[8px] ${
                hoveredRow === index ? 'bg-stone-100' : ''
              }`}
            >
                        
                    
                    {localStorage.getItem('profileName') !== entity[3] && (
                      <td className='w-12 max-sm:hidden'>
                        <div className='-my-1'>
                        <a className='p-0 m-0' 
                          href="/chat" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          onClick={(e) => changeToChat(entity[3], e)}
                        >
                          <button className="bg-slate-600 text-white rounded scale-150 p-0 m-0">
                            <IconWithTooltip icon={<BsChatDots className="scale-75"/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                          </button>
                        </a>
                        </div>
                      </td>
                    )}

                    {localStorage.getItem('profileName') === entity[3] && (
                                  <td className="w-12 max-sm:hidden border-none  items-center justify-center">
                                  <IconWithTooltip icon={
                         <div>
                             <div
                                 className={`w-4 h-4  mt-1.5 ml-0.5 rounded-full border-none  ${
                                     entity[9] ? 'bg-green-500' : 'bg-red-500'
                                 }`}
                                 style={{
                                   boxShadow: entity[9]
                                       ? '0 0 6px rgba(0, 255, 0, 0.8)'
                                       : '0 0 6px rgba(255, 0, 0, 0.8)',
                               }}
                             ></div> </div>} tooltipText={`${entity[9]?'aktiv':'inaktiv'}`} />
                         </td>
                    )}

                    <td className="w-8">
                      <h6 className="m-0 p-0">{((localStorage.getItem('pageNum')-1)*entitiesPerPage)+index+1}</h6>
                    </td>
                    <td className="col text-center w-20 text-lg">
                      <div
                        className="p-0 m-0 truncate w-16 -mr-2 cursor-pointer"
                        title="Klick zum kopieren"
                        onClick={() => {
                          const textToCopy = (entity[1] || '-')
                        navigator.clipboard.writeText(textToCopy);
                      }}
                    >
                        {entity[1] || '-'}
                      </div>
                    </td>
                    <td className="col text-center w-12"><h6 className='w-12 p-0 m-0'>{entity[2] || '-'}</h6></td>
                    <td className="text-center w-32 ">                     
                      <a className=''
                        href={`${baseUrl2}/profile?othProfileName=${entity[3]}&othProfileType=${entity[4]}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => changeOtherProfileName(entity[3], entity[4])}
                      >
                        <h6 className='max-sm:truncate max-sm:w-20 w-60 p-0 m-0'>{entity[3] || '-'}</h6>
                      </a>
                    </td>
                    <td className="col text-center max-sm:hidden "><h6 className='p-0 m-0'>{entity[5] || '-'}</h6></td>
                    <td className="col text-center w-28 "><h6 className='p-0 m-0'>{entity[7] + ' €' || '-'}</h6></td>
                    <td className="col text-center w-16">
                    <h6 className='p-0 m-0'>
                            <JustDateTimestampFormatter timestamp={entity[8]}></JustDateTimestampFormatter>
                          </h6>
                        </td>


                  </tr>
                  
                  {/* New row for entity[6] */}
                  <tr
                        onMouseEnter={() => setHoveredRow(index)}
                        onMouseLeave={() => setHoveredRow(null)}
                        className={`${hoveredRow === index ? 'bg-stone-100' : ''}`}
                      >
                                    <td colSpan={colSpan}  className="text-right">
                            <div
                              className={`p-0 m-0 overflow-hidden ${expandedRowIndex === index ? 'max-h-full' : 'hidden'}`}
                              style={{ transition: 'max-height 0.3s ease' }}
                            >
                              <DisplayHtml htmlContent={entity[6] || '-'} />
                            </div>

                            {entity[3] === localStorage.getItem('profileName') && (
                                <button
                                className="sm:py-1 -my-2 mr-4 px-2 text-lg bg-slate-600 text-white rounded"
                                type="button"
                                onClick={() => changeActiveOrInactiv(entity[1])} // Ensure this is only called on click
                            >
                                {entity[9] ? 'inaktiv setzen' : 'aktiv setzen'}
                            </button>
                            )}
                           
                            <button
                              className="sm:py-1 -my-2 px-2 -mt-2 text-lg bg-slate-600 text-white rounded"
                              type="button"
                              onClick={()=>toggleExpand(index)}
                            >
                              {expandedRowIndex === index ?'Weniger anzeigen' : 'Mehr anzeigen'}
                            </button>
                          </td>

                          { getUserProfileNames && !getUserProfileNames.includes(entity[3]) && (
                              <td colSpan={colSpan2} className="text-center">
                                <button
                                  className="sm:py-1 -my-2 -mt-1 px-2 text-lg bg-slate-600 text-white rounded"
                                  type="button"
                                  onClick={() => inquiryClick(entity[1], entity[3])}
                                >
                                  Anfragen
                                </button>
                              </td>
                            )}

                          {entity[3] === localStorage.getItem('profileName') && (
                            <td colSpan={colSpan2} className="text-center">
                                <button
                                className="sm:py-1 -my-2 -mt-1 px-2 text-lg bg-slate-600 text-white rounded"
                                type="button"
                                onClick={() => deletePinboardClick(entity[1], entity[3])}
                              >
                                Eintrag Löschen
                              </button>
                            </td>
                            )}
                  </tr>
                  </>
                )}
                </>
              ))}
              </>
            ) }  


            </tbody>
          </table>
              )}


              { showLoadingData === true && (
                      <div className="flex justify-center items-center min-h-screen">
                      <div className='loadingBox white-glassmorphism-loadingBox text-center transform z-30' style={{ minWidth: '250px', maxWidth: '350px', minHeight: '100px' }}>
                        <div className="flex justify-center items-center w-full h-full">
                          <div className='mt-10'>
                            <ImSpinner9 className="spinning-image animate-spin" size={50} color="#a7b7c8" />
                          </div>
                          <div className="mt-10 ml-3 text-slate-600">Loading...</div> {/* Optional loading text */}
                        </div>
                      </div>
                    </div>
                            
              )}

        </div>
      </div>
      <div className={`container-sm ViewCol max-sm:mt-6 dashboardPageChangeBottomCard white-glassmorphism-card ${firstLoginTrue2[2] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}>
      <div className='col-sm-12 sm:my-2  dashboardPageChangeTopCard white-glassmorphism-card '>
        <div className='col-sm-6'>
          {!myPinboardPosts && (
          <div className="flex max-sm:-mx-10 items-center text-center justify-center">
          <button 
              className="text-slate-600 rounded my-6 mr-3 scale-150" 
              onClick={() => {
                const pageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                fetchFirstTimeData(pageNum, categoryFilter);
              }}
            >
              <IconWithTooltip icon={<IoReloadCircle className="mr-1 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
            </button>

            <p className='FormStyling max-sm:text-lg mr-6'>Seite {localStorage.getItem('pageNum') || 1} von {getTotalPageNumber}</p>

            {parseInt(localStorage.getItem('pageNum')) > 1 && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}

            <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum')) || 1) - 1) * entitiesPerPage} - {(parseInt(localStorage.getItem('pageNum')) || 1) * entitiesPerPage}</p>

            {parseInt(localStorage.getItem('pageNum')) < getTotalPageNumber && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum')) || 1;
                  const newPageNumber = currentPageNum + 1;
                  localStorage.setItem('pageNum', newPageNumber);
                  fetchData(newPageNumber);
                }}
              >
                <IoIosArrowForward />
              </button>
            )}

            <p className="FormStyling max-sm:text-lg ml-6 max-sm:ml-4">Gehe zu Seite</p>
            <input 
              className="-py-1 my-3 ml-2 rounded-xl text-center FormStyling2" 
              style={{ width: '1cm' }} 
              name="pageNumber" 
              onChange={handlePageNumChange} 
              value={pageNum} 
            />

            <button 
              className="text-slate-600 rounded my-6 ml-3 scale-150 p-0 m-0" 
              onClick={() => {
                const newPageNumber = pageNum !== '' ? pageNum : (parseInt(localStorage.getItem('pageNum')) || 1);
                localStorage.setItem('pageNum', parseInt(newPageNumber));
                fetchData(newPageNumber);
              }}
            >
              <IconWithTooltip icon={<IoCheckmarkCircle className="ml-1 scale-150" />} tooltipText="Gehe zur gewählten Seite." />
            </button>

            
            </div>
            )}

{myPinboardPosts && (
          <div className="flex max-sm:-mx-10 items-center text-center justify-center">
          <button 
              className="text-slate-600 rounded my-6 mr-3 scale-150" 
              onClick={() => {
                const pageNum = !searchActive?parseInt(localStorage.getItem('pageNum2')) || 1:parseInt(localStorage.getItem('pageNum3')) || 1;
                fetchData2(pageNum);
              }}
            >
              <IconWithTooltip icon={<IoReloadCircle className="mr-1 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
            </button>

            <p className='FormStyling max-sm:text-lg mr-6'>Seite {localStorage.getItem('pageNum2') || 1} von {getTotalPageNumber2}</p>

            {parseInt(localStorage.getItem('pageNum2')) > 1 && !searchActive &&(
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum2')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum2', newPageNumber);
                  fetchData2(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}
           {parseInt(localStorage.getItem('pageNum3')) > 1 && searchActive && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum3')) || 1;
                  const newPageNumber = currentPageNum > 1 ? currentPageNum - 1 : currentPageNum;
                  localStorage.setItem('pageNum3', newPageNumber);
                  fetchData2(newPageNumber);
                }}
              >
                <IoIosArrowBack />
              </button>
            )}
        {!searchActive &&( <>
            <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum2')) || 1) - 1) * entitiesPerPage} - {(parseInt(localStorage.getItem('pageNum2')) || 1) * entitiesPerPage}</p>

            {parseInt(localStorage.getItem('pageNum2')) < getTotalPageNumber2 && (
              <button
                className="mt-0.5 scale-150"
                onClick={() => {
                  const currentPageNum = parseInt(localStorage.getItem('pageNum2')) || 1;
                  const newPageNumber = currentPageNum + 1;
                  localStorage.setItem('pageNum2', newPageNumber);
                  fetchData2(newPageNumber);
                }}
                
                
              >
                <IoIosArrowForward />
              </button>
            )} </>
          )}

        {searchActive &&( <>
              <p className='FormStyling max-sm:text-lg mx-4'>{((parseInt(localStorage.getItem('pageNum3')) || 1) - 1) * entitiesPerPage} - {(parseInt(localStorage.getItem('pageNum3')) || 1) * entitiesPerPage}</p>

              {parseInt(localStorage.getItem('pageNum3')) < getTotalPageNumber2 && (
                <button
                  className="mt-0.5 scale-150"
                  onClick={() => {
                    const currentPageNum = parseInt(localStorage.getItem('pageNum3')) || 1;
                    const newPageNumber = currentPageNum + 1;
                    localStorage.setItem('pageNum3', newPageNumber);
                    fetchData2(newPageNumber);
                  }}
                  
                  
                >
                  <IoIosArrowForward />
                </button>
              )}
          </> )}

            <p className="FormStyling max-sm:text-lg ml-6 max-sm:ml-4">Gehe zu Seite</p>
            <input 
              className="-py-1 my-3 ml-2 rounded-xl text-center FormStyling2" 
              style={{ width: '1cm' }} 
              name="pageNumber2" 
              onChange={handlePageNum2Change} 
              value={pageNum2} 
            />

            <button 
              className="text-slate-600 rounded my-6 ml-3 scale-150 p-0 m-0" 
              onClick={() => {
                const newPageNumber = pageNum2 !== '' ? pageNum2 : (!searchActive?parseInt(localStorage.getItem('pageNum2')) || 1:parseInt(localStorage.getItem('pageNum3')) || 1);
                !searchActive?localStorage.setItem('pageNum2', parseInt(newPageNumber)):localStorage.setItem('pageNum3', parseInt(newPageNumber));
                fetchData2(newPageNumber);
              }}
            >
              <IconWithTooltip icon={<IoCheckmarkCircle className="ml-1 scale-150" />} tooltipText="Gehe zur gewählten Seite." />
            </button>

            
            </div>
            )}
          </div>
          <div className='col-sm-6 max-sm:-mx-10 items-center justify-center mt-2'>
          { myPinboardPosts && (
            <button
                  className="sm:py-2 px-2 max-sm:mr-2 marginRightSM max-sm:text-lg bg-slate-600 text-white rounded"
                  type="button"
                  onClick={()=>{ fetchFirstTimeData(1, categoryFilter);
                     setMyPinboardPosts(false); setShowSearchRatingRow(false)}}
                >
                 Alle Pinnwandeinträge
                </button>
           )}
           { !myPinboardPosts && (
            <button
                  className={`sm:py-2 px-2 max-sm:mr-2 marginRightSM max-sm:text-lg bg-slate-600 text-white rounded ${firstLoginTrue2[3] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}
                  type="button"
                  onClick={()=>{getMyPinboardDocuments(); setSearchActive(false); setMyPinboardPosts(true);}}
                >
                 Meine Pinnwandeinträge
                </button>
           )}
              <button
                  className={`sm:py-2 px-8 max-sm:mb-2 max-sm:text-lg bg-slate-600 text-white rounded ${firstLoginTrue2[3] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}
                  type="button"
                  onClick={()=> setShowCreateNewPinboardPost(true)}
                >
                  + Pinnwandeintrag
                </button>
            </div>
            </div>
    </div>
    
    
    </>)}


  </div> 
  
  </div>
    
  );
}

export default PinboardView; 
export { default as PinboardView } from './PinboardView';