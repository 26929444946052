import './shareProfile.css';

import { useNavigate} from "react-router-dom";
import { useEffect } from 'react';


//Components
import ShareProfileAll from '../components/shareProfile/ShareProfileAll.js';
import { Footer } from '../components/navbar&Footer/Footer.js';
import { Navbar } from '../components/navbar&Footer/Navbar';

const ShareProfile = ({baseUrl}) => {

  return (
      <div className="ShareProfile skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
          <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
              <div className="ShareProfileAuthor" style={{ flex: 1 }}>
                <ShareProfileAll baseUrl={baseUrl} />
              </div>
              <div className="mt-auto mb-20 ">
                  <Footer/>
            </div> 
         </div>      
    </div>
  );
}

export default ShareProfile; 