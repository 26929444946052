import './CompRules.css';

const CompRules = () => {

return (
	<div className='max-w-6xl mx-auto p-6'>
	<h1 className='text-7xl font-bold text-center mb-4'>
	  Spielregeln der Plattform
	</h1>
	<p className='text-2xl text-center mb-6 mt-6'>
	  <strong>
		Bitte beachten Sie: Jedes Profil unterliegt eigenen Regeln.
	  </strong>
	</p>
<br></br><br></br>
<div className='text-left'>
	<h2 className='text-5xl font-semibold mt-8 mb-8'>Chatrechte</h2>
	<p className='text-2xl leading-relaxed mb-6'>
	  Um eine Überflutung von Nachrichten zu verhindern, wurden Chatrechte
	  eingeführt. Autoren können nur dann von Dienstleistern kontaktiert
	  werden, wenn sie in ihrem Profil unter "Suche nach" nach einem der
	  anderen Profile suchen. Sobald ein Autor ein anderes Profil direkt
	  anschreibt, kann dieses im Chat antworten.
	</p>

	<h3 className='text-4xl font-semibold mt-16 mb-4'>Autoren:</h3>
	<ul className='text-2xl list-disc list-inside mb-6'>
	  <li className='my-3'>
		Autoren können von Anfang an allen Profilen schreiben, außer
		Verlagen und anderen Autoren.
	  </li>
	  <li className='my-3'>
		Wenn Sie einen Schreibbuddy, Lektor, Testleser oder Coverdesigner suchen oder Chatrechte an jemanden
		vergeben haben, kann Ihr Profil von diesen Personen kontaktiert
		werden.
	  </li>
	  <li className='my-3'>
		In einem Chat mit Verlagen können Sie erst schreiben, sobald diese
		Sie angeschrieben haben.
	  </li>
	</ul>

	<h3 className='text-4xl font-semibold mt-16 mb-4'>
	  Testleser, Coverdesigner, Lektoren/Korrektoren:
	</h3>
	<p className='text-2xl leading-relaxed mb-6'>
		Diese Profile können sich gegenseitig schreiben, jedoch nur
		Autoren, wenn diese nach dem jeweiligen Profil suchen (erkennbar
		an Symbolen im Profil/Dashboard) oder Chatrechte vergeben haben.
</p>

	<h3 className='text-4xl font-semibold mt-16 mb-4'>Verlage:</h3>
	<p className='text-2xl leading-relaxed mb-6'>
		Verlage können jederzeit alle Profile kontaktieren.
</p>
<br></br><br></br>
	<h2 className='text-5xl font-semibold mt-8 mb-8'>Buchrechte</h2>
	<h3 className='text-4xl font-semibold mt-16 mb-4'>
	  Zusammenarbeit mit Dienstleistern:
	</h3>
	<p className='text-2xl leading-relaxed mb-6'>
		Ein Autor muss einem Dienstleister-Profil zuerst Buchrechte geben,
		um die Zusammenarbeit zu ermöglichen. Das Profil erhält dann:
	  </p>
	  <ul className='text-2xl list-disc list-inside ml-6'>
		<li className='my-3'>Download-Rechte für das Buch</li>
		<li className='my-3'>Das Recht, Autor und Buch zu bewerten</li>
		<li className='my-3'>
		  Der Autor kann das Dienstleister-Profil ebenfalls bewerten.
		</li>
	  </ul>

	<h3 className='text-4xl font-semibold mt-16 mb-4'>Bewertungen:</h3>
	<p className='text-2xl leading-relaxed mb-6'>
		Das Buch und der Bereich „Bearbeitete Buchprojekte“ erscheinen
		erst im Dienstleister-Profil, wenn alle 3 Bewertungen (1 vom
		Autor, 2 vom Dienstleister) abgegeben wurden.
	  </p>
	

	<h3 className='text-4xl font-semibold mt-16 mb-4'>Rechteverwaltung:</h3>
	<p className='text-2xl leading-relaxed mb-6'>
		Der Autor kann Buchrechte jederzeit in der Buchansicht (zugänglich
		über das eigene Profil) unter „Besitzer von Rechten“ überwachen
		oder entziehen.
	  </p>

<br></br><br></br>
	<h2 className='text-5xl font-semibold mt-8 mb-8'>Mehrere Profile</h2>
	<p className='text-2xl leading-relaxed mb-6'>
		Über das Profilbild oben rechts können weitere Profile hinzugefügt
		und zwischen ihnen gewechselt werden, wenn man mehrere Rollen
		einnehmen möchte (z. B. Autor und Lektor).
	</p>
	<br></br><br></br>
	<h2 className='text-5xl font-semibold mt-8 mb-8'>Profil teilen</h2>
	<p className='text-2xl leading-relaxed mb-6'>
	  Um dein Profil öffentlich zu zeigen, kopiere einfach den 'Teile-Link', den du unter
	   'Mein Profil' findest, und teile ihn. Andere Personen können dein Profil in einer 
		</p>
	</div>
  </div>

    );
}

export default CompRules;

export{ default as CompRules } from './CompRules';
