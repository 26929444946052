import './contact.css';

//Components
import { CompContact } from '../components/legalPrivacyContact/CompContact';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';

const Contact = ({baseUrl}) => {

  return (
      <div className="Contact skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
          <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
                <div className="mb-28 mt-28" style={{ flex: 1 , minHeight: '140vh'}}>
                      <CompContact />
                  </div> 
                  <div className="mt-20 mb-20 ">
                      <Footer/>
                </div>
          </div>     
    </div>
  );
}

export default Contact; 