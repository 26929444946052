
//import block from '../../images/shiny_symbol_plus Text1_gray.png'
import block from '../../images/Bluum_symbol_plus Text_new_white.png'
import './Navbar.css';
import api from '../../api/axiosConfig';
import { Link } from "react-router-dom";
import React, { useEffect, useState,useHistory  } from 'react';  //useContext
import 'bootstrap/dist/css/bootstrap.css';

import { AiOutlineClose } from 'react-icons/ai';
import { HiMenuAlt4 } from 'react-icons/hi';
import { useNavigate, useLocation } from "react-router-dom";
//import { UserProfileChangeContext } from '../../tools/UserProfileChangeContext.js';

const Navbar = ({baseUrl}) => {

    const [toggleMenu, setToggleMenu] = useState(false);
    const [getProfileName, setProfileName] = useState();
    const [getProfileImg, setProfileImg] = useState();
    const [isHovered, setIsHovered] = useState(false);
    const [getLogOutText, setLogOutText] = useState("Please Log in");
    const [render, setRender] = useState(false);
    const [isImageFetched, setIsImageFetched] = useState(false);
    
    const [userRole, setUserRole] = useState('');
    const [getShowProfiles, setShowProfiles] = useState(false);
    const [getProfileType, setProfileType  ] = useState('Empty');
    const [getUserData, setUserData ] = useState([]);

    const [getShareProfileTrue, setShareProfileTrue ] = useState(false);
    

    const [error, setError] = useState(null);
    
    const navigate = useNavigate();

    const location = useLocation(); 

    //const { userProfileChanged } = useContext(UserProfileChangeContext);


      ////////////////////////// First Login Explanation//////////////////////

      const [firstLoginTrue, setFirstLoginTrue] = useState(new Array(3).fill(false));
  
      const getUserFirstLogin = async () => {
     
       try {
    
         const response = await api.get(`/api/v1/auth/user/${localStorage.getItem(`userEmail`)}`, {
           headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
         });
    
         if(response.data !== null || response.data !== ''){
         const user = response.data;
    
         //console.log('response.data: ', response.data);
         //console.log('user.lastLoginAt.lenght : ', user.lastLoginAt.length );
    
         if(user.lastLoginAt.length >= 2 && user.lastLoginAt.length <= 3 && localStorage.getItem('explainDone0') === 'false'){
          updateElements(2,true,0,true);
         }else{
          updateElements(0,false,2,false);
           
         }
       }
    
    
       } catch (error) {
         handleError('Error fetching userLoginData:', error);
       }
     };
    
     const updateElements = (currentIndex, currentState, nextIndex, nextState) => {
      const updatedArray = [...firstLoginTrue];
      // Set the current index to the desired state
      updatedArray[currentIndex] = currentState;
      // Set the next index to the desired state
      updatedArray[nextIndex] = nextState;
      //console.log('nextIndex: ', nextIndex);
  
      if(nextIndex === 1 ){
        localStorage.setItem('explainDone0', 'true'); 
        //console.log(localStorage.getItem('explainDone2'));
      }
  
      // Update state with the modified array
      setFirstLoginTrue(updatedArray);
    };
    
    
      useEffect(() => { 
       getUserFirstLogin(); 
     }, []);
      
     
     ////////////////////////// First Login Explanation//////////////////////

    
    const logout = async () => {
      const logoutText = "Please Log in";
      setLogOutText(logoutText);
      setProfileImg(logoutText);
      setIsImageFetched(false);
      localStorage.setItem('logoutTrue', 'true');
    
      setIsHovered(!isHovered);
      try {
        localStorage.setItem(`logintoken`, logoutText);
        localStorage.setItem(`profileName`, logoutText);
        localStorage.setItem('currentProfileImg', logoutText)
        navigate("/");
        const response = await api.get(`/logout`)
        //console.log(response.data);
        return response.data;
      } catch (error) {
        handleError('Error during logout:', error);
      }
    };
    
    const fetchImage = async () => {
      try {
        const getFileName = localStorage.getItem('currentProfileImg');    
        //console.log('ImgName', getFileName);
        if (getFileName && getFileName !== getLogOutText && getFileName !== null && getFileName !== '') {
          setProfileImg(`${baseUrl}/api/files/image/${getFileName}`);
          setIsImageFetched(true);
        } 
        //console.log('ImgUrl', getProfileImg);
      } catch (error) {
        handleError('Error fetching image:', error);
      }
    };

    const fetchUserProfileIdData = async () => {

      try {

      const response = await api.get(`/api/v1/auth/user/${localStorage.getItem('userEmail')}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
      });

	    setUserData(response.data);

      } catch (error) {
        handleError('Error during fetching UserData:', error);
      }
    };


    const changeCurrentMyProfile = async (profileName, userProfileType, id) => {
   
      setIsHovered(!isHovered);

      try {


             localStorage.setItem(`currentProfileType`, userProfileType);
             localStorage.setItem(`profileName`, profileName);
             localStorage.setItem(`userRole`, getUserData.userRole);
             localStorage.setItem(`userProfileIdTimestamp`, id.timestamp);
            

                    if(userProfileType === 'Author'){
                      setProfileType('authors');
                      const response = await api.get(`/api/authors/${profileName}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}`}}
                      );
                      localStorage.setItem(`currentProfileImg`, response.data.profileImg);
                      setProfileImg(response.data.profileImg);
                    }
                    if(userProfileType === 'Editor'){
                      setProfileType('editors');
                      const response = await api.get(`/api/editors/${profileName}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}`}}
                      );
                      localStorage.setItem(`currentProfileImg`, response.data.profileImg);
                      setProfileImg(response.data.profileImg);
                    } 
                    if(userProfileType === 'Testreader'){
                      setProfileType('testReaders');
                      const response = await api.get(`/api/testReaders/${profileName}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}`}}
                      );
                      localStorage.setItem(`currentProfileImg`, response.data.profileImg);
                      setProfileImg(response.data.profileImg);
                    }
                    if(userProfileType === 'Publisher'){
                      setProfileType('publishers');
                      const response = await api.get(`/api/publishers/${profileName}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}`}}
                      );
                      localStorage.setItem(`currentProfileImg`, response.data.profileImg);
                      setProfileImg(response.data.profileImg);
                    }
                    if(userProfileType === 'CoverDesigner'){
                      setProfileType('coverDesigners');
                                          
                      const response = await api.get(`/api/coverDesigners/${profileName}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}`}}
                      );
                      localStorage.setItem(`currentProfileImg`, response.data.profileImg);
                      setProfileImg(response.data.profileImg);
                    }                

                /*  setTimeout(async () => {
                      if(response.data != null){  
                          localStorage.setItem(`currentProfileImg`, response.data.profileImg);
                            setProfileImg(response.data.profileImg); 
                      }
                  //setUserProfileChanged(!userProfileChanged);
                  //console.log('/myProfile')      
                  //navigate("/myProfile");          
                        return response.data;
                  }, 1000);  */
                  //clearTimeout(delayTimeout);

                  window.location.reload();
                  
      } catch (error) {
        handleError('Error during changing profile:', error);
      }
    };


    const handleSettingsClick = () => {
     // e.preventDefault();
      const url = `${window.location.origin}/settings`;
      window.open(url, '_blank', 'noopener,noreferrer');
    };

    // Function to handle link clicks
    const handleLinkClick = (url, isDashboard) => {
      if (isDashboard) {
        // If on the dashboard route, open in a new tab
        window.open(url, '_blank');
      } else {
        // Otherwise, close the menu (normal behavior) and navigate within the same tab
        setToggleMenu(false);         
        navigate(url);
      }
    };


    const rerender = () => {
      setRender(true);
    }
    
    
    useEffect(() => {
      fetchImage();
      const logInProfileName = localStorage.getItem('profileName');
      setProfileName(logInProfileName);
      //console.log('logInProfileName', logInProfileName);
      
    }, [toggleMenu, isHovered, location]);
    
    useEffect(() => {
      fetchImage();
      const logInProfileName = localStorage.getItem('profileName');
      setProfileName(logInProfileName);
      //console.log('logInProfileName', logInProfileName);

      const shareProfileName = new URLSearchParams(location.search).get('shareProfileName');
      setShareProfileTrue(shareProfileName);
      setUserRole(localStorage.getItem(`userRole`));
      //console.log('Navbar UserRole: ',localStorage.getItem('userRole'));
      setToggleMenu(false);
      setIsHovered(false);
      setError(null);
      rerender();
      setIsImageFetched(false);
      setShowProfiles(false);
      fetchUserProfileIdData();
      //console.log('currentProfileType: ',localStorage.getItem(`currentProfileType`));
    }, []);

    useEffect(() => {
      
        const delayTimeout = setTimeout(() => {
          fetchImage();
          setUserRole(localStorage.getItem(`userRole`));
          //console.log('isImageFetched', isImageFetched);
          //console.log('logoutTrue', localStorage.getItem('logoutTrue'));
          if (!isImageFetched && localStorage.getItem('logoutTrue') === 'false') {
          setRender(!render); 
          }
        }, 3000);
      
        return () => {
          clearTimeout(delayTimeout);
        };
         
    }, [render, isImageFetched, getProfileName, getProfileImg, getShowProfiles]);



    const handleError = (message, error) => {
      console.error(message, error);
      setError('An error occurred. Please try again.');
      };


  
    return (


 <div className="pt-20 -mt-24 z-40 top-0 skin-glassmorphism-navCard">
  <nav className="relative w-full flex px-4 py-2 sm:px-8">


{firstLoginTrue[2] === true && localStorage.getItem('explainDone0') === 'false' && (
  <div className='z-20 fixed inset-y-0 left-0 h-screen w-screen overflow-hidden max-md:hidden'>
    <div 
      className={`loadingBox white-glassmorphism-loadingBox bg-slate-200 border-sky-600 border-4 text-center fixed top-28 left-4 z-30`} 
      style={{ minWidth: '250px', maxWidth: '750px', minHeight: '80px', maxHeight: '80px' }}
    >
      <div className="flex justify-center items-center w-full h-full ">
        <div className='flex m-2'>
          { firstLoginTrue[0] === true && (
            <>   
              <h6 className='text-xl mx-2'>Durch Klicken auf das Profilbild oder das Menü-Symbol öffnet sich ein Menü, in dem du weitere Profile hinzufügen, zwischen ihnen wechseln und dich abmelden kannst.</h6>
              <button 
                className="sm:py-1 my-4 px-2 max-sm:text-md bg-gray-600 text-white rounded"
                type="button"  
                onClick={() => updateElements(0, false, 1, true)}
              >
                Fertig
              </button> 
            </>
          )}
        </div>               
      </div>
    </div>
  </div>
)}







    <div className="w-full flex items-center justify-between">
      <div className="col-sm-3">
        <Link to="/">
          <img src={block} alt="Bookboard Textlogo" className="cursor-pointer Navbar-logo" style={{ maxWidth: '250px', maxHeight: '60px' }} />
        </Link>
      </div>
      <div className="flex items-center pt-10">
        {/*{error && <div style={{ color: 'red' }}>{error}</div>}*/}
        <ul className={`lg:flex max-lg:hidden list-none flex-row justify-center items-center`}>
          
          {((getProfileName === 'Please Log in' || getProfileName === null || getProfileName === '') || getShareProfileTrue) && (     
          <>
          <li className="mx-3 text-3xl cursor-pointer">
            <Link to="/"><button className='LinkColor'>Home</button></Link>
          </li>
	          <li className="mx-3 text-3xl cursor-pointer">
              <Link to="/contact"><button className='LinkColor'>Kontakt</button></Link>
            </li>
            </>
          )}
          
          {getProfileName !== 'Please Log in' && getProfileName !== null && getProfileName !== '' && !getShareProfileTrue && (
        <>
          <li className="mx-3 text-3xl cursor-pointer">
            <Link to="/dashboard">
              <button className='LinkColor'>Dashboard</button>
            </Link>
          </li>
          <li className="mx-3 text-3xl cursor-pointer">
            <button className='LinkColor' onClick={() => handleLinkClick('/myProfile', location.pathname === '/dashboard')}>
              Mein Profil
            </button>
          </li>
          <li className="mx-3 text-3xl cursor-pointer">
            <button className='LinkColor' onClick={() => handleLinkClick('/forum', location.pathname === '/dashboard')}>
              Forum
            </button>
          </li>
          <li className="mx-3 text-3xl cursor-pointer">
            <button className='LinkColor' onClick={() => handleLinkClick('/chat', location.pathname === '/dashboard')}>
              Chat
            </button>
          </li>
          <li className="mx-3 text-3xl cursor-pointer">
            <button className='LinkColor' onClick={() => handleLinkClick('/rules', location.pathname === '/dashboard')}>
              Spielregeln
            </button>
          </li>
        </>
      )}
          {userRole === 'ADMIN' && getProfileName !== 'Please Log in' && getProfileName !== null && getProfileName !== '' &&  (
            <li className="mx-3 text-3xl cursor-pointer">
              <Link to="/adminConsole"><button className='LinkColor'>Admin Console</button></Link>
            </li>
          )}
        </ul>
        <div className="flex relative">
          {toggleMenu && (
            <ul className="z-10 fixed top-0 mt-14 -right-2 p-3 w-[40vw] max-sm:w-[70vw] h-screen shadow-2xl lg:hidden list-none flex flex-col justify-start items-end rounded-lg white-glassmorphism-card animate-slide-in">
              <li className={`text-2xl w-full my-9 cursor-pointer ${firstLoginTrue[0] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}>
                <AiOutlineClose className={`SymbolColor`}   onClick={() => {setToggleMenu(false); setIsHovered(false);}} />
              </li>
              {!getShareProfileTrue && (
                <li className="text-xl mx-3 justify-center text-center">
                  <div className="">
                    <ul className="lg:flex list-none flex-row justify-end items-center my-0 mt-5 mr-5">
                      <li className='pt-1' onClick={() => setIsHovered(!isHovered)}>
                        {(localStorage.getItem('currentProfileImg') !== '' && localStorage.getItem('currentProfileImg') !== getLogOutText && localStorage.getItem('currentProfileImg') !== null) && (
                          <button className={`${firstLoginTrue[0] ? 'bg-slate-200 border-sky-600 border-4'  : ''}`}>
                            <div className="profileImgNav">
                               <div className="profileImg2" style={{ maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px' }}>
                                <div>
                                  <img className="img-fluid profileImg3" src={`${getProfileImg}`} alt="" style={{ maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px' }} />
                                </div>
                              </div>  
                            </div>
                          </button>  
                        )}
                      </li>
                    </ul>
                  </div>
                </li>
              )}

	     {(getProfileName === 'Please Log in' || getProfileName === null || getProfileName === '' || getShareProfileTrue) && (
              <li className="mx-3 text-3xl cursor-pointer p-4">
                <Link to="/"><button className='LinkColor' onClick={() => setToggleMenu(false)}>Home</button></Link>
              </li>
              )}
      {getProfileName !== 'Please Log in' && getProfileName !== null && getProfileName !== '' && !getShareProfileTrue && (
        <>
          <li className="mx-3 text-3xl cursor-pointer p-4">
            <Link to="/dashboard">
              <button className='LinkColor' onClick={() => setToggleMenu(false)}>Dashboard</button>
            </Link>
          </li>
          <li className="mx-3 text-3xl cursor-pointer p-4">
            <button className='LinkColor' onClick={() => {handleLinkClick('/myProfile', location.pathname === '/dashboard');setToggleMenu(false)}}>
              Mein Profil
            </button>
          </li>
          <li className="mx-3 text-3xl cursor-pointer p-4">
            <button className='LinkColor' onClick={() => {handleLinkClick('/forum', location.pathname === '/dashboard');setToggleMenu(false)}}>
              Forum
            </button>
          </li>
          <li className="mx-3 text-3xl cursor-pointer p-4">
            <button className='LinkColor' onClick={() => {handleLinkClick('/chat', location.pathname === '/dashboard');setToggleMenu(false)}}>
              Chat
            </button>
          </li>
          <li className="mx-3 text-3xl cursor-pointer p-4">
            <button className='LinkColor' onClick={() => {handleLinkClick('/rules', location.pathname === '/dashboard');setToggleMenu(false)}}>
              Spielregeln
            </button>
          </li>
        </>
      )}
              {userRole === 'ADMIN' && getProfileName !== 'Please Log in' && getProfileName !== null && getProfileName !== '' &&  (
                <li className="mx-3 text-3xl cursor-pointer p-4">
                  <Link to="/adminConsole"><button className='LinkColor' onClick={() => setToggleMenu(false)}>Admin Console</button></Link>
                </li>
              )}
              <li className="mx-3 text-3xl cursor-pointer p-4">
                <Link to="/contact"><button className='LinkColor' onClick={() => setToggleMenu(false)}>Kontakt</button></Link>
              </li>
              {isHovered && toggleMenu && getProfileName !== 'Please Log in' && getProfileName !== null && getProfileName !== '' && !getShareProfileTrue && (
                <div className="">
                  <div className="text-right">
                    {getUserData.userProfilesIds && getUserData.userProfilesIds.length > 1 && localStorage.getItem(`currentProfileType`) !== 'Publisher' && (
                      <li className="mx-3 text-3xl text-[#566874] mb-3 pr-3">
                        <button className='LinkColor cursor-pointer pt-10' onClick={() => { setShowProfiles(!getShowProfiles); }}>Profil wechseln</button>
                        {getShowProfiles && (
                          <div>{getShowProfiles && getUserData.userProfilesIds && getUserData.userProfilesIds.map((profiles, index) => (
                            <div>
                              {profiles.userProfileType !== localStorage.getItem(`currentProfileType`) && (
                                <div className='cursor-pointer'>
                                  <h5 className='text-left'>{profiles.userProfileType}: </h5>
                                  <h5 className="pl-2 LinkColor" onClick={() => { changeCurrentMyProfile(profiles.userProfileName, profiles.userProfileType, profiles.id); setIsHovered(!isHovered); }}>{profiles.userProfileName}</h5>
                                </div>
                              )}
                            </div>
                          ))}</div>
                        )}
                      </li>
                    )}
                    {localStorage.getItem(`currentProfileType`) !== 'Publisher' && (getUserData.userProfilesIds.length < 4) && (
                      <li className="mx-3 text-3xl text-[#566874] cursor-pointer  mb-3  pr-3">
                        <Link to="/addProfile"><button className={`LinkColor ${(getUserData.userProfilesIds && getUserData.userProfilesIds.length > 1)?'pt-5':'pt-10'}`}>Profil hinzufügen</button></Link>
                      </li>
                    )}
                    {localStorage.getItem(`currentProfileType`) === 'Publisher' && getUserData.userProfilesIds && getUserData.userProfilesIds.length === 1 && (
                      <li className="mx-3 text-3xl text-[#566874] cursor-pointer  mb-3  pr-3">
                        <Link to="/addEmployee"><button className='LinkColor pt-5'>Mitarbeiter-Profil hinzufügen</button></Link>
                      </li>
                    )}
                    <li className="mx-3 text-3xl text-[#566874] cursor-pointer  mb-3  pr-3">
                  <button className='pt-5 LinkColor' onClick={() => {setIsHovered(!isHovered); handleSettingsClick()}}>Einstellungen</button>
                </li>
                    <li className="mx-3 text-3xl text-[#566874] hover:text-[#283035]  pr-3" onClick={logout}>
                      <button className='LinkColor pt-5'>Logout</button>
                    </li>
                  </div>
                </div>
              )}
            </ul>
          )}
        </div>
      </div>

   <div className="col-sm-3 ">
     {!toggleMenu && (
       <div className="">
	      <ul className="flex list-none flex-row justify-end items-center my-0 mt-8">
         {getProfileName !== 'Please Log in' && getProfileName !== null && getProfileName !== '' && (
           <>
{!getShareProfileTrue && (
  <>
    <li 
      className={`mx-3 mt-10 text-3xl text-[#8a9aad] hover:text-[#163a5e] max-lg:hidden `} 
      onClick={() => setIsHovered(!isHovered)}
    >
      {getProfileName && (
        <button className={`${firstLoginTrue[0] ? 'bg-slate-200 border-sky-600 border-4' : ''}`}>
          {getProfileName}
        </button>
      )}
    </li>
    <li className='pt-1' onClick={() => setIsHovered(!isHovered)}>
      {(localStorage.getItem('currentProfileImg') !== '' && localStorage.getItem('currentProfileImg') !== getLogOutText && localStorage.getItem('currentProfileImg') !== null) && (
        <button>
          <div className={`profileImgNav  `}>
            <div className={`profileImg2 ${firstLoginTrue[0] ? 'md:bg-slate-200 md:border-sky-600 md:border-4' : ''}`}>
              <div>
                <img 
                  className={`img-fluid profileImg3 `}
                  src={`${getProfileImg}`} 
                  alt="" 
                  style={{ maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px' }} 
                />
              </div>
            </div>
          </div>
        </button>
      )}
    </li>
  </>
)}


	    </>
            )}
            <li className='rounded-lg ml-10 border border-white lg:hidden text-right'>
               <HiMenuAlt4 fontSize={28} className="lg:hidden cursor-pointer fill-white" onClick={() => setToggleMenu(true)} />
            </li>
          </ul>
       </div>
      )}

        <div className="col-span-1 max-lg:hidden">
          {isHovered && !toggleMenu && getProfileName !== 'Please Log in' && getProfileName !== null && getProfileName !== '' && !getShareProfileTrue && (
            <div className="absolute white-glassmorphism-card rounded-lg max-lg:mt-5 max-lg:mr-14 max-lg:top-44 px-5 right-5 group-hover:block mt-0 top-44">
              <ul className="lg:flex list-none flex-col justify-between items-center">
                <li className="mx-3 text-3xl text-[#566874] cursor-pointer">
                  {getUserData.userProfilesIds && getUserData.userProfilesIds.length >= 2 &&  (
                    <div>
                      <button className='LinkColor pt-5' onClick={() => { setShowProfiles(!getShowProfiles); }}>Profil wechseln</button>
                      {getShowProfiles && (
                        <div>{getShowProfiles && getUserData.userProfilesIds && getUserData.userProfilesIds.map((profiles, index) => (
                          <div>
                            {profiles.userProfileType !== localStorage.getItem(`currentProfileType`) && (
                              <div className='flex cursor-pointer LinkColor'><h5>{profiles.userProfileType}: </h5><h5 className="pl-2 LinkColor" onClick={() => { changeCurrentMyProfile(profiles.userProfileName, profiles.userProfileType, profiles.id); setIsHovered(!isHovered) }}>{profiles.userProfileName}</h5></div>
                            )}
                          </div>
                        ))}</div>
                      )}
                    </div>
                  )}
                </li>
                {localStorage.getItem(`currentProfileType`) !== 'Publisher' && (getUserData.userProfilesIds.length < 4) && (
                  <li className="mx-3 text-3xl text-[#566874] cursor-pointer">
                    <Link to="/addProfile"><button className='LinkColor pt-5' onClick={() => setIsHovered(!isHovered)}>Profil hinzufügen</button></Link>
                  </li>
                )}
                {localStorage.getItem(`currentProfileType`) === 'Publisher' && getUserData.userProfilesIds && getUserData.userProfilesIds.length === 1 &&  (
                  <li className="mx-3 text-3xl text-[#566874] cursor-pointer">
                    <Link to="/addEmployee"><button className='LinkColor pt-5' onClick={() => setIsHovered(!isHovered)}>Mitarbeiter-Profil hinzufügen</button></Link>
                  </li>
                )}
                <li className="mx-3 text-3xl text-[#566874] cursor-pointer">
                  <button className='pt-5 LinkColor' onClick={() => {setIsHovered(!isHovered); handleSettingsClick()}}>Einstellungen</button>
                </li>
                <li className="mx-3 text-3xl text-[#566874] hover:text-[#283035] cursor-pointer" onClick={logout}>
                  <button className='LinkColor pt-5'>Logout</button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  </nav>
</div>
 
    );
}


export default Navbar;

export { default as Navbar } from './Navbar';