
import { useEffect, useState } from 'react';




const TimestampFormatter = ({ timestamp, prevTimestamp }) => {
	const [formattedDate, setFormattedDate] = useState(null);
  
	useEffect(() => {

		/*const [year, month, day, hour, minute, second, millisecond] = timestamp*/

		const [a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s] = timestamp;

		const year = a + b + c + d;
		const month = f + g;
		const day = i + j;
		const hour = l + m;
		const minute = o + p;   

		 // Function to add leading zero to single-digit numbers
		 const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

		if (prevTimestamp && prevTimestamp !== null ) {

			/*const [year2, month2, day2, hour2, minute2, second2, millisecond2] = prevTimestamp */

			const [a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s] = prevTimestamp;

			const year2 = a + b + c + d;
			const month2 = f + g;
			const day2 = i + j;
			const hour2 = l + m;
			const minute2 = o + p; 
	
		  if (year === year2 && month === month2 && day === day2) {
			if (hour !== hour2 || minute !== minute2) {
			  const formattedString = ``;
			  setFormattedDate(formattedString);
			} else {
			  setFormattedDate(null); // Clear the formatted date
			}
		  } else {
			const formattedString =  (
				<>
				<h4 className='max-sm:text-xl -mb-1 text-bold'>{day}.{month}.{year}</h4>  
				{/*  <h4 className='text-bold'>{addLeadingZero(day)}.{addLeadingZero(month)}.{addLeadingZero(year)}</h4>  */}	
				 		  
				</>
			  );
			  setFormattedDate(formattedString);
		  }

		}else{
			const formattedString =  (
				<>
				<h4 className='max-sm:text-xl -mb-1 text-bold'>{day}.{month}.{year}</h4> 
				{/* <h4 className='text-bold'>{addLeadingZero(day)}.{addLeadingZero(month)}.{addLeadingZero(year)}</h4> */}  	
				 			  
				</>
			  );
			  setFormattedDate(formattedString);
		}
		
	  }, [timestamp, prevTimestamp]);
  
	return (
	  <div>
		{formattedDate}
	  </div>
	);
  }

  export default TimestampFormatter; 
  export { default as TimestampFormatter } from './TimestampFormatter.js';