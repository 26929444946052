import './dashboard.css';
import { Link , useNavigate} from "react-router-dom";
import api from '../api/axiosConfig';
import React,{ useEffect, useState } from 'react';

import { ImSpinner9 } from 'react-icons/im';
import { IoReloadCircle } from "react-icons/io5";

//Components
import { AuthorView } from '../components/daschboardViews/AuthorView';
import { EditorView } from '../components/daschboardViews/EditorView';
import { TestreaderView } from '../components/daschboardViews/TestreaderView';
import { CoverDesignerView } from '../components/daschboardViews/CoverDesignerView';
import { PublisherView } from '../components/daschboardViews/PublisherView';
import { BookView } from '../components/daschboardViews/BookView';  
import { PinboardView } from '../components/daschboardViews/PinboardView'; 
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';

function IconWithTooltip({ icon, tooltipText }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className=""
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {React.cloneElement(icon, {
        style: { fontSize: '10px' },
        title: isHovered ? tooltipText : '',
      })}
    </div>
  );
}



const Dashboard = ({baseUrl, baseUrl2}) => {

  
  ///////////////////////////////// For First Login Explanation //////////////////////////7

  const [firstLoginTrue, setFirstLoginTrue] = useState(new Array(5).fill(false));
  const [firstLoginTrue2, setFirstLoginTrue2] = useState(new Array(6).fill(false));

  
  const [error, setError] = useState(null); 

  const getUserFirstLogin = async () => {
 
   try {

     const response = await api.get(`/api/v1/auth/user/${localStorage.getItem(`userEmail`)}`, {
       headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
     });

     if(response.data !== null || response.data !== ''){
     const user = response.data;

     //console.log('response.data: ', response.data);
     //console.log('user.lastLoginAt.lenght : ', user.lastLoginAt.length );

     if(user.lastLoginAt.length >= 2 && user.lastLoginAt.length <= 3 && localStorage.getItem('explainDone') === 'false'){
      updateElements(0,true,4,true);
     }else{
      updateElements(0,false,4,false);
       
     }

     if(user.lastLoginAt.length >= 2 && user.lastLoginAt.length <= 3 && localStorage.getItem('explainDone0') === 'false'){
      updateElements2(0,true,5,true);
     }else{
      updateElements2(0,false,5,false);
       
     }
   }


   } catch (error) {
     handleError('Error fetching userLoginData:', error);
   }
 };

 const updateElements = (currentIndex, currentState, nextIndex, nextState) => {
  const updatedArray = [...firstLoginTrue];
  // Set the current index to the desired state
  updatedArray[currentIndex] = currentState;
  // Set the next index to the desired state
  updatedArray[nextIndex] = nextState;

  // Update state with the modified array
  setFirstLoginTrue(updatedArray);
  //console.log('firstLoginTrue[0]: ', firstLoginTrue[0]);
};

const updateElements2 = (currentIndex, currentState, nextIndex, nextState) => {
  const updatedArray = [...firstLoginTrue2];
  // Set the current index to the desired state
  updatedArray[currentIndex] = currentState;
  // Set the next index to the desired state
  updatedArray[nextIndex] = nextState;

  // Update state with the modified array
  setFirstLoginTrue2(updatedArray);
  //console.log('firstLoginTrue[0]: ', firstLoginTrue[0]);
};


  useEffect(() => { 
   getUserFirstLogin(); 
 }, []);

 
 const handleError = (message, error) => {
  console.error(message, error);
  setError('An error occurred. Please try again.');
  };


  ///////////////////////////////// For First Login Explanation //////////////////////////7


  ///////////////////////////////// Logout redirect //////////////////////////7

	const navigate = useNavigate();

  const logoutRedirectToLogin = async () => {
    const logoutRedirectText = "Please Log in"
    try{
      if(logoutRedirectText === localStorage.getItem('logintoken')){
        navigate("/");
        //console.log('Logout Redirect')
      }else{
        //console.log('Logout Redirect not Same Text')
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    logoutRedirectToLogin();
}, []);


///////////////////////////////// Logout redirect //////////////////////////7


  const [showProfileDashboard, setShowProfileDashboard] = useState(false); 
  const [showPinboardDashboard, setShowPinboardDashboard] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showDemand, setShowDemand] = useState(true); 
  const [selectedDemandOption, setSelectedDemandOption] = useState('Lektorat'); 
  const [selectedSearchOption, setSelectedSearchOption] = useState('Lektorat'); 

  const [reloadPinboardCount, setReloadPinboardCount] = useState(false);

  const [totalCounts, setTotalCounts] = useState([]); 

    const navigate2 = useNavigate();

    const logout = async () => {
        const logoutText = "Please Log in"
        try{
            localStorage.setItem(`logintoken`, logoutText );
            navigate2("/");
            const response = await api.get(`/logout`)

            //console.log(response.data);            
            return response.data;
                        
        } catch(err){
            console.log(err);
        }
    } 

      const profiles = ['Autoren','Bücher', 'Lektoren', 'Testleser', 'Coverdesigner', 'Verlage'];
      const [selectedProfile, setSelectedProfile] = useState(null);
    
      const handleProfileClick = (profile) => {
        setSelectedProfile(profile);
      };
    
      const getProfileStyle = (profile) => {
        return selectedProfile === profile
          ? 'bg-slate-600 text-white'  // Change color when selected
          : 'white-glassmorphism-card text-black'; // Default style
      };


	const searchOptions = ['Lektorat', 'Korrektorat', 'Testlesung', 'Coverdesign', 'Anderes'];
	const demandOptions = ['Lektorat', 'Korrektorat', 'Testlesung', 'Coverdesign', 'Anderes'];


      const handleSearchClick = () => {
	      setShowDemand(false);
        setShowSearch(true);
      };

      const handleDemandClick = () => {
        setShowSearch(false);	
	      setShowDemand(true);
      };

      const handleSearchOptionsClick = (searchOption) => {
        setSelectedSearchOption(searchOption);
      };

      const getSearchOptionsStyle = (SearchOption) => {
        return selectedSearchOption === SearchOption
          ? 'bg-slate-600 text-white'  // Change color when selected
          : 'white-glassmorphism-card text-black'; // Default style
      };

      const handleDemandOptionsClick = (demandOption) => {
        setSelectedDemandOption(demandOption);
      };

      const getDemandOptionsStyle = (demandOption) => {
        return selectedDemandOption === demandOption
          ? 'bg-slate-600 text-white'  // Change color when selected
          : 'white-glassmorphism-card text-black'; // Default style
      };


      const backButton = () => {
        setShowProfileDashboard(false); 
      };

      const backButton2 = () => {
        setShowPinboardDashboard(false); 
      };



  useEffect(() => {
    setShowSearch(false);
    setShowDemand(true);
    setSelectedProfile('Autoren');
    setSelectedDemandOption('Lektorat');
    setSelectedSearchOption('Lektorat');
    
}, []);

  return (
      <div className="Dashboard z-20 skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
         
         <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'> 
          { showPinboardDashboard === false && showProfileDashboard === false && (<h3 className='max-sm:hidden'>Dashboard</h3>)}

{ showPinboardDashboard === false && showProfileDashboard === false && (

    <div className="flex items-center justify-center min-h-screen">
      <div className="space-y-4 md:space-y-0 md:flex md:space-x-4 max-sm:mx-3">
        <button onClick={()=> setShowPinboardDashboard(true) } className="w-full max-w-xl p-4 white-glassmorphism-card text-black text-5xl font-bold rounded-lg shadow-md hover:bg-slate-400  hover:border-2 hover:text-black border-black transition duration-300">
          Pinnwand
          <p className="text-2xl mt-3 font-normal">Unsere Pinnwand zeigt mit Filteroptionen, was gesucht und angeboten wird, und erleichtert den Austausch in der Community.</p>
        </button>
        <button onClick={()=> setShowProfileDashboard(true) } className="w-full max-w-xl p-4 white-glassmorphism-card text-black text-5xl font-bold rounded-lg shadow-md hover:bg-slate-400 hover:border-2 hover:text-black border-black transition duration-300">
          Profile
          <p className="text-2xl mt-3 font-normal">Eine Übersicht aller Profile, einschließlich Autoren, Bücher, Verlage und mehr. Nutze die Filteroptionen und entdecke die Vielfalt der Community.</p>
        </button>
      </div>
    </div>

)}

{ showPinboardDashboard === true && (

             <div className="mb-20 px-0 sm:px-5 mx-auto max-w-screen-xl" style={{ flex: 1 }}>
                    <div><button className='buttonTwo btnChange p-2 mt-5' onClick={backButton2}>zurück</button></div>
                    <h3 className='sm:mt-20'>Pinnwand</h3>
                <div className="row row-cols-2 sm:flex justify-center">

                  <div className="flex flex-wrap justify-center items-center mt-4 space-x-2 space-y-2 sm:space-y-0 w-full ">
                    <div className="col-md-12 col-lg-12 col-xl-12 text-center " > 

                    <button
                              onClick={() => handleDemandClick()}
                              className={`max-sm:px-16 px-32 py-3 text-2xl m-1 rounded-lg transition duration-300 ease-in-out white-glassmorphism-card text-black transform hover:scale-105 ${showDemand?'bg-slate-600 text-white': ''} 
				${firstLoginTrue2[0] && localStorage.getItem('explainDone0') === 'false' ? 'bg-slate-200 border-sky-600 border-4' : ''}`}
                            >
                             Biete 
                            </button>

                        <button
                              onClick={() => handleSearchClick()}
                              className={`max-sm:px-14 px-32 mr-2 py-3 text-2xl m-1 rounded-lg transition duration-300 ease-in-out white-glassmorphism-card text-black transform hover:scale-105 ${showSearch?'bg-slate-600 text-white' : ''}
					 ${firstLoginTrue2[0] && localStorage.getItem('explainDone0') === 'false' ? 'bg-slate-200 border-sky-600 border-4' : ''}`}
                            >
                              Suche
                            </button>
                      
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-12 text-center " > 
                
                    { showDemand === true && (
    		 		<>
                        <>  <button 
                            className="text-slate-600 rounded my-6 mr-3 scale-150" 
                            onClick={() => setReloadPinboardCount(!reloadPinboardCount)  }>
                            <IconWithTooltip icon={<IoReloadCircle className="mr-1 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
                            </button></>

					{demandOptions.map((demandOptions, index) => (


                      		  <button
                        		      key={demandOptions}
                          		    onClick={() => handleDemandOptionsClick(demandOptions)}
                            		  className={`p-3 m-1 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 ${getDemandOptionsStyle(demandOptions)} `}
                           		 >
                           		   {demandOptions} ({totalCounts[index+5]})
                           		 </button>

                   			   ))}
				</>
				)}

 				{ showSearch === true && (
    		 		<>

                        <>  <button 
                            className="text-slate-600 rounded my-6 mr-3 scale-150" 
                            onClick={() => setReloadPinboardCount(!reloadPinboardCount)  }>
                            <IconWithTooltip icon={<IoReloadCircle className="mr-1 scale-150" />} tooltipText="Aktualisiere die Dashboard-Daten." />
                            </button></>

					{searchOptions.map((searchOptions, index) => (
                        		<button
                         		     key={searchOptions}
                         		     onClick={() => handleSearchOptionsClick(searchOptions)}
                         		     className={`p-3 m-1 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 ${getSearchOptionsStyle(searchOptions)} ${firstLoginTrue2[0] && localStorage.getItem('explainDone0') === 'false' ? 'bg-slate-200 border-sky-600 border-4' : ''}`}
                        		    >
                         		     {searchOptions} ({totalCounts[index]})
                         		   </button>

                      			))}
				</>
				)}

                    </div>

                    <div className="min-sm:col-sm-12 flex-wrap max-sm:p-0 pt-10 w-full">
                        <div className="col-md-12 col-lg-12 col-xl-6 my-10 px-0 mx-auto">

                          <PinboardView selectedSearchOption={selectedSearchOption} showSearch={showSearch}
				      	                        selectedDemandOption={selectedDemandOption} showDemand={showDemand}
                                        totalCounts={totalCounts} setTotalCounts={setTotalCounts}
                                        reloadPinboardCount={reloadPinboardCount}
					                              firstLoginTrue2={firstLoginTrue2} setFirstLoginTrue2={setFirstLoginTrue2} 
					                              baseUrl2={baseUrl2}
			                    />
                        </div>
                    </div>


                  </div>
                </div>
              </div>

)}


{ showProfileDashboard === true && (

             <div className="mb-20 px-0 sm:px-5 mx-auto max-w-screen-xl" style={{ flex: 1 }}>
                <div><button className='buttonTwo btnChange p-2 mt-5' onClick={backButton}>zurück</button></div>
                <h3 className='sm:mt-20'>Profile</h3>
                <div className="row row-cols-2 sm:flex justify-center">


                  <div className="flex flex-wrap justify-center items-center mt-4 space-x-2 space-y-2 sm:space-y-0 w-full ">
                    <div className="col-md-12 col-lg-12 col-xl-12 text-center " > 
                      {profiles.map((profile) => (
                        <button
                              key={profile}
                              onClick={() => handleProfileClick(profile)}
                              className={`p-3 m-1 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 ${getProfileStyle(profile)} ${firstLoginTrue[0] && localStorage.getItem('explainDone') === 'false' ? 'bg-slate-200 border-sky-600 border-4' : ''}`}
                            >
                              {profile}
                            </button>


                      ))}
                    </div>

                    <div className="min-sm:col-sm-12 flex-wrap max-sm:p-0 pt-10 w-full">
                      {selectedProfile === 'Autoren' && (
                        <div className="col-md-12 col-lg-12 col-xl-6 my-10 px-0 mx-auto">
                          <AuthorView firstLoginTrue={firstLoginTrue} setFirstLoginTrue={setFirstLoginTrue} baseUrl2={baseUrl2}/>
                        </div>
                      )}
                      {selectedProfile === 'Lektoren' && (
                        <div className="col-md-12 col-lg-12 col-xl-12 my-10 px-0 mx-auto">
                          <EditorView baseUrl2={baseUrl2} />   
                        </div>
                      )}
                      {selectedProfile === 'Testleser' && (
                        <div className="col-md-12 col-lg-12 col-xl-6 my-10 px-0 mx-auto">
                          <TestreaderView baseUrl2={baseUrl2}/>  
                        </div>
                      )}
                      {selectedProfile === 'Coverdesigner' && (
                        <div className="col-md-12 col-lg-12 col-xl-6 my-10 px-0 mx-auto">
                          <CoverDesignerView baseUrl2={baseUrl2} />
                        </div>
                      )}
                      {selectedProfile === 'Verlage' && (
                        <div className="col-md-12 col-lg-12 col-xl-6 my-10 px-0 mx-auto">
                          <PublisherView baseUrl2={baseUrl2}/> 
                        </div>
                      )}
                      {selectedProfile === 'Bücher' && (
                        <div className="col-md-12 col-lg-12 col-xl-6 my-10 px-0 mx-auto">
                          <BookView baseUrl2={baseUrl2} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

	)}

                <div className="mt-auto mb-20 ">
                      <Footer/>
                </div>
          </div>
    </div>
  );
}

export default Dashboard; 