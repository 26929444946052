import './CompTwoBenefits.css';

import { useEffect, useState } from 'react';

import { FaPlus } from "react-icons/fa";

import { FaPencilAlt } from "react-icons/fa";
import { FaGlasses } from "react-icons/fa6";
import { AiFillPicture } from "react-icons/ai";
import { FaBook } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";


function CompTwoBenefits() {

  
  return (
    <>{/*}
    <div className="CompTwoBenefits">
      <div className="py-5 text-center mx-5 lg:mx-40">
        <div className="text-center items-center justify-content-center h-100">
          <div className="-mt-10 mb-20">
            <h1 className='md:text-7xl'>Bluumer wurde geschaffen für ...</h1>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <div className="flex flex-col items-center justify-center mt-10 mx-10">
              <h2 className="text-blue-950 md:text-5xl">Autoren</h2>
              <div className="my-10">
                <FaBook className="transform scale-150" />
              </div>
              <ul className="text-justify">
                <li>
                  <p className="mt-2 mb-2 text-3xl">Du suchst Lektoren, Testleser oder Coverdesigner.</p>
                </li>
                <li>
                  <p className="mt-2 mb-2 text-3xl">Du willst deine Sichtbarkeit gegenüber Verlagen erhöhen.</p>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center justify-center mt-10 mx-10">
              <h2 className="text-blue-950">Lektor & Coverdesigner</h2>
              <div className="my-10">
                <FaPencilAlt className="transform scale-150" />
              </div>
              <ul className="text-justify">
                <li>
                  <p className="mt-2 mb-2 text-3xl">Du willst deine Reichweite bei Kunden steigern.</p>
                </li>
                <li>
                  <p className="mt-2 mb-2 text-3xl">Du willst mehr Anfragen als je zuvor!</p>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center justify-center mt-10 mx-10">
              <h2 className="text-blue-950">Testleser</h2>
              <div className="my-10">
                <FaGlasses className="transform scale-150" />
              </div>
              <ul className="text-justify">
                <li>
                  <p className="mt-2 mb-2 text-3xl">Du suchst Zugang zu hunderten nicht veröffentlichten Büchern.</p>
                </li>
                <li>
                  <p className="mt-2 mb-2 text-3xl">Du willst Geld sparen und gleichzeitig verborgene Juwelen entdecken.</p>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center justify-center mt-10 mx-10">
              <h2 className="text-blue-950">Verlag</h2>
              <div className="my-10">
                <TbWorld className="transform scale-150" />
              </div>
              <ul className="text-justify">
                <li>
                  <p className="mt-2 mb-2 text-3xl">Du willst Zeit und Geld sparen in der Akquise.</p>
                </li>
                <li>
                  <p className="mt-2 mb-2 text-3xl">Du suchst qualitative Manuskripte & Autoren.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>   */}
<div className="CompTwoBenefits">
  <div className="py-5 text-center mx-5 lg:mx-40">
    <div className="text-center items-center justify-center h-100">
      <div className="-mt-10 mb-20">
        <h1 className="md:text-7xl">Bluumer wurde geschaffen für ...</h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10"> {/* Increased gap for more space */}
        {[
          {
            title: 'Autoren',
            icon: <FaBook className="transform my-10 scale-150" />,
            list: [
              'Du suchst Lektoren, Testleser oder Coverdesigner.',
              'Du willst deine Sichtbarkeit gegenüber Verlagen erhöhen.',
            ],
          },
          {
            title: 'Lektor & Coverdesigner',
            icon: <FaPencilAlt className="transform sm:mb-10 max-sm:my-10 scale-150" />,
            list: [
              'Du willst deine Reichweite bei Kunden steigern.',
              'Du willst mehr Anfragen als je zuvor!',
            ],
          },
          {
            title: 'Testleser',
            icon: <FaGlasses className="transform my-10 scale-150" />,
            list: [
              'Du suchst Zugang zu nicht veröffentlichten Büchern.',
              'Du willst Geld sparen und verborgene Juwelen entdecken.',
            ],
          },
          {
            title: 'Verlag',
            icon: <TbWorld className="transform my-10 scale-150" />,
            list: [
              'Du willst Zeit und Geld sparen in der Akquise.',
              'Du suchst qualitative Manuskripte & Autoren.',
            ],
          },
        ].map((item, index) => (
          <div
            key={index}
            className="flex flex-col pb-10 items-center justify-center p-6 rounded-lg transition-transform transform hover:scale-105" // Added padding for more space
          >
            <div className='sm:mb-20'>
            <h2 className="text-black text-5xl md:text-4xl lg:text-5xl mb-2 text-center">{item.title}</h2>
            </div>
            <div className="">{item.icon}</div>
            <div className=''>
            <ul className="text-justify sm:text-left">
              {item.list.map((text, idx) => (
                <li key={idx} className="text-2xl lg:text-3xl mt-5">
                  <p className="mt-2 mb-2">{text}</p>
                </li>
              ))}
            </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>




    
    
    
    </>

            
 
    
  );
}

export default CompTwoBenefits;
export { default as CompTwoBenefits } from './CompTwoBenefits';