import './CompFreeSpaceSmall.css';


function CompFreeSpaceSmall() { 


  return (

<div className="CompFreeSpace text-center max-sm:mb:10 max-sm:pb-10 mt-10 pt-10 max-sm:pt-0">
	
      <div className="py-20">
        <div className=" pl-5 pr-12 px-4">
          <div className="flex flex-col lg:flex-row w-full items-center justify-center max-sm:mb:0">
          <div className="col-sm-1 px-40"></div>
            </div>
          </div>
        </div>
      </div>

  
  );
}

export default CompFreeSpaceSmall; 
export { default as CompFreeSpaceSmall } from './CompFreeSpaceSmall';