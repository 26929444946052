import './passwordReset.css';
import { Link } from "react-router-dom";

//Components
import { ResetPassword } from '../components/loginRegistration/ResetPassword';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';

const PasswordReset = ({baseUrl}) => {

  return (
      <div className="PasswordReset skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
          <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
                  <div style={{ flex: 1 }} >
                        <ResetPassword />
                  </div>
                  <div className="mt-auto mb-20 ">
                        <Footer/>
                  </div>
          </div>
    </div>
  );
}

export default PasswordReset; 