
import './othersProfile.css';
import { useNavigate} from "react-router-dom";
import { useEffect } from 'react';


//Components
import { OthersProfileAllView } from '../components/otherUserProfileComponents/OthersProfileAllView.js';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';
import {LogoutRedirectCheck} from '../tools/LogoutRedirectCheck';

const OthersProfile = ({baseUrl, baseUrl2}) => {

     ///////////////////////////////// Logout redirect //////////////////////////7

     const navigate = useNavigate();

      useEffect(() => {
        LogoutRedirectCheck(navigate);
      }, [navigate]);
   
   
   ///////////////////////////////// Logout redirect //////////////////////////7


  return (

    <div className="OthersProfile skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
        <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
              <div className="OthersProfileView" style={{ flex: 1 }}>
                <OthersProfileAllView baseUrl={baseUrl} baseUrl2={baseUrl2}/>
              </div>    
              <div className="mt-auto mb-20">
                <Footer/>
              </div>
          </div>
    </div>

  );
}

export default OthersProfile; 