
import { useEffect, useState } from 'react';



const ShortTimestampFormatter = ({timestamp}) => {
	const [formattedDate, setFormattedDate] = useState(null);
  
	useEffect(() => { 
		
		/*const [year, month, day, hour, minute, second, millisecond] = timestamp*/

		const [a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s] = timestamp;

		const year = a + b + c + d;
		const month = f + g;
		const day = i + j;
		const hour = l + m;
		const minute = o + p;
		const second = r + s; 

	    // Function to add leading zero to single-digit numbers
		const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

	/*	const formattedString =
		  addLeadingZero(hour) +
		  ":" +
		  addLeadingZero(minute) +
		  ":" +
		  addLeadingZero(second) +
		  " " +
		  addLeadingZero(day) +
		  "." +
		  addLeadingZero(month) +
		  "." +
		  year;   */


		  
		const formattedString =
		  hour +":" + minute +":" + second + " " + day + "." + month + "." + year;  

	  setFormattedDate(formattedString);
	}, [timestamp]);
  
	return (
	  <div>
		<p>{formattedDate}</p>
	  </div>
	)
  } 

  export default ShortTimestampFormatter; 
  export { default as ShortTimestampFormatter } from './ShortTimestampFormatter.js';