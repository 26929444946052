import './settings.css';
import { Link , useNavigate} from "react-router-dom";
import { useEffect, useState } from 'react';

//Components
import { ChangePassword } from '../components/settings/changePassword';
import { ChangeEmail } from '../components/settings/changeEmail';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';
import {LogoutRedirectCheck} from '../tools/LogoutRedirectCheck';

const Settings = ({baseUrl}) => {

///////////////////////////////// Logout redirect //////////////////////////7

const navigate = useNavigate();

useEffect(() => {
  LogoutRedirectCheck(navigate);
}, [navigate]);

///////////////////////////////// Logout redirect //////////////////////////7

  return (
      <div className="Settings skin-glassmorphism-background"  style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
          <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>   
              <div style={{ flex: 1 }}>
                <h2 className="m-5 p-5 text-center">Einstellungen</h2>
                <ChangePassword/>
                <ChangeEmail/>
              </div>
              <div className="mt-auto mb-20 ">
                    <Footer/>
              </div>
           </div>   
      </div>
  );
}

export default Settings; 