import './CompContact.css';

const CompContact = () => {

return (
<div className=''>
<div className="rowpaddingOne">
<h1 className='mb-20'>Kontakt & Impressum</h1>

<div className='col-sm-12'>
<div className='col-sm-1'></div>
<div className='col-sm-10'>


		<div className="row   pt-3 pb-3">
<div className='col-sm-3'></div>
<div className='col-sm-6'>
			<h4 className="text-justify">
				Die Plattform „Bluumer“ ist ein Produkt und im Besitz der
	<a href="https://www.aleveni.com" target="_blank" rel="noreferrer"> ALEVENI GmbH </a>
				und die Domain „bluumer.com“ wird von Ihr verwaltet.
			</h4>
			<br></br>

<h3 className="">Geschäftsführer und verantwortlich für den <br/>Inhalt nach § 55 Abs. 2 RStV:</h3>
<h4 className="">Manuel Krexner (manuel.krexner@aleveni.com)</h4>
<br></br>
<div className="col-sm-6">
<h3 className="">Firmenstandort</h3>
<h4 className="">Obere Hauptsraße11,</h4>
	<h4 className="">3123 Großrust,</h4>
			<h4 className="">Österreich</h4>
			</div>
<div className="col col-sm-6">
<h3 className="">E-Mail Adresse</h3>
<a href="mailto:info@bluumer.com"><h4 className="">info@bluumer.com</h4></a>
<h3 className="">Telefonnummer</h3>
<h4 className="">+43 6801267380</h4>
</div>
</div>
<div className='col-sm-3'></div>

	</div>
		<div className="row pt-3 pb-3">
<br></br>
<div className='col-sm-12'>
<div className='col-sm-3'></div>
<div className='col-sm-6'><hr className='horizontalLine' /></div>
<div className='col-sm-3'></div>
<br></br>
<br></br>
<h3 className="">Steuernummer / UID</h3>
<h4 className="">037371945 / ATU78807668</h4>
<br></br>
				<br></br>
<div className='col-sm-3'></div>
<div className='col-sm-6'><hr className='horizontalLine' /></div>
<div className='col-sm-3'></div>
				<br></br>
			</div>
		</div>
		<div className="row pt-3 pb-3">
			<div className="col-sm-12">
<h3 className="">Firmenbuchnummer</h3>
<h4 className="">FN 594057p</h4>
<br></br>
				<br></br>
<div className='col-sm-3'></div>
<div className='col-sm-6'><hr className='horizontalLine' /></div>
<div className='col-sm-3'></div>
				<br></br>
</div>
		</div>
		<div className="row pt-3 pb-3">
			<div className="col-sm-12">
<h3 className="">Bankverbindung</h3>
<h4 className="">IBAN: AT69 4300 0430 9891 7008</h4>
			<h4 className="">BIC: VBOEATWW</h4>
<br></br>
				<br></br>
<div className='col-sm-3'></div>
<div className='col-sm-6'><hr className='horizontalLine' /></div>
<div className='col-sm-3'></div>
				<br></br>
</div>
		</div>
		<div className="row  pt-3 pb-3">
			<div className="col-sm-12">
<h3 className="">Behörden</h3>
<h4 className="">Eingetragen beim Landesgericht: Schießstattring 6, 3100 St. Pölten</h4>
<h4 className="">Aufsichtsbehörde: BH St. Pölten, Gewerbereferat: 3100 St.Pölten, Am Bischofteich 1</h4>
<h4 className="">Kammerzugehörigkeit: Wirtschaftskammer Niederösterreich</h4>
	</div>
		</div>
		<br></br>
	<br></br>
<div className='col-sm-3'></div>
<div className='col-sm-6'><hr className='horizontalLine' /></div>
<div className='col-sm-3'></div>
				<br></br>
		<div className="row  pt-3 pb-3">
			<div className="col-sm-12">
<h3 className="">Haftungshinweis</h3>
<h4 className="">Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine</h4>
<h4 className="">Haftung für Inhalte externer Links. Für den Inhalt der verlinkten Seiten</h4>
<h4 className="">sind ausschließlich deren Betreiber verantwortlich.</h4>
	</div>
		</div>
		<br></br>
	<br></br>
<div className='col-sm-3'></div>
<div className='col-sm-6'><hr className='horizontalLine' /></div>
<div className='col-sm-3'></div>
				<br></br>
		<div className="row  pt-3 pb-3">
			<div className="col-sm-12">
<h3 className="">Streitschlichtung</h3>
<h4 className="">Die Europäische Kommission stellt eine Plattform </h4>
<h4 className="">zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a></h4>
	</div>
		</div>
</div>
<div className='col-sm-1'></div>
</div>
</div>
<div className='row '></div>
</div>

    );
}

export default CompContact;

export{ default as CompContact } from './CompContact';
