
import './OthersProfileTestreaderView.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import { FaPencilAlt } from "react-icons/fa";
import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";

import { BsChatDots } from "react-icons/bs";

import he from 'he';
import DOMPurify from 'dompurify';

function IconWithTooltip2({ icon, tooltipText }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className=""
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {React.cloneElement(icon, {
        //style: { fontSize: '' },
        title: isHovered ? tooltipText : '',
      })}
    </div>
  );
}

  function DisplayHtml({ htmlContent }) {   
    //const escapedHtml = he.escape(htmlContent);  only html visible and not in formated way visible!
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    );
}




const OthersProfileTestreaderView = ({ getUserProfileType, getCurrentUserProfileName, testReaderState, setTestReaderState, baseUrl, baseUrl2 }) => {


///////////////////////////////////////////////////////////////////////
    ////    Code for show Profile ...    //////////////////
    /////////////////////////////////////////////////////////////////////


    const [getSingleEntityData,setSingleEntityData] = useState([]);                                                                   
    const [getSingleBookprojectData,setSingleBookprojectData] = useState([]);
    const [getEntityRatings,setEntityRatings] = useState([]);                     
    const [getSingleEntityRating,setSingleEntityRating] = useState([]); 
    
    const [ratingScoreAverage, setRatingScoreAverage] = useState();
    
    const [imageSrc, setImageSrc] = useState([]);
    const [getFavoriteHrefClick, setFavoriteHrefClick] = useState(false); 
    const [getAddRatingBtnClick, setAddRatingBtnClick] = useState(false); 
    const [ratingText, setRatingText] = useState(''); 

    const [getProfileType, setProfileType  ] = useState('Empty');

    const [expandedTexts, setExpandedTexts] = useState({});
    const [expandedColumn, setExpandedColumn] = useState('Author');
    const [expandedColumn2, setExpandedColumn2] = useState('Testreader');

    const [getLoggedInAuthorData,setLoggedInAuthorData] = useState([]);
    const [getLoggendInAuthorBookProjectIds,setLoggendInAuthorBookProjectIds] = useState([]);  
    const [getAddUDRBtnClick, setAddUDRBtnClick] = useState(false);

    const [getRightsData, setRightsData] = useState([]); 
    const [getChatRightsData, setChatRightsData] = useState([]); 
    

    const [getLoggedInProfileData, setLoggedInProfileData] = useState([]);
    const [getSingleUserProfileEntityData, setSingleUserProfileEntityData] = useState([]);
    const [render, setRender] = useState(false);  
    const [minusPlusFavorit, setMinusPlusFavorit] = useState(<><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);

    const [ratingScore, setRatingScore] = useState('');

    const [getTrueGenreData, setTrueGenreData] = useState([]);

    const [error, setError] = useState(null);

      ////////////////////////// First Login Explanation//////////////////////

      const [firstLoginTrue, setFirstLoginTrue] = useState(new Array(6).fill(false));
  
      const getUserFirstLogin = async () => {
     
       try {
    
         const response = await api.get(`/api/v1/auth/user/${localStorage.getItem(`userEmail`)}`, {
           headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
         });
    
         if(response.data !== null || response.data !== ''){
         const user = response.data;
    
         //console.log('response.data: ', response.data);
         //console.log('user.lastLoginAt.lenght : ', user.lastLoginAt.length );
    
         if(user.lastLoginAt.length >= 2 && user.lastLoginAt.length <= 3 && localStorage.getItem('explainDone3') === 'false'){
               //console.log('profileType: ', user.userProfilesIds[0].userProfileType);
             if(user.userProfilesIds[0].userProfileType !== 'Author'){
               updateElements(3,true,1,true);
             }else{
               updateElements(3,true,0,true);
             }
         
         }else{
          updateElements(0,false,3,false);
           
         }
       }
    
    
       } catch (error) {
         handleError('Error fetching userLoginData:', error);
       }
     };
    
     const updateElements = (currentIndex, currentState, nextIndex, nextState) => {
      const updatedArray = [...firstLoginTrue];
      // Set the current index to the desired state
      updatedArray[currentIndex] = currentState;
      // Set the next index to the desired state
      updatedArray[nextIndex] = nextState;
      //console.log('nextIndex: ', nextIndex);
  
      if(nextIndex === 3 ){
        localStorage.setItem('explainDone3', 'true'); 
        //console.log(localStorage.getItem('explainDone3'));
      }
  
      // Update state with the modified array
      setFirstLoginTrue(updatedArray);
    };
    
    
      useEffect(() => { 
       getUserFirstLogin(); 
     }, []);
      
     
     ////////////////////////// First Login Explanation//////////////////////


    const toggleCollapse = (column) => {   
        setExpandedColumn(column);    
    };

    const toggleCollapse2 = (column) => {
        setExpandedColumn2(column);
    };
    
    const toggleTextExpansion = (createdByProfileName) => {
      setExpandedTexts((prevExpandedTexts) => ({
          ...prevExpandedTexts,
          [createdByProfileName]: !prevExpandedTexts[createdByProfileName],
      }));
  };


  const getSingleUserProfileEntity = async () => {

    try {
        const addNewEntityData = {
          entityName: getCurrentUserProfileName,
        };

        const response = await api.get(`/api/userProfile/${addNewEntityData.entityName}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        });

        setSingleUserProfileEntityData(response.data);

    } catch (error) {
        handleError('Error fetching single Entity data:', error);
    }
  };

    const getSingleEntity = async () => {
        try {
            const addNewEntityData = {
              entityName: getCurrentUserProfileName,
            };
    
            const response = await api.get(`/api/testReaders/${addNewEntityData.entityName}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });
    
            if(response.data != null){
            setSingleEntityData(response.data);
            const singleEntity = response.data;
            setSingleBookprojectData(singleEntity.prevBookProjectsIds);
            showSingleRatings(singleEntity.testReaderName);

	    setTestReaderState(false);

      const filteredGenres = Object.keys(singleEntity.genresIds[0])
      .filter(key => key !== '_id' && singleEntity.genresIds[0][key] === true);

       const groupedData = filteredGenres.reduce((acc, item, index) => {
      const chunkIndex = Math.floor(index / 2);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = []; // start a new chunk
      }
      acc[chunkIndex].push(item);
      return acc;
    }, []);

    setTrueGenreData(groupedData);

          }
        } catch (error) {
            handleError('Error fetching single Entity data:', error);
        }
    };
    

	const getLoggedInAuthorEntity = async () => {
        try {

            const userProfileType = localStorage.getItem('currentProfileType');
    

            if(userProfileType === 'Author'){

            	const addNewLoggedInAuthorData = {
              	  entityName: localStorage.getItem('profileName'),
            	};
            
            	const response = await api.get(`/api/authors/${addNewLoggedInAuthorData.entityName}`, {
            	    headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
           	 });

            setLoggedInAuthorData(response.data);
            setLoggendInAuthorBookProjectIds(response.data.bookProjectsIds);
	    }
    

        } catch (error) {
            handleError('Error fetching single Entity data:', error);
        }
    	};


    const getEntityChatRights = async () => {
        try {
            const addNewEntityData = {
              entityName: localStorage.getItem('profileName'),
            };
    
            const response = await api.get(`/api/userProfile/${addNewEntityData.entityName}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });
    
            if(response.data != null){
            	setChatRightsData(response.data.allRights[0]);
            }
          
        } catch (error) {
            handleError('Error fetching single Entity data:', error);
        }
    };

    const getEntityRights = async () => {
      try {
          const addNewEntityData = {
            entityName: getCurrentUserProfileName,
          };
  
          const response = await api.get(`/api/userProfile/${addNewEntityData.entityName}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });
  
          if(response.data != null){
            setRightsData(response.data.allRights[0]);
          }
        
      } catch (error) {
          handleError('Error fetching single Entity data:', error);
      }
  };

    
    const showSingleRatings = async (entityName) => {
        try {
            const response = await api.get(`/api/ratings/createdFor/${entityName}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });
    
            setSingleEntityRating(response.data);
    
            const sumOfRatingScores = response.data.reduce((sum, item) => sum + item.ratingScore, 0);
            const averageRatingScore = sumOfRatingScores / response.data.length;
            const roundedAverageRatingScore = averageRatingScore.toFixed(1);
    
            if (response.data.length > 0) {
                setRatingScoreAverage(parseFloat(roundedAverageRatingScore));
            } else {
                setRatingScoreAverage('');
            }
        } catch (error) {
            handleError('Error showing single ratings:', error);
        }
    };
    
    const changeFavorite = async () => {
      try {
          setFavoriteHrefClick(true);

          const userProfileType = localStorage.getItem('currentProfileType');

          if(userProfileType === 'Author'){
            setProfileType('authors');
          }
          if(userProfileType === 'Editor'){
            setProfileType('editors');
          } 
          if(userProfileType === 'Testreader'){
            setProfileType('testReaders');
          }
          if(userProfileType === 'Publisher'){
            setProfileType('publishers');
          }
          if(userProfileType === 'CoverDesigner'){
            setProfileType('coverDesigners');
          }
            
          
      } catch (error) {
          handleError('Error changing favorite:', error);
      }
  };

   useEffect(() => {
    
    const addNewFavoriteData = {
      entityName: localStorage.getItem('profileName'),
      favoriteName: getCurrentUserProfileName,
  };

    // Make API call when profileType changes
    if (getProfileType && getProfileType !== '' && getFavoriteHrefClick === true) {
      const fetchData = async () => {
        try {

          const response = await api.post(`/api/${getProfileType}/changeFavorite`, addNewFavoriteData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });
          //console.log('Favorite:', response.data);
          setLoggedInProfileData(response.data);

          const isFavorite = response.data.favoriteIds.some(favorite => favorite.userProfileName === getCurrentUserProfileName);
        
          if (isFavorite) {
            setMinusPlusFavorit( <><FaMinusSquare className='mr-2 mt-1.5'/> Favorit</>);
          } else {
            setMinusPlusFavorit( <><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);
          }
          
        } catch (error) {
          handleError('Error changing favorite:', error);
        }
      };
      fetchData();
    }         

  }, [getProfileType, getFavoriteHrefClick]);

    
    const addRating = async (e) => {
        e.preventDefault();
    
        const { target } = e;
    
        const addNewRatingData = {
            ratingScore: target.ratingScore.value,
            ratingText: target.ratingText.value,
            createdByProfileName: localStorage.getItem('profileName'),
            isRatingForProfileName: getCurrentUserProfileName,
        };
    
        console.log('addNewFavoriteData:', addNewRatingData);
    
        setAddRatingBtnClick(false);
    
        try {
            const response = await api.post(`/api/ratings/createRating`, addNewRatingData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });
    
            window.location.reload();
           // console.log('Rating:', response.data);
        } catch (error) {
            handleError('Error adding rating:', error);
        }
    };
    

    const handleRatingTextChange = (event) => {
        setRatingText(event.target.value);
    };


    const changeUpDownRight = async (rightsOwnerName, whichRights, authorName ) => {
      try {
  
        if(localStorage.getItem('logintoken') === 'Please Log in'){
          setAddUDRBtnClick(!getAddUDRBtnClick);
        }else{
          
          const addNewUDRData = {
              rightsOwnerName: rightsOwnerName,
              rightsGiveToName: getCurrentUserProfileName,
              whichRights: whichRights,
              authorName: authorName
          };
  
          const response = await api.post(`/api/rights/update`, addNewUDRData, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
          });
  
           setAddUDRBtnClick(false);
  
          console.log('UpDownRightName:', response.data);
        }
      } catch (error) {
          handleError('Error changing UpDownRight:', error);
      }
  };
    
    const changeOtherProfileName = async (otherProfileName, otherProfileType) => {
        localStorage.setItem('siteProfile', otherProfileName);
        localStorage.setItem('otherProfileType', otherProfileType);
        setFavoriteHrefClick(true);
    };


    const renderFavorite = () => {
      // Check if favoriteIds exist and are not empty
      if (getLoggedInProfileData && getLoggedInProfileData.favoriteIds && getLoggedInProfileData.favoriteIds.length > 0) {
        // Check if the current user's profile name is in the favoriteIds
        const isFavorite = getLoggedInProfileData.favoriteIds.some(favorite => favorite.userProfileName === getCurrentUserProfileName);
        //console.log('isFavorite: ', isFavorite);
        if (isFavorite) { setMinusPlusFavorit( <><FaMinusSquare className='mr-2 mt-1.5'/> Favorit</>);
        } else {  setMinusPlusFavorit( <><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);
        }
      } else { // Handle the case where favoriteIds is empty or undefined
        setMinusPlusFavorit('Lade Favorit Status');
        reRenderTwo();
      }
    };

    const renderFavoriteButton = () => {
      if (getLoggedInProfileData && getLoggedInProfileData.favoriteIds && getLoggedInProfileData.favoriteIds.length > 0) {
        const isFavorite = getLoggedInProfileData.favoriteIds.some(favorite => favorite.userProfileName === getCurrentUserProfileName);    
        if (isFavorite) { setMinusPlusFavorit( <><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);
        } else {  setMinusPlusFavorit( <><FaMinusSquare className='mr-2 mt-1.5'/> Favorit</>);
        }
      } else { // Handle the case where favoriteIds is empty or undefined
        setMinusPlusFavorit('Lade Favorit Status');
        reRenderTwo();
      }
    };

    const renderChatButton = () => {
      // Check if getRightsData and getRightsData.toChat exist and are not empty
      if (getChatRightsData && getChatRightsData.toChat && getChatRightsData.toChat.length > 0 && getSingleUserProfileEntityData && getSingleUserProfileEntityData.id) {
        // Check if any of the timestamps match the localStorage value
        const hasChatRight = getChatRightsData.toChat.some(rightFor => rightFor.timestamp.toString() === getSingleUserProfileEntityData.id.timestamp.toString());

        if (hasChatRight) {
          return (
            <div className="mx-2 FormStyling text-right">
              <IconWithTooltip2 icon={ 
                    <button className="buttonTwo btnChange p-2 flex  hover:scale-105" onClick={() => {changeUpDownRight(localStorage.getItem('profileName'), 'toChat', null); reRenderTwo()}}>
                  <FaMinusSquare className='mr-2 mt-1.5' />Recht zu Chatten
                  </button>
            } tooltipText="Entferne das Recht mit dir zu schreiben." />                          
            </div>
          );
        } else {
          return (
            <div className="mx-2 FormStyling text-right">
           <IconWithTooltip2 icon={ 
              <button className={`buttonTwo btnChange p-2 flex hover:scale-105 ${firstLoginTrue[2] ? 'bg-slate-200 border-sky-600 border-4' : ''}`} onClick={() => {changeUpDownRight(localStorage.getItem('profileName'), 'toChat', null); reRenderTwo()}}>
              <FaPlusSquare className='mr-2 mt-1.5' />Recht zu Chatten
              </button>
            } tooltipText="Füge das Recht mit dir zu schreiben hinzu." />

            </div>
          );
        }
      } else {
        // Handle the case where toChat is empty or undefined
        return (
          <div className="mx-2 FormStyling text-right">
           <IconWithTooltip2 icon={ 
              <button className={`buttonTwo btnChange p-2 flex hover:scale-105 ${firstLoginTrue[2] ? 'bg-slate-200 border-sky-600 border-4' : ''}`} onClick={() => {changeUpDownRight(localStorage.getItem('profileName'), 'toChat', null); reRenderTwo()}}>
              <FaPlusSquare className='mr-2 mt-1.5' />Recht zu Chatten
              </button>
            } tooltipText="Füge das Recht mit dir zu schreiben hinzu." />
          </div>
        );
      }
    };

  const changeFavoriteButton = async () => {

    const userProfileType = localStorage.getItem('currentProfileType');

          if(userProfileType === 'Author'){
            setProfileType('authors');
          }
          if(userProfileType === 'Editor'){
            setProfileType('editors');
          } 
          if(userProfileType === 'Testreader'){
            setProfileType('testReaders');
          }
          if(userProfileType === 'Publisher'){
            setProfileType('publishers');
          }
          if(userProfileType === 'CoverDesigner'){
            setProfileType('coverDesigners');
          }

        try {
          if(getProfileType !== ''){
      const response2 = await api.get(`/api/${getProfileType}/${localStorage.getItem('profileName')}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}`}}
      );
   
      //console.log('Response2 Data:', response2.data);
      setLoggedInProfileData(response2.data);

      const isFavorite = response2.data.favoriteIds.some(favorite => favorite.userProfileName === getCurrentUserProfileName);
        
      if (isFavorite) {
        setMinusPlusFavorit(<><FaMinusSquare className='mr-2 mt-1.5'/> Favorit</>);
      } else {
        setMinusPlusFavorit(<><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);
      }
    }
    } catch (error) {
      handleError('Error changing favorite2:', error);
    }

  }

  const handleRatingScoreInputChange = (event) => {
    const { value } = event.target;
    // Allow only numbers and a single decimal point
    const regex = /^(\d+(\.\d{0,1})?)?$/;

    if (regex.test(value) && (value === '' || parseFloat(value) <= 100)) {
      setRatingScore(value);
    }
  };

  const changeToChat = (entityName, e) => {
    e.stopPropagation();
    localStorage.setItem('chatEntityName', entityName);
  }
    

    const reRenderTwo = () => {
     setTimeout(() => {
        setRender(!render);            
      }, 300);
    }

    useEffect(() => {
      changeFavoriteButton();
      getSingleUserProfileEntity();
      getEntityRights();
      getEntityChatRights()
  }, [render]);


    useEffect(() => {
      const fetchData = async () => {
        try {
                if (getUserProfileType === 'Testreader') {
                    const entityData = getSingleEntity();
                    if(entityData != null){
                    setSingleEntityData(entityData);
                    console.log('Testreaders:', entityData);
                    }
                  }
        } catch (error) {
            handleError('Error adding rating:', error);
        }
    };
        getLoggedInAuthorEntity();
        getEntityRights();
        getEntityChatRights() 
        fetchData();
      getSingleUserProfileEntity();
      setTimeout(() => {
        renderFavorite();           
      }, 3000);
  }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (getUserProfileType === 'Testreader') {
                    const entityData = getSingleEntity();
                    if(entityData != null){
                    setSingleEntityData(entityData);
                    console.log('Testreaders:', entityData);
                    }
                }
            } catch (error) {
                handleError('Error adding rating:', error);
            }
        };
        getLoggedInAuthorEntity();
        getEntityRights();
        getEntityChatRights() 
        fetchData();
        setImageSrc(`${baseUrl}/api/files/image/`);
        setExpandedColumn('Author');
        setExpandedColumn2('Testreader');
    }, [getUserProfileType]);
    


      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

    	///////////////////////////////// Logout redirect //////////////////////////7

        const navigate2 = useNavigate();

        useEffect(() => {
          LogoutRedirectCheck(navigate2);
          setFavoriteHrefClick(false);
          /*setAddUDRBtnClick(false);
          setAddRatingBtnClick(false); */
        }, [getFavoriteHrefClick, getAddRatingBtnClick, getAddUDRBtnClick  ]);
        
        ///////////////////////////////// Logout redirect //////////////////////////7





        return (
            <div className="OthersProfileTestreaderView">
              <div className={`${getAddRatingBtnClick ? 'blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden' : ''} `}></div>
          
              {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}


              { testReaderState === false && firstLoginTrue[3] === true && localStorage.getItem('explainDone3') === 'false' && (
                <div className='z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
            <div 
              className={`loadingBox white-glassmorphism-loadingBox bg-slate-200 border-sky-600 border-4 text-center fixed top-2/3 max-sm:top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30`} 
              style={{ minWidth: '250px', maxWidth: '550px', minHeight:'100px' }}
            >
              <div className="flex justify-content-center align-items-center w-full h-full">
                <div className='m-5'>
                  { firstLoginTrue[0] === true && (
                    <>   <h6 className='text-2xl'>Sobald du ein Buch erstellt hast, erscheint neben dem Button "Favoriten" ein eigener Button "Rechte vergeben". Hier kannst du einem anderen Profil die Rechte für eines deiner Bücher übertragen. Das Profil erhält dann die Berechtigung, deine Buchdatei herunterzuladen sowie dich und dein Buch zu bewerten.</h6>
                                  <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                                  type="button"  onClick={() => updateElements(0, false, 1, true)}>Weiter</button> </>
                  )}
                  { firstLoginTrue[1] === true && (
                    <>   <h6 className='text-2xl'>Mit diesem Button fügst du das Profil zu deinen Favoriten hinzu. Das Profil erhält einen zusätzlichen Follower, und du kannst deine Favoriten über die Ansicht 'Mein Profil' verwalten und schneller wiederfinden.</h6>
                                  <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                                  type="button"  onClick={() => updateElements(1, false, 2, true)}>Weiter</button> </>
                  )}
                  { firstLoginTrue[2] === true && (
                    <>   <h6 className='text-2xl'>Mit diesem Button kannst du dem Profil das Recht geben, dir Nachrichten zu senden – oder, falls du selbst die entsprechenden Rechte hast, das Profil direkt anschreiben. Die Übersicht zu den Rechten findest du im Reiter 'Spielregeln'.</h6>
                                  <button className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
                                  type="button"  onClick={() => {updateElements(2, false, 3, true)}}>Fertig</button> </>
                  )}

                </div>               
              </div>
            </div>
          </div>
          )}
          
              {getAddRatingBtnClick && (
                  <div className='blurPage text-center fixed top-0 left-0 w-full h-full flex justify-center items-center z-30'>
                    <div className='mx-2 FormStyling' style={{ minWidth: '350px', maxWidth: '1000px', maxHeight: '90vh' }}>
                      <div><button className='buttonTwo btnChange p-2' onClick={() => setAddRatingBtnClick(false)}>zurück</button></div>
                      <form onSubmit={addRating} method='POST' className='w-full justify-center items-center text-center'>
                        <div className='w-full justify-center items-center text-center'>
                          <h3 className='text-center'>Rating-Daten</h3>
                          <div className='row'>
                            <div className='text-center'>
                              <h4 className='text-center'>Dein Rating-Wert von 0.0 - 100.0</h4>
                              <input
                                className='FormStyling text-center rounded-lg text-white compThreeBGColor'
                                placeholder='Wert...'
                                name='ratingScore'
                                value={ratingScore}
                                onChange={handleRatingScoreInputChange}
                                style={{ minWidth: '100px', maxWidth: '100px' }}
                              />
                            </div>
                          </div>
                          <div className='row FormStyling mx-10 px-10'>
                            <div className='text-center' style={{ minWidth: '300px', minHeight: '300px', maxWidth: '1000px' }}>
                              <div className='row flex-grow-1 textAreaOne'>
                                <h4 className='text-center'>Kommentar</h4>
                                <textarea
                                  className='FormStyling w-full flex-grow-1 rounded-lg text-white compThreeBGColor'
                                  placeholder='Dein Text...'
                                  name='ratingText'
                                  style={{ overflowY: 'auto', overflowX: 'auto', maxHeight: '200px', maxWidth: '900px', minHeight: '180px', minWidth: '330px' }}
                                  value={ratingText}
                                  onChange={handleRatingTextChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <button type='submit' className='btnChange p-3 mb-10'>Bestätigen</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}



     {getAddUDRBtnClick && getLoggendInAuthorBookProjectIds && getLoggendInAuthorBookProjectIds.length > 0 && (
        <div>
         <div>
          <div className='blurPage z-20 fixed  inset-0 h-screen w-screen overflow-hidden'>
          </div>
          <div className='loadingBox compThreeBGColor minWidthRights flex flex-col  fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30'>
              <div className="w-full">
              <div className="mx-2 FormStyling ">
                  <div><button className="buttonTwo btnChange p-2" onClick={() => setAddUDRBtnClick(false)}>zurück</button></div>
                </div>  
                  <h3 className="text-center text-white max-sm:text-4xl">Für welches Buch sollen die Rechte vergeben werden:</h3>
                  { getLoggendInAuthorBookProjectIds.map((book, index) => (
				                <div key={book.title} className="row ">
				                   <div className="mx-2 FormStyling text-left "><h4 className="max-sm:ml-20 sm:ml-56 cursor-pointer bookChoicehover text-3xl waitingListColor" onClick={() => changeUpDownRight(book.title, 'Testreader', localStorage.getItem('profileName'))}>#{index+1} {book.title}</h4></div>
				                </div>
		                ))}
              </div>   
          </div>
       </div>
      </div> 
      )}
          
              
          <div className='container'>
                {getUserProfileType === "Testreader" && (
                  <div className=" ">
                    <div className="col">
                      {getSingleEntityData && (
                        <><div className="row">
                      </div>
                      
                      <div className="row profileDataCard2  paddingOne">
                          <div className="row titleRowBackgroundcolor white-glassmorphism-card ">
                          <div className="col col-sm-12 sm:col-sm-12 max-sm:text-center sm:flex md:col-sm-6">
                                 <h2 className="FormStyling md:text-left  titleTextColor">{getCurrentUserProfileName}</h2>
                                 {localStorage.getItem('profileName') !== getCurrentUserProfileName && ( 
                                      <a 
                                        href="/chat" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        onClick={(e) => changeToChat(getCurrentUserProfileName, e)}
                                      >
                                       <IconWithTooltip2 icon={<button className="p-2 mt-6 mx-3 bg-gray-600 text-white rounded">
                                          <BsChatDots />
                                        </button>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                                          
                                      </a>
                                      )}
                                      </div>
                      </div>

        <div className=''>
                            
		     { getCurrentUserProfileName !== localStorage.getItem('profileName') && (
            <div className='row sm:flex -pt-8 white-glassmorphism-card'> 
                <div className='col col-sm-12 sm:col-sm-5 md:justify-start md:col-sm-5 justify-center flex max-md:pb-5'>
                  <h4 className="FormStyling md:text-left pl-5 pt-3 titleTextColor"> | {getSingleEntityData.follower} Follower</h4>
                </div>  
                <div className='col col-sm-12 sm:col-sm-4 md:justify-end md:col-sm-4 justify-center flex max-md:pb-5'>

              
                { getRightsData && getRightsData.toRate && getRightsData.toRate.map((rightFor, index) => (
				                <div key={rightFor.timestamp.toString()}>
                          {rightFor.timestamp.toString() === localStorage.getItem(`userProfileIdTimestamp`)  && (
				 	                        <div className="mx-2 FormStyling text-right">
                                                            <IconWithTooltip2 icon={     <button className="buttonTwo btnChange p-2 flex hover:scale-105" onClick={() => setAddRatingBtnClick(true)}><FaPlusSquare className='mr-2 mt-1.5' />Rating</button>
                            } tooltipText="Füge dem Profil ein Rating von dir hinzu." />
                      
                                    </div>
                           		)}
                        </div>
                      ))}

                    <>{localStorage.getItem('currentProfileType') === "Author" && getLoggendInAuthorBookProjectIds && getLoggendInAuthorBookProjectIds.length > 0 && (
                      <div className="mx-2 lg:-mr-20 FormStyling text-right">
                        <IconWithTooltip2 icon={      <button className={`buttonTwo btnChange p-2 flex hover:scale-105 ${firstLoginTrue[0] ? 'bg-slate-200 border-sky-600 border-4' : ''}`} onClick={() => setAddUDRBtnClick(true)}><FaPencilAlt className='mr-2 mt-1.5' />Rechte vergeben</button>
                            } tooltipText="Entferne oder Füge ein Rechte-Set für ein bestimmtes Buch hinzu. Das Rechte-Set ist vorausgewählt jenachdem welchem Profil du Rechte vegibst." />
                      </div>
                    )} </> 

                </div>
                <div className='col col-sm-12 sm:col-sm-3 md:justify-end md:col-sm-3 justify-center flex max-md:pb-5'> 

                    <div className="mx-2 FormStyling text-right">
              <IconWithTooltip2 icon={    <button className={`buttonTwo btnChange p-2 px-4 flex hover:scale-105 ${firstLoginTrue[1] ? 'bg-slate-200 border-sky-600 border-4' : ''}`}  onClick={()=> {changeFavorite(); renderFavoriteButton(); }}>{minusPlusFavorit}</button>
                            } tooltipText="Entferne oder Füge dieses Profil deinen Favoriten hinzu." />
                            </div> 

                              {renderChatButton()}

                    </div>                 
                </div>   
          )}
          </div>

                          <hr className='horizontalLine' />
                          <div className="row mainRowBackgroundcolor sm:flex white-glassmorphism-card pt-3 pb-3">
                            <div className='col col-sm-8'>
                              <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                              <div className='max-md:mb-20  max-lg:w-full' style={{minWidth: 200}}>
                                <h3 className="FormStyling ">Name</h3>
                                <h5 className="FormStyling ">{getSingleEntityData.testReaderName?getSingleEntityData.testReaderName:' - '}</h5>
                                <br></br>
                                <h3 className="FormStyling ">Alter</h3>
                                <h5 className="FormStyling ">{getSingleEntityData.age?getSingleEntityData.age:' - '}</h5>
                              </div>
                            </div>
                            <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                      <div className='max-sm:mb-10 max-lg:w-full' style={{ minWidth: 200 }}>
                      {/*}  <h3 className="FormStyling">Website</h3>
                        <h5 className="FormStyling">
                          {getSingleEntityData.website ? getSingleEntityData.website : ' - '}
                        </h5>
                        <br />  */}
                        <h3 className="FormStyling">Genres</h3>
                        <div 
                          className='genre-container' 
                          style={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            justifyContent: 'flex-start',  /* Align left by default */
                            textAlign: 'left'               /* Left align text by default */
                          }}
                        >
                          {getTrueGenreData && getTrueGenreData.map((group, groupIndex) => (
                            <div 
                              key={groupIndex} 
                              className='genre-group' 
                              style={{ 
                                display: 'inline-block', 
                                margin: '0 0px' 
                              }}
                            >
                              {group.map((key, index) => (
                                <span key={index} className="FormStyling capitalize">
                                  {key}{index < group.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                              <br />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                            </div>
                            <div className='col col-sm-12'>
                              <div className="col col-sm-6 max-lg:w-full text-center md:text-left max-md:mb-20">
                                <div className="row  textAreaOne">
                                  <h3 className="FormStyling">Social Media Accounts</h3>
                                  {getSingleEntityData.socialMediaAccounts && getSingleEntityData.socialMediaAccounts.map((account, index) => (
                                    <h5 key={index} className="FormStyling">{account}</h5>
                                  ))}
                                </div>
                              </div>
                              <div className='col-sm-6 flex text-center justify-center items-center '>
                                <div className="profileImg  my-3  ">
                                  <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }}>
                                    <img className="img-fluid profileImg3" src={`${imageSrc}${getSingleEntityData.profileImg}`} alt="Dein Profilbild"
                                      style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div></>
                 )}
          
                {getSingleEntityData && (getSingleEntityData.experience && getSingleEntityData.experience.length > 0) && (
                  <div className="row profileDataCard2  paddingOne">
                    <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                      <h2 className="FormStyling text-left titleTextColor">Erfahrung</h2>
                    </div>
                    <hr className='horizontalLine' />
                    <div className="row mainRowBackgroundcolor white-glassmorphism-card">
                          <div className="col col-sm-12 text-left ml-5 mainRowBackgroundcolor py-5">
                              <DisplayHtml htmlContent={getSingleEntityData.experience} />
                          </div>
                      </div>
                  </div>
                )}
              </div>
          
                      {getSingleBookprojectData && (getSingleBookprojectData.length > 0) && (
                        <div className="row profileDataCard2  paddingOne">
                          <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                            <h2 className="FormStyling text-left titleTextColor">Buchprojekte</h2>
                          </div>
                          <div className="row titleRowBackgroundcolor2 white-glassmorphism-card ">
                            <div className="col col-sm-3 text-left"><h3 className="topRowStyling max-md:hidden">Titel</h3></div>
                            <div className="col col-sm-6 text-left"><h3 className="topRowStyling  max-md:hidden">Beschreibung</h3></div>
                            <div className="col col-sm-3 text-left"><h3 className="topRowStyling  max-md:hidden">Seitenanzahl</h3></div>
                          </div>
                          <hr className='horizontalLine' />

                          {getSingleEntityData.prevBookProjectsIds && getSingleEntityData.prevBookProjectsIds.map((bookProjects, index) => (
                            <div key={bookProjects.title}>
                              {bookProjects.ratingsReceivedIds.find(
                                  (rating) => rating.createdByProfileName === getCurrentUserProfileName
                                ) && (
                                  <div key={bookProjects.ratingsReceivedIds.find(
                                    (rating) => rating.createdByProfileName === getCurrentUserProfileName
                                  ).id}>
                              <div className='max-md:divide-y-3 '>
                              {index !== 0 && (<hr className='horizontalLine2 max-md:hidden' />)}
                              <div className="row mainRowBackgroundcolor2 paddingThree white-glassmorphism-card ">
                                <div className="col col-sm-3 text-left max-md:flex"><h3 className="FormStyling md:hidden">Titel: </h3>
                                  <h4 className="FormStyling max-md:pt-2 max-md:pl-2">
                                                  <a
                                                  href={`${baseUrl2}/profile?othProfileName=${bookProjects.title}&othProfileType=Book`}
                                                    target="_blank"
                                                      rel="noreferrer"
                                                      onClick={() => changeOtherProfileName(bookProjects.title, 'Book')}
                                                  >
                                                    {bookProjects.title}
                                                  </a>		
                                  </h4>
                              </div>
                              <div className="col col-sm-6 text-left"><h3 className="FormStyling md:hidden">Beschreibung: </h3><h5 className="FormStyling max-md:pt-2 max-md:pl-2"><DisplayHtml htmlContent={bookProjects.jacketText} /></h5></div>
                                            <div className="col col-sm-3 text-left max-md:flex"><h3 className="FormStyling md:hidden">Seitenanzahl: </h3><h4 className="FormStyling max-md:pt-2 max-md:pl-2">{bookProjects.pages}</h4></div>
                                            </div>
                                          </div>
                                          </div>
                          )}
                        </div>
                      ))}
                      <div className="row mainRowBackgroundcolor paddingOne white-glassmorphism-card"><br></br></div>
                    </div>
                  )}
                            
           {getSingleEntityData.favoriteIds && (getSingleEntityData.favoriteIds.length > 0) && (
             <div className="row profileDataCard2  paddingOne white-glassmorphism-card">
            	<div className="row row-col-3 titleRowBackgroundcolor  ">
              <div className="col col-sm-12 text-left paddingTwo"><h2 className="FormStyling text-left titleTextColor">Favoriten</h2></div>
            </div>
  
             <div className="row flex w-full titleRowBackgroundcolor2 ">
              <div className='col col-sm-12 xl:flex max-xl:pl-6 w-full'>
                <div className={`xl:col-sm-3 flex max-xl:text-left`}  style={{ width: expandedColumn === 'Author' ? '33%' : '33%' }} onClick={() => toggleCollapse('Author')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Author'? 'favoriteColor ':''}`}>Autoren{ expandedColumn === 'Author' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-3 flex max-xl:text-left'  style={{ width: expandedColumn === 'Book' ? '33%' : '33%' }} onClick={() => toggleCollapse('Book')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Book'? 'favoriteColor ':''}`}>Bücher{ expandedColumn === 'Book' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-3 flex max-xl:text-left'  style={{ width: expandedColumn === 'Editor' ? '33%' : '33%' }} onClick={() => toggleCollapse('Editor')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Editor'? 'favoriteColor ':''}`}>Lektoren{ expandedColumn === 'Editor' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
              </div>
              <div className='col col-sm-12 xl:flex w-full'>
                <div className='xl:col-sm-4 flex max-xl:text-left'  style={{ width: expandedColumn === 'Testreader' ? '33%' : '33%' }} onClick={() => toggleCollapse2('Testreader')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'Testreader'? 'favoriteColor ':''}`}>Testleser{ expandedColumn2 === 'Testreader' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-4 flex max-xl:text-left'  style={{ width: expandedColumn === 'CoverDesigner' ? '48%' : '48%' }} onClick={() => toggleCollapse2('CoverDesigner')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'CoverDesigner'? 'favoriteColor ':''}`}>CoverDesigner{ expandedColumn2 === 'CoverDesigner' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-4 flex max-xl:text-left'  style={{ width: expandedColumn === 'Publisher' ? '20%' : '20%' }} onClick={() => toggleCollapse2('Publisher')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'Publisher'? 'favoriteColor ':''}`}>Verlage{ expandedColumn2 === 'Publisher' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
              </div> 
            </div> 
            <hr className='horizontalLine' />
            <div className="row flex mainRowBackgroundcolor2 paddingThree">
              <div className='col  sm:flex w-full'>
                {["Author", "Book", "Editor","Testreader", "CoverDesigner","Publisher"].map((categoryType) => (

                 <><><> {expandedColumn === categoryType && (expandedColumn === 'Author' || expandedColumn === 'Book' || expandedColumn === 'Editor') && (

                    <div className='col ml-5 w-1/2'>
                      <div key={categoryType} className='text-left'>
                        {getSingleEntityData.favoriteIds && getSingleEntityData.favoriteIds.map((favorite, index) => (
                          <div key={favorite.userProfileName}>
                            {favorite.userProfileType === categoryType && (
                              <><>{expandedColumn === categoryType && index <= 2 && (<h4 className='sm:hidden text-left ml-5 font-bold'>{expandedColumn === 'Author' ? 'Autoren:' : expandedColumn === 'Editor' ? 'Lektor:' : 'Bücher:'}</h4>)}
                              </><div className='flex '>
                                <div className='' style={{ minWidth: '200px', maxWidth: '250px' }}>
                                  <h4 className="FormStyling">
                                    <a
                                      href={`${baseUrl2}/profile?othProfileName=${favorite.userProfileName}&othProfileType=${favorite.userProfileType}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={() => changeOtherProfileName(favorite.userProfileName, favorite.userProfileType)}
                                    >
                                      {favorite.userProfileName}
                                    </a>
                                  </h4>
                                </div>
                              </div></>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  </><> {expandedColumn2 === categoryType && (expandedColumn2 === 'Testreader' || expandedColumn2 === 'CoverDesigner' || expandedColumn2 === 'Publisher') && (
                    <div className='col ml-5 sm:-ml-5 w-1/2'>
                      <div key={categoryType} className='text-left '>
                        {getSingleEntityData.favoriteIds && getSingleEntityData.favoriteIds.map((favorite, index) => (
                          <div key={favorite.userProfileName}>
                            {favorite.userProfileType === categoryType && (
                                <><> {expandedColumn2 === categoryType && index <= 4 && (<h4 className='sm:hidden text-left ml-5 font-bold'>{expandedColumn2 === 'Testreader' ? 'Testleser:' : expandedColumn2 === 'CoverDesigner' ? 'Coverdesigner:' : 'Verlage:'}</h4>)}</><div className='flex '>
                                <div className='' style={{ minWidth: '200px', maxWidth: '250px' }}>
                                  <h4 className="FormStyling">
                                    <a
                                      href={`${baseUrl2}/profile?othProfileName=${favorite.userProfileName}&othProfileType=${favorite.userProfileType}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={() => changeOtherProfileName(favorite.userProfileName, favorite.userProfileType)}
                                    >
                                      {favorite.userProfileName}
                                    </a>
                                  </h4>
                                </div>
                              </div></>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                    </></></>
     
                ))}
    
             </div>
            </div>
            <div className="row mainRowBackgroundcolor paddingOne"><br></br></div>
          </div>
        )}

        {getSingleEntityRating && (getSingleEntityRating.length > 0) && (
          <div className="row profileDataCard2  paddingOne mb-20">
            <div className="row row-col-3 max-md:flex  titleRowBackgroundcolor white-glassmorphism-card">
              <div className="col md:col-sm-9 col-sm-6 w-full text-left paddingTwo"><h2 className="FormStyling text-left titleTextColor">Ratings</h2></div>
              <div className="col md:col-sm-1  col-sm-4 w-full text-right md:text-left paddingZero">
                <h4 className="FormStyling sm:text-right">Gesamt: </h4>
              </div>
              <div className="col md:col-sm-2 col-sm-1 w-full text-left ml-4 paddingZero">
                <h2 className="FormStyling text-left max-sm:-ml-14 text-left paddingZero">{ratingScoreAverage}</h2>
              </div>
            </div>
            <div className="row titleRowBackgroundcolor2 max-md:flex white-glassmorphism-card">
              <div className="col col-sm-1 max-md:hidden text-left"><h3 className="topRowStyling">Wert</h3></div>
              <div className="col col-sm-3 max-md:hidden text-left"><h3 className="topRowStyling">Von</h3></div>
              <div className="col col-sm-8 max-md:hidden text-left"><h3 className="topRowStyling">Kommentar</h3></div>
            </div>
            <hr className='horizontalLine' />

      
            {getSingleEntityRating.map((rating, index) => (
              <div key={rating.createdByProfileName} className="row mainRowBackgroundcolor2 paddingThree white-glassmorphism-card">
                <div className="col col-sm-1 max-md:flex text-left">
                  <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Wert:</h3>
                  <h4 className="FormStyling max-md:pl-3">{rating.ratingScore}</h4>
                </div>
                <div className="col col-sm-3 max-md:flex text-left">
                  <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Von:</h3>
                  <h5 className="FormStyling max-md:pl-3">{rating.createdByProfileName}</h5>
                </div>
                <div className="col col-sm-8 text-left">
                  <div
                    style={{
                      maxWidth: '730px', // Set a fixed height as an example, adjust as needed
                      overflow: 'hidden',
                      cursor: 'pointer'
                    }}
                  >
                    <h3 className="topRowStyling md:hidden titleTextColor">Kommentar:</h3>
                    <h5
                      className="FormStyling"
                      onClick={() => toggleTextExpansion(rating.createdByProfileName)}
                    >
                      {expandedTexts[rating.createdByProfileName]
                        ? rating.ratingText // Show full text if expanded
                        : `${rating.ratingText.substring(0, 80)}...` // Show truncated text
                      }
                    </h5>
                  </div>
                  <hr style={{ borderColor: 'lightgray' }} className={`horizontalLine mt-5 mb-5 ${(index === 1 ||  getSingleEntityRating.length === index +1) ?'hidden':'sm:hidden'}`}/>
                </div>
              </div>
            ))}
            <div className="row mainRowBackgroundcolor paddingOne white-glassmorphism-card"><br></br></div>
          </div>
        )}
        
        </div> 
      )} 

        </div>        
    </div>  
    
    
  );
}

export default OthersProfileTestreaderView; 
export { default as OthersProfileTestreaderView } from './OthersProfileTestreaderView';