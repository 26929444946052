import './chat.css';
import {useNavigate} from "react-router-dom";
import { useEffect, useState } from 'react';

import { ImSpinner9 } from 'react-icons/im';

//Components
import { Chatbox } from '../components/chatbox/Chatbox';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';
import {LogoutRedirectCheck} from '../tools/LogoutRedirectCheck';


const Chat = ({baseUrl, baseUrl2}) => {

///////////////////////////////// Logout redirect //////////////////////////7

const navigate = useNavigate();

useEffect(() => {
  LogoutRedirectCheck(navigate);
}, [navigate]);

///////////////////////////////// Logout redirect //////////////////////////7


const [chatState, setChatState] = useState(true);

useEffect(() => {
  setChatState(true);
}, []);

  return (
      <div className="Chat z-40 skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>

      { chatState && (
          <div className='blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
            <div className='loadingBox white-glassmorphism-loadingBox text-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' style={{ minWidth: '250px', maxWidth: '350px', minHeight:'100px' }}>
                <div className="flex justify-content-center align-items-center w-full h-full">
                  <div className='mt-10 ml-40'>
                        <ImSpinner9
                            className="spinning-image"
                            size={50}
                            color="#a7b7c8"
                        />
                  </div>               
                </div>
            </div>
        </div>

         )}
          <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
                <div style={{flex: 1}}>
                    <Chatbox chatState={chatState} setChatState={setChatState} />
                </div>
                  <div className="mt-auto mb-20 ">
                      <Footer/>
                </div> 
          </div>
    </div>
  );
}

export default Chat; 