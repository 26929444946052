import './ProfileAddAll.css';

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import api from '../../api/axiosConfig';

//Components
import { ProfileAddAuthor } from './ProfileAddAuthor'
import  ProfileAddEditor from './ProfileAddEditor'
import { ProfileAddTestreader } from './ProfileAddTestreader'
import { ProfileAddCoverdesigner } from './ProfileAddCoverdesigner';

import { LogoutRedirectCheck } from '../../tools/LogoutRedirectCheck';


const ProfileAddAll = ({baseUrl}) => {


///////////////////////////////// Logout redirect //////////////////////////7

const navigate = useNavigate();

useEffect(() => {
  LogoutRedirectCheck(navigate);
}, [navigate]);

///////////////////////////////// Logout redirect //////////////////////////7

const ProfileTypes = {
AUTHOR: 'Author',
EDITOR: 'Editor',
TESTREADER: 'Testreader',
COVERDESIGNER: 'CoverDesigner',
};

const [profileType, setProfileType] = useState(null);
const [userData, setUserData] = useState(null);
const [getAvailableProfileTypes, setAvailableProfileTypes] = useState(null);
const [getIsConfirmed, setIsConfirmed] = useState(false);
const [getIsSameName, setIsSameName] = useState(false);

const setProfile = async (type) => {
setProfileType(type);
};

const backButton = async () => {
setProfileType(null);
};

const renderProfileComponent = () => {
switch (profileType) {
case ProfileTypes.AUTHOR:
 return <ProfileAddAuthor baseUrl={baseUrl} setIsConfirmed={setIsConfirmed} getIsConfirmed={getIsConfirmed} setIsSameName={setIsSameName}/>;
// Add cases for other profile types and import their respective components
case ProfileTypes.EDITOR:
 return <ProfileAddEditor baseUrl={baseUrl} setIsConfirmed={setIsConfirmed} getIsConfirmed={getIsConfirmed}  setIsSameName={setIsSameName}/>;
 break;
case ProfileTypes.TESTREADER:
 return <ProfileAddTestreader baseUrl={baseUrl} setIsConfirmed={setIsConfirmed} getIsConfirmed={getIsConfirmed}  setIsSameName={setIsSameName}/>;
 break;
case ProfileTypes.COVERDESIGNER:
 return <ProfileAddCoverdesigner baseUrl={baseUrl} setIsConfirmed={setIsConfirmed} getIsConfirmed={getIsConfirmed}  setIsSameName={setIsSameName}/>;
 break;
default:
 return null;
}
};

const fetchUserData = async () => {
  const response2 = await api.get(`/api/v1/auth/user/${localStorage.getItem('userEmail')}` );
    if(response2.data != null) {
        setUserData(response2.data);
        const userProfileTypes = response2.data.userProfilesIds.map(profile => profile.userProfileType);
        const allProfileTypes = ['Testreader', 'Editor', 'CoverDesigner', 'Author'];

        // Filter out profile types that are not in userData
        const availableProfileTypes = allProfileTypes.filter(userProfileType => !userProfileTypes.includes(userProfileType));
         setAvailableProfileTypes(availableProfileTypes);
    }
  };


    // List of all profile types


  useEffect(() => {
    setProfileType(null);
    fetchUserData();
    }, []);
    

return (
<div className="container ">

{!profileType && (
<div className="ProfileAddAll pb-8 flex justify-center items-center h-screen">
    
      <div className="max-w-2xl max-h-500 overflow-hidden">
        <div>
          <h2 className="mb-4 pb-10">Füge ein Profil hinzu</h2>
        </div>
        <div className="grid grid-cols-2 gap-8">


        { getAvailableProfileTypes && getAvailableProfileTypes.map((type, index, array) => (  
           
            <div className={`col bg-slate-200 ${index === array.length - 1  && (array.length % 2 === 1) ? 'col-span-2' : ''}`} key={type}>
              <div className="border p-4 rounded text-center">
                <h3
                  className={`px-4 py-2 rounded  text-black cursor-pointer`}
                  onClick={() => setProfile(type)}
                >
                  {type === 'Author'? 'Autor':''}
                  {type === 'Editor'? 'Lektor':''}
                  {type === 'Testreader'? 'Testleser':''}
                  {type === 'CoverDesigner'? 'Coverdesigner':''}
                </h3>
              </div>
            </div>
          
        ))}

        </div>
      </div>
   
  </div>
 )}

  <div className="ProfileAddAll pb-8">
        <div className="">
        {profileType && (
          <div className="">
            {!getIsConfirmed && !getIsSameName && (
              <>
            {/*<div><h2 className="mb-4">Füge ein Profil hinzu</h2></div>*/}
            <h5 className='my-10'>{profileType.charAt(0).toUpperCase() + profileType.slice(1)}</h5>
            <button
              className="px-4 py-2 bg-slate-600 text-white rounded mr-2"
              onClick={backButton}
            >
              Zurück
            </button>
            </>
            )}
            <div className="mb-36 "> 
              <div className="">
                  {renderProfileComponent()}
              </div>
            </div>
          </div>
        )}

        <div className="mb-4"></div>
        </div>
      </div>
 </div>
);

}

export default ProfileAddAll; 
export { default as ProfileAddAll } from './ProfileAddAll';