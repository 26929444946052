
import './ShareProfileBookView.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';
import {Link} from 'react-router-dom';

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';
import {ShortTimestampFormatter} from '../../tools/ShortTimestampFormatter.js';

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import he from 'he';
import DOMPurify from 'dompurify';

function IconWithTooltip({ icon, tooltipText }) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
        className="col col-sm-1 FormStyling text-left"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {React.cloneElement(icon, {
          style: { fontSize: '3rem' },
          title: isHovered ? tooltipText : '',
        })}
      </div>
    );
  }


  function DisplayHtml({ htmlContent }) {   
    //const escapedHtml = he.escape(htmlContent);  only html visible and not in formated way visible!
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    );
}  



const ShareProfileBookView = ({ getUserProfileType, getCurrentUserProfileName, bookState, setBookState, baseUrl }) => {


///////////////////////////////////////////////////////////////////////
    ////    Code for show Profile ...    //////////////////
    /////////////////////////////////////////////////////////////////////


    const [getSingleEntityData,setSingleEntityData] = useState([]);                                                                                      
    const [getSingleEntityRating,setSingleEntityRating] = useState([]); 
    
    const [imageSrc, setImageSrc] = useState([]);
    const [ratingText, setRatingText] = useState(''); 

    const [ratingScoreAverage, setRatingScoreAverage] = useState();
    
    const [expandedTexts, setExpandedTexts] = useState({});

    const [getTrueGenreData, setTrueGenreData] = useState([]);

    const [error, setError] = useState(null);

    
    const toggleTextExpansion = (createdByProfileName) => {
      setExpandedTexts((prevExpandedTexts) => ({
          ...prevExpandedTexts,
          [createdByProfileName]: !prevExpandedTexts[createdByProfileName],
      }));
  };

    const getSingleEntity = async () => {
        try {
            const addNewEntityData = {
              entityName: getCurrentUserProfileName,
            };
    
            const response = await api.get(`/api/bookProjects/share/${addNewEntityData.entityName}`);
    
            if(response.data != null){
            setSingleEntityData(response.data);
            const singleEntity = response.data;
            showSingleRatings(singleEntity.title);

	    setBookState(false);

      const filteredGenres = Object.keys(singleEntity.genresIds[0])
      .filter(key => key !== '_id' && singleEntity.genresIds[0][key] === true);

             const groupedData = filteredGenres.reduce((acc, item, index) => {
      const chunkIndex = Math.floor(index / 2);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = []; // start a new chunk
      }
      acc[chunkIndex].push(item);
      return acc;
    }, []);

    setTrueGenreData(groupedData);

          }
        } catch (error) {
            handleError('Error fetching single Entity data:', error);
        }
    };

    
    const showSingleRatings = async (entityName) => {
        try {
            const response = await api.get(`/api/ratings/createdFor/${entityName}`);
    
            setSingleEntityRating(response.data);
    
            const sumOfRatingScores = response.data.reduce((sum, item) => sum + item.ratingScore, 0);
            const averageRatingScore = sumOfRatingScores / response.data.length;
            const roundedAverageRatingScore = averageRatingScore.toFixed(1);
    
            if (response.data.length > 0) {
                setRatingScoreAverage(parseFloat(roundedAverageRatingScore));
            } else {
                setRatingScoreAverage('');
            }
        } catch (error) {
            handleError('Error showing single ratings:', error);
        }
    };
    

    const handleRatingTextChange = (event) => {
        setRatingText(event.target.value);
    };
    
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (getUserProfileType === 'Book') {
                    const entityData = getSingleEntity();
                    if(entityData != null){
                    setSingleEntityData(entityData);
                    console.log('Books:', entityData);
                    }
                }
            } catch (error) {
                handleError('Error adding rating:', error);
            }
        };
        fetchData();
        setImageSrc(`${baseUrl}/api/files/image/`);
    }, [getUserProfileType]);
    


      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };



        return (
          <div className="ShareProfileBookView">

            {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}
        
        
            <div className='container'>
              {getUserProfileType === "Book" && (
                <div className="">
                  <div className="col">
                    {getSingleEntityData && (
                      <>
                        <div className="row">
                        </div>
                        <div className="row profileDataCard2  paddingOne">
                          <div className="row titleRowBackgroundcolor white-glassmorphism-card ">
                          <div className="col col-sm-12  md:flex">
                            <div className="col col-sm-12 col-md-8">
                               <h2 className="FormStyling md:text-left  titleTextColor">{getCurrentUserProfileName}-Stammdaten</h2>
                            </div>
                            <div className="col col-sm-12 col-md-4">
                              <h4 className="FormStyling md:text-right pr-10 pt-3 titleTextColor">{getSingleEntityData.follower} Follower</h4>
                            </div>
                          </div>
                          </div>
                          <hr className='horizontalLine' />
        
                          <div className="row mainRowBackgroundcolor sm:flex white-glassmorphism-card pt-3 pb-3">
                            <div className='col col-sm-6'>
                              <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                                <div className='max-md:mb-20  max-lg:w-full' style={{ minWidth: 200 }}>
                                  <h3 className="FormStyling ">Titel</h3>
                                  <h5 className="FormStyling ">{getSingleEntityData.title ? getSingleEntityData.title : ' - '}</h5>
                                  <br></br>
                                  <h3 className="FormStyling ">Normseiten</h3>
                                  <h5 className="FormStyling ">{getSingleEntityData.pages ? getSingleEntityData.pages : ' - '}</h5>
                                </div>
                              </div>
                    <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                      <div className='max-sm:mb-10 max-lg:w-full' style={{ minWidth: 200 }}>
                        <h3 className="FormStyling">Untertitel</h3>
                        <h5 className="FormStyling">
                          {getSingleEntityData.subtitle ? getSingleEntityData.subtitle : ' - '}
                        </h5>
                        <br />
                        <h3 className="FormStyling">Genres</h3>
                        <div 
                          className='genre-container' 
                          style={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            justifyContent: 'flex-start',  /* Align left by default */
                            textAlign: 'left'               /* Left align text by default */
                          }}
                        >
                          {getTrueGenreData && getTrueGenreData.map((group, groupIndex) => (
                            <div 
                              key={groupIndex} 
                              className='genre-group' 
                              style={{ 
                                display: 'inline-block', 
                                margin: '0 0px' 
                              }}
                            >
                              {group.map((key, index) => (
                                <span key={index} className="FormStyling capitalize">
                                  {key}{index < group.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                              <br />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                            </div>
                            <div className="col col-sm-6 text-left ml-5 mainRowBackgroundcolor ">
                            <h3 className="FormStyling">Klappentext</h3> 
                            <DisplayHtml htmlContent={getSingleEntityData.jacketText} style={{ wordWrap: 'break-word' }} />
                          </div>
                          </div>
                        </div>
                      </>
                    )}
        
                    {((getSingleEntityData.tableOfContentText && getSingleEntityData.tableOfContentText.length > 0) || getSingleEntityData.coverImgName) && (
                      <div className="row profileDataCard2  paddingOne">
                        <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                          <h2 className="FormStyling text-left titleTextColor">Inhaltsverzeichnis</h2>
                        </div>
                        <hr className='horizontalLine' />
                        <div className="row mainRowBackgroundcolor white-glassmorphism-card">
                          <div className="col col-sm-7 text-left mainRowBackgroundcolor ml-20 mt-4" style={{ wordWrap: 'break-word' }}>
                              <DisplayHtml htmlContent={getSingleEntityData.tableOfContentText} />
                          </div>
                          <div className='col col-sm-4 mb-5'>
                            <div className='col-sm-6 flex text-center justify-center items-center mt-4'>
                              <div className="profileImg  my-3  ">
                                <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }}>
                                  <img className="img-fluid profileImg3" src={`${imageSrc}${getSingleEntityData.coverImgName}`} alt="Dein Coverbild"
                                    style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
        
                    {(getSingleEntityData.description && getSingleEntityData.description.length > 0) && (
                      <div className="row profileDataCard2  paddingOne">
                        <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                          <h2 className="FormStyling text-left titleTextColor">Genauere Beschreibung</h2>
                        </div>
                        <hr className='horizontalLine' />
                        <div className="row mainRowBackgroundcolor white-glassmorphism-card">
                          <div className="col col-sm-12 text-left ml-5 mainRowBackgroundcolor" style={{ wordWrap: 'break-word' }}>
                              <DisplayHtml htmlContent={getSingleEntityData.description} />
                          </div>
                      </div>
                  </div>
                    )}

                    
                    <div className="row profileDataCard2  paddingOne">
                        <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                          <h2 className="FormStyling text-left titleTextColor">Leseprobe</h2>
                        </div>
                        <div className="row white-glassmorphism-card py-2 text-left">
                        <div className="col col-sm-12 sm:flex  justify-between">
                            <div className="col-sm-2 max-sm:hidden"><h3 className="topRowStyling">Typ</h3></div>
                            <div className="col-sm-6  max-sm:hidden"><h3 className="topRowStyling">Titel</h3></div>
                            <div className="col-sm-4   max-sm:hidden"><h3 className="topRowStyling">Uploaded am</h3></div>
                         </div>
                        </div>
                        <hr className='horizontalLine' />
                        <div>
                        {(getSingleEntityData.bookFilesIds && getSingleEntityData.bookFilesIds.length > 0) && getSingleEntityData.bookFilesIds.map((bookFiles, index) => (
                            <div key={bookFiles} className={`row  white-glassmorphism-card ${(index === 1 || getSingleEntityData.bookFilesIds.length === 1 )?'mainRowBackgroundcolor':''}`}>
                              <div className="col col-sm-12 sm:flex text-left justify-between mainRowBackgroundcolor ">
                              { bookFiles.uploadedByProfileName === 'ReadingSample' && (
                            <>  <div className="col col-sm-2 flex"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Typ:</h3><h5 className="FormStyling">{bookFiles.uploadedByProfileName === 'ReadingSample'?'Leseprobe':'Buchdatei'}</h5></div>
                              <div className="col col-sm-6"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Titel:</h3><h5 className="FormStyling">{bookFiles.fileName}</h5></div>
                              <div className="col col-sm-4"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Uploaded am:</h3><h5 className="FormStyling"><ShortTimestampFormatter timestamp={bookFiles.filesUploadedAt} /></h5></div>   
                          </>  )}
                              </div>
                             
                            </div>
                          ))}
                          </div>
                    </div>
        
                    {getSingleEntityRating && (getSingleEntityRating.length > 0) && (
                      <div className="row profileDataCard2  paddingOne mb-20">
                        <div className="row row-col-3 max-md:flex  titleRowBackgroundcolor white-glassmorphism-card">
                          <div className="col md:col-sm-9 col-sm-6 w-full text-left paddingTwo">
                            <h2 className="FormStyling text-left titleTextColor">Ratings</h2>
                          </div>
                          <div className="col md:col-sm-1  col-sm-4 w-full text-right md:text-left paddingZero">
                            <h4 className="FormStyling text-right">Gesamt: </h4>
                          </div>
                          <div className="col md:col-sm-2 col-sm-1 w-full text-left  max-sm:-ml-10 ml-4 paddingZero">
                            <h2 className="FormStyling text-left paddingZero">{ratingScoreAverage}</h2>
                          </div>
                        </div>
                        <div className="row titleRowBackgroundcolor2 max-md:flex white-glassmorphism-card">
                          <div className="col col-sm-1 max-md:hidden text-left"><h3 className="topRowStyling">Wert</h3></div>
                          <div className="col col-sm-3 max-md:hidden text-left"><h3 className="topRowStyling">Von</h3></div>
                          <div className="col col-sm-8 max-md:hidden text-left"><h3 className="topRowStyling">Kommentar</h3></div>
                        </div>
                        <hr className='horizontalLine' />
        
                        {getSingleEntityRating.map((rating, index) => (
                          <div key={rating.createdByProfileName} className="row mainRowBackgroundcolor2 paddingThree white-glassmorphism-card">
                            <div className="col col-sm-1 max-md:flex text-left">
                              <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Wert:</h3>
                              <h4 className="FormStyling max-md:pl-3">{rating.ratingScore}</h4>
                            </div>
                            <div className="col col-sm-3 max-md:flex text-left">
                              <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Von:</h3>
                              <h5 className="FormStyling max-md:pl-3">{rating.createdByProfileName}</h5>
                            </div>
                            <div className="col col-sm-8 text-left">
                              <div
                                style={{
                                  maxWidth: '730px', // Set a fixed height as an example, adjust as needed
                                  overflow: 'hidden',
                                  cursor: 'pointer'
                                }}
                              >
                                <h3 className="topRowStyling md:hidden titleTextColor">Kommentar:</h3>
                                <h5
                                  className="FormStyling"
                                  onClick={() => toggleTextExpansion(rating.createdByProfileName)}
                                >
                                  {expandedTexts[rating.createdByProfileName]
                                    ? rating.ratingText // Show full text if expanded
                                    : `${rating.ratingText.substring(0, 80)}...` // Show truncated text
                                  }
                                </h5>
                              </div>
                              <hr style={{ borderColor: 'lightgray' }} className={`horizontalLine mt-5 mb-5 ${(index === 1 ||  getSingleEntityRating.length === index +1) ?'hidden':'sm:hidden'}`}/>
                           
                            </div>
                            
                          </div>
                        ))}
                        <div className="row mainRowBackgroundcolor paddingOne white-glassmorphism-card"><br></br></div>
                      </div>
                    )}
        
        
                  </div>
                </div>
              )}
        
            </div>
          </div>
        );
        
}

export default ShareProfileBookView; 
export { default as ShareProfileBookView } from './ShareProfileBookView';