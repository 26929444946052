import './addProfile.css';

import { useNavigate} from "react-router-dom";
import { useEffect } from 'react';


//Components
import {LogoutRedirectCheck} from '../tools/LogoutRedirectCheck';
import ProfileAddAll from '../components/addProfileComponents/ProfileAddAll.js';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';

const AddProfile = ({baseUrl}) => {

     ///////////////////////////////// Logout redirect //////////////////////////7

     const navigate = useNavigate();

      useEffect(() => {
        LogoutRedirectCheck(navigate);
      }, [navigate]);
   
   
   ///////////////////////////////// Logout redirect //////////////////////////7


  return (
      <div className="AddProfile skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
          <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
                  <div className="ProfileAddAuthor" style={{flex:1}}>
                    <ProfileAddAll baseUrl={baseUrl}/>
                  </div>
                  <div className="mt-auto mb-20 ">
                      <Footer/>
                </div>    
          </div>
    </div>
  );
}

export default AddProfile; 