
import './myProfile.css';
import { Link , useNavigate} from "react-router-dom";
import { useEffect, useState } from 'react';


//Components
import { MyProfileAllViews } from '../components/myUserProfileComponents/MyProfileAllViews';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';
import {LogoutRedirectCheck} from '../tools/LogoutRedirectCheck';


const MyProfile = ({baseUrl, baseUrl2}) => {

     ///////////////////////////////// Logout redirect //////////////////////////

     const navigate = useNavigate();

     useEffect(() => {
       LogoutRedirectCheck(navigate);
     }, [navigate]);
   
   ///////////////////////////////// Logout redirect //////////////////////////7


  return (
      <div className="myProfile skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
           <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
                <h2 className="rowSpace1">Mein Profil</h2>
                <div className="pb-20" style={{ flex: 1 }}>
                      <MyProfileAllViews baseUrl={baseUrl} baseUrl2={baseUrl2}/>         
                </div>    
                <div className="">
                      <Footer/>
                </div>
          </div>
    </div>
  );
}

export default MyProfile; 