import './confirmEmail.css';
import { Link } from "react-router-dom";

//Components
import { ConfirmEmailComponent } from '../components/loginRegistration/ConfirmEmailComponent';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';

const ConfirmEmail= ({baseUrl}) => {

  return (
      <div className="ConfirmEmail skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
        <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'>
              <div style={{flex: 1}}>
                  <ConfirmEmailComponent />
              </div>
              <div className="mt-auto mb-20 ">
                    <Footer/>
              </div> 
          </div>
    </div>
  );
}

export default ConfirmEmail; 